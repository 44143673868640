import axios from 'axios';

const BASIC_API_URL = '/odoo',
	STRIPE_API_URI = '/api/stripe/client_secret',
	PAIEMENT_API_URI = '/api/paiement',
	JUSTIFY_API_URI = '/api/paiement/justify',
	LIMIT = 0;

export const GetPaiementStripeMail = (
	tokenUserCurent,
	model,
	id,
	fncSuccess,
	fnError = () => {},
) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + PAIEMENT_API_URI}${
			'/' + model + '/' + id + '/?access_token=' + tokenUserCurent
		}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			
		},
	};
	console.log('Confirpaiementv', config);
	axios(config)
		.then(function (response) {
			fncSuccess(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const AcceptPaiementStripeMail = (
	tokenUserCurent,
	model,
	id,
	amount,
	fncSuccess,
	fnError = () => {},
) => {
	console.log('amount', amount);
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + STRIPE_API_URI}${
			'/' + model + '/' + id + '/?access_token=' + tokenUserCurent + '&amount=' + amount
		}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const paymentJustifyAPI = (payInfo, fnSucces, fnErr) => {
	const config = {
		method: 'post',
		url: `${BASIC_API_URL + JUSTIFY_API_URI}`,
		headers: {
			'Content-Type': 'application/json',
		},
		data: payInfo,
	};
	axios(config)
		.then(function (result) {
			fnSucces(result?.elements);
		})
		.catch(function (error) {
			console.log(error);
			fnErr(error);
		});
};
