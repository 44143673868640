import React, { useContext, useEffect, useState } from 'react';
import data from '../../common/data/dummyEventsData';
import AuthContext from '../../contexts/authContext';
import { updateSingleMateriel } from '../../odooApi/atelier';
import { createproduction, createProductionFromDevis } from '../../odooApi/fabrication';
import { getDevisLines, getDevisLinesById } from '../../odooApi/lineDevis';
import Button from '../bootstrap/Button';
import Card, {
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../bootstrap/Card';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import Modal, { ModalBody, ModalHeader } from '../bootstrap/Modal';
import Spinner from '../bootstrap/Spinner';
export default function FabricationPopup({
	setFabrication,
	simulateur,
	line,
	type,
	loadAteliersData,
	setBonFab,
}) {
	const [sousProduit, setSousProduit] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { userData } = useContext(AuthContext);
	const [error, setError] = useState(false);
	const [devisLines, setDevisLines] = useState([]);

	const handleSave = () => {
		setIsLoading(true);

		if (simulateur.length > 0) {
			const newLine = {
				order_id: simulateur[0]?.devis,
				product_id: simulateur[0]?.produit,
				name: devisLines[0]?.name,
			};
			let infos = {
				production: newLine,
				production_lines: sousProduit,
				largeur: simulateur[0]?.largeur || 0,
				hauteur: simulateur[0]?.hauteur || 0,
				//verrou: simulateur?.verrou || 0,
			};
			createProductionFromDevis(
				userData?.password,
				infos,
				(data) => {
					const newMat = {
						id: line?.id,
						type_produit: type,
						production: data[0],
						state: 'en cours',
					};
					updateSingleMateriel(userData?.password, newMat, () => {
						setBonFab(data[0]);
						setIsLoading(false);
						setFabrication(false);
						loadAteliersData();
					});
				},
				(error) => {
					setError(error);
				},
			);
		} else {
			console.log('fabrication devis', line?.devis?.id);
			const newprod = {
				sale_order_id: line?.devis?.id,
				origin: line?.name,
				partner_invoice_id: line?.devis?.partner_id?.id,
				product_id: line?.product,
			};
			createproduction(userData?.password, newprod, (data) => {
				console.log(data);
				const newMat = {
					id: line?.id,
					type_produit: type,
					production: data?.id,
					state: 'en cours',
				};
				updateSingleMateriel(userData?.password, newMat, () => {
					setBonFab(data?.id);
					setIsLoading(false);
					setFabrication(false);
					loadAteliersData();
				});
			});
		}
	};

	//console.log('devisLines', devisLines[0]?.name);
	useEffect(() => {
		getDevisLines(
			userData?.password,
			simulateur[0]?.devis_line,
			({ elements }) => {
				setDevisLines(elements);
			},
			(error) => {
				console.log('erreur', error);
			},
		);
		if (!!simulateur?.length) {
			var total = [];
			simulateur?.map((sim) => {
				!!sim?.products?.length &&
					sim?.products?.map((item) => {
						total.push(item?.id);
						console.log(item?.id);
					});
			});
			setSousProduit(total);
		}
	}, [simulateur]);

	return (
		<Modal setIsOpen={() => {}} isOpen={true} size={'lg'} classNameMd='nv-2'>
			<ModalHeader
				setIsOpen={() => {
					setFabrication(false);
				}}></ModalHeader>
			<ModalBody className='px-2'>
				<Card shadow={'none'}>
					<CardHeader>
						<CardLabel icon='Article' iconColor='primary'>
							<CardTitle>{line?.name}</CardTitle>
							<CardSubTitle>{line?.state}</CardSubTitle>
						</CardLabel>
					</CardHeader>
					<CardBody>
						<div className='row g-3'>
							{simulateur?.length > 0 && (
								<table className='table table-modern table-hover'>
									<thead>
										<tr>
											<th></th>
											<th>Sous produits</th>
											<th>LARGEUR</th>
											<th>HAUTEUR</th>
										</tr>
									</thead>

									<tbody>
										{simulateur?.map((prod, idx) => {
											return (
												<tr>
													<th scope='row'>{idx + 1}</th>
													<td>
														<ul>
															{prod?.products?.map((product) => {
																return <li>{product.name}</li>;
															})}
														</ul>
													</td>

													<td>{prod?.largeur}</td>
													<td>{prod?.hauteur}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							)}
						</div>
					</CardBody>
					<CardFooter>
						<CardFooterRight>
							<Button color='primary' disabled={isLoading} onClick={handleSave}>
								{isLoading && <Spinner isSmall inButton />}
								{isLoading ? 'En cours' : 'Sauvegarder'}
							</Button>
						</CardFooterRight>
					</CardFooter>
				</Card>
			</ModalBody>
		</Modal>
	);
}
