import axios from 'axios';

const WebSocketSession = (fn) => {
	const WEB_SOCKET_SESSION_URL = process.env.REACT_APP_REMINDER_WEB_SOCKET_SESSION_URL;

	const tokenList = [
		'17915b8c-6ab2-4d26-b437-1b5a6f92d9ca',
		'c95c9b95-a489-4fc1-a937-4341eb33d78c',
		'7b70d623-327a-48bb-b7c1-aa5d72d2d995',
		'946718b8-6297-46b9-b8e6-7484927f56a3',
		'21dcfce1-1df1-40c1-8156-2a31279ace93', // DIGITAL MARKET LINE
	];

	const getLocalStorage = (key) => {
		const itemStr = localStorage.getItem(key);

		const item = JSON.parse(itemStr);
		const now = new Date();
		if (item == null || now.getTime() > item.expiry) {
			localStorage.removeItem(key);
			return null;
		}
		return item.value;
	};

	const setLocalStorage = (key, value) => {
		const now = new Date();
		const item = {
			value: value,
			expiry: now.getTime() + 24 * 60 * 60 * 1000, // 24 hours from now
		};
		localStorage.setItem(key, JSON.stringify(item));
	};

	var config = {
		method: 'post',
		url: WEB_SOCKET_SESSION_URL,
		headers: {},
	};

	const sessionId = getLocalStorage('OvhsessionId');
	if (sessionId === null) {
		axios(config)
			.then(function (response) {
				tokenList.map((token) => {
					axios({
						method: 'post',
						url: `${WEB_SOCKET_SESSION_URL}/${response.data.id}/subscribe/${token}`,
					})
						.then(function (response) {
							console.log(JSON.stringify(response.data));
						})
						.catch(function (error) {
							console.log(error);
						});
				});
				setLocalStorage('OvhsessionId', response.data.id);
				fn(response.data.id);
				console.log(response.data.id);
			})
			.catch(function (error) {
				console.log(error);
			});
	} else {
		fn(sessionId);
	}
};

export default WebSocketSession;
