import axios from 'axios';

const 
  API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
  SEARCH_METHOD_API=process.env.REACT_APP_SEARCH_METHOD_API,
  JOURNAL_API_URI="/account.journal",
  LIMIT=0
;

export const searchJournal = (tokenUserCurent,filters,fnSuccess,page=1,limit=LIMIT) => {
    var dataFilter = JSON.stringify({
        "kwargs": {
            "domain": filters,
            "limit":limit,
            "page":page
        }
      });
      
      var config = {
        method: 'patch',
        url: `${"/odoo"+API_URL_WEB_ADMIN+JOURNAL_API_URI+SEARCH_METHOD_API}`,
        headers: { 
          'Authorization': `Basic ${btoa(process.env.REACT_APP_AUTH_OPEN_API_DB+":"+tokenUserCurent)}`, 
          'Content-Type': 'application/json',
          
        },
        data : dataFilter
      };
      
      axios(config)
      .then(function (response) {
        fnSuccess(response?.data);
        
      })
      .catch(function (error) {
        console.log(error);
      });
  
     
  }