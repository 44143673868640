import * as React from 'react';

const SvgCustomCarMap = (props) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		height='1em'
		viewBox='-10 -10 65 70'
		width='1em'
		className='svg-icon'
		fill='currentColor'
		{...props}>
		<path
			d='m245.18,135.49c.13,0,.2-.04.22-.05.3-.22.32-.37.32-.53l-.03-20.31c-.02-16.02-12.53-29.33-28.49-30.31-4.48-.27-8.99-1.05-13.8-2.39l-.32-.09c-7.98-2.21-16.41-8.06-21.47-14.9-5.75-7.43-26.64-31.79-52.8-31.79-1.56,0-3.14.09-4.67.27-19.24,2.28-35.06,14.81-48.38,26.38-14.78,12.84-29.86,26.92-37.58,45.37-3.11,7.43-5.8,21.43,3.85,26.79,1.5.83,3.18,1.36,5.03,1.58,7.02-12.77,20.27-20.65,34.9-20.65s27.84,7.85,34.86,20.57c7.79.02,15.59.04,23.38.04h35.23c7.03-12.75,20.28-20.61,34.88-20.61s27.85,7.86,34.88,20.61Z'
		/>
		<path
			d='m210.3,169.53c6.94,0,12.87-4.72,14.43-11.49.27-1.17.4-2.26.4-3.33,0-8.18-6.65-14.83-14.83-14.83s-14.83,6.65-14.83,14.83c0,1.08.13,2.19.39,3.31,1.54,6.67,7.61,11.51,14.44,11.51Z'
		/>
		<path opacity={0.3}
			d='m81.95,169.53c6.68,0,12.56-4.51,14.3-10.97.35-1.3.53-2.59.53-3.85,0-8.18-6.65-14.83-14.83-14.83s-14.82,6.65-14.82,14.83c0,1.21.17,2.47.5,3.75,1.71,6.51,7.6,11.07,14.32,11.07Z'
		/>

		<path opacity={0.3}
			d='m210.3,184.03c-14.74,0-26.94-10.55-29.02-25.08l-1.29-9s-17.05-.02-21.02-.02c-9.77,0-18.29.02-26.03.06-4.34.02-8.58.08-11.79.14l-8.9.15-1.31,8.8c-2.15,14.45-14.34,24.94-29,24.94s-27.66-11.33-29.17-26.36l-.75-7.54-7.39-1.66c-8.17-1.83-13.83-5.02-17.83-10.02-3.04-3.8-4.97-8.76-5.58-14.37-3.36-30.86,31.57-61.21,52.43-79.34,12.46-10.83,32.02-23.73,54.49-23.73,3.06,0,6.13.25,9.12.74,21.08,3.49,41.56,16.91,56.19,36.84,2.98,4.03,8.64,7.92,13.48,9.26l.4.1c3.76,1.04,7.27,1.66,10.73,1.87,23.58,1.45,42.08,21.1,42.11,44.76l.03,20.31c0,7.3-5.18,13.54-12.34,14.85l-7.51,1.37-1.01,7.57c-1.96,14.69-14.18,25.35-29.06,25.35Zm0-54.65c-13.97,0-25.33,11.36-25.33,25.33,0,1.88.22,3.78.66,5.67,2.62,11.38,13,19.65,24.67,19.65s22-8.07,24.66-19.63c.45-1.96.67-3.82.67-5.69,0-13.97-11.36-25.33-25.33-25.33Zm-128.35,0c-13.96,0-25.32,11.36-25.32,25.33,0,2.09.28,4.24.83,6.37,2.94,11.18,13.01,18.95,24.49,18.95s21.46-7.71,24.44-18.74c.59-2.18.89-4.4.89-6.58,0-13.97-11.36-25.33-25.33-25.33Zm46.86-104.75c-1.98,0-3.96.12-5.91.35-22.21,2.63-39.54,16.29-54.04,28.88-15.72,13.66-31.78,28.71-40.38,49.25-5.97,14.27-5.68,32.19,8.44,40.02,2.69,1.5,5.69,2.45,8.92,2.82l7.01.81,3.4-6.19c5.15-9.38,15-15.2,25.69-15.2s20.51,5.8,25.67,15.15l2.99,5.41h6.18c7.83.03,15.62.05,23.4.05h41.43l2.99-5.43c5.16-9.36,15-15.18,25.69-15.18s20.52,5.82,25.69,15.18l3.2,5.81,6.62-.39c2.01-.12,3.93-.77,5.54-1.88l.19-.13.18-.14c2.86-2.16,4.5-5.41,4.5-8.92l-.03-20.32c-.02-21.55-16.87-39.46-38.35-40.77-3.74-.22-7.54-.89-11.63-2.02l-.37-.1c-5.64-1.56-12.15-6.1-15.8-11.02l-.13-.18c-6.51-8.41-30.17-35.86-61.1-35.86Z'
		/>
		<path opacity={0.3}
			d='m128.15,10.5c-25.72,0-47.57,14.31-61.38,26.31C44.36,56.29,6.84,88.89,10.79,125.21c.83,7.6,3.53,14.45,7.82,19.79,5.49,6.86,13.26,11.36,23.73,13.71,2.02,20.2,19.13,35.81,39.61,35.81s36.5-14.45,39.39-33.9c3.75-.06,7.71-.12,11.66-.14,7.83-.04,16.33-.06,25.97-.06,3.97,0,7.94,0,11.92,0,2.8,19.56,19.43,34.09,39.41,34.09s36.82-14.71,39.46-34.46c12.06-2.2,20.97-12.76,20.96-25.19l-.03-20.31c-.04-29.18-22.87-53.44-51.97-55.22-2.75-.17-5.58-.67-8.7-1.54l-.27-.07c-2.59-.72-6.25-3.22-7.82-5.36-16.24-22.12-39.19-37.06-62.95-40.99-3.55-.59-7.19-.88-10.83-.88h0Zm-81.1,125.02c-1.85-.21-3.54-.74-5.03-1.58-9.65-5.35-6.96-19.36-3.85-26.79,7.72-18.45,22.8-32.53,37.58-45.37,13.32-11.57,29.14-24.1,48.38-26.38,1.54-.18,3.11-.27,4.67-.27,26.16,0,47.04,24.36,52.8,31.79,5.06,6.84,13.49,12.69,21.47,14.9l.32.09c4.81,1.34,9.33,2.12,13.8,2.39,15.96.98,28.47,14.29,28.49,30.31l.03,20.31c0,.16-.02.3-.32.53-.02.01-.09.04-.22.05-7.03-12.74-20.28-20.61-34.88-20.61s-27.85,7.86-34.88,20.61h-35.23c-7.79,0-15.59-.02-23.38-.04-7.03-12.72-20.27-20.57-34.86-20.57s-27.88,7.88-34.9,20.65h0Zm163.25,34c-6.83,0-12.91-4.84-14.44-11.51-.26-1.12-.39-2.23-.39-3.31,0-8.18,6.65-14.83,14.83-14.83s14.83,6.65,14.83,14.83c0,1.07-.13,2.16-.4,3.33-1.56,6.76-7.49,11.49-14.43,11.49h0Zm-128.35,0c-6.72,0-12.61-4.56-14.32-11.07-.33-1.28-.5-2.54-.5-3.75,0-8.18,6.65-14.83,14.82-14.83s14.83,6.65,14.83,14.83c0,1.25-.18,2.55-.53,3.85-1.74,6.46-7.62,10.97-14.3,10.97h0Z'
		/>
	</svg>
);
export default SvgCustomCarMap;
