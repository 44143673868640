import formInputsTypes from '../simulatorConstant';
import infoMesure from './infoMesure';

const tablierRideauMetalliqueData = {
	id: 'TABLIERRMData',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Type de lames',
	name: 'TABLIERRMData',
	className: 'col-6',
	classGroup: 'SCategory',
	nextStepData: infoMesure,
	nextStep: false,
	options: [
		{
			id: 'Lames_cobra',
			label: 'Lames cobra',
			value: 'lames cobra',
			nextStep: infoMesure,
		},
		{
			id: 'Lames_pleines',
			label: 'Lames pleines',
			value: 'lames pleines',
			nextStep: infoMesure,
		},
		{
			id: 'Lames_polycarbonate',
			label: 'Lames polycarbonate',
			value: 'lames polycarbonate',
			nextStep: infoMesure,
		},
		{
			id: 'Lames_micro_perforees',
			label: 'Lames micro-perforées',
			value: 'lames micro perforees',
			nextStep: infoMesure,
		},
	],
};

export default tablierRideauMetalliqueData;
