import formInputsTypes from '../../simulatorConstant';
export const CATEG_ID_MOTEUR_RIDEAU = 36;
import {
	AxeManuelRM,
	bobineRM,
	EnroulementRM,
	MesureHauteurRM,
	MesureLargeurRM,
	platBombeRM,
	PoseRM,
} from '../data';

const ButeeRMData = {
	id: 'ButeeRM',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'ButeeRM',
	className: 'col-6 d-none',
	label: 'Butées',
	nextStepData: false,
	nextStep: false,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateDescription = (data) => {
			return `axe de rideau métallique manuel \n \n Axe de rideau métallique avec système de compensation par ressorts \n \n ${
				currentFormValue?.AxeMRMData
					? '\n - Axe diamètre ' + currentFormValue?.AxeMRMData?.value
					: ''
			}${currentFormValue?.PoseRM ? '\n - ' + currentFormValue?.PoseRM?.value : ''} ${
				currentFormValue?.Enroulement
					? '\n - Enroulement ' + currentFormValue?.Enroulement?.value
					: ''
			}${
				currentFormValue?.NbAgrafesRM
					? '\n - ' + currentFormValue?.NbAgrafesRM + ' agrafes'
					: ''
			}${currentFormValue?.platBombe ? '\n - ' + currentFormValue?.platBombe?.value : ''} ${
				currentFormValue?.bobine
					? '\n - ' + currentFormValue?.bobine?.value + ' bobines'
					: ''
			}${
				currentFormValue?.largeurRM
					? '\n - Largeur: ' +
					  currentFormValue?.largeurRM +
					  'mm (' +
					  currentFormValue?.MesureLargeur?.value +
					  ')'
					: ''
			} ${
				currentFormValue?.hauteurRM
					? ' x Hauteur: ' +
					  currentFormValue?.hauteurRM +
					  'mm (' +
					  currentFormValue?.MesureHauteur?.value +
					  ')'
					: ''
			}`;
		};

		const options = [
			{
				id: 'RMSBUTEE',
				label: 'Sans butées',
				value: 'sans butées',
				product_id: currentFormValue?.RMData?.id,
				description: generateDescription('RMSBUTEE'),
				checked: true,
				nextStep: false,
			},
			{
				id: 'RMABUTEE',
				label: 'Avec butées',
				value: 'avec butées',
				product_id: currentFormValue?.RMData?.id,
				description: generateDescription('RMABUTEE'),
				nextStep: false,
			},
		];

		fnSuccess(options);
	},
};

const NbAgrafesRMData = {
	id: 'NbAgrafesRM',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'NbAgrafesRM',
	className: 'col-6',
	label: "Nombre d'agrafes",
	nextStepData: ButeeRMData,
	nextStep: false,
};

const EnroulementData = {
	id: 'RMENROULEMENT',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'Enroulement',
	className: 'col-6',
	label: 'Enroulement',
	nextStepData: NbAgrafesRMData,
	nextStep: false,
	options: EnroulementRM,
};

const MesureHauteurRMData = {
	id: 'RMH',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'MesureHauteur',
	className: 'col-6',
	label: 'Mesure selon hauteur',
	nextStepData: EnroulementData,
	nextStep: false,
	options: MesureHauteurRM,
};

const hauteurRM = {
	id: 'HRM',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'hauteurRM',
	className: 'col-6',
	label: "Hauteur de l'axe (mm)",
	nextStepData: MesureHauteurRMData,
	nextStep: false,
	validation: (value) => {
		return value > 0;
	},
};

const MesureLargeurRMData = {
	id: 'RM-TML',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'MesureLargeur',
	className: 'col-6',
	label: 'Mesure selon largeur',
	nextStepData: hauteurRM,
	nextStep: false,
	options: MesureLargeurRM,
};

const largeurRM = {
	id: 'LRM',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'largeurRM',
	className: 'col-6',
	label: "Largeur de l'axe (mm)",
	nextStepData: MesureLargeurRMData,
	nextStep: false,
	validation: (value) => {
		return value > 0;
	},
};

const PoseRMData = {
	id: 'PoseRM',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Pose',
	name: 'PoseRM',
	className: 'col-6',
	nextStepData: largeurRM,
	nextStep: false,
	options: PoseRM,
};

const bobine = {
	id: 'bobine',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Bobine',
	name: 'bobine',
	className: 'col-6',
	nextStepData: PoseRMData,
	nextStep: false,
	options: bobineRM,
};

const platBombe = {
	id: 'platBombe',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Plat Bombé',
	name: 'platBombe',
	className: 'col-6',
	nextStepData: bobine,
	nextStep: false,
	options: platBombeRM,
};
const AxeManuelData = {
	id: 'AxeMRMData',
	type: formInputsTypes?.SELECT_INPUT,
	label: "Type d'axe",
	name: 'AxeMRMData',
	className: 'col-6',
	nextStepData: platBombe,
	nextStep: false,
	options: AxeManuelRM,
};

export default AxeManuelData;
