export const prix_Tablier41 = [
	{ id: 1000, price: 59.64 },
	{ id: 1500, price: 89.44 },
	{ id: 2000, price: 119.28 },
	{ id: 2500, price: 149.08 },
	{ id: 3000, price: 178.92 },
	{ id: 3500, price: 208.78 },
	{ id: 4000, price: 238.56 },
	{ id: 4500, price: 268.38 },
	{ id: 5000, price: 298.2 },
	{ id: 5500, price: 328 },
	{ id: 6000, price: 357.84 },
	{ id: 6500, price: 387.64 },
	{ id: 7000, price: 417.48 },
	{ id: 7500, price: 447.28 },
	{ id: 8000, price: 477.12 },
];
export const prix_Tablier55 = [
	{ id: 1000, price: 70.8 },
	{ id: 1500, price: 106.2 },
	{ id: 2000, price: 150.76 },
	{ id: 2500, price: 177 },
	{ id: 3000, price: 212.4 },
	{ id: 3500, price: 247.8 },
	{ id: 4000, price: 283.6 },
	{ id: 4500, price: 318.6 },
	{ id: 5000, price: 354 },
	{ id: 5500, price: 389.4 },
	{ id: 6000, price: 424.8 },
	{ id: 6500, price: 460.2 },
	{ id: 7000, price: 495.6 },
	{ id: 7500, price: 531 },
	{ id: 8000, price: 566.4 },
];
