import axios from 'axios';

const BASIC_API_URL = '/odoo',
    API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
    SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
    MAIL_MESSAGE_API_URL = '/mail.message',
    LIMIT = 20;


export const searchMailMessage = (
    tokenUserCurent,
    filters,
    fnSuccess,
    fnError = () => { },
    page = 1,
    limit = LIMIT,
) => {
    var dataFilter = JSON.stringify({
        kwargs: {
            domain: filters,
            limit: limit,
            page: page,
        },
    });

    var config = {
        method: 'patch',
        url: `${BASIC_API_URL + API_URL_WEB_ADMIN + MAIL_MESSAGE_API_URL + SEARCH_METHOD_API}`,
        headers: {
            Authorization: `Basic ${btoa(
                process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
            )}`,
            'Content-Type': 'application/json',
        },
        data: dataFilter,
    };
    axios(config)
        .then(function (response) {
            fnSuccess(response?.data || []);
        })
        .catch(function (error) {
            fnError(error);
        });
};



export const createMailMessage = (tokenUserCurent, data, fnSuccess, fnError = () => { }) => {
    var config = {
        method: 'post',
        url: `${BASIC_API_URL + API_URL_WEB_ADMIN + MAIL_MESSAGE_API_URL}`,
        headers: {
            Authorization: `Basic ${btoa(
                process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
            )}`,
            'Content-Type': 'application/json',
        },
        data: JSON.stringify(data),
    };
    axios(config)
        .then(function (response) {
            fnSuccess(response?.data || []);
        })
        .catch(function (error) {
            fnError(error);
        });
};



export const updateMailMessage = (tokenUserCurent, message, fnSucces, fnError = () => { }) => {
    const messageLineString = JSON.stringify(message);

    const config = {
        method: 'put',
        url: `${BASIC_API_URL + API_URL_WEB_ADMIN + MAIL_MESSAGE_API_URL + '/' + message?.id}`,
        headers: {
            Authorization: `Basic ${btoa(
                process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
            )}`,
            'Content-Type': 'application/json',
        },
        data: messageLineString,
    };

    axios(config)
        .then(function () {
            fnSucces();
        })
        .catch(function (error) {
            fnError(error);
        });
};




export const deleteMailMessage = (tokenUserCurent, idMessage, fnSucces, fnError = () => { }) => {
    const config = {
        method: 'delete',
        url: `${BASIC_API_URL + API_URL_WEB_ADMIN + MAIL_MESSAGE_API_URL + '/' + idMessage}`,
        headers: {
            Authorization: `Basic ${btoa(
                process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
            )}`,
            'Content-Type': 'application/json',
        },
    };

    axios(config)
        .then(function () {
            fnSucces();
        })
        .catch(function (error) {
            fnError(error);
        });
};