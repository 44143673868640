import React, { useContext } from 'react';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import store2000 from '../../../../assets/img/store2000.png';
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Icon from '../../../../components/icon/Icon';
import ClientMontantContext from '../../../../contexts/clientMontantContext';
import Alert from '../../../../components/bootstrap/Alert';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Button from '../../../../components/bootstrap/Button';
import Popovers from '../../../../components/bootstrap/Popovers';
import html2pdf from 'html2pdf.js';
import Image from '../../../../assets/img/wanna/richie/richie6.png';
import { ClientContextProvider } from '../../../../contexts/clientContext';
import moment from 'moment';
import HeaderPage from './HeaderPage';
const Confirmation = () => {
	const { clientMontant, setClientMontant } = useContext(ClientMontantContext);
	console.log('clientMontant', clientMontant);
	const handleDownloadPDF = () => {
		const element = document.getElementById('pdf-content');
		const options = {
			margin: 0.5,
			filename: 'recu.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
		};

		html2pdf().set(options).from(element).save();
	};
	console.log("clientMontant",clientMontant)

	return (
		<PageWrapper isProtected={false}>
			<HeaderPage />
			<Page>
				<div className='row d-flex align-items-center'>
					<div className='row  justify-content-center'>
						<div className='col-lg-4'>
							<Card stretch>
								<CardBody>
									<div className='row pt-5 g-4 text-center'>
										<div className='col-12'>
											<img
												srcSet={Image}
												src={Image}
												alt='Droit'
												width={'100%'}
											/>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
						<div className='col-lg-5'>
							<Card stretch>
								<CardHeader>
									<Alert
										isLight
										className='text-center border-0 flex-nowrap'
										shadow='md'
										icon='LocalPolice'>
										Nous avons le plaisir de vous informer que votre paiement a
										été reçu avec succès pour la {clientMontant?.client?.name}
									</Alert>
								</CardHeader>
								<CardBody id='pdf-content'>
								
									<div className='row g-3 text-center'>
										<div className='text-muted'>Nom & prénom</div>
										<div className='fw-bold fs-6 mb-0'>{clientMontant?.client?.customer}</div>
										<div className='text-muted'>Montant</div>
										<div className='fw-bold fs-6 mb-0'>
											{
											clientMontant?.client?.amount_total?.toLocaleString('fr-FR', {
												style: 'currency',
												currency: 'EUR',
											})
										}
										</div>

										<div className='text-muted'>Date de paiement</div>
										<div className='fw-bold fs-6 mb-0'>{moment(new Date()).format('DD-MM-YYYY')}</div>

										<div className='text-muted'>Méthode de paiement</div>
										<div className='fw-bold fs-6 mb-0'>{clientMontant?.paiement}</div>
									</div>
									{/* <div className='row g-3'>
									<div className='col-lg-12'>
										<span>
											Si vous avez des questions ou des besoins particuliers,
											n'hésitez pas à nous contacter !
										</span>
									</div>
									<div className='col-lg-12'>
										<span>
											Nous vous remercions pour la confiance que vous nous
											accordez, et nous sommes impatients de travailler avec
											vous.
										</span>
									</div>
								</div> */}
								</CardBody>
								<CardFooter>
									<CardFooterRight>
										<Popovers desc={'Télécharger'} trigger='hover'>
											<Button
												icon={'PictureAsPdf'}
												color='danger'
												isLight
												onClick={handleDownloadPDF}>
												Télécharger le reçu
											</Button>
										</Popovers>
									</CardFooterRight>
								</CardFooter>
							</Card>
						</div>
					</div>
					<div className='col-12 d-flex align-items-baseline justify-content-center'></div>
				</div>
			</Page>
			
		</PageWrapper>
	);
};

export default Confirmation;
