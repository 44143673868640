import React, { useContext, useEffect, useState } from 'react';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import AuthContext from '../../contexts/authContext';
import PopUpUpdateAndCreateLineDevis from './PopUpUpdateAndCreateLineDevis';
import PropTypes from 'prop-types';
import { searchAccountTax } from '../../odooApi/accountTax';
import { getAccountMoveLinesById } from '../../odooApi/accountMoveLine';
import FactureLinesContext from '../../contexts/factureLineContext';
import { imageProduct } from '../../odooApi/product';
import FormGroup from '../bootstrap/forms/FormGroup';
import Select from '../bootstrap/forms/Select';

const DevisTableRow = ({ data, tvaValue, subTotal, isDisabled, loadData }) => {
	const { darkModeStatus } = useDarkMode();
	const [editModalLineStatus, setEditModalLineStatus] = useState(false);
	const { userData } = useContext(AuthContext);
	return (
		<>
			<tr>
				{!isDisabled && (
					<>
						<th onClick={() => setEditModalLineStatus(true)}>{data?.id}</th>{' '}
						<td>
							<div>
								{data?.display_name}

								<div className='text-muted'>
									<small>{data?.category}</small>
								</div>
							</div>
						</td>
					</>
				)}

				<td>
					<span className='truncate-line-3 devis-desc'>{data?.name}</span>
				</td>
				<td>
					<span>
						{data?.price_unit?.toLocaleString('fr-FR', {
							style: 'currency',
							currency: 'EUR',
						})}
					</span>
				</td>
				<td>
					<span>{data?.quantity}</span>
				</td>
				{!isDisabled && (
					<td>
						<span>{data?.discount}</span>
					</td>
				)}

				<td>
					<span>{tvaValue}</span>
				</td>
				<td>
					<span>
						{
							// @ts-ignore
							subTotal?.toLocaleString('fr-FR', {
								style: 'currency',
								currency: 'EUR',
							})
						}
					</span>
				</td>
			</tr>
			{editModalLineStatus && data?.id != 'Total' && (
				<PopUpUpdateAndCreateLineDevis
					data={data}
					setEditModalLineStatus={setEditModalLineStatus}
				/>
			)}
		</>
	);
};

const DevisTable = ({
	totalPriceRemise,
	settotalPriceRemise,
	total,
	setTotal,
	totalUntaxed,
	setTotalUntaxed,
	remise,
	setRemise,
	cardId,
	card,
	isDisabled,
	acompte,
}) => {
	const { userData } = useContext(AuthContext);
	const { factureLines, setFactureLines } = useContext(FactureLinesContext);
	const [editModalLineStatus, setEditModalLineStatus] = useState(false);
	const [taxs, setTaxs] = useState([]);
	const remiseList = [0, 5, 7, 10, 12, 15, 17, 20, 22, 25];
	useEffect(() => {
		let t = 0;
		let untax = 0;
		factureLines.map((line) => {
			const tvaValue = !!taxs.length
				? taxs?.filter((tax) => tax.id == line.tax_ids[0])[0]?.amount
				: 0;
			const subTotal = (line?.price_subtotal / 100) * (tvaValue + 100);
			const amount_untaxed = (line?.price_subtotal / 100) * 100;
			t += subTotal;
			untax += amount_untaxed;
		});
		if (!!remise) {
			settotalPriceRemise((untax / 100) * remise);
			setTotalUntaxed(untax - (untax / 100) * remise);
			setTotal(t - (untax / 100) * remise);
		} else {
			setTotal(t);
			setTotalUntaxed(untax);
		}

		//settotalPriceRemise(t - (t / 100) * remise);
	}, [remise, factureLines]);

	// useEffect(() => {
	// 	settotalPriceRemise((totalUntaxed / 100) * remise);
	// 	setTotalUntaxed(totalUntaxed - (totalUntaxed / 100) * remise);
	// 	setTotal(total - (totalUntaxed / 100) * remise);
	// }, [total, remise]);

	useEffect(() => {
		if (userData && userData?.id && userData?.password) {
			getAccountMoveLinesById(userData?.password, cardId, ({ elements }) => {
				//console.log("setFactureLinessetFactureLines",result);
				setFactureLines(elements);
			});
			searchAccountTax(
				userData?.password,
				['&', ['type_tax_use', '=', 'sale'], ['active', '=', true]],
				(taxsData) => {
					setTaxs(taxsData?.elements);
				},
			);
		}
	}, [userData, cardId, setFactureLines]);
	var totalPrice = 0;
	return (
		<>
			<Card>
				<CardHeader>
					<CardLabel icon='Storefront' iconColor='primary'>
						<CardTitle tag='h4' className='h5'>
							Lignes
						</CardTitle>
						{!!factureLines?.length && (
							<CardSubTitle>{factureLines?.length} ligne(s)</CardSubTitle>
						)}
					</CardLabel>
					{!isDisabled && (
						<CardActions>
							<Button
								color='primary'
								isOutline
								onClick={() => setEditModalLineStatus(true)}>
								Nouveau
							</Button>
						</CardActions>
					)}
				</CardHeader>

				<CardBody className='table-responsive'>
					<table className='table table-modern table-hover'>
						<thead>
							<tr>
								{!isDisabled && <th scope='col'>#ref </th>}
								{!isDisabled && <th scope='col'>Article </th>}
								<th scope='col'>Description</th>
								<th scope='col'>Prix unitaire</th>
								<th scope='col'>Quantité </th>
								{!isDisabled && <th scope='col'>Disc.% </th>}
								<th scope='col'>TVA.% </th>
								<th scope='col'>Sous-total </th>
							</tr>
						</thead>
						{factureLines?.length > 0 && (
							<>
								<tbody>
									{factureLines.map((line) => {
										const tvaValue =
											!!taxs.length && !!line.tax_ids?.length
												? taxs?.filter(
														(tax) => tax.id == line.tax_ids[0],
												  )[0]?.amount
												: 0;
										const subTotal =
											(line?.price_subtotal / 100) * (tvaValue + 100);
										totalPrice += subTotal;
										return (
											// eslint-disable-next-line react/jsx-props-no-spreading
											<DevisTableRow
												key={line.id}
												data={line}
												tvaValue={tvaValue}
												subTotal={subTotal}
												isDisabled={isDisabled}
											/>
										);
									})}
									{/* 
								<DevisTableRow
									key={'last-row'}
									data={{ id: 'Total' }}
									subTotal={totalPrice}
									isDisabled={true}
								/> */}
								</tbody>
							</>
						)}
					</table>
					<table className='table table-modern table-hover'>
						<thead>
							<tr>
								<th>
									<span className='fw-normal me-2'>Total H.T :</span>
									{totalUntaxed?.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})}
								</th>

								<th>
									<span className='fw-normal me-2'>Acompte :</span>
									{acompte.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})}
								</th>
								<th>
									<span className='fw-normal me-2'>Net à payer :</span>
									{total?.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})}
								</th>
								{card?.amount_by_group?.length > 0 && (
									<th>
										<span className='fw-normal me-2'>
											{card?.amount_by_group[0][0]} :
										</span>
										{card?.amount_by_group[0][1]?.toLocaleString('fr-FR', {
											style: 'currency',
											currency: 'EUR',
										})}
									</th>
								)}
								<th className='d-flex align-items-center'>
									<span className='fw-normal me-2'>Remise :</span>
									<span className='me-2'>
										{totalPriceRemise?.toLocaleString('fr-FR', {
											style: 'currency',
											currency: 'EUR',
										})}
									</span>

									{/* <Select
										size='sm'
										disabled={card?.state != 'draft'}
										className='w-auto'
										id='remise'
										ariaLabel='remise'
										placeholder='remise'
										list={remiseList?.map((item) => {
											return { value: item, text: item + '%' };
										})}
										onChange={(event) => setRemise(event.target.value)}
										value={remise}
									/> */}
								</th>
							</tr>
						</thead>
					</table>
				</CardBody>
			</Card>
			{/* <Card>
				<CardBody>
					<table className='table table-modern table-hover'>
						<thead>
							<tr>
								<th>
									<span className='fw-normal me-2'>Total H.T :</span>
									{totalUntaxed?.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})}
								</th>

								<th>
									<span className='fw-normal me-2'>Acompte :</span>
									{card?.accompte?.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})}
								</th>
								<th>
									<span className='fw-normal me-2'>Net à payer :</span>
									{total?.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})}
								</th>
								<th>
									<span className='fw-normal me-2'>
										{card?.amount_by_group[0][0]} :
									</span>
									{card?.amount_by_group[0][1]?.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})}
								</th>
								<th className='d-flex align-items-center'>
									<span className='fw-normal me-2'>Remise :</span>
									{totalPriceRemise?.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})}
									<Select
										disabled={card?.state != 'draft'}
										className='w-auto'
										id='remise'
										ariaLabel='remise'
										placeholder='remise'
										list={remiseList?.map((item) => {
											return { value: item, text: item + '%' };
										})}
										onChange={(event) => setRemise(event.target.value)}
										value={remise}
									/>
								</th>
							</tr>
						</thead>
					</table>
				</CardBody>
			</Card> */}
			<div className='mx-2 row d-flex justify-content-end'>
				{/* <Card shadow='sm' className='col-lg-4'>
					<CardBody>
						<div className='flex-grow-1 ms-3 mb-3 d-flex'>
							<div className='text-muted'>Total H.T :</div>
							<div className='ms-3 fw-bold fs-6 mb-0'>
								{totalUntaxed?.toLocaleString('fr-FR', {
									style: 'currency',
									currency: 'EUR',
								})}
							</div>
						</div>
						<div className='flex-grow-1 ms-3 mb-3 d-flex'>
							<div className='text-muted'>Remise :</div>
							<div className='ms-3 fw-bold fs-6 mb-0'>
								{totalPriceRemise?.toLocaleString('fr-FR', {
									style: 'currency',
									currency: 'EUR',
								})}
							</div>
						</div>

						<FormGroup className='d-flex ms-3 align-content-center'>
							<div className='text-muted me-3'>Remise</div>
							<Select
								disabled={card?.state != 'draft'}
								className='w-auto'
								id='remise'
								ariaLabel='remise'
								placeholder='remise'
								list={remiseList?.map((item) => {
									return { value: item, text: item + '%' };
								})}
								onChange={(event) => setRemise(event.target.value)}
								value={remise}
							/>
						</FormGroup>
						<div className='flex-grow-1 ms-3 mb-3 d-flex'>
							<div className='text-muted'>Montant à payer (acompte) :</div>
							<div className='ms-3 fw-bold fs-6 mb-0'>
								{card?.accompte?.toLocaleString('fr-FR', {
									style: 'currency',
									currency: 'EUR',
								})}
							</div>
						</div>
						<div className='flex-grow-1 ms-3 mb-3 d-flex'>
							<div className='text-muted'>{card?.amount_by_group[0][0]}</div>
							<div className='ms-3 fw-bold fs-6 mb-0'>
								{card?.amount_by_group[0][1]?.toLocaleString('fr-FR', {
									style: 'currency',
									currency: 'EUR',
								})}
							</div>
						</div>

						<div className='flex-grow-1 ms-3 mb-3 d-flex'>
							<div className='text-muted'>Net à payer :</div>
							<div className='ms-3 fw-bold fs-6 mb-0'>
								{total?.toLocaleString('fr-FR', {
									style: 'currency',
									currency: 'EUR',
								})}
							</div>
						</div>
					</CardBody>
				</Card> */}
			</div>

			{editModalLineStatus && (
				<PopUpUpdateAndCreateLineDevis
					data={{ order_id: cardId }}
					setEditModalLineStatus={setEditModalLineStatus}
					create={true}
				/>
			)}
		</>
	);
};

DevisTableRow.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	data: PropTypes.object.isRequired,
	tvaValue: PropTypes.number,
	subTotal: PropTypes.number,
	isDisabled: PropTypes.bool,
};
DevisTable.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	cardId: PropTypes.any.isRequired,
	isDisabled: PropTypes.bool,
};
export default DevisTable;
