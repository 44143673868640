import * as React from 'react';

const SvgCustomModifyCar = (props) => (
    <svg
      	xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 672 502.32'
		style={{
			enableBackground: 'new 0 0 512 512',
		}}
		xmlSpace='preserve'
		width='1em'
		height='1em'
		className='svg-icon' fill='currentColor'
		{...props}>
        <path d="m333.83,349.88c0-90.4,64.66-165.68,150.25-182.16l-35.61-101.67C434.6,26.5,397.32,0,355.41,0h-186.56c-41.65,0-78.71,26.49-92.49,66.02l-35.93,103.19C16.74,179.07,0,202.58,0,230v197.17c0,18.13,14.7,32.83,32.83,32.83h32.34c18.13,0,32.83-14.7,32.83-32.83v-49.17h237.95c-1.39-9.17-2.12-18.56-2.12-28.12ZM139.31,87.85c4.59-13.07,16.95-21.85,30.83-21.85h184.98c13.88,0,26.24,8.78,30.83,21.85l26.64,76.15H112.67l26.64-76.15Zm-40.83,207.81c-18.13,0-32.83-14.7-32.83-32.83s14.7-32.83,32.83-32.83,32.83,14.7,32.83,32.83-14.7,32.83-32.83,32.83Z"/>
        <path d="m519.32,196.96c-84.32,0-152.68,68.36-152.68,152.68s68.36,152.68,152.68,152.68,152.68-68.36,152.68-152.68-68.36-152.68-152.68-152.68Zm-19.35,209.34c-2.98,2.99-6.69,5.17-10.74,6.38l-34.57,10.17c-2.41.69-5.03.03-6.81-1.75s-2.44-4.4-1.72-6.84l10.17-34.56c1.21-4.06,3.39-7.76,6.38-10.75l66.97-67.01,37.36,37.36-67.04,67Zm87.44-87.4l-13.91,13.9-37.35-37.35,13.91-13.91h.02c7.19-7.18,18.82-7.18,26.01,0l11.32,11.35c7.18,7.19,7.18,18.82,0,26.01Z"/>
        </svg>

)
export default SvgCustomModifyCar;