export const DashboardMenu = {
	id: 'dashboard',
	text: 'Tableau de bord',
	path: '/',
	icon: 'Dashboard',
	permissions: ['superadmin', 'gestionnaire', 'comptabilite', 'admin'],
};

export const DashboardTechMenu = {
	id: 'dashboardTechnicien',
	text: 'Tableau de bord',
	path: '/dashboard',
	icon: 'Dashboard',
	permissions: ['technicien', 'achat'],
};

export const planningMenu = {
	id: 'planningCalendar',
	text: 'Planning',
	path: 'plannig2',
	icon: 'EventNote',
	permissions: [
		'superadmin',
		'gestionnaire',
		'admin',
		'technicien',
		'achat',
		'comptabilite',
		'planning',
	],
};

export const interventionsTermineesMenu = {
	id: 'interventionsTerminees',
	text: 'Interventions Terminées',
	path: 'interventionsTerminees',
	icon: 'EventNote',
	permissions: ['technicien', 'achat'],
};

export const productionMenu = {
	id: 'productions',
	text: 'Ordre de fabrications',
	path: 'productions',
	icon: 'LocalGroceryStore',
	permissions: ['superadmin', 'achat'],

	production: {
		id: 'production',
		text: 'production',
		icon: 'SupervisedUserCircle',
		path: 'production',
		hide: true,
	},
};
export const atelierMenu = {
	id: 'atelier',
	text: 'Gestion de commandes',
	icon: 'ProductionQuantityLimits',
	path: 'atelier',
	permissions: ['superadmin', 'achat'],
};

export const atelierMaterielMenu = {
	id: 'fabrication',
	text: 'Type de fabrication',
	icon: 'ViewHeadline',
	path: 'fabrication',
	permissions: ['superadmin', 'gestionnaire', 'achat', 'comptabilite'],
};

export const devisMenu = {
	id: 'devis',
	text: 'Devis',
	path: '/devis/tous',
	icon: 'RequestQuote',
	permissions: [
		'superadmin',
		'admin',
		'gestionnaire',
		'technicien',
		'achat',
		'comptabilite',
		'planning',
	],
};

export const devisAnnuleMenu = {
	id: 'devisAnnules',
	text: 'Devis annulés',
	path: '/devis/annules',
	icon: 'CancelPresentation',
	permissions: ['superadmin', 'admin', 'gestionnaire', 'comptabilite'],
};

export const facturationAchatTableMenu = {
	id: 'facturationAchatPage',
	text: 'Factures fournisseurs',
	path: 'comptabilite/facturation/achat',
	icon: 'FactCheck',
	permissions: ['superadmin', 'admin', 'comptabilite'],
};

export const facturationTableMenu = {
	id: 'allFactorationPage',
	text: 'Factures clients',
	path: 'comptabilite/facturation/vente',
	icon: 'FactCheck',
	permissions: ['superadmin', 'technicien', 'comptabilite', 'admin', 'achat'],
};

export const SuiviPaymentsTableMenu = {
	id: 'suiviPayments',
	text: 'Suivi de paiements',
	path: 'comptabilite/ListePaiements',
	icon: 'Payment',
	permissions: ['superadmin', 'comptabilite', 'admin'],
};

export const relanceFactureMenu = {
	id: 'relanceFacture',
	text: 'Relance facture client',
	path: 'comptabilite/facturation/relance',
	icon: 'RestorePage',
	permissions: ['superadmin', 'admin', 'comptabilite'],
};

export const RelanceMenu = {
	id: 'relance',
	text: 'Relance devis',
	path: '/relance',
	icon: 'PublishedWithChanges',
	permissions: ['superadmin', 'gestionnaire', 'admin'],
};
export const PoseMenu = {
	id: 'pose',
	text: 'Poses à planifier',
	path: '/pose',
	icon: 'PublishedWithChanges',
	permissions: ['superadmin', 'gestionnaire', 'admin'],
};

export const ChargeMenu = {
	id: 'charges',
	text: 'Charges',
	path: 'charges',
	icon: 'Money',
	permissions: ['superadmin', 'admin'],

	singleCharge: {
		id: 'charge',
		text: 'charge',
		icon: 'Money',
		path: 'charges/add',
		hide: true,
	},
};

export const NoteFraisMenu = {
	id: 'noteFrais',
	text: 'Notes de frais',
	icon: 'DocumentScanner',
	path: 'noteFrais',
	permissions: ['superadmin', 'admin', 'comptabilite', 'technicien'],
};
export const AchatsMenu = {
	id: 'achats',
	text: 'Demandes de prix',
	icon: 'LocalOffer',
	path: 'achats',
	permissions: ['superadmin', 'achat', 'comptabilite'],
	singleAchat: {
		id: 'achat',
		text: 'achat',
		icon: 'SupervisedUserCircle',
		path: 'achat',
		hide: true,
	},
};

export const AppelsMenu = {
	id: 'callHistory',
	text: "Liste d'appels",
	path: 'telephony/listAppels',
	icon: 'SwapHoriz',
	permissions: ['superadmin', 'admin', 'gestionnaire'],
};

export const LinesMenu = {
	id: 'lines',
	text: 'Lignes',
	path: 'telephony/lignes',
	icon: 'Dialpad',
	permissions: ['superadmin', 'admin', 'gestionnaire'],
	lineID: {
		id: 'lineID',
		text: 'Ligne',
		path: 'telephony/lignes',
		hide: true,
	},
};

export const ServicesMenu = {
	id: 'groupes',
	text: 'Facturation',
	path: 'telephony/groupes',
	icon: 'Paid',
	permissions: ['superadmin', 'admin', 'gestionnaire'],
	serviceID: {
		id: 'serviceID',
		text: 'Service',
		path: 'telephony/services',
		hide: true,
	},
};

export const TelephonyDashboardMenu = {
	id: 'telephonyDashboard',
	text: 'Tableau de bord',
	path: 'telephony/dashboard',
	icon: 'TrendingUp',
	permissions: ['superadmin', 'admin', 'gestionnaire'],
};
export const ProductsPage2 = {
	id: 'ProductsPage2',
	text: 'Produits',
	path: 'products2',
	icon: 'Assignment',
	permissions: ['superadmin', 'gestionnaire', 'achat'],
};
export const CategoriesPage = {
	id: 'CategoriesPage',
	text: 'Categories',
	path: 'categories',
	icon: 'Storage',
	permissions: ['superadmin', 'admin', 'gestionnaire', 'achat'],
};
export const InventairesPage = {
	id: 'InventairesPage',
	text: 'Inventaires',
	path: 'inventaires',
	icon: 'Storefront',
	permissions: ['superadmin', 'admin', 'gestionnaire', 'achat'],
};
export const EmplacementPage = {
	id: 'EmplacementPage',
	text: 'Emplacement',
	path: 'emplacemen',
	icon: 'Storefront',
	permissions: ['superadmin', 'admin', 'gestionnaire', 'achat'],
};

export const EntrepotsPage = {
	id: 'EntrepotsPage',
	text: 'Entrepôts',
	path: 'entrepots',
	icon: 'Storefront',
	permissions: ['superadmin', 'admin', 'gestionnaire', 'achat'],
	entrepot: {
		id: 'employee',
		text: 'Entrepôt',
		path: 'entrepots/entrepot',
		hide: true,
	},
};

export const RequestLogin = {
	id: 'request-login',
	text: 'Demandes-login',
	path: 'requests-login',
	icon: 'Store',
	permissions: ['superadmin'],
};

export const EmployeeTable = {
	id: 'EmployeeTable',
	text: 'Employés',
	icon: 'SupervisorAccount',
	path: 'RH',
	permissions: ['superadmin'],
	employee: {
		id: 'employee',
		text: 'Employee',
		path: 'RH/employee',
		hide: true,
	},
};
export const clientTable = {
	id: 'clientTable',
	text: 'Clients',
	icon: 'Contacts',
	path: 'Clients',
	permissions: ['superadmin', 'admin', 'gestionnaire'],
	clientID: {
		id: 'clientID',
		text: 'clientID',
		path: 'client',
		hide: true,
	},
};

export const CongesMenu = {
	id: 'congés',
	text: 'Congés',
	path: 'Conges',
	icon: 'EmojiTransportation',
	permissions: ['superadmin'],
};
export const voitureMenu = {
	id: 'voitureTable',
	text: 'Voitures',
	icon: 'DirectionsCar',
	path: 'voitures',
	permissions: ['superadmin', 'admin'],
	// voitureID: {
	// 	id: 'voitureID',
	// 	text: 'voitureID',
	// 	path: 'voiture',
	// 	hide: true,
	// },
};

export const voitureMapMenu = {
	id: 'voitureMap',
	text: 'Voiture Map',
	icon: 'DirectionsCar',
	path: 'voitures-map',
	permissions: ['superadmin', 'admin'],
	// voitureID: {
	// 	id: 'voitureID',
	// 	text: 'voitureID',
	// 	path: 'voiture',
	// 	hide: true,
	// },
};

export const qrScannerMenu = {
	id: 'qrScanner',
	text: 'Qr Scanner',
	icon: 'QrCodeScanner',
	path: 'qr-scanner',
	permissions: ['superadmin', 'admin'],
	// voitureID: {
	// 	id: 'voitureID',
	// 	text: 'voitureID',
	// 	path: 'voiture',
	// 	hide: true,
	// },
};

export const voitureIDMenu = {
	id: 'voitureIdTable',
	text: 'Voiture',
	icon: 'DirectionsCar',
	path: 'voiture',
	permissions: ['technicien'],
};

export const PDFMenu = {
	id: 'pdf',
	text: 'Pièces jointes',
	path: 'pdf',
	icon: 'PictureAsPdf',
	permissions: ['superadmin', 'admin', 'gestionnaire', 'technicien', 'achat', 'comptabilite'],
};

export const DemandeMailMenu = {
	id: 'mail',
	text: 'Demandes par mail',
	path: 'demande-mail',
	icon: 'ContactMail',
	permissions: ['superadmin', 'admin', 'gestionnaire', 'achat', 'comptabilite'],
};

export const MailMenu = {
	acceptDevis: {
		id: 'signature',
		text: 'Signature devis',
		path: 'signature-devis',
		icon: 'check_box_outline_blank ',
		hide: true,
	},
	paiement: {
		id: 'paiement',
		text: 'Paiement Store2000',
		path: 'paiement',
		icon: 'Payments',
		hide: true,
	},
	confirmation: {
		id: 'confirmation',
		text: 'Confirmation Paiement Store2000',
		path: 'confirmation',
		icon: 'DoneAll',
		hide: true,
	},
};

export const ActivityMenu = {
	id: 'activity',
	text: 'Tâches',
	icon: 'Book',
	path: 'taches',
	permissions: ['superadmin', 'admin', 'gestionnaire', 'technicien', 'achat', 'comptabilite'],
};

export const ProjectsMenu = {
	id: 'projects',
	text: 'Projets',
	icon: 'AutoStories',
	path: 'projets',
	permissions: ['superadmin', 'admin', 'gestionnaire', 'technicien', 'achat', 'comptabilite'],
};

export const ChatMenu = {
	id: 'chat',
	text: 'Chat',
	path: 'chat',
	icon: 'Chat',
	permissions: ['superadmin', 'admin', 'gestionnaire', 'technicien', 'achat', 'comptabilite'],
};

/*****  Les modules  ******/

export const DashboardModule = {
	Dashboard: DashboardMenu,
	DashboardTechnicien: DashboardTechMenu,
	permissions: ['superadmin', 'admin', 'gestionnaire', 'comptabilite', 'technicien', 'achat'],
};

export const InterventionModule = {
	Planning: planningMenu,
	'Interventions Terminées': interventionsTermineesMenu,
	VoituresMap: voitureMapMenu,
	QrScanner: qrScannerMenu,
	PoseDevis: PoseMenu,
	permissions: [
		'superadmin',
		'gestionnaire',
		'admin',
		'technicien',
		'achat',
		'comptabilite',
		'planning',
	],
};

export const VenteModule = {
	Devis: devisMenu,
	// Devis annuler
	'Devis annulés': devisAnnuleMenu,
	'Demande par mail': DemandeMailMenu,

	RelanceDevis: RelanceMenu,

	permissions: [
		'superadmin',
		'admin',
		'gestionnaire',
		'technicien',
		'achat',
		'comptabilite',
		'planning',
	],
};

export const ComptabiliteModule = {
	FactureClient: facturationTableMenu,
	FactureFournisseur: facturationAchatTableMenu,
	RelanceFacture: relanceFactureMenu,
	SuiPaiements: SuiviPaymentsTableMenu,
	NoteFrais: NoteFraisMenu,
	Charges: ChargeMenu,
	permissions: ['superadmin', 'technicien', 'comptabilite', 'admin', 'achat'],
};

export const ActivityModule = {
	Activités: ActivityMenu,
	Projets: ProjectsMenu,
	permissions: ['superadmin', 'admin', 'gestionnaire', 'technicien', 'achat', 'comptabilite'],
};

export const AtelierModule = {
	Atelier: atelierMenu,
	//Type: atelierMaterielMenu,
	Production: productionMenu,
	Achats: AchatsMenu,

	permissions: ['superadmin', 'achat', 'comptabilite'],
};

export const StockModule = {
	Inventaire: InventairesPage,
	Entrepôts: EntrepotsPage,
	//Stock : CategoriesPage ,
	Produit: ProductsPage2,
	permissions: ['superadmin', 'achat', 'comptabilite'],
};

export const ContactModule = {
	Clients: clientTable,
	permissions: ['superadmin', 'admin', 'gestionnaire'],
};
export const GRHModule = {
	Employer: EmployeeTable,
	Conges: CongesMenu,
	permissions: ['superadmin'],
	// Contrat : ,
};

export const TelephonyModule = {
	TelephonyDashboard: TelephonyDashboardMenu,
	Appels: AppelsMenu,
	Lignes: LinesMenu,
	Services: ServicesMenu,
	permissions: ['superadmin', 'admin', 'gestionnaire'],
};

export const VoitureModule = {
	Voitures: voitureMenu,
	Voiture: voitureIDMenu,

	permissions: ['superadmin', 'admin', 'technicien'],
};

export const PdfModule = {
	'Pièces jointes': PDFMenu,
	permissions: ['superadmin', 'admin', 'gestionnaire', 'technicien', 'achat', 'comptabilite'],
};

export const demandeParMailCard = {
	permissions: ['superadmin', 'admin', 'gestionnaire', 'technicien', 'achat', 'comptabilite'],
};

/********* Final modules **********/

export const modules = {
	Tableau_de_bord: DashboardModule,
	"Gestion d'interventions": InterventionModule,
	Vente: VenteModule,
	'Commande & Fabrication': AtelierModule,
	'Gestion de Stock': StockModule,
	Comptabilité: ComptabiliteModule,
	Tâches: ActivityModule,
	Contact: ContactModule,
	"Gestion d'appel": TelephonyModule,
	GRH: GRHModule,
	Voiture: VoitureModule,
	Extra: PdfModule,
};
