import React, { useContext } from 'react';
import Input from '../../bootstrap/forms/Input';
import Modal, { ModalBody, ModalHeader } from '../../bootstrap/Modal';
import Icon from '../../icon/Icon';
import PropTypes from 'prop-types';
import AuthContext from '../../../contexts/authContext';

const SearchPopUp = ({
	setSearchModalStatus,
	value,
	setValue,
	filterResult,
	name,
	label,
	setSelectValue,
	handelChange,
	linkImage,
}) => {
	const { userData } = useContext(AuthContext);

	const handleChange = (e) => {
		handelChange(e);
	};
	const handelClick = (item) => {
		setSelectValue({
			id: item?.id,
			value: item?.name || item?.display_name,
			product_id: item?.id,
			description: item?.name || item?.display_name,
		});
		setSearchModalStatus(false);
	};
	return (
		<Modal
			setIsOpen={() => {}}
			isOpen={true}
			isStaticBackdrop
			isScrollable
			data-tour='search-modal'
			classNameMd='nv-3'>
			<ModalHeader setIsOpen={setSearchModalStatus}>
				<label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
					<Icon icon='Search' size='2x' color='primary' />
				</label>
				<Input
					name={name || 'search'}
					className='border-0 shadow-none bg-transparent'
					placeholder='Search...'
					value={value}
					onChange={handleChange}
					autoComplete='off'
				/>
			</ModalHeader>
			<ModalBody>
				<table className='table table-hover table-modern caption-top mb-0'>
					<caption>Results: {filterResult?.length}</caption>
					<thead style={{ top: -13 }}>
						<tr>
							<th scope='col'>{label || 'Results'}</th>
						</tr>
					</thead>
					<tbody>
						{filterResult?.length ? (
							filterResult?.map((item) => (
								<tr
									key={item?.id}
									className='cursor-pointer'
									onClick={() => {
										handelClick(item);
									}}>
									<td className='row'>
										{/* {item.id && (
                                            <Icon
                                                icon={item.id}
                                                size='lg'
                                                className='me-2'
                                                color='primary'
                                            />
                                        )} */}
										<span className='col'>
											{userData && userData?.id && userData?.password && (
												<img
													src={linkImage(userData?.password, item?.id)}
													width={60}
													height={60}
												/>
											)}
										</span>
										<span className='col'>
											{item?.name || item?.display_name}
										</span>
									</td>
								</tr>
							))
						) : (
							<tr className='table-active'>
								<td>No result found for query </td>
							</tr>
						)}
					</tbody>
				</table>
			</ModalBody>
		</Modal>
	);
};

SearchPopUp.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	setSearchModalStatus: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	value: PropTypes.any.isRequired,
	setValue: PropTypes.func,
	filterResult: PropTypes.any,
	name: PropTypes.any,
	label: PropTypes.any,
	setSelectValue: PropTypes.any,
	handelChange: PropTypes.func,
	linkImage: PropTypes.func,
	/* selectedValue:PropTypes.object */
};

export default SearchPopUp;
