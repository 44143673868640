import * as React from 'react';

const SvgCustomStore = (props) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 216 216'
        xmlSpace='preserve'
        width='1em'
        height='1em'
        className='svg-icon' fill='currentColor'
        {...props}>
        <path opacity={0.3} d="M53.1101 124.878C49.5784 124.846 46.1926 124.336 43.0022 123.415V174.19C43.0022 179.713 47.4794 184.19 53.0022 184.19H75.7522C81.275 184.19 85.7522 179.713 85.7522 174.19V154.94C85.7522 149.969 89.7817 145.94 94.7522 145.94H121.752C126.723 145.94 130.752 149.969 130.752 154.94V174.19C130.752 179.713 135.229 184.19 140.752 184.19H163.502C169.025 184.19 173.502 179.713 173.502 174.19V123.415C170.312 124.336 166.925 124.846 163.392 124.878C162.896 124.903 162.397 124.903 161.9 124.878C151.424 124.782 141.986 120.385 135.252 113.375C128.443 120.463 118.869 124.88 108.252 124.88C97.6358 124.88 88.0612 120.463 81.2523 113.375C74.5188 120.385 65.0805 124.782 54.6051 124.878C54.107 124.903 53.6081 124.903 53.1101 124.878Z" />
        <path d="M62.4618 32C50.2204 32 39.3523 39.8332 35.4813 51.4465L26.4813 78.4465C20.3486 96.8445 34.0234 115.844 53.4057 115.88C53.7061 115.903 54.0077 115.903 54.308 115.88C66.8602 115.855 77.5041 107.7 81.2519 96.3994C85.0054 107.717 95.6756 115.88 108.252 115.88C120.829 115.88 131.498 107.717 135.252 96.3994C139 107.7 149.643 115.855 162.195 115.88C162.496 115.903 162.797 115.903 163.096 115.88C182.479 115.844 196.154 96.8446 190.022 78.4465L181.022 51.4465C177.15 39.8332 166.282 32 154.041 32H62.4618Z" />
    </svg>

)
export default SvgCustomStore;