import React from 'react';
import Modal, { ModalBody, ModalHeader } from '../../bootstrap/Modal';
import Card, { CardBody } from '../../bootstrap/Card';
import Icon from '../../icon/Icon';
import Button from '../../bootstrap/Button';

export default function NavigatePopup({
	icon,
	color,
	setOpen,
	title,
	label,
	btn1,
	btn2,
	fn1 = () => {},
	fn2 = () => {},
}) {
	return (
		<Modal isOpen={true} setIsOpen={() => {}} size={'sm'} classNameMd='nv-3'>
			<ModalHeader
				setIsOpen={() => {
					setOpen(false);
				}}></ModalHeader>
			<ModalBody>
				<Card shadow={'none'} isCompact>
					<CardBody>
						<div className='row  g-4 text-center'>
							<div className='col-12'>
								<Icon icon={icon} size='5x' color={color} />
							</div>
							<div className='col-12'>
								<h4>{title}</h4>
							</div>
							<div className='col-12'>
								<p>{label}</p>
							</div>
							<div className='col-12'>
								<Button
									color={color}
									isLight
									className='w-100 py-3 text-uppercase'
									size='lg'
									onClick={() => {
										fn1();
										setOpen(false);
									}}>
									{btn1}
								</Button>
							</div>
							<div className='col-12'>
								<Button
									color={color}
									className='w-100 py-3 text-uppercase'
									size='lg'
									onClick={() => {
										fn2();
										setOpen(false);
									}}>
									{btn2}
								</Button>
							</div>
						</div>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
}
