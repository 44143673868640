import { useFormik } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImageIcon } from '../../../../common/function/functionsUtils';
import AuthContext from '../../../../contexts/authContext';
import ClientMontantContext from '../../../../contexts/clientMontantContext';
import { searchJournal } from '../../../../odooApi/accountJournal';
import {
	paymentJustify,
	searchPayments,
	updatePaymentJustifyState,
	uploadAttachmentPaymentJustify,
} from '../../../../odooApi/paymentJustify';
import { getAttachment } from '../../../../odooApi/voiture';
import Alert from '../../../bootstrap/Alert';
import Badge from '../../../bootstrap/Badge';
import Button from '../../../bootstrap/Button';
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../bootstrap/Card';
import Spinner from '../../../bootstrap/Spinner';
import Checks, { ChecksGroup } from '../../../bootstrap/forms/Checks';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';
import Label from '../../../bootstrap/forms/Label';
import Icon from '../../../icon/Icon';

export default function PaiementsCard({ facturesDatas, event, setFormState }) {
	const minAmount = 0;
	const navigate = useNavigate();
	const [journals, setJournals] = useState([]);
	const { userData } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [spinner, setSpinner] = useState(false);

	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const { clientMontant, setClientMontant } = useContext(ClientMontantContext);
	const fileInputRef = useRef(null);
	const [popupImage, setPopupImage] = useState(false);
	const [selectedImage, setSelectedImage] = useState(false);
	const [paiements, setPaiements] = useState([]);
	const TYPE = ['Acompte', 'Reste', 'Total'];

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	const handleFileInputChange = (e) => {
		const newAttachments = Array.from(e?.target?.files);
		formik?.setFieldValue('attachements', newAttachments);
	};

	const handelBlurAmount = (e) => {
		formik.setFieldValue(
			'amount',
			(e.target.value < minAmount && minAmount) ||
				(e.target.value > facturesDatas?.amountResidual && facturesDatas?.amountResidual) ||
				e.target.value,
		);
	};

	const formik = useFormik({
		initialValues: {
			amount: facturesDatas?.amountResidual,
			date: new Date(),
			moyenPayment: '',
			typePayment: '',
			attachements: [],
			memo: '',
		},
		onSubmit: (values) => {
			setSuccess(false);
			if (userData && userData?.id && userData?.password) {
				setIsLoading(true);
				const payInfo = {
					moyen_paiement: values?.moyenPayment,
					montant: values?.amount,
					client: event?.partner_id?.id,
					total: facturesDatas?.amount_total,
					source: facturesDatas?.id,
					user_id: userData?.id,
					planning: event?.id,
					type_paiement: values?.typePayment,
					memo: values?.memo,
					date_paiement: moment(values?.date).format('YYYY-MM-DD'),
				};
				paymentJustify(
					userData?.password,
					payInfo,
					(data) => {
						setFormState((prev) => ({ ...prev, paiement: true }));
						if (values?.attachements.length > 0) {
							uploadAttachmentPaymentJustify(
								userData?.password,
								data?.id,
								values?.attachements[0],
								(result) => {
									updatePaymentJustifyState(
										userData?.password,
										{
											id: data?.id,
											attachment: result?.data?.attachment[0],
										},
										() => {
											setIsLoading(false);
											setPaiements([
												...paiements,
												{
													...data,
													attachment: { id: result?.data?.attachment[0] },
												},
											]);
										},
									);
								},
							);
						} else {
							setIsLoading(false);
							setPaiements([...paiements, data]);
						}
					},
					(error) => {
						setSuccess(false);
						setIsLoading(false);
						setError(error);
					},
				);
			}
		},
	});

	useEffect(() => {
		if (userData && userData?.id && userData?.password) {
			searchJournal(
				userData?.password,
				['&', ['type', 'in', ['cash', 'bank']], ['company_id', '=', userData?.company_id]],
				(data) => {
					setJournals(data?.elements);
				},
			);
		}
	}, [userData]);

	useEffect(() => {
		const price = (formik?.values?.amount / facturesDatas?.amountResidual) * 100;
		setClientMontant({ ...clientMontant, montant: price.toFixed(2) });
	}, [formik?.values?.amount]);

	useEffect(() => {
		let FilterEtat = [['planning', '=', event?.id]];

		searchPayments(
			userData?.password,
			FilterEtat,
			(dataResult) => {
				setPaiements([...paiements, ...dataResult?.elements]);
			},
			(error) => {
				setError(error);
			},
			null,
			1,
			0,
		);
		// if (!!event?.parent_id) {
		// 	let FilterEtat = [['planning', '=', event?.parent_id]];

		// 	searchPayments(
		// 		userData?.password,
		// 		FilterEtat,
		// 		(dataResult) => {
		// 			setPaiements([...paiements, ...dataResult?.elements]);
		// 		},
		// 		(error) => {
		// 			setError(error);
		// 		},
		// 		null,
		// 		1,
		// 		0,
		// 	);
		// }
	}, [event?.id]);
	console.log('paiements', paiements);
	return (
		<div className='row'>
			{!(
				(userData?.role != 'Technicien' &&
					userData?.role?.toLocaleLowerCase() != 'achat') ||
				event?.state == 'done'
			) && (
				<div className='col-12'>
					<Alert color='primary' isLight isDismissible>
						Merci d'enregistrer tous vos paiements ici afin que le comptable puisse les
						valider ultérieurement. Vous trouverez ci-dessous l'historique de vos
						paiements.{' '}
					</Alert>
				</div>
			)}
			<div className='col-lg-6'>
				<Card isCompact stretch>
					<CardHeader>
						<CardLabel icon='Add' iconColor='primary'>
							<CardTitle>Nouveau Paiement</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='py-2 '>
						<FormGroup className='col-12 py-2'>
							<Label>Type de paiement</Label>
							<ChecksGroup className={'checkGr simulator-check'}>
								{!!TYPE?.length &&
									TYPE?.map((type) => {
										return (
											<Checks
												type='radio'
												isInline
												id={type}
												label={type}
												name='typePayment'
												value={type}
												onChange={formik.handleChange}
												checked={formik.values.typePayment == type && true}
												className='small'
											/>
										);
									})}
							</ChecksGroup>
						</FormGroup>
						<FormGroup className='col-12 py-2'>
							<Label>Moyen de paiement</Label>
							{/* <ChecksGroup className={'checkGr simulator-check'}> */}
							<div className='checkGr simulator-check'>
								{!!journals?.length > 0 &&
									journals?.map((journal) => {
										return (
											<Checks
												type='radio'
												isInline
												id={journal?.name}
												label={journal?.name}
												name='moyenPayment'
												value={journal?.id}
												onChange={formik.handleChange}
												checked={
													journal?.id == formik?.values?.moyenPayment
												}
												className='small'
											/>
										);
									})}
							</div>
							{/* </ChecksGroup> */}
							{/* {!!journals?.length && (
								<Select
									ariaLabel='ariaLabel'
									placeholder={'-- aucun --'}
									onChange={formik.handleChange}
									name='moyenPayment'>
									{journals?.map((journal, idx) => {
										return (
											<Option
												key={idx}
												type='radio'
												name={'moyenPayment'}
												id={journal?.name}
												value={journal?.id}
												checked={
													journal?.id == formik?.values?.moyenPayment
												}>
												{journal?.name}
											</Option>
										);
									})}
								</Select>
							)} */}
						</FormGroup>

						<FormGroup className='col-12 py-2 d-none' id='date'>
							<Label>Date comptable</Label>
							<Input
								type={'date'}
								value={moment(formik.values?.date).format(moment.HTML5_FMT.DATE)}
								name='date'
								autoComplete='off'
								onChange={formik.handleChange}

								//disabled={true}
							/>
						</FormGroup>

						<FormGroup className='col-12 py-2' id='amount'>
							<Label>Montant</Label>
							<Input
								onChange={formik.handleChange}
								onBlur={handelBlurAmount}
								value={formik.values.amount}
								name='amount'
								type='number'
								min={minAmount}
								max={facturesDatas?.amountResidual}
								autoComplete='off'
							/>
						</FormGroup>
						<div className='row g-2'>
							<Label>Pièce jointe</Label>
							{formik?.values?.attachements?.length <= 0 ? (
								<div className='col-12'>
									<div className='py-5 flex-column d-flex justify-content-center align-items-center  rounded-1 w-100 bg-l10-dark border-dashed'>
										<Icon
											icon='CloudDownload'
											size={'2x'}
											onClick={handleButtonClick}
											className='cursor-pointer'></Icon>
										<span>Déposer vos fichiers</span>
									</div>
									<Input
										type='file'
										multiple
										name='attachments'
										onChange={handleFileInputChange}
										style={{ display: 'none' }}
										ref={fileInputRef}
									/>
								</div>
							) : (
								<>
									{!!formik?.values?.attachements?.length &&
										formik?.values?.attachements?.map((file, index) => {
											return (
												<div key={index} className='col-auto'>
													<Badge
														className='px-3 py-2 rounded-1'
														color={'dark'}
														isLight>
														<Icon
															icon={ImageIcon(file?.type)}
															size={'lg'}
															className='me-2 cursor-pointer'></Icon>
														<span
															className='fs-6 fw-semibold cursor-pointer'
															onClick={() => {
																setSelectedImage(file);
																setPopupImage(true);
															}}>
															{file?.name}
														</span>

														<Icon
															icon='Clear'
															size={'md'}
															className='ms-2 cursor-pointer'
															onClick={() => {
																formik?.setFieldValue(
																	'attachements',
																	[],
																);
															}}></Icon>
													</Badge>
												</div>
											);
										})}
									<div className='col-auto'>
										<Input
											type='file'
											multiple
											name='attachments'
											onChange={handleFileInputChange}
											style={{ display: 'none' }}
											ref={fileInputRef}
										/>
									</div>
								</>
							)}
						</div>
					</CardBody>
					<CardFooter className='py-2'>
						<CardFooterRight>
							<Button
								color='primary'
								className='w-auto'
								icon={!isLoading && 'Save'}
								type='submit'
								isDisable={
									!(formik.values.amount && formik.values.moyenPayment) ||
									isLoading
								}
								onClick={formik.handleSubmit}>
								{isLoading && <Spinner isSmall inButton />}
								{isLoading ? 'En cours' : 'Ajouter'}
							</Button>
						</CardFooterRight>
					</CardFooter>
				</Card>
			</div>
			<div className='col-lg-6'>
				<Card isCompact stretch>
					<CardHeader>
						<CardLabel icon='Euro' iconColor='success'>
							<CardTitle>Historique de paiement</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='g-4 py-4'>
						<div className='table-responsive'>
							<table className='table table-modern table-hover'>
								<thead>
									<tr>
										<th>Date</th>
										<th>Responsable</th>
										<th>Montant</th>
										<th>Intermédiaire</th>
										<th></th>
									</tr>
								</thead>
								{!spinner && (
									<tbody>
										{paiements.map((item) => (
											<tr key={item.id}>
												<td
												// className='cursor-pointer'
												// onClick={() => {
												//     setData(item);
												//     setIsOpen(true);
												// }}
												>
													<div>{moment(item?.date).format('ll')}</div>
												</td>
												<td>
													<div>{item?.user_id?.name}</div>
												</td>
												<td className='cursor-pointer'>
													<div>
														<div>
															{item.montant &&
																item.montant?.toLocaleString(
																	'fr-FR',
																	{
																		style: 'currency',
																		currency: 'EUR',
																	},
																)}
														</div>
													</div>
													<div className='small text-muted'></div>
												</td>
												<td>{item?.moyen_paiement?.name}</td>
												<td>
													{item?.attachment?.name != '' && (
														<Button
															tag='a'
															color='success'
															isLight
															icon='FileDownload'
															className='text-center'
															href={getAttachment(
																userData?.password,
																item?.attachment?.id,
															)}
															target='_blank'></Button>
													)}
												</td>
											</tr>
										))}
									</tbody>
								)}
							</table>
						</div>
					</CardBody>
				</Card>
			</div>
		</div>
	);
}
