import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';
/* import Avatar from '../Avatar'; */
import Badge from '../bootstrap/Badge';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../bootstrap/Card';
import useDarkMode from '../../hooks/useDarkMode';
import useTourStep from '../../hooks/useTourStep';
import COLORS from '../../common/data/enumColors';
import FacturePopUp from './FacturePopUp';
import moment from 'moment';
/* import UserImage2 from '../../assets/img/wanna/wanna2.png'; */
/* import UserImage2Webp from '../../assets/img/wanna/wanna2.webp'; */

const FactureCard = ({ card, loadFactures = () => { } }) => {
	useTourStep(13);

	const { darkModeStatus } = useDarkMode();
	const [editModalStatus, setEditModalStatus] = useState(false);

	return (
		<>
			<Card
				shadow='none'
				borderSize={1}
				className='rounded-2 cursor-pointer'
				onClick={() => setEditModalStatus(true)}>
				<CardHeader>
					<CardLabel>
						<CardTitle
							tag='h6'
							className={classNames({
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}
							data-tour={card.name}>
							{card.name}
						</CardTitle>
						{card?.nom_client && (
							<CardSubTitle className='text-muted'>
								nom client: {card?.nom_client}
							</CardSubTitle>
						)}
						{card.phone_client && (
							<CardSubTitle className='text-muted mt-2'>
								Tél client: {card.phone_client}
							</CardSubTitle>
						)}
						{card.mobile_client && (
							<CardSubTitle className='text-muted mt-2'>
								Tél client: {card.mobile_client}
							</CardSubTitle>
						)}
					</CardLabel>

					<CardActions>
						<div className='col-auto'>
							<small className='border border-primary border-2 text-primary fw-bold px-2 py-1 rounded-1'>
								{card?.company_id?.name}
							</small>
						</div>

					</CardActions>

				</CardHeader>
				<CardBody className='pt-0'>
					<div className='row g-2 mb-3'>
						{card.date && (
							<div className='col-auto'>
								<small className='border border-info border-2 text-info fw-bold px-2 py-1 rounded-1'>
									{moment(card.date).format('DD-MM-YYYY')}
								</small>
							</div>
						)}
						{card.invoice_payment_state == 'not_paid' && card?.state != 'draft' && (
							<div className='col-auto'>
								<small className='border border-danger border-2 text-danger fw-bold px-2 py-1 rounded-1'>
									reste:
									{card?.amount_residual?.toLocaleString('en-US', {
										style: 'currency',
										currency: 'EUR',
									})}
								</small>
							</div>
						)}
						{!!card.paiement_digital?.payment_date && (
							<div className='col-auto'>
								<small className='border border-info border-2 text-info fw-bold px-2 py-1 rounded-1'>
									{card.paiement_digital?.payment_date}
								</small>
							</div>
						)}
					</div>
					{card.img && (
						<img
							src={card.img}
							className={classNames('img-fluid rounded mb-3 mt-1', {
								'bg-lo25-primary': darkModeStatus,
								'bg-l25-primary': !darkModeStatus,
							})}
							alt={card.name}
						/>
					)}
					{card.project_title}
				</CardBody>
				<CardFooter className='pt-0' size='sm'>
					<CardFooterRight>
						<div className='col-auto'>
							<small className='border border-success border-2 text-success fw-bold px-2 py-1 rounded-1'>
								Total :
								{card?.amount_total?.toLocaleString('fr-FR', {
									style: 'currency',
									currency: 'EUR',
								})}
							</small>
						</div>
						{/* {
							!!card?.amount_residual&&card?.amount_residual!=card?.amount_total&&
							<div className='col-auto'>
								<small className='border border-success border-2 text-success fw-bold px-2 py-1 rounded-1'>
									Rest:
									{
										card?.amount_residual?.toLocaleString('en-US', {
											style: 'currency',
											currency: 'EUR',
										})
									}
									
								</small>
							</div>
						} */}
					</CardFooterRight>
				</CardFooter>
			</Card>

			{editModalStatus && (
				<FacturePopUp
					card={card}
					setEditModalStatus={setEditModalStatus}
					loadFactures={loadFactures}
				/>
			)}
		</>
	);
};
FactureCard.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	card: PropTypes.object.isRequired,
};

export default FactureCard;
