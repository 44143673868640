import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { getImageByModule } from '../../../../common/function/functionsUtils';
import AuthContext from '../../../../contexts/authContext';
import MailMessageContext from '../../../../contexts/mailMessageContext';
import { searchMailMessage } from '../../../../odooApi/mailMessage';
import Avatar from '../../../Avatar';
import Button from '../../../bootstrap/Button';
import Card, { CardActions, CardBody, CardHeader, CardTitle } from '../../../bootstrap/Card';
import Textarea from '../../../bootstrap/forms/Textarea';
import Icon from '../../../icon/Icon';
import AddChronologiePopUp from './AddChronologiePopUp';
import EditChronologiePopup from './EditChronologiePopup';
import Tasks from './Tasks';
import Historique from './Historique';

export default function Chronologies({ event }) {
	const { userData } = useContext(AuthContext);
	const [messageIDS, setMessageIDS] = useState('');
	const { mailMessages, setMailMessages } = useContext(MailMessageContext);
	const [allMessages, setAllMessages] = useState([]);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [isEditModal, setIsEditModal] = useState(false);
	const [editItem, setEditItem] = useState();

	useEffect(() => {
		var filter = ['&', ['res_id', '=', event?.id], ['record_name', '=', event?.name_seq]];
		searchMailMessage(userData?.password, filter, ({ elements }) => {
			setMailMessages(elements);
		});
	}, []);

	const handelEdit = (i) => {
		setEditItem(i);
		setIsEditModal(true);
	};

	return (
		<>
			<div className='row'>
				<Card>
					<CardHeader>
						<CardTitle>
							<Icon icon={'PendingActions'} size={'3x'} color='primary'></Icon>
							Activités
						</CardTitle>
						<CardActions className='d-flex'>
							<Button
								color='primary'
								icon='AddCircleOutline'
								onClick={() => {
									setIsOpenModal(true);
								}}>
								Ajouter
							</Button>
						</CardActions>
					</CardHeader>

					<CardBody>
						{mailMessages.map((i, index) => (
							<>
								<div className='row g-4 mb-3'>
									<div className='col-lg-2'>
										<Avatar
											src={getImageByModule(
												userData?.password,
												userData?.employee,
												'hr.employee',
											)}
											srcSet={getImageByModule(
												userData?.password,
												userData?.employee,
												'hr.employee',
											)}
											/* color={card.user.color} */
											size={30}
											userName={`${userData.name}`}
											className='cursor-pointer img-avatar me-2'
										/>{' '}
										<span>{i?.author_id?.name}</span>
										{i.date && (
											<div className='todo-subtitle text-muted small ms-5'>
												{moment(i.date).fromNow()}
											</div>
										)}
									</div>

									<div className='col-lg-7 '>
										<Textarea
											className='cursor-pointer'
											value={i?.body
												?.replace(/<br\s*\/?>/g, '\n')
												.replace(/<\/?[^>]+(>|$)/g, '')}
											onClick={() => {
												handelEdit(i);
											}}
											style={{ height: 'auto' }}
										/>
									</div>
								</div>
							</>
						))}
					</CardBody>
				</Card>
			</div>
			<div className='row'>
				<Tasks event={event} />
			</div>
			<div className='row'>
				<Historique event={event} />
			</div>
			{isOpenModal && <AddChronologiePopUp event={event} setIsOpenModal={setIsOpenModal} />}
			{isEditModal && (
				<EditChronologiePopup item={editItem} setIsEditModal={setIsEditModal} />
			)}
		</>
	);
}
