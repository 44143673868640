const prix_VRCMotorise = [
	{ id: 1000, price: 462.02 },
	{ id: 1500, price: 512.84 },
	{ id: 2000, price: 569.25 },
	{ id: 2500, price: 631.87 },
	{ id: 3000, price: 701.37 },
	{ id: 3500, price: 778.52 },
	{ id: 4000, price: 864.16 },
	{ id: 4500, price: 959.22 },
	{ id: 5000, price: 1064.73 },
	{ id: 5500, price: 1181.85 },
	{ id: 6000, price: 1313.09 },
	{ id: 6500, price: 1457.53 },
	{ id: 7000, price: 1617.86 },
	{ id: 7500, price: 1795.83 },
	{ id: 8000, price: 1993.37 },
];
export default prix_VRCMotorise;
