import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { organizationDataFacturation } from '../common/function/organizationDataDevis';
import COLORS from '../common/data/colorDummyData';

const FactureContext = createContext([]);

export const FactureContextProvider = ({ children }) => {
	const [facture, setFacture] = useState([
		{
			id: 'draft',
			title: 'Factures brouillons',
			color: COLORS.DARK,
			icon: 'RateReview',
			pay: 'not_paid',
			state: 'draft',
			total: 0,
			/* amountTotal:amountTotalNotPaid, */
			current_page: 1,
			cards: [],
		},

		{
			id: 'posted',
			title: 'Factures comptabilisées',
			color: COLORS.WARNING,
			icon: 'ContentPaste',
			// amountTotal: amountTotalNotPaidNotDraft,
			pay: 'not_paid',
			state: 'posted',
			total: 0,
			current_page: 1,
			cards: [],
		},
		{
			id: 'paid',
			title: 'Factures acquittées',
			color: COLORS.SUCCESS,
			icon: 'Verified',
			total: 0,
			// amountTotal: amountTotalPaid,
			pay: 'paid',
			state: 'posted',
			current_page: 1,
			cards: [],
		},
		// {
		// 	id: 'cancel',
		// 	title: 'Factures Annulés',
		// 	color: COLORS.DANGER,
		// 	icon: 'ContentPasteOff',
		// 	pay: 'not_paid',
		// 	state: 'cancel',
		// 	total: 0,
		// 	current_page: 1,
		// 	cards: [],
		// },
	]);

	const value = useMemo(
		() => ({
			facture,
			setFacture,
		}),
		[facture],
	);
	return <FactureContext.Provider value={value}>{children}</FactureContext.Provider>;
};
FactureContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default FactureContext;
