import axios from 'axios';
import FormData from 'form-data';
import BeveledCone from '../assets/img/abstract/beveled-cone.png';
//import fs from "fs";

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	API_URL_FUNCTION_UTILS = process.env.REACT_APP_API_URL_FUNCTION_UTILS,
	PRODUCT_API_URI = '/product.product',
	LIMIT = 20;
/* const getAllProduct =  (tokenUserCurent,fnSuccess) => {
  var dataSearch = JSON.stringify({
    "kwargs": {
      "limit": LIMIT,
      "domain": [
        [
          "type",
          "=",
          "consu"
        ]
      ]
    }
  });
  var config = {
    method: 'patch',
    url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PRODUCT_API_URI + SEARCH_METHOD_API}`,
    headers: {
      'Authorization': `Basic ${btoa(process.env.REACT_APP_AUTH_OPEN_API_DB + ":" + tokenUserCurent)}`,
      'Content-Type': 'application/json',

    },
    data: dataSearch
  };
  

  axios(config)
    .then(function (response) {
      
      if (!!response.data?.length) {
        
        const data = [
          {
            id: response.data[0].id,
            image: BeveledCone,
            display_name: response.data[0].display_name,
            categ_id: response.data[0].categ_id,
            series: [
              {
                data: [25, 66, 41, 89, 63],
              },
            ],
            description: response.data[0].description,
            stock: 500,
            lst_price: response.data[0].lst_price,
            store: 'Company A',
            file: response.data[0].type,
    
          },
          ...response.data
    
        ];
        fnSuccess(data) ;
      }else{
        fnSuccess([]) ;
      }
      
      
    })
    .catch(function (error) {
      console.log(error);
    });

}
export default getAllProduct; */

const getAllProducts = (tokenUserCurent, fnSuccess, page = 1, limit = LIMIT) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PRODUCT_API_URI}?limit=${limit}&page=${page}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
	};

	axios(config)
		.then(function (response) {
			fnSuccess(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export default getAllProducts;

export const getSingleProduct = (idProduct, tokenUserCurent, fnSuccess) => {
	var config = {
		method: 'get',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + PRODUCT_API_URI + '/' + idProduct,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const searchProduct = async (
	tokenUserCurent,
	filters,
	fnSuccess,
	fnError = () => {},
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PRODUCT_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};
	console.log('searchProductsearchProduct', config);
	await axios(config)
		.then(function (response) {
			fnSuccess(response?.data);
			return;
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const searchProductFilter = async (
	tokenUserCurent,
	filter,
	fnSuccess,
	fnError = () => {},
	page = 1,
	limit = LIMIT,
) => {
	const FilterResult = [
		'|',
		['name', 'like', filter],
		'|',
		['name', 'like', filter.charAt(0).toUpperCase() + filter.substring(1).toLowerCase()],
		'|',
		['name', 'like', filter.toUpperCase()],
		'|',
		['default_code', 'like', filter],
		'|',
		[
			'default_code',
			'like',
			filter.charAt(0).toUpperCase() + filter.substring(1).toLowerCase(),
		],

		['default_code', 'like', filter.toUpperCase()],
	];
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: FilterResult,
			limit: limit,
			page: page,
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PRODUCT_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};
	console.log('searchProductsearchProduct', config);
	await axios(config)
		.then(function (response) {
			fnSuccess(response?.data);
			return;
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const deleteProduct = (tokenUserCurent, idDelete, fcSuccess) => {
	var config = {
		method: 'delete',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + PRODUCT_API_URI + '/' + idDelete,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};
	
	axios(config)
		.then(function () {
			fcSuccess();
		})
		.catch(function (error) {
			console.log(error);
		});
};
export const createProduct = (tokenUserCurent, newProduct, fntSucces, fnError = () => { }) => {
	var data = JSON.stringify(newProduct);

	var config = {
		method: 'post',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + PRODUCT_API_URI,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			fntSucces(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const updateProduct = (tokenUserCurent, idProduct, body, fnSuccess) => {
	var data = JSON.stringify(body);
	var config = {
		method: 'put',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + PRODUCT_API_URI + '/' + idProduct,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const imageProduct = (tokenUserCurent, idProduct) => {
	return (
		BASIC_API_URL +
		'/api/digital/image' +
		PRODUCT_API_URI +
		'/' +
		idProduct +
		'?token=' +
		tokenUserCurent
	);
};

/* export const uploadImageProduct = (tokenUserCurent,idProduct,file,fnc) => {
  
  let data = new FormData();
  data.append('image_1920', file);

  let config = {
    method: 'put',
    url: BASIC_API_URL + API_URL_WEB_ADMIN + "/upload"+PRODUCT_API_URI+ "/" + idProduct+"?token="+tokenUserCurent,
    headers: { 
      'Authorization': `Basic ${btoa(process.env.REACT_APP_AUTH_OPEN_API_DB + ":" + tokenUserCurent)}`, 
      'Content-Type': file?.type
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    fnc(response);
  })
  .catch((error) => {
    console.log("error",error);
  });
} */

export const uploadImageProduct = (tokenUserCurent, idProduct, image, fnc) => {
	let data = new FormData();
	data.append('image_1920', image);

	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_binary' +
			PRODUCT_API_URI +
			'/' +
			idProduct +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};
	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			console.log('errorerrorerror', error);
		});
};
