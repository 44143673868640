import React, { useContext, useEffect, useState } from 'react';
import Modal, { ModalBody, ModalHeader } from '../bootstrap/Modal';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Icon from '../icon/Icon';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import SearchAutoComplete from '../customComponents/SearchAutoComplete/SearchAutoComplete';
import Button from '../bootstrap/Button';
import { useFormik } from 'formik';
import { searchClientPartner } from '../../odooApi/clientPartner';
import AuthContext from '../../contexts/authContext';
import ListGroup, { ListGroupItem } from '../bootstrap/ListGroup';
import Spinner from '../bootstrap/Spinner';
import { AutoComplete, getPlaceDetails } from '../../googleApi/placeAutoComplete';
import { calcDistance } from '../../common/function/distanceService';
import { getAllParc } from '../../odooApi/fleetParc';
import { searchVoiture } from '../../odooApi/voiture';
import optimizationRDV from '../../apis/localApis/optimizationRDV';
import { useTranslation } from 'react-i18next';
import LoadSpinner from '../Spinner/LoadSpinner';
import FilterByDate from '../telephony/callHistory/FilterByDate';
import moment from 'moment';
import InterventionCreatePopUp from './Intervention/InterventionCreatePopUp';

export default function PlanifierPlanning({ setOpenPopup }) {
	const { userData } = useContext(AuthContext);
	const [clientIsLoading, setClientIsLoading] = useState(false);
	const [filterResult, setfilterResult] = useState([]);
	const [resultSearchAdr, setResultSearchAdr] = useState([]);
	const { t } = useTranslation(['translation', 'menu']);
	const [searchItem, setSearchItem] = useState('');
	const [isSpinner, setSpinner] = useState(false);
	const [suggestions, setSuggestions] = useState([]);
	const [filterDataByDate, setFilterDataByDate] = useState({
		startDate: moment(new Date()).add('minute', 15).format('yyyy-MM-DD HH:mm:ss'),
		endDate: moment(new Date())
			.endOf('week')
			.startOf('date')
			.set('hour', 19)
			.format('yyyy-MM-DD HH:mm:ss'),
	});
	const [createIntervention, setCreateIntervention] = useState(false);

	const formik = useFormik({
		initialValues: {
			idClient: null,
			name: '',
			mobile: '',
			email: '',
			adrsFact: '',
			place_id: '',
			temps: 30,
			nbVoiture: 1,
		},

		onSubmit: (values) => {
			//handelUpdate(values);
		},
	});
	const handleChange = (e) => {
		formik.handleChange(e);

		if (e.target.name == 'name') {
			if (e.target.value != formik?.values?.name) {
				formik?.setFieldValue('idClient', null);
			}
			setSearchItem(e.target.value);

			// if (userData && userData?.id && userData?.password) {
			// 	searchClientPartner(
			// 		userData?.password,
			// 		[
			// 			'|',
			// 			['name', 'like', e.target.value],
			// 			'|',
			// 			[
			// 				'name',
			// 				'like',
			// 				e.target.value.charAt(0).toUpperCase() +
			// 					e.target.value.substring(1).toLowerCase(),
			// 			],
			// 			['name', 'like', e.target.value.toUpperCase()],
			// 		],
			// 		({ elements }) => {
			// 			setfilterResult(elements);
			// 			setClientIsLoading(false);
			// 		},
			// 		() => {},
			// 		1,
			// 		10,
			// 	);
			// }
		} else if (e.target.name == 'adrsFact') {
			AutoComplete(e.target.value, (result) => {
				const newLocations = [];
				result?.predictions?.map((item) => {
					newLocations.push({
						id: item?.place_id,
						label: item?.description,
						icon: '',
					});
				});
				setResultSearchAdr(newLocations);
			});
		} else if (e.target.name == 'temps') {
			if (userData && userData?.id && userData?.password && formik?.values?.adrsFact) {
				setSpinner(true);
				optimizationRDV(
					userData?.password,
					formik?.values?.adrsFact,
					e.target.value,
					filterDataByDate?.startDate,
					filterDataByDate?.endDate,
					(suggestions) => {
						setSuggestions(suggestions);
						setSpinner(false);
					},
				);
			}
		}
	};

	const handelSelectClient = (item) => {
		formik?.setFieldValue('idClient', item.id);
		formik?.setFieldValue('name', item.display_name);
		formik?.setFieldValue('mobile', !!item.mobile ? item.mobile : item.phone);
		const adressClient =
			item?.street +
			' ' +
			item?.zip +
			' ' +
			item?.city +
			' ' +
			item?.country_id?.display_name;
		formik.setFieldValue('adrsFact', adressClient);
		setfilterResult([]);
		if (userData && userData?.id && userData?.password && adressClient) {
			setSpinner(true);
			optimizationRDV(
				userData?.password,
				adressClient,
				formik?.values?.temps,
				filterDataByDate?.startDate,
				filterDataByDate?.endDate,
				(suggestions) => {
					setSuggestions(suggestions);
					setSpinner(false);
				},
			);
		}
	};

	const handleSelectAdr = (item) => {
		formik?.setFieldValue('adrsFact', item?.label);

		setResultSearchAdr([]);

		if (userData && userData?.id && userData?.password) {
			setSpinner(true);
			optimizationRDV(
				userData?.password,
				item?.label,
				formik?.values?.temps,
				filterDataByDate?.startDate,
				filterDataByDate?.endDate,
				(suggestions) => {
					setSuggestions(suggestions);
					setSpinner(false);
				},
			);
		}

		/* getPlaceDetails(
			item?.id,
			({ result }) => {
				console.log("resultresultresult",result);
			}
		) */
	};

	const handleChangeDate = (filterDate) => {
		
		setFilterDataByDate(filterDate);
		if (userData && userData?.id && userData?.password && formik?.values?.adrsFact) {
			setSpinner(true);
			optimizationRDV(
				userData?.password,
				formik?.values?.adrsFact,
				formik?.values?.temps,
				filterDate?.startDate,
				filterDate?.endDate,
				(suggestions) => {
					setSuggestions(suggestions);
					setSpinner(false);
				},
			);
		}
	};

	const handleCreateIntervention = (id) => {
		/* if(!formik?.values?.idClient){
			const newUser = {
				name: formik?.values?.name,
				phone: formik?.values?.mobile || '',
				email: formik?.values?.email || '',
				place_id:formik?.values?.place_id || '',
				
				
			};
			console.log("newUsernewUser",newUser);
		} */
		setCreateIntervention(id);
	};

	useEffect(() => {
		// Create an AbortController instance
		const abortController = new AbortController();
		const signal = abortController.signal;

		if (searchItem === '') {
			setfilterResult([]);
			setClientIsLoading(false);
		} else {
			if (userData && userData?.id && userData?.password) {
				setClientIsLoading(true);
				searchClientPartner(
					userData?.password,
					[
						'|',
						['name', 'like', searchItem],
						'|',
						[
							'name',
							'like',
							searchItem.charAt(0).toUpperCase() +
								searchItem.substring(1).toLowerCase(),
						],
						['name', 'like', searchItem.toUpperCase()],
					],

					({ elements }) => {
						setfilterResult(elements);
						setClientIsLoading(false);
					},
					() => {
						setClientIsLoading(true);
					},
					0,
					0,
					signal,
				);
			}
		}

		// Clean up the AbortController when component is unmounted or when client changes
		return () => {
			abortController.abort();
		};
	}, [searchItem]);

	return (
		<>
			<Modal isOpen={true} setIsOpen={() => {}} size={'xl'}>
				<ModalHeader setIsOpen={() => setOpenPopup(false)}></ModalHeader>
				<ModalBody>
					<Card stretch={'fluid'}>
						<CardHeader borderSize={1}>
							<CardLabel icon='Today' iconColor='info'>
								<CardTitle>Planifier un rendez-vous</CardTitle>
							</CardLabel>
							{/* <CardActions>
								<Button
									
									style={{
										color: 'light',
										hoverShadow: 'default',
										
									}}
									isLight
									icon={'EventNote'}
									color='info'
									onClick={() => {
										
										setOpenPopup(false);
									}}>
									Voir planning
								</Button>
							</CardActions> */}
						</CardHeader>

						<CardBody>
							<div className='row g-3 mb-3'>
								<div className='col-lg-6 col-12'>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<Icon icon='AccountCircle' size='2x' color='info' />
										</div>
										<div className='flex-grow-1 ms-3 ' /* ref={ref} */>
											<div className='fw-bold fs-6 mb-0'>
												<Input
													placeholder='Sélectionnez un client'
													id='name'
													onChange={handleChange}
													onFocus={handleChange}
													value={formik?.values?.name}
													name='name'
													autoComplete='off'
												/>
												{
													/* openList && */
													clientIsLoading ? (
														<ListGroup tag={'div'}>
															<ListGroupItem tag={'button'}>
																<Spinner isSmall isGrow />{' '}
																<Spinner isSmall isGrow />{' '}
																<Spinner isSmall isGrow />
															</ListGroupItem>
														</ListGroup>
													) : (
														!!filterResult.length && (
															<ListGroup tag={'div'}>
																{filterResult?.map((item) => (
																	<ListGroupItem
																		tag={'button'} // 'section' || 'div' || 'li' || 'a' || 'button' || 'label'
																		onClick={() => {
																			handelSelectClient(
																				item,
																			);
																		}}>
																		<Icon
																			color={'primary'}
																			isLight
																			icon={'Person'}
																			size='lg'
																		/>
																		{item?.name}{' '}
																		<Icon
																			color={'primary'}
																			isLight
																			icon={'Place'}
																			size='lg'
																		/>
																		{item?.street +
																			' ' +
																			item?.zip +
																			' ' +
																			item?.city}
																		{item?.display_name}
																	</ListGroupItem>
																))}
															</ListGroup>
														)
													)
												}
											</div>
										</div>
									</div>
								</div>

								<div className='col-lg-6 col-12'>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<Icon icon='Phone' size='2x' color='info' />
										</div>
										<div className='flex-grow-1 ms-3'>
											<div className='fw-bold fs-6 mb-0'>
												<FormGroup id='mobile' isFloating label='Téléphone'>
													<Input
														type='number'
														id='mobile'
														name='mobile'
														value={formik?.values?.mobile}
														onChange={formik?.handleChange}
													/>
												</FormGroup>
											</div>
										</div>
									</div>
								</div>

								<div className='col-lg-6 col-12'>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<Icon icon='LocationOn' size='2x' color='info' />
										</div>
										<div className='flex-grow-1 ms-3'>
											<div className='fw-bold fs-6 mb-0'>
												<SearchAutoComplete
													label='Adresse de facturation *'
													className='col-12'
													value={formik?.values.adrsFact}
													onChange={handleChange}
													options={resultSearchAdr}
													setSelectItem={handleSelectAdr}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className='col-lg-6 col-12'>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<Icon icon='AccessTime' size='2x' color='info' />
										</div>
										<div className='flex-grow-1 ms-3'>
											<div className='fw-bold fs-6 mb-0'>
												<FormGroup
													id='mobile'
													isFloating
													label="Durée de l'intervention (min)">
													<Input
														type='number'
														id='temps'
														name='temps'
														step={15}
														min={30}
														value={formik?.values?.temps}
														onChange={handleChange}
													/>
												</FormGroup>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* <div className='row g-3 mb-3'>
								
								<div className='col-lg-6 col-12'>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<Icon icon='AccessTime' size='2x' color='info' />
										</div>
										<div className='flex-grow-1 ms-3'>
											<div className='fw-bold fs-6 mb-0'>
												<FormGroup
													id='mobile'
													isFloating
													label="Nombre de Voiture">
													<Input
														type='number'
														id='nbVoiture'
														name='nbVoiture'
														value={formik?.values?.nbVoiture}
														onChange={formik?.handleChange}
														disabled={true}
													/>
												</FormGroup>
											</div>
										</div>
									</div>
								</div>
							</div> */}
						</CardBody>
					</Card>

					<Card stretch={'fluid'}>
						<CardHeader borderSize={1}>
							<CardLabel icon='Today' iconColor='info'>
								<CardTitle>Suggestions</CardTitle>
							</CardLabel>
							<CardActions>
								<FilterByDate
									setAnalysisDate={handleChangeDate}
									defaultDate={filterDataByDate}
								/>
							</CardActions>
						</CardHeader>

						<CardBody>
							{isSpinner ? (
								<LoadSpinner />
							) : (
								<div className='table-responsive'>
									<table className='table table-modern mb-0'>
										<thead>
											<tr>
												<th scope='col'>Réf</th>
												<th scope='col'>Véhicule</th>
												<th scope='col'>Heure de début</th>
												{/* <th scope='col'>{t('Description')}</th> */}
												<th scope='col'>Heure de fin</th>
												<th scope='col'>Distance parcourue</th>

												<th scope='col'>Temps total</th>
											</tr>
										</thead>
										{!!suggestions?.length && (
											<tbody>
												{suggestions.map((suggestion, idx) => {
													// Calcul des jours, heures et minutes pour Distance parcourue
													const totalMinutes = (
														suggestion?.distance1 / 60
													).toFixed(2);
													const days = Math.floor(totalMinutes / 1440);
													const remainingMinutesAfterDays =
														totalMinutes % 1440;
													const hours = Math.floor(
														remainingMinutesAfterDays / 60,
													);
													const minutes = (
														remainingMinutesAfterDays % 60
													).toFixed(0);

													// Calcul des jours, heures et minutes pour Temps total
													const totalMinutesSum = (
														(suggestion?.distance1 +
															suggestion?.estimation) /
														60
													).toFixed(2);
													const daysSum = Math.floor(
														totalMinutesSum / (24 * 60),
													);
													const hoursSum = Math.floor(
														(totalMinutesSum % (24 * 60)) / 60,
													);
													const minutesSum = (
														totalMinutesSum % 60
													).toFixed(0);

													return (
														<tr
															key={idx}
															/* onClick={() => setisOpen(true)} */
															className='cursor-pointer'
															onClick={() =>
																handleCreateIntervention(idx + 1)
															}>
															<th scope='row'>{idx + 1}</th>
															<td>
																<div>
																	{suggestion?.voiture?.name}
																</div>
															</td>
															<td>
																<div>
																	{suggestion?.start_datetime}
																</div>
															</td>
															<td>
																<div>
																	{suggestion?.end_datetime}
																</div>
															</td>
															<td>
																<div>
																	{`${days}j - ${hours}h - ${minutes}min`}
																</div>
															</td>
															<td>
																<div>
																	{/* {(
																	(suggestion?.distance1 +
																		suggestion?.estimation) /
																	60
																).toFixed(2) + ' '}
																min */}
																	{`${daysSum}j - ${hoursSum}h - ${minutesSum}min`}
																</div>
															</td>
														</tr>
													);
												})}
											</tbody>
										)}
									</table>
								</div>
							)}
						</CardBody>
					</Card>
				</ModalBody>
			</Modal>
			{createIntervention && suggestions?.length && (
				<InterventionCreatePopUp
					setCreateModalStatus={() => setCreateIntervention(false)}
					newInterventionData={{
						start: suggestions[createIntervention - 1]?.start_datetime,
						end: suggestions[createIntervention - 1]?.end_datetime,
						employee: {
							voitures: [suggestions[createIntervention - 1]?.voiture],
						},
						client: {
							id: formik?.values?.idClient,
							name: formik?.values?.name,
						},
					}}
					getInterventions={() => {}}
				/>
			)}
		</>
	);
}
