import axios from 'axios';

const REMINDER_AUTH_BY_USER_PASSWORD_URL = process.env.REACT_APP_REMINDER_AUTH_BY_USER_PASSWORD_URL;

const getToken = async (username, password, fn,fn2) => {
	var reminderData = JSON.stringify({
		login: username,
		password: password,
	});

	var reminderConfig = {
		method: 'post',
		url: '/reminder' + REMINDER_AUTH_BY_USER_PASSWORD_URL,
		headers: {
			'Content-Type': 'application/json',
		},
		data: reminderData,
	};

	axios(reminderConfig)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			fn2();
			console.log(error);
		});
};

export default getToken;
