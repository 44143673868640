import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const SignatureInput = ({ name, sigCanvas }) => {
	const [inputValue, setInputValue] = useState('');

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	const drawText = (ctx, text, x, y) => {
		ctx.font = '20px Arial';
		ctx.fillText(text, x, y);
	};

	const handleSetSignature = () => {
		if (sigCanvas.current) {
			sigCanvas.current.clear();
			sigCanvas.current.off();
			const canvas = sigCanvas.current.getCanvas();
			const ctx = canvas.getContext('2d');
			drawText(ctx, 'Bon pour accord', 50, 50);
			drawText(ctx, name, 100, 100);
		}
	};

	useEffect(() => {
		handleSetSignature();
	}, [inputValue]);

	return (
		<div>
			<SignatureCanvas
				ref={sigCanvas}
				canvasProps={{ width: 500, height: 200, className: 'signatureCanvas' }}
			/>
			{/* <input type='text' value={inputValue} onChange={handleInputChange} /> */}
		</div>
	);
};

export default SignatureInput;
