import Modal, { ModalHeader, ModalBody } from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import { sendPaiementDevisByMail } from '../../../../odooApi/devis';

export default function CreatePaimentFromDevisPopup({ setOpen, handleConfirm, handleCancel }) {



	return (
		<Modal isOpen={true} setIsOpen={() => { }} isCentered size={'sm'} classNameMd='nv-2'>
			<ModalHeader></ModalHeader>
			<ModalBody>
				<Card shadow={'none'}>
					<CardBody>
						<div className='row g-4 text-center justify-content-center'>
							{/* <Icon icon={'Payments'} color={'info'} size='7x' /> */}

							{/* <h2>{'Paiement 30%'}</h2> */}

							<h5>Merci d'avoir accepté et signé le devis.</h5>
							<span>Email envoyé avec succés</span>
							{/* <span>Souhaitez-vous effectuer le paiement maintenant?</span> */}
							<div className='row g-4'>
								{/* <div className='col-lg-6'>
									<Button
										color={'info'}
										className='w-100'
										size={'lg'}
										onClick={() => {
											!!handleConfirm && handleConfirm();
											setOpen(false);
										}}>
										Oui
									</Button>
								</div> */}
								<div className='col-lg-12'>
									<Button
										color={'info'}
										isLight
										className='w-100'
										size={'lg'}
										onClick={() => {
											handleCancel();
											setOpen(false);
										}}>
										Fermer
									</Button>
								</div>
							</div>
						</div>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
}
