import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	PARC_API_URI = '/fleet.parc';

export const getAllParc = (tokenUserCurent, fnSuccess,fnError=()=>{}) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PARC_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};
	
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data?.elements);
		})
		.catch(function (error) {
			console.log(error);
			fnError(error)
		});
};



export const updateParc = (tokenUserCurent, idParc, body, fnSuccess) => {
	var data = JSON.stringify(body);
	//console.log("data",data)
	var config = {
		method: 'put',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + PARC_API_URI + '/' + idParc,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	
	axios(config)
		.then(function (response) {
			fnSuccess(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const createParc = (tokenUserCurent, newParc, fntSucces) => {
	var data = JSON.stringify(newParc);

	var config = {
		method: 'post',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + PARC_API_URI,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			fntSucces(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const deleteParc = (tokenUserCurent, idDelete, fcSuccess=()=>{}) => {
	var config = {
		method: 'delete',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + PARC_API_URI + '/' + idDelete,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};
	
	axios(config)
		.then(function () {
			fcSuccess();
		})
		.catch(function (error) {
			console.log(error);
		});
};