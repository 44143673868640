import React, { useEffect } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import Card, { CardBody } from '../bootstrap/Card';
import Icon from '../icon/Icon';
import Button from '../bootstrap/Button';

export default function StatusPopup({
	icon,
	color,
	setOpen,
	title,
	label,
	setParentIsHidden = () => {},
}) {
	useEffect(() => {
		!!setParentIsHidden && setParentIsHidden(true);
	}, []);

	return (
		<Modal isOpen={true} setIsOpen={() => {}} isCentered size={'sm'} classNameMd='nv-3'>
			<ModalHeader
				setIsOpen={() => {
					setOpen(false);
					!!setParentIsHidden && setParentIsHidden(false);
				}}></ModalHeader>
			<ModalBody>
				<Card shadow={'none'}>
					<CardBody>
						<div className='row g-4 text-center'>
							{/* <div className='col-12'> */}
							<Icon icon={icon} size='7x' color={color} />
							{/* </div> */}
							{/* <div className='col-12'> */}
							<h2>{title}</h2>
							{/* </div> */}
							{/* <div className='col-12'> */}
							<p>{label}</p>
							{/* </div> */}
							{/* <div className='col-12'> */}
							<Button
								color={color}
								isLight
								className='w-100 py-3 text-uppercase'
								size='lg'
								onClick={() => {
									!!setParentIsHidden && setParentIsHidden(false);
									setOpen(false);
								}}>
								Ok
							</Button>
							{/* </div> */}
						</div>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
}
