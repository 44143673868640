import React from 'react'

const SvgCustomStoreBanne = () => (
    <svg
      	xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 672 502.32'
		style={{
			enableBackground: 'new 0 0 512 512',
		}}
		xmlSpace='preserve'
		width='1em'
		height='1em'
		className='svg-icon' fill='currentColor'
		{...props}>
        <path class="cls-1" d="m23.14,20.94H2.86c-.18,0-.32-.13-.32-.29v-10.74h.64v10.45h19.65v-10.45h.64v10.74c0,.16-.14.29-.32.29h0Z"/>
        <path class="cls-1" d="m7.26,10.34h-1.03c-1.05,0-1.9-.85-1.9-1.9v-.8c0-.17.14-.32.32-.32h4.21c.17,0,.32.14.32.32v.81c0,1.05-.85,1.9-1.9,1.9h0Zm-2.3-2.4v.49c0,.7.57,1.27,1.27,1.27h1.03c.7,0,1.27-.57,1.27-1.27v-.49h-3.57Z"/>
        <path class="cls-1" d="m8.85,7.94h-4.21c-.1,0-.19-.04-.25-.12-.06-.08-.08-.17-.06-.27L6.02.37c.03-.14.16-.24.31-.24h3.36c.09,0,.18.04.24.11.06.07.09.16.08.25l-.84,7.19c-.02.16-.15.28-.31.28h0Zm-3.81-.63h3.53l.77-6.56h-2.76l-1.53,6.56Z"/>
        <path class="cls-1" d="m3.06,10.34h-1.03c-1.05,0-1.9-.85-1.9-1.9v-.8c0-.17.14-.32.32-.32h4.2c.17,0,.32.14.32.32v.81c0,1.05-.85,1.9-1.9,1.9h0ZM.76,7.94v.49c0,.7.57,1.27,1.27,1.27h1.03c.7,0,1.27-.57,1.27-1.27v-.49H.76Z"/>
        <path class="cls-1" d="m4.64,7.94H.44c-.1,0-.2-.05-.26-.13-.06-.08-.07-.19-.04-.29L2.67.34c.05-.12.16-.21.3-.21h3.36c.1,0,.19.04.25.12.06.08.08.17.06.27l-1.68,7.19c-.04.14-.16.24-.31.24h0Zm-3.76-.63h3.51L5.93.76h-2.74L.89,7.31Zm10.58,3.03h-1.03c-1.05,0-1.9-.85-1.9-1.9v-.8c0-.17.14-.32.32-.32h4.2c.17,0,.32.14.32.32v.81c0,1.05-.85,1.9-1.9,1.9h0Zm-2.3-2.4v.49c0,.7.57,1.27,1.27,1.27h1.03c.7,0,1.27-.57,1.27-1.27v-.49h-3.57Z"/>
        <path class="cls-1" d="m13.05,7.94h-4.2c-.09,0-.18-.04-.24-.11-.06-.07-.09-.16-.08-.25l.84-7.19c.02-.16.15-.28.31-.28h3.36c.17,0,.32.14.32.32v7.19c0,.17-.14.32-.32.32h0Zm-3.85-.63h3.53V.76h-2.76l-.77,6.56Z"/>
        <path class="cls-1" d="m15.67,10.34h-1.03c-1.05,0-1.9-.85-1.9-1.9v-.8c0-.17.14-.32.32-.32h4.2c.17,0,.32.14.32.32v.81c0,1.05-.85,1.9-1.9,1.9h0Zm-2.3-2.4v.49c0,.7.57,1.27,1.27,1.27h1.03c.7,0,1.27-.57,1.27-1.27v-.49h-3.57Z"/>
        <path class="cls-1" d="m17.26,7.94h-4.2c-.17,0-.32-.14-.32-.32V.45c0-.17.14-.32.32-.32h3.36c.16,0,.29.12.31.28l.84,7.19c0,.09-.02.18-.08.25-.06.07-.15.11-.24.11h0Zm-3.89-.63h3.53l-.77-6.56h-2.76v6.56Z"/>
        <path class="cls-1" d="m19.88,10.34h-1.03c-1.05,0-1.9-.85-1.9-1.9v-.8c0-.17.14-.32.32-.32h4.21c.17,0,.32.14.32.32v.81c0,1.05-.85,1.9-1.9,1.9h0Zm-2.3-2.4v.49c0,.7.57,1.27,1.27,1.27h1.03c.7,0,1.27-.57,1.27-1.27v-.49h-3.57Z"/>
        <path class="cls-1" d="m21.46,7.94h-4.21c-.16,0-.29-.12-.31-.28l-.84-7.19c0-.09.02-.18.08-.25.06-.07.15-.11.24-.11h3.36c.15,0,.27.1.31.24l1.68,7.19c.02.09,0,.19-.06.27-.06.08-.15.12-.25.12h0Zm-3.92-.63h3.53l-1.53-6.56h-2.76l.77,6.56Z"/>
        <path class="cls-1" d="m24.08,10.34h-1.03c-1.05,0-1.9-.85-1.9-1.9v-.8c0-.17.14-.32.32-.32h4.2c.17,0,.32.14.32.32v.81c0,1.05-.85,1.9-1.9,1.9h0Zm-2.3-2.4v.49c0,.7.57,1.27,1.27,1.27h1.03c.7,0,1.27-.57,1.27-1.27v-.49h-3.57Z"/>
        <path class="cls-1" d="m25.67,7.94h-4.2c-.15,0-.27-.1-.31-.24l-1.68-7.19c-.02-.09,0-.19.06-.27.06-.08.15-.12.25-.12h3.36c.13,0,.25.08.3.21l2.52,7.19c.03.1.02.2-.04.29-.06.08-.15.13-.26.13h0Zm-3.95-.63h3.51l-2.3-6.56h-2.74l1.53,6.56Zm-10.05,13.63h-3.47c-.08,0-.16-.03-.22-.09-.06-.06-.09-.14-.09-.22v-8.39c0-.08.03-.16.09-.22.06-.06.14-.09.22-.09h3.47c.17,0,.32.14.32.32v8.39c0,.08-.03.16-.09.22-.06.06-.14.09-.22.09h0Zm-3.15-.63h2.84v-7.76h-2.84v7.76Z"/>
        <path class="cls-1" d="m8.19,20.94h-3.47c-.17,0-.32-.14-.32-.32v-8.39c0-.17.14-.32.32-.32h3.47c.17,0,.32.14.32.32v8.39c0,.17-.14.32-.32.32h0Zm-3.15-.63h2.84v-7.76h-2.84v7.76Zm16.65-2.61h-8.62c-.17,0-.32-.14-.32-.32v-5.15c0-.17.14-.32.32-.32h8.62c.17,0,.32.14.32.32v5.15c0,.17-.14.32-.32.32h0Zm-8.3-.63h7.98v-4.52h-7.98v4.52Z"/>
        <path class="cls-1" d="m21.7,18.92h-8.62c-.17,0-.32-.14-.32-.32v-1.22c0-.17.14-.32.32-.32h8.62c.17,0,.32.14.32.32v1.22c0,.17-.14.32-.32.32h0Zm-8.3-.63h7.98v-.59h-7.98v.59Z"/>
        <rect class="cls-1" x=".94" y="20.31" width="24.22" height=".63" rx=".32" ry=".32"/>
    </svg>
)

export default SvgCustomStoreBanne