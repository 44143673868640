import * as React from 'react';

const SvgCustomAddCar = (props) => (
    <svg
      	xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 672 502.32'
		style={{
			enableBackground: 'new 0 0 512 512',
		}}
		xmlSpace='preserve'
		width='1em'
		height='1em'
		className='svg-icon' fill='currentColor'
		{...props}>
        <path d="m519.32,502.32c84.32,0,152.68-68.36,152.68-152.68s-68.36-152.68-152.68-152.68-152.68,68.36-152.68,152.68,68.36,152.68,152.68,152.68Zm-14.32-99.82v-38.5s-38.5,0-38.5,0c-6.25,0-12.85-4.9-14.26-10.98-2.22-9.58,4.91-18.02,14.09-18.02h38.67v-38.5c0-6.25,4.9-12.85,10.98-14.26,9.58-2.22,18.02,4.91,18.02,14.09v38.67h38.5c6.25,0,12.85,4.9,14.26,10.98,2.22,9.58-4.91,18.02-14.09,18.02h-38.67v38.67c0,9.18-8.44,16.31-18.02,14.09-6.09-1.41-10.98-8.01-10.98-14.26Z" />
        <path d="m333.83,349.88c0-90.4,64.66-165.68,150.25-182.16l-35.61-101.67C434.6,26.5,397.32,0,355.41,0h-186.56c-41.65,0-78.71,26.49-92.49,66.02l-35.93,103.19C16.74,179.07,0,202.58,0,230v197.17c0,18.13,14.7,32.83,32.83,32.83h32.34c18.13,0,32.83-14.7,32.83-32.83v-49.17h237.95c-1.39-9.17-2.12-18.56-2.12-28.12ZM139.31,87.85c4.59-13.07,16.95-21.85,30.83-21.85h184.98c13.88,0,26.24,8.78,30.83,21.85l26.64,76.15H112.67l26.64-76.15Zm-40.83,207.81c-18.13,0-32.83-14.7-32.83-32.83s14.7-32.83,32.83-32.83,32.83,14.7,32.83,32.83-14.7,32.83-32.83,32.83Z" />
    </svg>

)
export default SvgCustomAddCar;