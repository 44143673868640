import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Alert from '../bootstrap/Alert';
import Button from '../bootstrap/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import PropTypes from 'prop-types';
import { searchJournal } from '../../odooApi/accountJournal';
import AuthContext from '../../contexts/authContext';
import Label from '../bootstrap/forms/Label';
import Select from '../bootstrap/forms/Select';
import Option from '../bootstrap/Option';
import moment from 'moment';
import { payFacture } from '../../odooApi/pay';
import { searchFactures, updateFacture } from '../../odooApi/accountMove';
import { organizationDataFacturation } from '../../common/function/organizationDataDevis';
import FactureContext from '../../contexts/facturationContext';
import Icon from '../icon/Icon';
import { uploadAttachmentPayment } from '../../odooApi/accountPayment';
import Spinner from '../bootstrap/Spinner';
import Attachements from '../Attachements/Attachements';
import StatusPopup from '../PlanningCalendar/StatusPopup';
import { paymentJustifyAPI } from '../../odooApi/paiementMail';
import { uploadAttachementsCommercial } from '../../odooApi/planningSlot';
import { paymentJustify, uploadAttachmentPaymentJustify } from '../../odooApi/paymentJustify';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import ErrorPopup from '../extras/ErrorPopup';
export default function ComptabiliserPopup({
	setPaymentModalStatus,
	amountResidual,
	invoiceId,
	Invoice,
	setAcompte,
}) {
	const { userData } = useContext(AuthContext);
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { setFacture } = useContext(FactureContext);

	const minAmount = 0;

	// const updateFactureContext = (values) => {
	// 	setFacture((prevFactures) => [
	// 		...prevFactures.map((group) => {
	// 			if (
	// 				group.payment === values.invoice_payment_state &&
	// 				group.state === values.state
	// 			) {
	// 				return {
	// 					...group,
	// 					cards: [...group.cards, values],
	// 				};
	// 			}

	// 			return group;
	// 		}),
	// 	]);
	// };

	const updateFactureContext = (values) => {
		console.log('values.id', values);
		Invoice.acompte = values?.acompte;
		Invoice.state = 'posted';
		setFacture((prevFactures) => [
			...prevFactures.map((group) => {
				if (group.pay === values.invoice_payment_state && group.state === values.state) {
					let cardIndex = group.cards.findIndex((card) => card.id === values.id);
					console.log('cardIndex', cardIndex);
					if (cardIndex !== -1) {
						console.log(values.id, 'existe');
						let newCards = [...group.cards];
						newCards[cardIndex] = values;
						return {
							...group,
							cards: newCards,
						};
					} else {
						return {
							...group,
							cards: [values, ...group.cards],
						};
					}
				}

				return {
					...group,
					cards: group.cards.filter((card) => card.id !== values.id),
				};
			}),
		]);
	};

	const formik = useFormik({
		initialValues: {
			amount: amountResidual,
			accompte: '',
		},
		onSubmit: (values) => {
			setIsLoading(true);
			if (Invoice?.state == 'posted') {
				updateFacture(
					userData?.password,
					{
						id: invoiceId,
						accompte: parseFloat(values?.amount),
					},
					() => {
						updateFactureContext({
							...Invoice,
							accompte: parseFloat(values?.amount),
						});
						setAcompte(values?.amount);
						setIsLoading(false);
						setPaymentModalStatus(false);
					},
				);
			} else {
				updateFacture(
					userData?.password,
					{
						id: invoiceId,
						state: 'posted',
						accompte: values?.amount,
					},
					() => {
						updateFactureContext({
							...Invoice,
							state: 'posted',
							accompte: values?.amount,
						});
						setAcompte(values?.amount);
						setIsLoading(false);
						setPaymentModalStatus(false);
					},
				);
			}
		},
	});
	const handelBlurAmount = (e) => {
		formik.setFieldValue(
			'amount',
			(e.target.value < minAmount && minAmount) ||
				(e.target.value > amountResidual && amountResidual) ||
				e.target.value,
		);
	};

	const handelBlurPercent = (e) => {
		const amount = amountResidual * (e.target.value / 100);
		formik.setFieldValue('amount', amount);
	};

	return (
		<Modal
			setIsOpen={() => {}}
			isOpen={true}
			isStaticBackdrop
			isScrollable
			size='md'
			classNameMd='nv-3'>
			<ModalHeader setIsOpen={setPaymentModalStatus} className='pb-0'>
				<></>
			</ModalHeader>
			<ModalBody className='pt-0'>
				<Card shadow='none'>
					<CardHeader className='pt-0'>
						<CardLabel icon='Payments' iconColor='primary'>
							<CardTitle>Comptabiliser cette facture</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody>
						<div className='row g-4'>
							<ChecksGroup className=' mt-2 ms-3'>
								<Checks
									type='radio'
									id='normal'
									label='Facture normale'
									name='accompte'
									value='normal'
									onChange={formik.handleChange}
									checked={formik.values.accompte == 'normal' && true}
								/>
								<Checks
									type='radio'
									id='pourcentage'
									label='Acompte (pourcentage)'
									name='accompte'
									value='pourcentage'
									onChange={formik.handleChange}
									checked={formik.values.accompte == 'pourcentage' && true}
								/>
								<Checks
									type='radio'
									id='montant'
									label="Montant de l'acompte (montant fixe)"
									name='accompte'
									value='montant'
									onChange={formik.handleChange}
									checked={formik.values.accompte == 'montant' && true}
								/>
							</ChecksGroup>
							{formik?.values?.accompte == 'pourcentage' && (
								<FormGroup className='col-6' id='percent' label='pourcentage %'>
									<Input
										onChange={formik.handleChange}
										onBlur={handelBlurPercent}
										value={formik.values.percent}
										name='percent'
										type='number'
										min={minAmount}
										max={amountResidual}
										autoComplete='off'
									/>
								</FormGroup>
							)}
							{formik?.values?.accompte == 'montant' && (
								<FormGroup className='col-6' id='amount' label='Montant *'>
									<Input
										onChange={formik.handleChange}
										onBlur={handelBlurAmount}
										value={formik.values.amount}
										name='amount'
										type='number'
										min={minAmount}
										max={amountResidual}
										autoComplete='off'
									/>
								</FormGroup>
							)}
						</div>
					</CardBody>
				</Card>
			</ModalBody>
			<ModalFooter>
				<Button
					color='primary'
					className='w-auto'
					type='submit'
					onClick={formik.handleSubmit}>
					Enregistrer
				</Button>
			</ModalFooter>
			{!!error && (
				<ErrorPopup
					error={error}
					setOpen={setError}
				/>
			)}
		</Modal>
	);
}
