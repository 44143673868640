import React, { useState } from 'react';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import PropTypes from 'prop-types';
import Icon from '../../icon/Icon';
import useOutsideClick from './useOutsideClick';

const SearchAutoComplete = ({
	value,
	name,
	onChange,
	className,
	label,
	options,
	setSelectItem = () => {},
	isTouched = null,
	isValid = null,
	onBlur = null,
	invalidFeedback = null,
}) => {
	const [openListItems, setOpenListItems] = useState(false);
	const handelChange = (e) => {
		onChange(e);
		setOpenListItems(true);
	};
	const handelSelect = (item) => {
		setSelectItem(item);
		setOpenListItems(false);
	};
	const handleClickOutside = () => {
		setOpenListItems(false);
	};

	const ref = useOutsideClick(handleClickOutside);
	return (
		<div ref={ref} className={className}>
			<FormGroup className={`autocomplete `} id={name || 'adrsFact'} isFloating label={label}>
				<Input
					placeholder={label}
					onChange={handelChange}
					value={value || ''}
					name={name || 'adrsFact'}
					type='search'
					autoComplete='off'
					isTouched={isTouched}
					isValid={isValid}
					onBlur={onBlur}
					invalidFeedback={invalidFeedback}
				/>
				{!!options?.length && openListItems && (
					<div id='myInputautocomplete-list' className='autocomplete-items'>
						<div className='body'>
							{options?.map((item, idx) => {
								return (
									<div key={idx} id={item?.id} onClick={() => handelSelect(item)}>
										<Icon icon={item?.icon || 'AddLocation'} size='2x' />
										{item?.label}
									</div>
								);
							})}
						</div>
					</div>
				)}
			</FormGroup>
		</div>
	);
};
SearchAutoComplete.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	value: PropTypes.any.isRequired,
	onChange: PropTypes.func,
	className: PropTypes.string,
	label: PropTypes.string,
	options: PropTypes.array,
	setSelectItem: PropTypes.func,
};
export default SearchAutoComplete;
