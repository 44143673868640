import React, { createContext, useState } from 'react';

const MissedCallsContext = createContext();

export const MissedCallsContextProvider = ({ children }) => {
	const [missedCalls, setMissedCalls] = useState(false);

	return (
		<MissedCallsContext.Provider value={{ missedCalls, setMissedCalls }}>
			{children}
		</MissedCallsContext.Provider>
	);
};

export default MissedCallsContext;
