import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	ACTIVITY_API_URL = '/mail.activity',
	ACTIVITY_TYPE_API_URL = '/mail.activity.type',
	ACTIVITY_NOTE_API_URL = '/mail.activity.note',
	LIMIT = 20;
export const searchActivities = (
	tokenUserCurent,
	filters,
	fnSuccess,
	fnError = () => {},
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACTIVITY_API_URL + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const getAllActivities = (tokenUserCurent, fnSuccess, fnError = () => {}) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACTIVITY_API_URL}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const createActivity = (tokenUserCurent, data, fnSuccess, fnError = () => {}) => {
	var config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACTIVITY_API_URL}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: JSON.stringify(data),
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const getAllActivitiesTypes = (tokenUserCurent, fnSuccess, fnError = () => {}) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACTIVITY_TYPE_API_URL}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const createActivityType = (tokenUserCurent, data, fnSuccess, fnError = () => {}) => {
	var config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACTIVITY_TYPE_API_URL}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: JSON.stringify(data),
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

// export const deleteActivityType = (tokenUserCurent, id, fnSuccess, fnError = () => {}) => {
// 	var config = {
// 		method: 'delete',
// 		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACTIVITY_TYPE_API_URL + '/' + id}`,
// 		headers: {
// 			Authorization: `Basic ${btoa(
// 				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
// 			)}`,
// 			'Content-Type': 'application/json',
// 		},
// 	};
// 	axios(config)
// 		.then(function (response) {
// 			fnSuccess(response?.data || []);
// 		})
// 		.catch(function (error) {
// 			fnError(error);
// 		});
// };
export const deleteActivityType = (tokenUserCurent, id, fnSuccess, fnError = () => {}) => {
	var config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACTIVITY_TYPE_API_URL + '/' + id}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: JSON.stringify({ id: id, active: false }),
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const createActivityNote = (tokenUserCurent, data, fnSuccess, fnError = () => {}) => {
	var config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACTIVITY_NOTE_API_URL}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: JSON.stringify(data),
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const updateActivityNote = (tokenUserCurent, data, fnSuccess, fnError = () => {}) => {
	var config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACTIVITY_NOTE_API_URL + '/' + data?.id}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: JSON.stringify(data),
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const deleteActivityNote = (tokenUserCurent, id, fnSuccess, fnError = () => {}) => {
	var config = {
		method: 'delete',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACTIVITY_NOTE_API_URL + '/' + id}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const updateActivity = (tokenUserCurent, data, fnSuccess, fnError = () => {}) => {
	var config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACTIVITY_API_URL + '/' + data?.id}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: JSON.stringify(data),
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const uploadAttachementsActivity = (
	tokenUserCurent,
	idSlot,
	attachements,
	fnc,
	fnError = () => {},
) => {
	let data = new FormData();
	Object.keys(attachements)?.map((key) => {
		data.append('attachment_ids', attachements[key]);
	});
	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_attachments' +
			ACTIVITY_API_URL +
			'/' +
			idSlot +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};
	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			fnError(error);
		});
};

export const deleteActivity = (tokenUserCurent, id, fnSuccess, fnError = () => {}) => {
	var config = {
		method: 'delete',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACTIVITY_API_URL + '/' + id}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const searchActivityType = (
	tokenUserCurent,
	filters,
	fnSuccess,
	fnError = () => {},
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + ACTIVITY_TYPE_API_URL + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};

	axios(config)
		.then(function (response) {
			fnSuccess(response?.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};
