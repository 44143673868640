import moment from 'moment';
import { useTranslation } from 'react-i18next';
import COLORS from '../../common/data/enumColors';
import { CardBody, CardLabel, CardTitle } from '../bootstrap/Card';
import Modal, { ModalBody, ModalHeader } from '../bootstrap/Modal';

export default function PopUpSeachParentIntervention({
	setParentIntervention,
	parentInterventionsData,
	handelSubmit,
	formState,
}) {
	const { t } = useTranslation(['translation', 'menu']);

	const getColor = (event) => {
		const color =
			event?.state == 'draft'
				? COLORS.WARNING.name
				: event.state == 'sale'
				? COLORS.INFO.name
				: event.state == 'cancel'
				? COLORS.DANGER.name
				: COLORS.SUCCESS.name;
		return color;
	};
	console.log('formState', formState);
	return (
		<Modal isOpen={true} setIsOpen={() => {}} size={'xl'} classNameMd='nv-3'>
			<ModalHeader
				setIsOpen={() => {
					setParentIntervention(false);
				}}>
				<CardLabel iconColor='primary'>
					<CardTitle>Veuillez associer l'intervention du devis à la pose </CardTitle>
				</CardLabel>
			</ModalHeader>
			<ModalBody>
				<CardBody className='table-responsive'>
					<table className='table table-modern table-hover'>
						<thead>
							<tr>
								<th scope='col'>N° Int</th>
								<th scope='col'>Type</th>
								<th scope='col'>Status</th>
								<th scope='col'>Date</th>
							</tr>
						</thead>
						<tbody>
							{parentInterventionsData?.map((line) => {
								return (
									<tr
										key={line.id}
										className='cursor-pointer'
										onClick={() => {
											formState.parent_id = line?.id;
											handelSubmit();
											setParentIntervention(false);
										}}>
										<td>
											<div>{line.name_seq}</div>
										</td>
										<td>
											{line.type_int}
											<div>
												{' '}
												<small>{line.motif}</small>
											</div>
										</td>

										<td>
											<span
												class={`p-3 rounded-2 bg-l10-${getColor(
													line,
												)} text-${getColor(line)}`}>
												{t(line.state)}
											</span>
										</td>

										<td>
											<div>
												{moment(line?.start_datetime).format('DD-MM-YYYY')}
											</div>
											<small>
												{' '}
												{moment(line?.start_datetime).format(
													'hh:mm',
												)} - {moment(line?.end_datetime).format('hh:mm')}
											</small>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</CardBody>
			</ModalBody>
		</Modal>
	);
}
