import React, { createContext, useMemo, useState } from 'react';

const ActivitiesContext = createContext([]);

export const ActivitiesContextProvider = ({ children }) => {
	const [activities, setActivities] = useState([]);

	const value = useMemo(
		() => ({
			activities,
			setActivities,
		}),
		[activities],
	);
	return <ActivitiesContext.Provider value={value}>{children}</ActivitiesContext.Provider>;
};

export default ActivitiesContext;
