const ERRORS = {
    "100": "La requête continue",
    "101": "Changement de protocole en cours",
    "102": "Traitement en cours",
    "103": "Informations précoces",

    "200": "OK - Requête réussie",
    "201": "Créé avec succès",
    "202": "Accepté",
    "203": "Information non autoritaire",
    "204": "Aucun contenu trouvé",
    "205": "Réinitialisation du contenu",
    "206": "Contenu partiel",
    "207": "Multi-états",
    "208": "Déjà signalé",
    "226": "IM utilisé",

    "300": "Choix multiple",
    "301": "Déplacé de façon permanente",
    "302": "Trouvé",
    "303": "Voir autre",
    "304": "Aucune modification apportée",
    "305": "Utiliser un proxy",
    "307": "Redirection temporaire",
    "308": "Redirection permanente",

    "400": "Requête incorrecte",
    "401": "Non autorisé - Vérifiez vos identifiants",
    "402": "Paiement requis",
    "403": "Accès interdit - Vous n'avez pas les autorisations nécessaires",
    "404": "Non trouvé - La ressource demandée est introuvable",
    "405": "Méthode non autorisée",
    "406": "Non acceptable",
    "407": "Authentification de proxy requise",
    "408": "Délai d'attente de la requête - La requête a pris trop de temps",
    "409": "Conflit - Des conflits de données ont été détectés",
    "410": "Gone - La ressource n'est plus disponible",
    "411": "Longueur requise",
    "412": "Échec de la précondition",
    "413": "Charge trop grande",
    "414": "URI trop longue",
    "415": "Type de média non pris en charge",
    "416": "Plage non satisfaisante",
    "417": "Attente de l'espérance",
    "418": "Je suis une théière",
    "421": "Requête mal dirigée",
    "422": "Entité non traitable",
    "423": "Verrouillé",
    "424": "Échec de la dépendance",
    "425": "Trop tôt",
    "426": "Mise à niveau requise",
    "428": "Précondition requise",
    "429": "Trop de demandes - Veuillez réduire la fréquence de vos demandes",
    "431": "Champs d'en-tête de la requête trop grands",
    "451": "Indisponible pour des raisons légales",

    "500": "Erreur interne du serveur - Nous rencontrons des problèmes techniques",
    "501": "Non implémenté",
    "502": "Passerelle incorrecte",
    "503": "Service non disponible - Réessayez ultérieurement",
    "504": "La connexion a mis trop de temps à répondre",
    "505": "Version HTTP non prise en charge",
    "506": "La variante négocie également",
    "507": "Espace de stockage insuffisant",
    "508": "Détection de boucle",
    "510": "Non étendu",
    "511": "Authentification réseau requise"
};

export default ERRORS;
