import React, { useEffect, useContext } from 'react';
import Button from '../../bootstrap/Button';
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../bootstrap/Modal';
import { Textarea } from '../../icon/bootstrap';
import Icon from '../../icon/Icon';
import moment from 'moment';
import { createClientPartner } from '../../../odooApi/clientPartner';
import AuthContext from '../../../contexts/authContext';
import { convertURL } from '../../../common/function/functionsUtils';

export default function PosePopup({
	setposePopup,
	setFormState,
	formState,
	setPose,
	pose,
	setAttachmentsCommercial,
	handelSelect,
}) {
	const { userData } = useContext(AuthContext);
	console.log(
		!!pose?.Remarque
			? pose?.Remarque.replace(/<br\s*\/?>/g, '\n').replace(/<\/?[^>]+(>|$)/g, '')
			: '',
	);
	const handelCreate = () => {
		if (!!pose?.clientData?.id) {
			const clientObject = {
				type_int: pose?.type_int,
				partner_id: pose?.clientData?.id,
				client: pose?.clientData?.name,
				Remarque: !!pose?.Remarque
					? pose?.Remarque.replace(/<br\s*\/?>/g, '\n').replace(/<\/?[^>]+(>|$)/g, '')
					: '',
				devis_liste: pose?.devis_liste,
			};
			handelSelect({
				selected: { client: pose?.clientData },
				value: clientObject,
			});
		} else {
			const clientObject = {
				Remarque: !!pose?.Remarque
					? pose?.Remarque.replace(/<br\s*\/?>/g, '\n').replace(/<\/?[^>]+(>|$)/g, '')
					: '',
			};
			handelSelect({
				selected: { client: {} },
				value: clientObject,
			});
		}
		!!pose?.attachmentsCommercial && !!pose?.attachmentsCommercial?.length
			? convertURL(
					pose?.attachmentsCommercial,
					'Rapport_questionnaire',
					(file) => {
						setAttachmentsCommercial([file]);
					},
					(error) => {
						console.error('Erreur :', error);
					},
			  )
			: setAttachmentsCommercial([]);
		// handelSelect(clientObject);

		if (pose?.attachments?.length > 0) {
			pose?.attachments?.map((item) => {
				convertURL(
					item?.url,
					item?.name,
					(file) => {
						setAttachmentsCommercial((prev) => [...prev, file]);
					},
					(error) => {
						console.error('Erreur :', error);
					},
				);
			});
		}
		setposePopup(false);
	};

	const handelDelete = () => {
		setposePopup(false);
	};

	return (
		<Modal setIsOpen={() => {}} isOpen={true} isScrollable size='md' classNameMd='nv-3'>
			<ModalHeader
				setIsOpen={() => {
					setposePopup(false);
				}}></ModalHeader>
			<ModalBody>
				<Card shadow='none' isCompact>
					<CardBody>
						<div className='row g-4'>
							<div className='col-12 text-center'>
								<Icon icon={'Report'} size='5x' color={'primary'} />
							</div>
							<div className='col-12 text-center'>
								<h4>
									{/* Êtes-vous sûr de vouloir planifier une intervention avec ses
										informations ? */}
									Créer une intervention avec ces données ?
								</h4>
							</div>

							{!!pose?.clientData && !!Object.keys(pose?.clientData).length && (
								<>
									{!!pose?.clientData?.name && (
										<div className='col-lg-6 col-12'>
											<div className='d-flex align-items-center'>
												<div className='flex-shrink-0'>
													<Icon
														icon='AssignmentInd'
														size='2x'
														color='primary'
													/>
												</div>
												<div className='flex-grow-1 ms-3'>
													<div className='text-muted'>Client</div>
													<div className='fw-bold fs-6 mb-0'>
														{pose?.clientData?.name}
													</div>
												</div>
											</div>
										</div>
									)}
									{!!pose?.clientData?.email && (
										<div className='col-lg-6 col-12'>
											<div className='d-flex align-items-center'>
												<div className='flex-shrink-0'>
													<Icon
														icon='AssignmentInd'
														size='2x'
														color='primary'
													/>
												</div>
												<div className='flex-grow-1 ms-3'>
													<div className='text-muted'>E-mail</div>
													<div className='fw-bold fs-6 mb-0'>
														{pose?.clientData?.email}
													</div>
												</div>
											</div>
										</div>
									)}
									{!!pose?.clientData?.mobile && (
										<div className='col-lg-6 col-12'>
											<div className='d-flex align-items-center'>
												<div className='flex-shrink-0'>
													<Icon
														icon='AssignmentInd'
														size='2x'
														color='primary'
													/>
												</div>
												<div className='flex-grow-1 ms-3'>
													<div className='text-muted'>Tél client</div>
													<div className='fw-bold fs-6 mb-0'>
														{pose?.clientData?.mobile}
													</div>
												</div>
											</div>
										</div>
									)}
									{(!!pose?.clientData?.street ||
										!!pose?.clientData?.zip ||
										!!pose?.clientData?.city ||
										!!pose?.clientData?.street2) && (
										<div className='col-lg-6 col-12'>
											<div className='d-flex align-items-center'>
												<div className='flex-shrink-0'>
													<Icon
														icon='AssignmentInd'
														size='2x'
														color='primary'
													/>
												</div>
												<div className='flex-grow-1 ms-3'>
													<div className='text-muted'>Adresse client</div>
													<div className='fw-bold fs-6 mb-0'>
														{pose?.clientData?.street +
															' ' +
															pose?.clientData?.zip +
															' ' +
															pose?.clientData?.city}
													</div>
												</div>
											</div>
										</div>
									)}
								</>
							)}

							{!!pose?.type_int && (
								<div className='col-lg-6 col-12'>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<Icon icon='EventNote' size='2x' color='primary' />
										</div>
										<div className='flex-grow-1 ms-3'>
											<div className='text-muted'>Type d'intervention</div>
											<div className='fw-bold fs-6 mb-0'>
												{pose?.type_int}
											</div>
										</div>
									</div>
								</div>
							)}
							{!!pose?.devis?.length && (
								<div className='col-lg-6 col-12'>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<Icon icon='Assignment' size='2x' color='primary' />
										</div>
										<div className='flex-grow-1 ms-3'>
											<div className='text-muted'>N° devis</div>
											<div className='fw-bold fs-6 mb-0'>{pose['devis']}</div>
										</div>
									</div>
								</div>
							)}
							{!!pose?.Remarque && (
								<div className='col-12'>
									<div className='d-flex '>
										<div className='flex-shrink-0'>
											<Icon icon='Assignment' size='2x' color='primary' />
										</div>
										<div className='flex-grow-1 ms-3'>
											<div className='text-muted'>Remarque</div>
											<div className=' fs-6 mb-0'>
												{React.createElement('div', {
													dangerouslySetInnerHTML: {
														__html: (pose?.Remarque || '').replace(
															/\n/g,
															'<br />',
														),
													},
												})}
												{/* {pose?.Remarque} */}
											</div>
										</div>
									</div>
								</div>
							)}

							{/* <div className='row mt-3'>
								<div className='d-flex align-items-center col-lg-4'>
									<div className='flex-shrink-0'>
										<Icon icon='Event' size='2x' color='warning' />
									</div>
									<div className='flex-grow-1 ms-3'>
										<div className='fw-bold fs-6 mb-0'>
											{moment(`${formState['start_datetime']}`).format(
												'yyyy-MM-DD',
											)}
										</div>
										<div className='text-muted'>Date</div>
									</div>
								</div>
								<div className='d-flex align-items-center col-lg-4'>
									<div className='flex-shrink-0'>
										<Icon icon='AccessTime' size='2x' color='warning' />
									</div>
									<div className='flex-grow-1 ms-3'>
										<div className='fw-bold fs-6 mb-0'>
											{moment(`${formState['end_datetime']}`).format(
												'HH:mm:ss',
											)}
										</div>
										<div className='text-muted'>Temps</div>
									</div>
								</div>
							</div> */}
							<div className='col-12'>
								<Button
									color='primary'
									className='w-100 py-3'
									type='submit'
									isLight
									onClick={() => handelCreate()}>
									Planifier avec ces données
								</Button>
							</div>
							<div className='col-12'>
								<Button
									color='primary'
									className='w-100 py-3'
									type='submit'
									onClick={() => handelDelete()}>
									Planifier avec de nouvelles données
								</Button>
							</div>
						</div>
					</CardBody>
				</Card>
			</ModalBody>
		</Modal>
	);
}
