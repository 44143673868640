import React, { useContext } from 'react';
import { useState } from 'react';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Label from '../../bootstrap/forms/Label';
import PropTypes from 'prop-types';
import AuthContext from '../../../contexts/authContext';
import SearchPopUp from './SearchPopUp';
import { getImageByModule } from '../../../common/function/functionsUtils';

const InputTextSearch = ({ data, handelChange, currentFormValue }) => {
	const [value, setValue] = useState('');
	const [filterResult, setFilterResult] = useState({});
	const { userData } = useContext(AuthContext);
	const [searchModalStatus, setSearchModalStatus] = useState(false);
	const [selectValue, setSelectValue] = useState({});

	const handleChange = (e) => {
		setValue(e.target.value);
		setSearchModalStatus(true);
		setSelectValue({});
		handelChange(true, {});

		if (userData && userData?.id && userData?.password) {
			data?.fetchData(userData?.password, e.target.value, currentFormValue, (optionsData) => {
				console.log('optionsDataoptionsDataoptionsData', optionsData);
				setFilterResult(optionsData);
			});
		}
	};

	const handelSelect = (props) => {
		setSelectValue(props);
		handelChange(data?.nextStepData || false, props);
	};

	const handelFocus = () => {
		setSearchModalStatus(true);
		if (userData && userData?.id && userData?.password) {
			data?.fetchData(
				userData?.password,
				selectValue?.value || value,
				currentFormValue,
				(optionsData) => {
					console.log('optionsDataoptionsDataoptionsData2', optionsData);
					setFilterResult(optionsData);
				},
			);
		}
	};

	return (
		<>
			<FormGroup className={data.className || 'col-12'}>
				{data?.label && <Label>{data?.label}</Label>}
				<Input
					type={'search'}
					id={data?.id}
					name={data?.id}
					placeholder={data?.placeholder || ''}
					autoComplete={'off'}
					value={selectValue?.value || value}
					onChange={handleChange}
					onFocus={handelFocus}
				/>
			</FormGroup>

			{searchModalStatus && (
				<SearchPopUp
					setSearchModalStatus={setSearchModalStatus}
					filterResult={filterResult}
					value={selectValue?.value || value}
					setValue={setValue}
					name={data?.id}
					label={data?.label}
					setSelectValue={handelSelect}
					handelChange={handleChange}
					linkImage={(token, productId) =>
						getImageByModule(token, productId, data?.module)
					}
				/>
			)}
		</>
	);
};

InputTextSearch.propTypes = {
	data: PropTypes.object.isRequired,
	handelChange: PropTypes.func.isRequired,
	currentFormValue: PropTypes.any.isRequired,
};

export default InputTextSearch;
