import axios from 'axios';

const REMINDER_CLIENT_MICROSERVICE_API_URL =
	process.env.REACT_APP_REMINDER_CLIENT_MICROSERVICE_API_URL;

export const getSingleClient = async (tokenUserCurent, idClient, fn) => {
	var config = {
		method: 'get',
		url: '/reminder' + REMINDER_CLIENT_MICROSERVICE_API_URL + '/' + idClient,
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
		},
	};

	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const searchClients = (tokenUserCurent, conditions, fn) => {
	var config = {
		method: 'patch',
		url: '/reminder' + REMINDER_CLIENT_MICROSERVICE_API_URL + '/search',
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
			'Content-Type': 'application/json',
		},
		data: JSON.stringify(conditions),
	};

	console.log(JSON.stringify(conditions));

	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const updateClient = (
	tokenUserCurent,
	idClient,
	oldClient,
	fnSucces,
	fnError = () => {},
) => {
	const data = JSON.stringify(oldClient);
	var config = {
		method: 'put',
		url: '/reminder' + REMINDER_CLIENT_MICROSERVICE_API_URL + '/' + idClient,
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			fnSucces(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};
