const typeIntervention = [
	{
		id: 0,
		name: 'Pose',
	},
	{
		id: 1,
		name: 'Devis',
	},
	{
		id: 2,
		name: 'Intervention',
	},
	{
		id: 3,
		name: 'Reclamation',
	},
	{
		id: 4,
		name: 'Déblocage',
	},
	{
		id: 5,
		name: 'Prendre mesure',
	},
	{
		id: 6,
		name: 'Enlèvement',
	},
];

export default typeIntervention;
