import React, { useContext, useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { FUNDING } from '@paypal/react-paypal-js';
import StatusPopup from '../../../../../components/PlanningCalendar/StatusPopup';
import { paymentJustify } from '../../../../../odooApi/paymentJustify';
import moment from 'moment';
import { paymentJustifyAPI } from '../../../../../odooApi/paiementMail';
import { MailMenu } from '../../../../../gestioneerMenu';
import { useNavigate } from 'react-router-dom';
import ClientMontantContext from '../../../../../contexts/clientMontantContext';
const paypalClientID = process.env.REACT_APP_PAYPAL_ID;

const PayPalButton = ({ amount, result, token }) => {
	const navigate = useNavigate();

	const { clientMontant, setClientMontant } = useContext(ClientMontantContext);
	const [success, setSuccess] = useState();
	const createOrder = (data, actions) => {
		return actions.order.create({
			purchase_units: [
				{
					amount: {
						value: amount,
					},
				},
			],
		});
	};

	const onApprove = (data, actions) => {
		return actions.order.capture().then(function (details) {
			
			// paymentJustifyAPI(
			// 	payInfo,

			// 	(result) => {
			// 		console.log(result?.result);
			// 		const elements = result?.result;
			// 		setClientMontant({
			// 			...clientMontant,
			// 			client: result?.customer_id,
			// 			date: moment(new Date()).format('YYYY-MM-DD'),
			// 			Moyen: 'PayPal',
			// 		});
			// 	},
			// 	(error) => {
			// 		console.log('error', error);
			// 	},
			// );
			setClientMontant({...clientMontant,client:result,paiement:'Paypal'})
			navigate("/confirmation")
			//setSuccess('Transaction terminée par ' + details.payer.name.given_name);
			//alert('Transaction completed by ' + details.payer.name.given_name);
		});
	};

	return (
		<div>
			<PayPalScriptProvider options={{ currency: 'EUR', 'client-id': paypalClientID }}>
				<PayPalButtons
					createOrder={createOrder}
					onApprove={onApprove}
					fundingSource={FUNDING.PAYPAL}
				/>
			</PayPalScriptProvider>
			{!!success && (
				<StatusPopup
					icon='Verified'
					color='success'
					title={'Super!'}
					label={success}
					setOpen={setSuccess}
				/>
			)}
		</div>
	);
};

export default PayPalButton;
