import React from "react";
import { useState } from "react";
import FormGroup from "../bootstrap/forms/FormGroup";
import Input from "../bootstrap/forms/Input";
import Label from "../bootstrap/forms/Label";
import PropTypes from 'prop-types';
import formInputsTypes from "../../common/data/simulatorData/simulatorConstant";

const InputNumber = ({data,handelChange}) => {
    const [value,setValue] = useState(data?.defaultValue||(data?.type==formInputsTypes.INPUT_NUMBER?0:""));

    const handleChange = (e) => {
        setValue(e.target.value);
        
        if(!data?.validation||data?.validation(e.target.value)){
            console.log("dcbdgcgdvcgvdgcvd",data?.nextStepData,data?.nextStep,e.target.value);
            handelChange(data?.nextStepData||data?.nextStep||false,e.target.value||0);
        }/* else{
            console.log("tesssst");
            handelChange(false,e.target.value);
        } */
        
    }
   
    return (
        
        <FormGroup className={data.className||"col-12"}>
            {
                data?.label&&
                <Label>
                    {data?.label}
                </Label>
            }
            <Input
                type={ data?.type|| "number" }
                id={ data?.id }
                name={ data?.id }
                placeholder={ data?.placeholder||"" }
                autoComplete={ "off" }
                value={value}
                onChange={ handleChange }
            />
            
        </FormGroup>
           
    )
};

InputNumber.propTypes = {
	
	data:PropTypes.object.isRequired,
    handelChange:PropTypes.func.isRequired
};

export default InputNumber;