import React, { useContext, useEffect, useState, useCallback } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import FormGroup from '../bootstrap/forms/FormGroup';
import Button from '../bootstrap/Button';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Textarea from '../bootstrap/forms/Textarea';
import { useFormik } from 'formik';
import Input from '../bootstrap/forms/Input';
import moment from 'moment';
import AuthContext from '../../contexts/authContext';
import { searchClients, updateClient } from '../../reminderApi/Clients';
import { updateCall } from '../../reminderApi/Consumptions';
import { useNavigate } from 'react-router-dom';
import { devisMenu, planningMenu } from '../../gestioneerMenu';
import Label from '../bootstrap/forms/Label';
import Icon from '../icon/Icon';

export default function StartCallingPopup({ event, onClose }) {
	const clientNumber =
		event?.ressource != event?.data.Calling
			? event?.data?.Calling.replace(/^00/, '').replace(
					/(\d{2})(\d)(\d{2})(\d{2})(\d{2})(\d{2})/,
					'+$1 $2 $3 $4 $5 $6',
			  )
			: event?.data?.Called.replace(/^00/, '').replace(
					/(\d{2})(\d)(\d{2})(\d{2})(\d{2})(\d{2})/,
					'+$1 $2 $3 $4 $5 $6',
			  );
	const { userData } = useContext(AuthContext);
	const [clientData, setclientData] = useState();
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			comment: '',
			displayName: '',
			etat: '',
		},
		onSubmit: (values) => {
			clientData &&
				updateClient(userData?.tokenJava, clientData?.id, {
					displayName: values.displayName,
				});
			const existingCalls = JSON.parse(localStorage.getItem('calls') || '{}');
			const savedCall = existingCalls[event?.data.CallId];
			if (!savedCall?.saved) {
				savedCall.saved = true;
				savedCall.data = {
					comment: values.comment,
					etat: values.etat,
					displayName: values.displayName,
				};
				existingCalls[event?.data.CallId] = savedCall;
				localStorage.setItem('calls', JSON.stringify(existingCalls));
			} else {
				userData?.tokenJava &&
					values.displayName &&
					updateClient(userData?.tokenJava, savedCall.clientId, {
						displayName: values.displayName,
					});
				if (userData?.tokenJava && (values.etat || values.comment)) {
					updateCall(
						userData?.tokenJava,
						savedCall.callId,
						{ comment: values.comment, etat: values.etat },
						() => {
							delete existingCalls[event?.data.CallId];
							localStorage.setItem('calls', JSON.stringify(existingCalls));
						},
					);
				} else {
					delete existingCalls[event?.data.CallId];
					localStorage.setItem('calls', JSON.stringify(existingCalls));
				}
			}
			onClose();
		},
	});

	useEffect(() => {
		userData?.tokenJava &&
			searchClients(
				userData?.tokenJava,
				[['phone', '=', clientNumber], 'OR', ['mobile', '=', clientNumber]],
				(result) => {
					formik.setValues({
						comment: '',
						displayName: result[0]?.displayName,
						etat: '',
					});
					setclientData(result[0]);
				},
			);
	}, []);

	return (
		<Modal isOpen={true} setIsOpen={() => {}} size='md' isScrollable>
			<ModalBody className='px-4'>
				<Card shadow={'none'}>
					<CardHeader>
						<CardLabel icon='info' iconColor='primary'>
							<CardTitle>Fiche d'appel</CardTitle>
						</CardLabel>
					</CardHeader>
					<form onSubmit={formik.handleSubmit}>
						<CardBody>
							<div className='row g-3'>
								<div className='col-12 text-center'>
									<h2>
										{clientNumber
											.replace(/^00/, '')
											.replace(
												/(\d{2})(\d)(\d{2})(\d{2})(\d{2})(\d{2})/,
												'+$1 $2 $3 $4 $5 $6',
											)}
									</h2>
								</div>

								<div className='col-12'>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<Icon
												icon='AccountCircle'
												size='2x'
												color='secondary'
											/>
										</div>
										<div className='flex-grow-1 ms-3'>
											<div className='fw-bold fs-6 mb-0'>
												<FormGroup
													id='displayName'
													className='col-12'
													label='Nom du client'>
													<Input
														placeholder='Nom du client ...'
														onChange={formik.handleChange}
														value={formik.values.displayName}
													/>
												</FormGroup>
											</div>
											<div className='text-muted'>Client</div>
										</div>
									</div>
								</div>

								<div className='col-12'>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<Icon icon='Call' size='2x' color='secondary' />
										</div>

										<div className='flex-grow-1 ms-3'>
											<div className='fw-bold fs-6 mb-0'>
												{event?.ressource
													.replace(/^00/, '')
													.replace(
														/(\d{2})(\d)(\d{2})(\d{2})(\d{2})(\d{2})/,
														'+$1 $2 $3 $4 $5 $6',
													)}
											</div>
											<div className='text-muted'>Source</div>
										</div>
									</div>
								</div>

								<div className='row mt-3'>
									<div className='d-flex align-items-center col-lg-4'>
										<div className='flex-shrink-0'>
											<Icon
												icon='CalendarToday'
												size='2x'
												color='secondary'
											/>
										</div>
										<div className='flex-grow-1 ms-3'>
											<div className='fw-bold fs-6 mb-0'>
												{moment(`${event?.data.Datetime}`).format(
													'yyyy-MM-DD',
												)}
											</div>
											<div className='text-muted'>Date</div>
										</div>
									</div>
									<div className='d-flex align-items-center col-lg-4'>
										<div className='flex-shrink-0'>
											<Icon icon='AccessTime' size='2x' color='secondary' />
										</div>
										<div className='flex-grow-1 ms-3'>
											<div className='fw-bold fs-6 mb-0'>
												{moment(`${event?.data.Datetime}`).format(
													'HH:mm:ss',
												)}
											</div>
											<div className='text-muted'>Temps</div>
										</div>
									</div>
								</div>

								<div className='col-12'>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<Icon icon='Settings' size='2x' color='secondary' />
										</div>
										<div className='flex-grow-1 ms-3'>
											<div className='fw-bold fs-6 mb-0'>Motif</div>
										</div>
									</div>
								</div>
								<div className='col-12 ms-3'>
									<FormGroup id='etat'>
										<ChecksGroup>
											<Checks
												type='radio'
												id='Renseignements'
												label='Renseignements'
												name='etat'
												value='Renseignements'
												onChange={formik.handleChange}
												checked={
													formik.values.etat == 'Renseignements' && true
												}
											/>
											<Checks
												type='radio'
												id='Réclamation'
												label='Réclamation'
												name='etat'
												value='Réclamation'
												onChange={formik.handleChange}
												checked={
													formik.values.etat == 'Réclamation' && true
												}
											/>
											<Checks
												type='radio'
												id='Facture'
												label='Facture'
												name='etat'
												value='Facture'
												onChange={formik.handleChange}
												checked={formik.values.etat == 'Facture' && true}
											/>
											<Checks
												type='radio'
												id='Hors Sujet'
												label='Hors sujet'
												name='etat'
												value='Hors sujet'
												onChange={formik.handleChange}
												checked={formik.values.etat == 'Hors sujet' && true}
											/>
										</ChecksGroup>
									</FormGroup>
								</div>

								<div className='col-12'>
									<div className='d-flex align-items-center'>
										<div className='flex-shrink-0'>
											<Icon icon='Comment' size='2x' color='secondary' />
										</div>
										<div className='flex-grow-1 ms-3'>
											<div className='fw-bold fs-6 mb-0'>Commentaire</div>
										</div>
									</div>
								</div>
								<div className='col-12'>
									<FormGroup id='comment'>
										<Textarea
											id='comment'
											name='comment'
											placeholder="Notes sur l'appel ..."
											onChange={formik.handleChange}
											value={formik.values.comment}
										/>
									</FormGroup>
								</div>

								<div className='row mt-5'>
									<div className='col-4'>
										<Button
											color='primary'
											className='w-100'
											type='submit'
											isLight>
											Sauvegarder
										</Button>
									</div>
									<div className='col-4'>
										<Button
											color='primary'
											className='w-100'
											type='submit'
											isLight
											onClick={() => {
												navigate(`../${planningMenu.path}`);
												formik.setValues({
													comment: formik.values.comment,
													displayName: formik.values.displayName,
													etat: 'Rendez-vous',
												});
											}}>
											Rendez-vous
										</Button>
									</div>
									<div className='col-4'>
										<Button
											color='primary'
											className='w-100'
											type='submit'
											isLight
											onClick={() => {
												formik.setValues({
													comment: formik.values.comment,
													displayName: formik.values.displayName,
													etat: 'Devis',
												});
												navigate(`../${devisMenu.path}`);
											}}>
											Devis
										</Button>
									</div>
								</div>
							</div>
						</CardBody>
					</form>
				</Card>
				{/* <div className='row'>
						<div>
							<div className='row g-4'>
								
								<FormGroup
									id='displayName'
									className='col-12'
									label='Nom du client'>
									<Input
										placeholder='Nom du client ...'
										onChange={formik.handleChange}
										value={formik.values.displayName}
									/>
								</FormGroup>
								<FormGroup className='col-12' id='etat'>
									<Label>Motif d'appel</Label>
									<ChecksGroup>
										<Checks
											type='radio'
											id='Renseignements'
											label='Renseignements'
											name='etat'
											value='Renseignements'
											onChange={formik.handleChange}
											checked={formik.values.etat == 'Renseignements' && true}
										/>
										<Checks
											type='radio'
											id='Réclamation'
											label='Réclamation'
											name='etat'
											value='Réclamation'
											onChange={formik.handleChange}
											checked={formik.values.etat == 'Réclamation' && true}
										/>
										<Checks
											type='radio'
											id='Facture'
											label='Facture'
											name='etat'
											value='Facture'
											onChange={formik.handleChange}
											checked={formik.values.etat == 'Facture' && true}
										/>
										<Checks
											type='radio'
											id='Hors Sujet'
											label='Hors sujet'
											name='etat'
											value='Hors sujet'
											onChange={formik.handleChange}
											checked={formik.values.etat == 'Hors sujet' && true}
										/>
									</ChecksGroup>
								</FormGroup>
								<FormGroup id='comment' className='col-12' label='Notes'>
									<Textarea
										placeholder="Notes sur l'appel ..."
										onChange={formik.handleChange}
										value={formik.values.comment}
									/>
								</FormGroup>
							</div>
						</div>
						<Button color='primary	' className='w-100' type='submit'>
							Sauvegarder
						</Button>
					</div> */}
			</ModalBody>
			{/* <ModalFooter className='px-4 pb-4'>
					<Button
						type='submit'
						color='warning'
						className='w-100'
						onClick={() => {
							navigate(`../${planningMenu.planningCalendar.path}`);
							formik.setValues({
								comment: formik.values.comment,
								displayName: formik.values.displayName,
								etat: 'Rendez-vous',
							});
						}}>
						Rendez-vous
					</Button>
					<Button
						type='submit'
						color='warning'
						className='w-100'
						onClick={() => {
							formik.setValues({
								comment: formik.values.comment,
								displayName: formik.values.displayName,
								etat: 'Devis',
							});
							navigate(`../${devisMenu.devis.path}`);
						}}>
						Devis
					</Button>
				</ModalFooter> */}
		</Modal>
	);
}
