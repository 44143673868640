const motifIntervention = [
	{
		id: 0,
		name: 'Rideau',
	},
	{
		id: 1,
		name: 'Volet',
	},
	{
		id: 2,
		name: 'Store',
	},
	{
		id: 3,
		name: 'Porte',
	},
	{
		id: 4,
		name: 'Portail',
	},
	{
		id: 5,
		name: 'Autre',
	},
];

export default motifIntervention;
