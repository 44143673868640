import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import simulatorData from '../../common/data/simulatorData';
import Button from '../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../components/bootstrap/Card';
import AuthContext from '../../contexts/authContext';
import CompanieContext from '../../contexts/companieContext';
import DevisLinesContext from '../../contexts/devisLinesContext';
import useDarkMode from '../../hooks/useDarkMode';
import { searchAccountTax } from '../../odooApi/accountTax';
import { createSimulateur } from '../../odooApi/atelier';
import { imageSignature } from '../../odooApi/devis';
import { createDevisLine, getDevisLinesById, updateDevisLine } from '../../odooApi/lineDevis';
import { searchProduct } from '../../odooApi/product';
import SimulatorPopUp from '../Simulateur/SimulatorPopUp';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import FormGroup from '../bootstrap/forms/FormGroup';
import Select from '../bootstrap/forms/Select';
import Textarea from '../bootstrap/forms/Textarea';
import ErrorPopup from '../extras/ErrorPopup';
import PopUpUpdateAndCreateLineDevis from './PopUpUpdateAndCreateLineDevis';

// import { createProductionFromDevis } from '../../odooApi/fabrication';

const DevisTableRow = ({ data, tvaValue, subTotal, isDisabled, loadData, selectedTax }) => {
	const { darkModeStatus } = useDarkMode();
	const [isOpen, setisOpen] = useState(false);
	const { userData } = useContext(AuthContext);
	return (
		<>
			<tr onClick={() => setisOpen(true)} className='cursor-pointer'>
				{/* <th scope='row'>{data?.id}</th> */}
				{/* <td>
					{data?.display_name&&<Link to={`../${demoPages.sales.subMenu.productID.path}/${data?.id}`}>
						{
							userData&&userData?.id&&userData?.password?
							<img src={imageProduct(userData?.password,data?.product_id)} alt='' width={54} height={54} />
							:null
						}
						
					</Link>}
				</td> */}
				<td>
					<div>
						{data?.display_name}

						<div className='text-muted'>
							<small>{data?.category}</small>
						</div>
					</div>
				</td>
				{/* <td>
					<span className={!!data?.name ? 'devis-desc' : undefined}>{data?.name}</span>
				</td> */}
				<td>
					<span>{data?.product_uom_qty}</span>
				</td>
				{!!!data?.display_type && (
					<>
						<td>
							<span>
								{data?.price_unit?.toLocaleString('fr-FR', {
									style: 'currency',
									currency: 'EUR',
								})}
							</span>
						</td>

						{/* <td>
					<span>{data?.discount}</span>
				</td> */}

						{/* <td>
							<span>{tvaValue}</span>
						</td> */}

						<td>
							<span>
								{
									// @ts-ignore
									subTotal?.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})
								}
							</span>
						</td>
					</>
				)}
			</tr>
			{isOpen && data?.id != 'Total' && (
				<PopUpUpdateAndCreateLineDevis
					data={data}
					setisOpen={setisOpen}
					loadData={loadData}
					isDisabled={isDisabled}
					selectedTax={selectedTax}
				/>
			)}
		</>
	);
};

const DevisTable = ({
	totalPriceRemise,
	settotalPriceRemise,
	remise,
	setRemise,
	setRemiseMontant,
	remiseMontant,
	cardId,
	setRemarque,
	remarque,
	isDisabled,
	card,
	companie,
}) => {
	const { darkModeStatus } = useDarkMode();
	const { userData } = useContext(AuthContext);
	const { devisLines, setDevisLines } = useContext(DevisLinesContext);
	const [isLoadingTable, setIsLoadingTable] = useState(true);
	const [currentFormValue, setCurrentFormValue] = useState({});
	const { t } = useTranslation(['translation', 'menu']);
	const [isOpen, setisOpen] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const [spinnerGarantie, setSpinnerGarantie] = useState(false);
	const [editModalSimulator, setEditModalSimulator] = useState(false);
	const [totalsPrice, setTotalsPrice] = useState(0);
	const remiseList = [0, 5, 7, 10, 12, 15, 17, 20, 22, 25];
	const [subTotalHT, setSubTotalHT] = useState(card?.amount_untaxed || 0);
	const [taxs, setTaxs] = useState([]);
	const [total, setTotal] = useState(0);
	const [error, setError] = useState();
	const [taxValue, setTaxValue] = useState(card?.amount_tax || 0);
	const [selectedTax, setSelectedTax] = useState();

	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'dark'];
	const handelCancel = () => {
		setCurrentFormValue({});
		setEditModalSimulator(false);
	};
	const handelCreateLine = () => {
		if (userData && userData?.id && userData?.password) {
			let infos = {};
			let description = '';
			const productFinal = currentFormValue?.productFinal;
			if (
				productFinal?.product_id === 9495 ||
				productFinal?.product_id === 10065 ||
				(Array.isArray(productFinal) && productFinal[0]?.product_id === 9495) ||
				productFinal[0]?.product_id === 10065
			) {
				//description = productFinal?.description || productFinal[0]?.description;
				if (productFinal?.length) {
					description += productFinal[0].description;
					productFinal.forEach((item) => {
						description += '\n - ' + item.label;
					});
				} else {
					description += productFinal?.description;
				}
			} else {
				description = 'Fourniture de ';

				if (productFinal?.length) {
					description += productFinal[0].description;
					productFinal.forEach((item) => {
						description += '\n - ' + item.label;
					});
				} else {
					description += productFinal?.description;
				}
			}

			const newLine = {
				order_id: cardId,
				name: description,
				product_id: currentFormValue?.productFinal?.length
					? currentFormValue?.productFinal[0]?.product_id
					: currentFormValue?.productFinal?.product_id,
				price_unit:
					currentFormValue?.productFinal?.lst_price ||
					currentFormValue?.productFinal[0]?.lst_price,
				tax_id: [selectedTax?.value],
			};

			// if (currentFormValue?.productFinal?.production) {
			// 	infos = {
			// 		production: newLine,
			// 		production_lines: currentFormValue?.productFinal?.production,
			// 		largeur: currentFormValue?.productFinal?.largeur || 0,
			// 		hauteur: currentFormValue?.productFinal?.hauteur || 0,
			// 		verrou: currentFormValue?.productFinal?.verrou || 0,
			// 	};
			// 	console.log('infosssss', infos);
			// 	createProductionFromDevis(
			// 		userData?.password,
			// 		infos,
			// 		() => {},
			// 		(error) => {
			// 			setError(error);
			// 		},
			// 	);
			// }

			// createDevisLine(userData?.password, newLine, (data) => {
			// 	console.log('infossoiiiiiiiisss');
			// 	setCurrentFormValue({});
			// 	getDevisLinesById(userData?.password, cardId, ({ elements }) => {
			// 		setDevisLines(elements);
			// 		setEditModalSimulator(false);
			// 	});
			// });
			createDevisLine(userData?.password, newLine, (data) => {
				if (currentFormValue?.productFinal?.production) {
					infos = {
						devis: cardId,
						devis_line: data?.id,
						produit: currentFormValue?.productFinal?.product_id,
						products: currentFormValue?.productFinal?.production,
						largeur: parseInt(currentFormValue?.productFinal?.largeur) || 0,
						hauteur: parseInt(currentFormValue?.productFinal?.hauteur) || 0,
						//verrou: currentFormValue?.productFinal?.verrou[1] || 0,
					};

					createSimulateur(
						userData?.password,
						infos,
						() => {
							setCurrentFormValue({});
							setEditModalSimulator(false);
						},
						(error) => {
							setError(error);
						},
					);
				}
				setCurrentFormValue({});
				getDevisLinesById(userData?.password, cardId, ({ elements }) => {
					setDevisLines(elements);
					setEditModalSimulator(false);
				});
			});
		}
	};

	const handelCreate = (ligneId) => {
		searchProduct(userData?.password, [['id', '=', ligneId]], ({ elements }) => {
			const newLine = {
				order_id: cardId,
				name: elements[0]?.name,
				product_id: ligneId,
				price_unit: elements[0].lst_price,
				tax_id: [selectedTax?.value],
			};

			createDevisLine(
				userData?.password,
				newLine,
				() => {
					getDevisLinesById(userData?.password, cardId, ({ elements }) => {
						setDevisLines(elements);
						loadData();
					});
				},
				(error) => {
					console.log('error', error);
				},
			);
		});
	};
	const loadData = (setTax) => {
		if (userData && userData?.id && userData?.password) {
			setIsLoadingTable(true);
			getDevisLinesById(
				userData?.password,
				cardId,
				({ elements }) => {
					setDevisLines(elements);
					searchAccountTax(
						userData?.password,
						[
							'&',
							['type_tax_use', '=', 'sale'],
							['active', '=', true],
							['company_id', '=', companie?.id],
						],
						(taxsData) => {
							setTaxs(taxsData?.elements);

							if (!!elements?.length) {
								let tax = taxsData?.elements?.find(
									(i) => i?.id == elements[0]?.tax_id[0],
								);
								!!setTax
									? setTax({
											value: tax?.id,
											text: tax?.name,
											amount: tax?.amount,
									  })
									: !selectedTax?.value &&
									  setSelectedTax({
											value: taxsData?.elements[0]?.id,
											text: taxsData?.elements[0]?.name,
											amount: taxsData?.elements[0]?.amount,
									  });
							} else {
								!selectedTax?.value &&
									setSelectedTax({
										value: taxsData?.elements[0]?.id,
										text: taxsData?.elements[0]?.name,
										amount: taxsData?.elements[0]?.amount,
									});
							}
						},
					);
				},
				(error) => {
					setIsLoadingTable(false);
				},
			);

			setIsLoadingTable(false);
		}
	};
	useEffect(() => {
		loadData(setSelectedTax);
	}, []);
	useEffect(() => {
		let totalTTC = 0;
		let subtotHT = 0;
		let tax = 0;
		let remiseTotal = 0;

		devisLines?.map((line) => {
			const tvaPercentage = selectedTax?.amount;
			const discountPrice =
				((line?.product_uom_qty * (line?.price_unit || 0)) / 100) * (100 - remise);
			const subTotal = ((discountPrice / 100) * (100 + (tvaPercentage || 0))).toFixed(2) || 0;
			subtotHT += parseFloat(discountPrice);
			remiseTotal += line?.product_uom_qty * (line?.price_unit || 0) - discountPrice;
			totalTTC += parseFloat(subTotal);
		});

		// devisLines?.map((line) => {
		// 	const tvaValue =
		// 		!!line?.tax_id?.length && !!taxs.length
		// 			? taxs?.filter((tax) => tax.id == line.tax_id)[0]?.amount
		// 			: 0;

		// 	const subTotal = (line?.price_unit / 100) * (tvaValue + 100); // product price with TVA
		// 	const subTotalWithRemise = subTotal - (subTotal / 100) * remise; // product price TVA with remise
		// 	subtotHT += line?.price_unit - (line?.price_unit / 100) * remise; // default price of product
		// 	remiseTotal += (line?.price_unit / 100) * remise;
		// 	totalTTC += subTotalWithRemise;
		// });
		tax = totalTTC - subtotHT;
		setTaxValue(tax);
		setSubTotalHT(subtotHT);
		setTotal(totalTTC);
		settotalPriceRemise(totalTTC);
		setRemiseMontant(remiseTotal);
	}, [devisLines, remise, selectedTax]);

	const updateTax = (newTVA) => {
		setSelectedTax({
			value: newTVA?.id,
			text: newTVA?.name,
			amount: newTVA?.amount,
		});
		devisLines?.map((item, index) => {
			updateDevisLine(
				userData?.password,
				{
					id: item?.id,
					tax_id: [newTVA?.id],
				},
				() => {
					//fnSuccess();
				},
				(error) => {
					setError(error);
				},
			);
		});
	};

	// useEffect(() => {
	// 	setTaxValue(total - (total / 100) * remise - (subTotalHT - (subTotalHT / 100) * remise));
	// 	setSubTotalHT(subTotalHT - (subTotalHT / 100) * remise);
	// 	setRemiseMontant((total / 100) * remise);
	// 	settotalPriceRemise(total - (total / 100) * remise);
	// }, [remise]);

	var totalPrice = 0;
	return (
		<>
			<Card>
				<CardHeader>
					<CardLabel icon='ListAlt' iconColor='info'>
						<CardTitle tag='h4' className='h5'>
							{t('Command lines')}
						</CardTitle>
						{/* {!!devisLines?.length && (
							<CardSubTitle>
								{' '}
								<Button isDisable color='info' isLight className='me-'>
									{devisLines?.length} Lignes
								</Button>{' '}
								<Button isDisable color='info' isLight>
									{totalPriceRemise?.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})}{' '}
									Prix total
								</Button>
							</CardSubTitle>
						)} */}
					</CardLabel>

					{!isDisabled && (
						<CardActions>
							<Dropdown>
								<DropdownToggle>
									<Button color='success' isLight>
										Ajouter
									</Button>
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem>
										<Button
											className='mt-2'
											onClick={() => setEditModalSimulator(true)}>
											{t('Simulator')}
										</Button>
									</DropdownItem>
									<DropdownItem>
										<Button className='mt-2' onClick={() => setisOpen(true)}>
											Produit
										</Button>
									</DropdownItem>
									<DropdownItem>
										<Button
											onClick={() => handelCreate(286)}
											isDisable={spinner}
											className='mt-2'>
											Déplacement
										</Button>
									</DropdownItem>
									<DropdownItem>
										<Button onClick={() => handelCreate(139)} className='mt-2'>
											Garantie
										</Button>
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</CardActions>
					)}
				</CardHeader>

				<CardBody className='table-responsive'>
					<table className='table table-modern table-hover'>
						<thead>
							<tr>
								{/* <th scope='col'>{t('ref')} </th> */}
								{/* <th scope='col'>Image</th> */}
								<th scope='col'>{t('Product')} </th>
								{/* <th scope='col'>{t('Description')}</th> */}
								<th scope='col'>{t('Quantity')} </th>
								<th scope='col'>{t('Price unite')}</th>

								{/* <th scope='col'>{t('Disc')} </th> */}
								{/* <th scope='col'>{t('Taxe')} </th> */}
								<th scope='col'>{t('Sous-total')} </th>
							</tr>
						</thead>
						{devisLines?.length > 0 && (
							<tbody>
								{devisLines.map((line) => {
									// const tvaValue = !!taxs.length
									// 	? taxs?.filter((tax) => tax.id == line.tax_id)[0]?.amount
									// 	: 0;

									// const subTotal = !!line?.price_subtotal
									// 	? line?.price_subtotal
									// 	: (line?.price_subtotal / 100) * (tvaValue + 100);
									// totalPrice += subTotal;
									console.log('selectedTax?.amount', selectedTax?.amount);
									const tvaPercentage = selectedTax?.amount;
									const discountPrrice =
										((line?.product_uom_qty * (line?.price_unit || 0)) / 100) *
										(100 - remise);
									const subTotal =
										(
											(discountPrrice / 100) *
											(100 + (tvaPercentage || 0))
										).toFixed(2) || 0;

									return (
										// eslint-disable-next-DevisTableRowline react/jsx-props-no-spreading
										<DevisTableRow
											key={line.id}
											data={line}
											tvaValue={tvaPercentage}
											subTotal={subTotal}
											isDisabled={isDisabled}
											loadData={loadData}
											selectedTax={selectedTax}
										/>
									);
								})}
								{/* 
								<DevisTableRow
									key={'last-row'}
									data={{ id: 'Total' }}
									subTotal={totalPrice}
									isDisabled={true}
								/> */}
							</tbody>
						)}
					</table>
				</CardBody>
			</Card>

			<div className='row g-2'>
				<div className='col-lg-4'>
					<Card stretch className='pb-4'>
						<CardBody>
							<div className='text-muted'>Notes</div>
							<Textarea
								id='remarque'
								name='remarque'
								value={remarque}
								onChange={(e) => {
									setRemarque(e.target.value);
								}}
								className='w-100 h-100'
							/>
						</CardBody>
					</Card>
				</div>
				<div className='col-lg-4'>
					{!!card?.signature && (
						<Card stretch>
							<CardBody className='text-center'>
								<div className='text-muted'>Signature</div>
								<img
									src={
										card?.signature &&
										userData?.password &&
										imageSignature(userData?.password, card.id)
									}
									style={{ maxWidth: '100%' }}
								/>
							</CardBody>
						</Card>
					)}
				</div>
				<div className='col-lg-4'>
					<Card stretch className='d-flex justify-content-end'>
						<CardBody>
							<div className='flex-grow-1 mb-3 d-flex justify-content-between'>
								<div className='text-muted'>Sous-total H.T </div>
								<div className='ms-3 fw-bold fs-6 mb-0'>
									{subTotalHT?.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})}
								</div>
							</div>
							<div className='border-bottom mb-3'></div>
							<FormGroup className='d-flex align-content-center justify-content-between mb-2'>
								<div className='text-muted me-3'>TVA</div>
								<div className='row align-items-center'>
									{!!selectedTax && (
										<Select
											className='col-auto me-2'
											id='selectedTax'
											ariaLabel='selectedTax'
											placeholder='select tax...'
											list={taxs?.map((item) => {
												return {
													value: item?.id,
													text: item?.name,
													amount: item?.amount,
												};
											})}
											onChange={(event) => {
												const newTVA = taxs?.find(
													(i) => i?.id == event.target.value,
												);
												updateTax(newTVA);
											}}
											value={selectedTax?.value}
										/>
									)}
								</div>
							</FormGroup>
							<div className='flex-grow-1 mb-3 d-flex justify-content-between'>
								<div className='text-muted'>Montant TVA </div>
								<div className='ms-3 fw-bold fs-6 mb-0'>
									{taxValue?.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})}
								</div>
							</div>

							{/* <div className='col-12'>
								<div className='flex-grow-1 mb-3 d-flex justify-content-between'>
									<div className='text-muted'>Total </div>
									<div className='ms-3 fw-bold fs-6 mb-0'>
										{total?.toLocaleString('fr-FR', {
											style: 'currency',
											currency: 'EUR',
										})}
									</div>
								</div>
							</div> */}
							<div className='border-bottom mb-3'></div>
							<FormGroup className='d-flex align-content-center justify-content-between'>
								<div className='text-muted me-3'>Remise</div>
								<div className='row align-items-center'>
									<Select
										className='w-auto me-2'
										id='remise'
										ariaLabel='remise'
										placeholder='remise'
										list={remiseList?.map((item) => {
											return { value: item, text: item + '%' };
										})}
										onChange={(event) => setRemise(event.target.value)}
										value={remise}
									/>
								</div>
							</FormGroup>
							<div className='flex-grow-1 mb-3 d-flex justify-content-between'>
								<div className='text-muted'>Montant remise </div>
								<div className='ms-3 fw-bold fs-6 mb-0'>
									{remiseMontant?.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})}
								</div>
							</div>
						</CardBody>
						<CardFooter
							className={classNames(
								`text-info bg-l${darkModeStatus ? 'o25' : '25'}-${'info'}`,
							)}>
							<CardFooterLeft>
								<div className='fw-bold fs-6 mb-0'>Total T.T.C</div>
							</CardFooterLeft>
							<CardFooterRight>
								<div className='ms-3 fw-bold fs-6 mb-0'>
									{totalPriceRemise?.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})}
								</div>
							</CardFooterRight>

							{/* <div className='col-12'>
									<div className='flex-grow-1 ms-3 mb-3 d-flex'>
										<div className='text-muted'>Total avec remise </div>
										<div className='ms-3 fw-bold fs-6 mb-0'></div>
									</div>
								</div> */}
						</CardFooter>
					</Card>
				</div>
			</div>

			{isOpen && (
				<PopUpUpdateAndCreateLineDevis
					data={{ order_id: cardId }}
					setisOpen={setisOpen}
					create={true}
					loadData={loadData}
					selectedTax={selectedTax}
				/>
			)}
			{editModalSimulator && (
				<SimulatorPopUp
					setEditModalSimulator={setEditModalSimulator}
					data={{
						order_id: cardId,
						modalTitle: 'Conception de produits et simulation',
						cardTitle: t('Simulate Product'),
					}}
					simulatorData={simulatorData}
					currentFormValue={currentFormValue}
					setCurrentFormValue={setCurrentFormValue}
					handelCreateLine={handelCreateLine}
					handelCancel={handelCancel}>
					<Button
						color='success'
						className='w-100'
						onClick={handelCreateLine}
						isDisable={!currentFormValue?.complet}>
						{t('Add to devis')}
					</Button>
					<Button color='warning' className='w-100' onClick={handelCancel}>
						{t('Cancel')}
					</Button>
				</SimulatorPopUp>
			)}
			{!!error && <ErrorPopup error={error} setOpen={setError} />}
		</>
	);
};

DevisTableRow.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	data: PropTypes.object.isRequired,
	tvaValue: PropTypes.number,
	subTotal: PropTypes.number,
	isDisabled: PropTypes.bool,
};
DevisTable.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	cardId: PropTypes.any.isRequired,
	isDisabled: PropTypes.bool,
};
export default DevisTable;
