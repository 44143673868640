import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';




const FactureLinesContext = createContext([]);


export const FactureLinesContextProvider= ({ children }) => {
	const [factureLines, setFactureLines] = useState([]);
	
	/* useEffect(() => {
		localStorage.setItem('facit_authUser', userData?.id&&userData?.username?JSON.stringify(userData):"{}");
	}, [devisLines]); */

	const value = useMemo(
		() => ({
			factureLines,
			setFactureLines
		}),
		[factureLines],
	);
	return <FactureLinesContext.Provider value={value}>{children}</FactureLinesContext.Provider>;
};
FactureLinesContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default FactureLinesContext;
