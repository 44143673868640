import * as React from 'react';

const SvgCustomFranceMap = (props) => (
    <svg
      	xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 70 70'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		className='svg-icon' fill='currentColor'
		{...props}>
        <path opacity={0.3} d="m57.93,17.48c-.27.86-1.12,1.4-2.02,1.4h-.54v4.82c0,.87-.26,1.72-.74,2.44l-3.65,5.44c-.39.58.03,1.37.74,1.37h1c.49,0,.89.39.89.88v8.71c0,.62.22,1.21.62,1.69l2.7,3.21c.62.74.53,1.83-.21,2.46l-4.06,3.45c-.5.43-1.2.54-1.81.3l-6.84-2.71c-.33-.13-.68-.19-1.02-.19-.35,0-.69.08-1.01.21l-4.12,1.75c-.65.28-1.07.91-1.07,1.61v2.86c0,.61-.61,1.03-1.19.83l-.58-.21-6.91-2.49c-.35-.12-.72-.14-1.06-.05h0s0,0,0,0c-.3.08-.57.23-.8.46l-.11.1c-.52.52-1.31.66-1.99.36l-6.74-3.04c-1.11-.5-1.73-1.68-1.52-2.87l2.92-15.92c.13-.73-.43-1.39-1.17-1.39s-1.4-.45-1.66-1.14l-1.43-3.78c-.5-1.32-1.61-2.32-2.98-2.7l-5.71-1.55c-1.09-.29-1.85-1.28-1.85-2.4,0-1.38,1.13-2.49,2.51-2.49h9.89v-5.27c0-1.15,1.11-2.04,2.33-1.67.75.23,1.21.99,1.21,1.77,0,.43.35.78.79.78h2.85c.44,0,.79-.35.79-.78v-.36c0-.37.23-.69.58-.82l2.08-.77,2.71-1.01c1.04-.39,1.73-1.37,1.73-2.47v-2.57c0-.97.79-1.76,1.77-1.76h.6c.77,0,1.53.2,2.2.58l4.29,2.43c.55.31.89.9.89,1.53v.33c0,.77.5,1.44,1.24,1.68l.53.17,13.29,4.16.34.11c1.05.33,1.68,1.44,1.33,2.56Z"/>
      	<path class="cls-2" d="m59.35,23.71v-1.92c1.12-.74,1.98-1.84,2.38-3.14.98-3.14-.78-6.5-3.94-7.49l-12.78-4c-.37-1.51-1.35-2.84-2.74-3.63l-4.29-2.43c-1.27-.72-2.71-1.1-4.17-1.1h-.6c-3.17,0-5.75,2.56-5.75,5.7v1.66l-3.93,1.46c-.49.18-.94.44-1.33.75-.64-.65-1.43-1.14-2.33-1.41-.56-.17-1.14-.26-1.73-.26-3.17,0-5.75,2.56-5.75,5.7v1.33h-5.91c-3.58,0-6.49,2.89-6.49,6.44,0,2.9,1.97,5.45,4.78,6.21l5.71,1.54c.14.04.25.14.3.27l1.43,3.78c.43,1.15,1.23,2.11,2.22,2.76l-2.49,13.61c-.54,2.97,1.01,5.92,3.78,7.17l6.74,3.04c.75.34,1.55.51,2.38.51,1.08,0,2.12-.29,3.02-.85l6.34,2.29c.54.19,1.09.29,1.66.29,2.68,0,4.86-2.17,4.86-4.83v-1.42l2.28-.97,6.33,2.51c.68.27,1.4.41,2.14.41,1.37,0,2.7-.49,3.74-1.37l4.06-3.45c2.37-2.02,2.67-5.6.67-7.98l-2.39-2.84v-8.24c0-1.21-.45-2.32-1.2-3.17l1.56-2.32c.92-1.37,1.41-2.97,1.41-4.62Zm-3.44-4.82h-.54v4.82c0,.87-.26,1.72-.74,2.44l-3.65,5.44c-.39.58.03,1.37.74,1.37h1c.49,0,.89.39.89.88v8.71c0,.62.22,1.21.62,1.69l2.7,3.21c.62.74.53,1.83-.21,2.46l-4.06,3.45c-.5.43-1.2.54-1.81.3l-6.84-2.71c-.33-.13-.68-.19-1.02-.19-.35,0-.69.07-1.01.21l-4.12,1.75c-.65.28-1.07.91-1.07,1.61v2.86c0,.61-.61,1.03-1.19.83l-.58-.21-6.91-2.49c-.35-.12-.72-.14-1.06-.05h0s0,0,0,0c-.3.08-.57.23-.8.46l-.11.1c-.52.52-1.31.66-1.99.36l-6.74-3.04c-1.11-.5-1.73-1.68-1.52-2.87l2.92-15.92c.13-.73-.43-1.39-1.17-1.39s-1.4-.45-1.66-1.14l-1.43-3.78c-.5-1.32-1.61-2.32-2.98-2.7l-5.71-1.54c-1.09-.29-1.85-1.28-1.85-2.4,0-1.38,1.13-2.49,2.51-2.49h9.89v-5.27c0-1.15,1.11-2.04,2.33-1.67.75.23,1.21.99,1.21,1.77,0,.43.35.78.79.78h2.85c.44,0,.79-.35.79-.78v-.36c0-.37.23-.69.58-.82l2.08-.77,2.71-1.01c1.04-.39,1.72-1.37,1.72-2.47v-2.57c0-.97.79-1.76,1.77-1.76h.6c.77,0,1.53.2,2.2.58l4.29,2.43c.55.31.89.9.89,1.53v.33c0,.77.5,1.44,1.24,1.68l.53.17,13.29,4.16.34.11c1.05.33,1.68,1.44,1.33,2.56-.27.86-1.12,1.4-2.02,1.4Z"/>
    </svg>

)
export default SvgCustomFranceMap;