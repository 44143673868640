import formInputsTypes from '../../simulatorConstant';
import infoMesure from '../infoMesure';
const tablierData = {
	id: 'TABLIERRRMCOMPLETData',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Type de lame',
	name: 'TABLIERRRMCOMPLETData',
	className: 'col-6',
	nextStepData: infoMesure,
	nextStep: false,
	options: [
		{
			id: 'Lames_cobra',
			label: 'Lames cobra',
			value: 'Lames cobra',
			nextStep: infoMesure,
		},
		{
			id: 'Lames_pleines',
			label: 'Lames pleines',
			value: 'Lames pleines',
			nextStep: infoMesure,
		},
		{
			id: 'Lames_polycarbonate',
			label: 'Lames polycarbonate',
			value: 'Lames polycarbonate',
			nextStep: infoMesure,
		},
		{
			id: 'Lames_micro_perforees',
			label: 'Lames micro-perforées',
			value: 'Lames_micro_perforees',
			nextStep: infoMesure,
		},
	],
};
const type_axe = {
	id: 'AxeMRMData',
	type: formInputsTypes?.SELECT_INPUT,
	label: "Type d'axe",
	name: 'AxeMRMData',
	className: 'col-6',
	nextStepData: tablierData,
	nextStep: false,
	options: [
		{
			id: '50',
			label: '50',
			value: '50',
			nextStep: false,
		},
		{
			id: '60',
			label: '60',
			value: '60',
			nextStep: false,
		},
		{
			id: '76',
			label: '76',
			value: '76',
			nextStep: false,
		},
		{
			id: '133',
			label: '133',
			value: '133',
			nextStep: false,
		},
		{
			id: '168',
			label: '168',
			value: '168',
			nextStep: false,
		},
	],
};

const coulisseRM = {
	id: 'coulisseRM',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Coulisses',
	name: 'coulisseRM',
	className: 'col-6',
	nextStepData: type_axe,
	nextStep: false,
	options: [
		{
			id: 'sansCOULL',
			label: 'Sans coulisses',
			value: 'Sans coulisses',
			nextStep: false,
		},
		{
			id: 'avecCOULL',
			label: 'Avec coulisses',
			value: 'Avec coulisses',
			nextStep: false,
		},
	],
};

const PoseRM = {
	id: 'PoseRM',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Pose',
	name: 'PoseRM',
	className: 'col-6',
	nextStepData: coulisseRM,
	nextStep: false,
	options: [
		{
			id: 'POSEAPP',
			label: 'Pose en applique',
			value: 'Pose en applique',
			nextStep: false,
		},
		{
			id: 'POSETUNNEL',
			label: 'Pose en tunnel',
			value: 'Pose en tunnel',
			nextStep: false,
		},
		{
			id: 'POSEENCAST',
			label: 'Pose encastré',
			value: 'Pose encastré',
			nextStep: false,
		},
	],
};

const coffreRM = {
	id: 'coffreRM',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Coffre',
	name: 'coffreRM',
	className: 'col-6',
	nextStepData: PoseRM,
	nextStep: false,
	options: [
		{
			id: 'sansCOFF',
			label: 'Sans coffre',
			value: 'Sans coffre',
			nextStep: false,
		},
		{
			id: 'avecCOFF',
			label: 'Avec coffre',
			value: 'Avec coffre',
			nextStep: false,
		},
	],
};

const bobine = {
	id: 'bobines',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Bobines',
	name: 'bobines',
	className: 'col-6',
	nextStepData: coffreRM,
	nextStep: false,
	options: [
		{
			id: '2',
			label: '2',
			value: '2',
			nextStep: false,
		},
		{
			id: '4',
			label: '4',
			value: '4',
			nextStep: false,
		},
		{
			id: '6',
			label: '6',
			value: '6',
			nextStep: false,
		},
		{
			id: '8',
			label: '8',
			value: '8',
			nextStep: false,
		},
	],
};

const RideauMetalliqueManuelCompletData = {
	id: 'platBombe',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Plat Bombé',
	name: 'platBombe',
	className: 'col-6',
	nextStepData: bobine,
	nextStep: false,
	options: [
		{
			id: 'sansPB',
			label: 'Sans plats bombés',
			value: 'Sans plats bombés',
			nextStep: false,
		},
		{
			id: 'avecPB',
			label: 'Avec plats bombés',
			value: 'Avec plats bombés',
			nextStep: false,
		},
	],
};

export default RideauMetalliqueManuelCompletData;
