import { useFormik } from 'formik';
import { useState } from 'react';
import motifRapportIntervention, {
	autreMotifs,
	motifsDevis,
	motifsPaiement,
} from '../../../../common/data/PlanningData/motif_rapportIntervention';
import Checks, { ChecksGroup } from '../../../bootstrap/forms/Checks';
import FormGroup from '../../../bootstrap/forms/FormGroup';

export default function MotifsRapports({ stateForm, setStateForm }) {
	const [checkboxChecked, setCheckboxChecked] = useState(motifRapportIntervention);

	const formik = useFormik({
		initialValues: {
			type_paiement: '',
			devis: '',
		},
		onSubmit: () => {
			handelSubmit();
		},
	});

	return (
		<div className='mb-4 g-4'>
			<FormGroup>
				<ChecksGroup className={'checkGr simulator-check'}>
					{checkboxChecked.map((item) => {
						return (
							<Checks
								isInline
								type='checkbox'
								id={'detail' + item?.id}
								label={item?.name}
								name={item?.name}
								onChange={() => {
									setStateForm({
										service: {
											id: item?.id,
											name: item?.name,
											label: item?.label,
											title: 'Service',
										},
									});

									setCheckboxChecked((prev) =>
										prev.map((i) =>
											i.id == item.id
												? {
														...i,
														checked: !i.checked,
												  }
												: i,
										),
									);
								}}
								checked={stateForm?.service?.id == item?.id && true}
							/>
						);
					})}
				</ChecksGroup>
			</FormGroup>

			{!!motifsPaiement?.length &&
				!!motifsPaiement?.filter((item) => item?.parentId?.includes(stateForm?.service?.id))
					.length > 0 && (
					<FormGroup>
						<ChecksGroup className={'checkGr simulator-check'}>
							{motifsPaiement?.map((option) => {
								return (
									<Checks
										className={'simulatorRadio '}
										type='radio'
										isInline
										name='type_paiement'
										id={option?.name + option?.id + ''}
										label={option?.name}
										value={option?.name}
										onChange={() =>
											setStateForm((oldObject) => ({
												...oldObject,
												moyen: {
													id: option?.id,
													name: option?.name,
													label: option?.label,
													title: 'Moyen',
												},
											}))
										}
										checked={stateForm?.moyen?.id == option?.id && true}
									/>
								);
							})}
						</ChecksGroup>
					</FormGroup>
				)}

			{!!motifsDevis?.length &&
				!!motifsDevis?.filter((item) => item?.parentId?.includes(stateForm?.service?.id))
					.length > 0 && (
					<FormGroup>
						<ChecksGroup className={'checkGr simulator-check'}>
							{motifsDevis?.map((option) => {
								return (
									<Checks
										className={'simulatorRadio'}
										type='radio'
										isInline
										name='devis'
										id={option?.name + option?.id + ''}
										label={option?.name}
										value={option?.name}
										onChange={() =>
											setStateForm((oldObject) => ({
												...oldObject,
												autre: {
													id: option?.id,
													name: option?.name,
													label: option?.label,
													title: 'autre',
												},
											}))
										}
										checked={stateForm?.autre?.id == option?.id && true}
										// onChange={formik.handleChange}
										// checked={formik.values.devis == option.name && true}
									/>
								);
							})}
						</ChecksGroup>
					</FormGroup>
				)}

			{!!autreMotifs?.length &&
				!!autreMotifs?.filter((item) => item?.parentId?.includes(stateForm?.autre?.id))
					.length > 0 && (
					<FormGroup>
						<ChecksGroup className={'checkGr simulator-check'}>
							{autreMotifs?.map((option) => {
								return (
									<Checks
										className={'simulatorRadio '}
										type='radio'
										isInline
										name='devis'
										id={option?.name + option?.id + ''}
										label={option?.name}
										value={option?.name}
										onChange={() =>
											setStateForm((oldObject) => ({
												...oldObject,
												supp: {
													id: option?.id,
													name: option?.name,
													label: option?.label,
													title: 'Supp',
												},
											}))
										}
										checked={stateForm?.supp?.id == option?.id && true}
									/>
								);
							})}
						</ChecksGroup>
					</FormGroup>
				)}
		</div>
	);
}
