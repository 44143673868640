import formInputsTypes from '../simulatorConstant';

const ButeeRM = {
	id: 'ButeeRM',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'ButeeRM',
	className: 'col-6',
	label: 'Butées',
	nextStepData: false,
	nextStep: false,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		let desc_data = '';
		let data = '';
		if (currentFormValue?.BoiteRMData?.length >= 1) {
			currentFormValue?.BoiteRMData?.map((option) => {
				desc_data += '\n - ' + option.label;
			});
		}
		const generateDescription = (data) => {
			if (currentFormValue?.RMData.value == 'RMData_Tablier') {
				return `tablier de rideau métallique ${currentFormValue?.TABLIERRMData?.value}${
					currentFormValue?.TABLIERRMData
						? `\n \n Tablier de rideau métallique à la ${currentFormValue?.TABLIERRMData?.value}` +
						  ' agrafées en acier galvanisé \n Lame finale renforcée en acier galvanisé \n Une cornière de renfort \n \n'
						: ''
				}${
					currentFormValue?.LameFinaleRM
						? ` \n - ` +
						  currentFormValue?.LameFinaleRM.value +
						  '\n - Conforme à la norme NF EN13241-1'
						: ''
				}  ${
					currentFormValue?.Enroulement
						? '\n - Enroulement ' + currentFormValue?.Enroulement?.value
						: ''
				}  ${currentFormValue?.Sabot ? ` \n - ` + currentFormValue?.Sabot?.value : ''} ${
					currentFormValue?.NbAgrafesRM
						? '\n - ' + currentFormValue?.NbAgrafesRM + ' agrafes'
						: ''
				} ${(data = 'RMABUTEE' ? '\n - Avec butées' : '')} ${
					currentFormValue?.largeurRM
						? '\n - Largeur: ' +
						  currentFormValue?.largeurRM +
						  'mm (' +
						  currentFormValue?.MesureLargeur?.value +
						  ')'
						: ''
				} ${
					currentFormValue?.hauteurRM
						? ' x Hauteur: ' +
						  currentFormValue?.hauteurRM +
						  'mm (' +
						  currentFormValue?.MesureHauteur?.value +
						  ')'
						: ''
				} `;
			} else if (currentFormValue?.RMData.value == 'RMData_Manuelcomplet') {
				return `rideau métallique manuel complet \n \n Tablier à ${
					currentFormValue?.TABLIERRRMCOMPLETData?.value
				} agrafées en acier galvanisé \n Lame finale renforcée en acier galvanisé \n Cornière de renfort \n Axe compensé par des ressorts \n Conforme à la norme EN 13241-1 \n${
					currentFormValue?.AxeMRMData
						? '\n - Axe diamètre ' + currentFormValue?.AxeMRMData?.value
						: ''
				}  ${
					currentFormValue?.Enroulement
						? '\n - Enroulement ' + currentFormValue?.Enroulement?.value
						: ''
				} ${currentFormValue?.Sabot ? ` \n - ` + currentFormValue?.Sabot?.value : ''} ${
					currentFormValue?.bobine
						? '\n -' + currentFormValue?.bobine?.value + ' bobines'
						: ''
				} ${
					currentFormValue?.NbAgrafesRM
						? '\n - ' + currentFormValue?.NbAgrafesRM + ' agrafes'
						: ''
				} ${
					currentFormValue?.platBombe ? '\n -' + currentFormValue?.platBombe?.value : ''
				} ${currentFormValue?.PoseRM ? '\n - ' + currentFormValue?.PoseRM?.value : ''}  ${
					currentFormValue?.coffreRM ? '\n -' + currentFormValue?.coffreRM?.value : ''
				} ${
					currentFormValue?.coulisseRM ? '\n -' + currentFormValue?.coulisseRM?.value : ''
				} ${
					currentFormValue?.TABLIERRRMCOMPLETData
						? `\n - ${currentFormValue?.TABLIERRRMCOMPLETData?.value}`
						: ''
				}${
					currentFormValue?.LameFinaleRM
						? ` \n - Lame finale ` + currentFormValue?.LameFinaleRM.value
						: ''
				} ${(data = 'RMABUTEE' ? '\n - Avec butées' : '')} ${
					currentFormValue?.largeurRM
						? '\n - Largeur: ' +
						  currentFormValue?.largeurRM +
						  'mm (' +
						  currentFormValue?.MesureLargeur?.value +
						  ')'
						: ''
				} ${
					currentFormValue?.hauteurRM
						? ' x Hauteur: ' +
						  currentFormValue?.hauteurRM +
						  'mm (' +
						  currentFormValue?.MesureHauteur?.value +
						  ')'
						: ''
				}`;
			} else if (currentFormValue?.RMData.value == 'RMData_Motorisecomplet') {
				return `rideau métallique motorisé complet \n \n Tablier à ${
					currentFormValue?.TABLIERRRMCOMPLETData?.value
				} agrafées en acier galvanisé \n Lame finale renforcée en acier galvanisé \n Cornière de renfort \n Axe motorisé compensé par des ressorts${
					currentFormValue?.AxeMotoriseRMData
						? '\n Moteur ' +
						  currentFormValue?.AxeMotoriseRMData?.value +
						  " 220 Volts \n Câble de dépannage de secours (débrayage de l'électro frein)  \n Conforme à la norme EN 13241-1 \n \n"
						: ''
				} ${
					currentFormValue?.MoteurRMData
						? '\n - ' + currentFormValue?.MoteurRMData?.label
						: ''
				}${
					currentFormValue?.AxeMRMData
						? '\n - Axe diamètre ' + currentFormValue?.AxeMRMData?.value
						: ''
				} ${currentFormValue?.BoiteRMData ? desc_data : ''}   ${
					currentFormValue?.Enroulement
						? '\n - Enroulement ' + currentFormValue?.Enroulement?.value
						: ''
				} ${currentFormValue?.Sabot ? ` \n - ` + currentFormValue?.Sabot?.value : ''} ${
					currentFormValue?.NbAgrafesRM
						? '\n - ' + currentFormValue?.NbAgrafesRM + ' agrafes'
						: ''
				}  ${currentFormValue?.platBombe ? '\n - Renfort plats bombés' : ''} ${
					currentFormValue?.bobine
						? '\n -' + currentFormValue?.bobine?.value + ' bobines'
						: ''
				} ${
					currentFormValue?.coffreRM ? '\n - ' + currentFormValue?.coffreRM?.value : ''
				} ${
					currentFormValue?.coulisseRM
						? '\n - ' + currentFormValue?.coulisseRM?.value
						: ''
				} ${
					currentFormValue?.LameFinaleRM
						? ` \n - Lame finale ` + currentFormValue?.LameFinaleRM.value
						: ''
				} ${(data = 'RMABUTEE' ? '\n - Avec butées' : '')} ${
					currentFormValue?.PoseRM ? '\n - ' + currentFormValue?.PoseRM?.value : ''
				} ${
					currentFormValue?.largeurRM
						? '\n - Largeur: ' +
						  currentFormValue?.largeurRM +
						  'mm (' +
						  currentFormValue?.MesureLargeur?.value +
						  ')'
						: ''
				} ${
					currentFormValue?.hauteurRM
						? ' x Hauteur: ' +
						  currentFormValue?.hauteurRM +
						  'mm (' +
						  currentFormValue?.MesureHauteur?.value +
						  ')'
						: ''
				} `;
			}
		};

		const options = [
			{
				id: 'RMSBUTEE',
				label: 'Sans butées',
				value: 'Sans butées',
				product_id: currentFormValue?.RMData?.id,
				description: generateDescription((data = 'RMSBUTEE')),
				checked: true,
				nextStep: false,
			},
			{
				id: 'RMABUTEE',
				label: 'Avec butées',
				value: 'Avec butées',
				product_id: currentFormValue?.RMData?.id,
				description: generateDescription((data = 'RMABUTEE')),
				nextStep: false,
			},
		];

		fnSuccess(options);
	},
};

const NbAgrafesRM = {
	id: 'NbAgrafesRM',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'NbAgrafesRM',
	className: 'col-6',
	label: "Nombre d'agrafes",
	nextStepData: ButeeRM,
	nextStep: false,
};

const SabotRM = {
	id: 'SabotRM',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'Sabot',
	className: 'col-6',
	label: 'Sabot',
	nextStepData: NbAgrafesRM,
	nextStep: false,
	options: [
		{
			id: 'RMASABOT',
			label: 'Avec sabot',
			value: 'Avec sabot',
			nextStep: false,
		},
		{
			id: 'RMESSABOT',
			label: 'Sans sabot',
			value: 'Sans sabot',
			nextStep: false,
		},
	],
};

const LameFinaleRM = {
	id: 'LameFinaleRM',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'LameFinaleRM',
	className: 'col-6',
	label: 'Lame finale',
	nextStepData: SabotRM,
	nextStep: false,
	options: [
		{
			id: 'RMAS',
			label: 'Avec serrure',
			value: 'équipée d’une serrure ',
			nextStep: false,
		},
		{
			id: 'RMESS',
			label: 'Sans serrure',
			value: 'Sans serrure',
			nextStep: false,
		},
	],
};

const Enroulement = {
	id: 'RMENROULEMENT',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'Enroulement',
	className: 'col-6',
	label: 'Enroulement',
	nextStepData: LameFinaleRM,
	nextStep: false,
	options: [
		{
			id: 'RMINT',
			label: 'Intérieur',
			value: 'intérieur',
			nextStep: false,
		},
		{
			id: 'RMEXT',
			label: 'Extérieur',
			value: 'extérieur',
			nextStep: false,
		},
	],
};

const MesureHauteur = {
	id: 'RMH',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'MesureHauteur',
	className: 'col-6',
	label: 'Mesure selon hauteur',
	nextStepData: Enroulement,
	nextStep: false,
	options: [
		{
			id: 'RMEC',
			label: 'Enroulement compris',
			value: 'enroulement compris',
			nextStep: false,
		},
		{
			id: 'RMPE',
			label: '+E',
			value: '+E',
			nextStep: false,
		},
	],
};

const hauteurRM = {
	id: 'HRM',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'hauteurRM',
	className: 'col-6',
	label: 'Hauteur de la lame(mm)',
	nextStepData: MesureHauteur,
	nextStep: false,
	validation: (value) => {
		return value > 0;
	},
};

const MesureLargeurRM = {
	id: 'RM-TML',
	type: formInputsTypes?.RADIO_INPUT,
	name: 'MesureLargeur',
	className: 'col-6',
	label: 'Mesure selon largeur',
	nextStepData: hauteurRM,
	nextStep: false,
	options: [
		{
			id: 'RMFN',
			label: 'FINI',
			value: 'FINI',
			nextStep: false,
		},
		{
			id: 'RME',
			label: 'DC',
			value: 'DC',
			nextStep: false,
		},
	],
};

const largeurRM = {
	id: 'LRM',
	type: formInputsTypes?.INPUT_NUMBER,
	name: 'largeurRM',
	className: 'col-6',
	label: 'Largeur de la lame(mm)',
	nextStepData: MesureLargeurRM,
	nextStep: false,
	validation: (value) => {
		return value > 0;
	},
};

export default largeurRM;
