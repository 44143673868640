import { Store } from 'react-notifications-component';

const SETTINGS = {
	insert: 'top',
	container: 'top-right',
	animationIn: ['animate__animated', 'animate__fadeIn'],
	animationOut: ['animate__animated', 'animate__fadeOut'],
};

const showNotification = (id, title, message, type = 'default', ringing) => {
	Store.addNotification({
		id,
		title,
		message,
		type,
		dismiss: {
			duration: ringing ? 60000 : 1,
			pauseOnHover: true,
			onScreen: true,
			showIcon: true,
			waitForAnimation: true,
		},
		...SETTINGS,
	});
};

export const removeNotification = (id) => {
	console.log(id);
	Store.removeNotification(id);
};

export default showNotification;
