import classNames from "classnames";
import Button from "../bootstrap/Button";
import Badge from "../bootstrap/Badge";
import { useContext, useEffect, useState } from "react";
import { firestore } from "../../apis/firebase/config";
import AuthContext from "../../contexts/authContext";



const ChatHeaderIcon = () => {
    const [unreadChats, setUnreadChats] = useState([]);
    const [firstIntraction, setFirstIntraction] = useState(false);
    const { userData } = useContext(AuthContext);

    useEffect(() => {
        if (userData && userData?.id && userData?.password) {

            firestore
                .collection('chats')
                .where("members", "array-contains", userData?.id)
                .where("lastSenderId", '!=', userData?.id)
                //.where("unreadMsgs",">",0)
                .onSnapshot((snapshot) => {
                    const chats = [];
                    snapshot.docs.filter((doc) => {
                        if (doc.data().unreadMsgs > 0) {
                            chats.push({ ...doc.data(), id: doc.id })
                        }
                    })
                    setUnreadChats(chats);
                });
        }

    }, []);

    useEffect(() => {
        const handleUserInteraction = () => {
            // Play audio when user interacts with the document
            setFirstIntraction(true);
        };
        document.addEventListener('click', handleUserInteraction);
        // Play the sound
        if (firstIntraction) {
            if (unreadChats?.length && window.location.pathname != "/chat") {
                const audio = new Audio(require('../../assets/Chocolate_Notification_Tone.mp3'))
                audio.type = 'audio/mp3';
                audio.preload = 'auto';
                
                audio.play();

            }
        }






    }, [unreadChats,firstIntraction]);


    return (
        <div className='col-auto mt-4'>
            <Button
                // eslint-disable-next-line react/jsx-props-no-spreading


                size={"lg"}
                hoverShadow={"default"}
                isLight
                icon={'Chat'}
                color='primary'
                //onClick={handleOnClickToCallHistoryPage}
                className='btn-only-icon'
                tag={"a"}
                isLink={true}
                href={"/chat"}
            ></Button>

            <div
                className={classNames(
                    'position-relative',
                    'animate__animated animate__infinite animate__slower',
                )}
                style={{ top: '-44px', right: '-28px' }}>
                <Badge rounded={'circle'} color='danger'>
                    {!!unreadChats?.length && unreadChats?.length}
                </Badge>
            </div>


        </div>
    )
};

export default ChatHeaderIcon;