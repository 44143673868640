import axios from 'axios';

const
    BASIC_API_URL = process.env.REACT_APP_GOOGLE_PLACE_BASE,
    AUTOCOMPLETE = process.env.REACT_APP_GOOGLE_PLACE_AUTOCOMPLETE,
    PLACE_DETAILS = process.env.REACT_APP_GOOGLE_PLACE_DETAILS,
    REACT_APP_GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY
    ;


export const AutoComplete = (keyWord, fnSucces, fnError = () => { }) => {

    const config = {
        method: 'get',
        url: `${"/google" + BASIC_API_URL + AUTOCOMPLETE}?input=${keyWord}&language=fr&key=${REACT_APP_GOOGLE_KEY}`,
        headers: {}

    };

    axios(config)
        .then(function (response) {
            console.log("AutoComplete", response.data);
            fnSucces(response.data)
        })
        .catch(function (error) {
            console.log(error)
            fnError(error);
        });
}

export const getPlaceDetails = (placeID, fnSucces) => {

    const config = {
        method: 'get',
        url: `${"/google" + BASIC_API_URL + PLACE_DETAILS}?place_id=${placeID}&language=fr&key=${REACT_APP_GOOGLE_KEY}`,
        headers: {}

    };

    axios(config)
        .then(function (response) {
            //console.log(JSON.stringify(response.data));
            fnSucces(response.data)
        })
        .catch(function (error) {
            console.log(error);
        });
}


export const getPlaceByLatAndLng = (lat, lng, fnSucces) => {
    const config = {
        method: 'get',
        url: `/google/maps/api/geocode/json?latlng=${lat+","+lng}&language=fr&key=${REACT_APP_GOOGLE_KEY}`,
        headers: {}

    };
    
    axios(config)
        .then(function (response) {
            
            fnSucces(response?.data?.results?.length?response?.data?.results[0]:{});
        })
        .catch(function (error) {
            console.log(error);
        });

}