import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	DEVIS_LINE_API_URI = '/sale.order.line';
export const getDevisLinesById = (tokenUserCurent, devisId, fnSucces) => {
	const bodyData = JSON.stringify({
		kwargs: {
			domain: [['order_id', '=', devisId]],
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + DEVIS_LINE_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: bodyData,
	};

	axios(config)
		.then(function (response) {
			fnSucces(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getDevisLines = (tokenUserCurent, id, fnSucces) => {
	const bodyData = JSON.stringify({
		kwargs: {
			domain: [['id', '=', id]],
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + DEVIS_LINE_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: bodyData,
	};

	axios(config)
		.then(function (response) {
			fnSucces(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const updateDevisLine = async (tokenUserCurent, devisLine, fnSucces, fnError = () => {}) => {
	const devisLineString = JSON.stringify(devisLine);

	const config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + DEVIS_LINE_API_URI + '/' + devisLine?.id}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: devisLineString,
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const deleteDevisLine = async (tokenUserCurent, idLine, fnSucces) => {
	const config = {
		method: 'delete',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + DEVIS_LINE_API_URI + '/' + idLine}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const createDevisLine = async (tokenUserCurent, newLine, fnSucces, fnError = () => {}) => {
	var data = JSON.stringify(newLine);

	var config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + DEVIS_LINE_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			fnSucces(response?.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};
