import axios from 'axios';

const REMINDER_TELEPHONY_MICROSERVICE_API_URL =
	process.env.REACT_APP_REMINDER_TELEPHONY_MICROSERVICE_API_URL;

const CONSUMPTION_API_URI = '/consumptions';
const LIMIT = 10;

const searchCallsByPage = (tokenUserCurent, conditions, fn, page = 1, limit = LIMIT) => {
	var config = {
		method: 'patch',
		url:
			'/reminder' +
			REMINDER_TELEPHONY_MICROSERVICE_API_URL +
			CONSUMPTION_API_URI +
			'/search/' +
			page +
			'/' +
			limit,
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
			'Content-Type': 'application/json',
		},
		data: JSON.stringify(conditions),
	};
	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};
export default searchCallsByPage;

export const searchOneCall = (tokenUserCurent, date, source, destination, fn) => {
	var config = {
		method: 'get',
		url: `/reminder${REMINDER_TELEPHONY_MICROSERVICE_API_URL}${CONSUMPTION_API_URI}/searchOneCall?date=${date}&source=${source}&destination=${destination}`,
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getSingleCall = (tokenUserCurent, idCall, fn) => {
	var config = {
		method: 'get',
		url:
			'/reminder' +
			REMINDER_TELEPHONY_MICROSERVICE_API_URL +
			CONSUMPTION_API_URI +
			'/' +
			idCall,
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
		},
	};
	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const updateCall = (tokenUserCurent, idCall, oldCall) => {
	const data = JSON.stringify(oldCall);
	var config = {
		method: 'put',
		url:
			'/reminder' +
			REMINDER_TELEPHONY_MICROSERVICE_API_URL +
			CONSUMPTION_API_URI +
			'/' +
			idCall,
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			console.log(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const searchCallsByClient = (
	tokenUserCurent,
	conditions,
	clientConditions,
	fn,
	fnErr,
	page = 1,
	limit = LIMIT,
) => {
	const requestConditions = {
		conditions: conditions,
		clientConditions: clientConditions,
	};
	console.log(requestConditions);

	var config = {
		method: 'patch',
		url:
			'/reminder' +
			REMINDER_TELEPHONY_MICROSERVICE_API_URL +
			CONSUMPTION_API_URI +
			'/searchByClient/' +
			page +
			'/' +
			limit,
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
			'Content-Type': 'application/json',
		},
		data: requestConditions,
	};

	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			fnErr(error);
			console.log(error);
		});
};

export const getCallsByNatureAndDate = (tokenUserCurent, fn) => {
	var config = {
		method: 'get',
		url:
			'/reminder' +
			REMINDER_TELEPHONY_MICROSERVICE_API_URL +
			CONSUMPTION_API_URI +
			'/calls-by-nature-date',
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
		},
	};
	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getCallsAnalysis = (tokenUserCurent, conditions, fn) => {
	var config = {
		method: 'patch',
		url:
			'/reminder' +
			REMINDER_TELEPHONY_MICROSERVICE_API_URL +
			CONSUMPTION_API_URI +
			'/analysis',
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
		},
		data: conditions,
	};
	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getCallsNumbers = (tokenUserCurent, conditions, fn) => {
	var config = {
		method: 'patch',
		url:
			'/reminder' +
			REMINDER_TELEPHONY_MICROSERVICE_API_URL +
			CONSUMPTION_API_URI +
			'/analysis/total',
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
		},
		data: conditions,
	};
	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getMissedCalls = (tokenUserCurent, fn) => {
	var config = {
		method: 'get',
		url:
			'/reminder' +
			REMINDER_TELEPHONY_MICROSERVICE_API_URL +
			CONSUMPTION_API_URI +
			'/missed-calls',

		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
		},
	};
	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};
