import React, { useContext, useState } from 'react'
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../bootstrap/Modal'
import InputGroup from '../../../bootstrap/forms/InputGroup'
import Textarea from '../../../bootstrap/forms/Textarea'
import Button from '../../../bootstrap/Button'
import { createMailMessage } from '../../../../odooApi/mailMessage'
import AuthContext from '../../../../contexts/authContext'
import Card, { CardBody, CardFooter, CardFooterRight } from '../../../bootstrap/Card'
import Label from '../../../bootstrap/forms/Label'
import MailMessageContext from '../../../../contexts/mailMessageContext'

export default function AddChronologiePopUp({ setIsOpenModal, event }) {
    const { userData } = useContext(AuthContext);
    const [messageIDS, setMessageIDS] = useState('')
    const { mailMessages, setMailMessages } = useContext(MailMessageContext);

    const updateChronologieContext = (values) => {
        setMailMessages((prevMailMessages) => {
            let updatedMailMessages = [...prevMailMessages];
            let groupIndex = updatedMailMessages.findIndex((group) => group.id === values.id);

            if (groupIndex !== -1) {
                // Le groupe existe déjà, vérifions si la carte existe
                let cardIndex = updatedMailMessages[groupIndex].cards.findIndex((card) => card.id === values.id);

                if (cardIndex !== -1) {
                    // La carte existe déjà, mettons à jour la carte existante
                    updatedMailMessages[groupIndex].cards[cardIndex] = values;
                } else {
                    // La carte n'existe pas, ajoutons-la au groupe
                    updatedMailMessages[groupIndex].cards.push(values);
                }
            } else {
                // Le groupe n'existe pas, créons-le avec la nouvelle carte
                updatedMailMessages.push({
                    id: values.id,
                    body: values?.body,
                    author_id: values?.author_id,
                    date: values?.date, // Assurez-vous d'ajuster cela en fonction de votre structure
                    cards: [values],
                });
            }

            return updatedMailMessages;
        });

    };

    const createMessage = (noteData) => {
        createMailMessage(userData?.password, noteData, (result) => {
            setMessageIDS('');
            setIsOpenModal(false)
            console.log(result)
            updateChronologieContext(result)

        });
    };



    return (
        <Modal setIsOpen={() => { }} isOpen={true} titleId='tour-title' classNameMd='nv-3'>
            <ModalHeader className='px-4 pb-0' setIsOpen={setIsOpenModal}>
                <div className='w-100 text-muted text-center'>
                    <ModalTitle id='tour-title' className='fw-semibold fs-4'>Nouvelle activité</ModalTitle>
                </div>
            </ModalHeader>
            <ModalBody>
                <Card>
                    <CardBody>
                        <Label>Note</Label>
                        <Textarea onChange={(e) => setMessageIDS(e.target.value)} value={messageIDS} />
                    </CardBody>
                    <CardFooter>
                        <CardFooterRight>
                            <Button
                                color='info'
                                icon='Save'
                                onClick={() =>
                                    createMessage({
                                        subject: "tache Intervention" + event?.name_seq,
                                        body: messageIDS,
                                        model: 'planning.slot',
                                        res_id: event?.id,
                                        record_name: event?.name_seq,
                                        email_from: userData?.email,
                                        message_type: 'comment',
                                        subtype_id: 2
                                    })
                                }
                            >
                                Enregistrer
                            </Button>
                        </CardFooterRight>

                    </CardFooter>
                </Card>


            </ModalBody>
        </Modal>
    )
}
