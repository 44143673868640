import { useContext, useState } from 'react';
import AuthContext from '../../../contexts/authContext';
import useOutsideClick from '../../customComponents/SearchAutoComplete/useOutsideClick';
import { useFormik } from 'formik';
import { searchVoiture } from '../../../odooApi/voiture';
import Modal, { ModalBody, ModalHeader } from '../../bootstrap/Modal';
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../bootstrap/Card';
import Icon from '../../icon/Icon';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import ListGroup, { ListGroupItem } from '../../bootstrap/ListGroup';
import Button from '../../bootstrap/Button';
import Spinner from '../../bootstrap/Spinner';
import StatusPopup from '../StatusPopup';
import { DropdownItem } from '../../bootstrap/Dropdown';
import ErrorPopup from '../../extras/ErrorPopup';

export default function VoiturePopup({ setVoiturePopup, formState, setFormState }) {
	const { userData } = useContext(AuthContext);
	const [openList, setOpenList] = useState(false);
	const [clientIsLoading, setClientIsLoading] = useState(false);
	const [filterResult, setfilterResult] = useState({});
	const [result, setResult] = useState(
		formState?.voitures?.length > 0 ? formState?.voitures : [formState?.voiture],
	);
	const [error, setError] = useState();
	const [success, setSuccess] = useState();
	const handleClickOutside = () => {
		setOpenList(false);
	};

	const ref = useOutsideClick(handleClickOutside);

	const formik = useFormik({
		initialValues: {
			id: '',
			name: '',
		},

		onSubmit: (values) => {
			//handelUpdate(values);
		},
	});
	const handleSave = () => {
		setFormState({ ...formState, voitures: result });
		setVoiturePopup(false);
	};

	const handleChange = (e) => {
		formik.handleChange(e);
		formik?.setFieldValue('name', e?.target?.value);
		setOpenList(true);
		if (e?.target?.value == '') {
			setfilterResult([]);
			formik?.setFieldValue('name', '');
		} else {
			if (userData && userData?.id && userData?.password) {
				setClientIsLoading(true);
				searchVoiture(
					userData?.password,
					[
						'|',
						['name', 'like', e.target.value],
						'|',
						[
							'name',
							'like',
							e.target.value.charAt(0).toUpperCase() +
								e.target.value.substring(1).toLowerCase(),
						],
						'|',
						['name', 'like', e.target.value.toUpperCase()],
						'|',
						['conducteur.name', 'like', e.target.value],
						'|',
						[
							'conducteur.name',
							'like',
							e.target.value.charAt(0).toUpperCase() +
								e.target.value.substring(1).toLowerCase(),
						],

						['conducteur.name', 'like', e.target.value.toUpperCase()],
					],
					({ elements }) => {
						console.log('djfbdsrf', elements);
						setfilterResult(elements);
						setClientIsLoading(false);
					},
					(error) => {
						setError(error);
					},
				);
			}
		}
	};

	const handelSelect = (item) => {
		console.log('item : ', item);
		setOpenList(false);
		formik?.setFieldValue('name', '');
		const exist = !!result?.find((i) => i?.id == item?.id);
		!exist && setResult([...result, item]);

		setfilterResult([]);
	};
	console.log(result);
	return (
		<Modal setIsOpen={() => {}} isOpen={true} size={'md'} classNameMd='nv-2'>
			<ModalHeader
				setIsOpen={() => {
					setVoiturePopup(false);
				}}></ModalHeader>
			<ModalBody className='px-2'>
				<Card shadow={'none'}>
					<CardHeader>
						<CardLabel icon='CustomModifyCar' iconColor='primary'>
							<CardTitle>Voitures et techniciens</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody>
						<div className='row g-3 mb-3'>
							<div className=' col-12'>
								<div className='d-flex '>
									<div className='flex-grow-1 ms-3 '>
										<div className='fw-bold fs-6 mb-0' ref={ref}>
											<FormGroup id='name' className='autocomplete'>
												<Input
													placeholder='🔎︎ Rechercher technicien, voiture ...'
													id='name'
													onChange={handleChange}
													onFocus={handleChange}
													value={formik?.values?.name}
													name='name'
													autoComplete='off'
												/>
												{openList && clientIsLoading ? (
													<div
														id='myInputautocomplete-list'
														className='autocomplete-items'>
														<div className='body'>
															<div tag={'button'}>
																<Spinner isSmall isGrow />{' '}
																<Spinner isSmall isGrow />{' '}
																<Spinner isSmall isGrow />
															</div>
														</div>
													</div>
												) : (
													!!filterResult.length && (
														<div
															id='myInputautocomplete-list'
															className='autocomplete-items'>
															<div className='body'>
																{filterResult?.map((item) => (
																	<div
																		// className='btn dropdown-item'
																		key={item?.id}
																		// color='primary'
																	>
																		<div
																			onClick={() => {
																				handelSelect(item);
																			}}>
																			<Icon
																				color={'primary'}
																				isLight
																				icon={
																					'DirectionsCar'
																				}
																				size='lg'
																			/>
																			{item?.name}{' '}
																			{item?.conducteur
																				?.length > 0 ? (
																				item?.conducteur?.map(
																					(
																						technicien,
																					) => (
																						<>
																							<Icon
																								className='ms-2'
																								color={
																									'primary'
																								}
																								isLight
																								icon={
																									'Person'
																								}
																								size='lg'
																							/>
																							{
																								technicien?.name
																							}
																						</>
																					),
																				)
																			) : (
																				<>
																					<Icon
																						className='ms-2'
																						color={
																							'primary'
																						}
																						isLight
																						icon={
																							'Person'
																						}
																						size='lg'
																					/>
																					-- aucun --
																				</>
																			)}
																		</div>
																	</div>
																))}
															</div>
														</div>
													)
												)}
											</FormGroup>
										</div>
									</div>
								</div>
							</div>
						</div>
						{result?.length > 0 && (
							<div className='row g-3 mt-3'>
								{/* <div className='col-12 text-center'>
									<h2>Voitures</h2>
								</div> */}
								<table className='table table-modern table-hover'>
									<thead>
										<tr>
											<th>Voiture</th>
											<th>Technicien</th>
											<th></th>
										</tr>
									</thead>

									<tbody>
										{result?.map((voiture, idx) => {
											return (
												<tr>
													<td>
														<div>
															{voiture?.name}
															{/* {!!line?.email && (
																<div className='text-muted'>
																	<small>{line?.email}</small>
																</div>
															)} */}
														</div>
													</td>

													<td>
														{voiture?.conducteur?.length > 0 &&
															voiture?.conducteur?.map(
																(technicien) => (
																	<span className='me-2'>
																		{technicien?.name}
																	</span>
																),
															)}
													</td>
													<td>
														<Button
															color='primary'
															isLight
															icon='DeleteOutline'
															onClick={() => {
																setResult(
																	result?.filter(
																		(item) =>
																			item?.id != voiture?.id,
																	),
																);
															}}></Button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}
					</CardBody>
					<CardFooter>
						<CardFooterRight>
							<Button color='primary' onClick={handleSave}>
								Sauvegarder
							</Button>
						</CardFooterRight>
					</CardFooter>
				</Card>
			</ModalBody>
			{!!error && (
				<ErrorPopup
					error={error}
					setOpen={setError}
				/>
			)}
			{!!success && (
				<StatusPopup
					icon='Verified'
					color='success'
					title={'Super!'}
					label={success}
					setOpen={setSuccess}
				/>
			)}
		</Modal>
	);
}
