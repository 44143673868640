import React, { useContext } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import AuthContext from '../../contexts/authContext';
import { createAttachementUrl } from '../../odooApi/planningSlot';

export default function DisplayImage({ setSelectedImage, file }) {
	console.log(file)
	const { userData } = useContext(AuthContext);
	const theFile = !!file?.file ? file?.file : file;
	const fileType = !!theFile?.type
		? theFile?.type?.split('/')[0]
		: theFile?.name?.split('.').pop();
	const src = !!theFile?.src ? theFile?.src : !!theFile?.type
		? URL.createObjectURL(theFile)
		: userData?.password && createAttachementUrl(userData?.password, theFile?.id || theFile);
	console.log(theFile);
	return (
		<Modal isOpen={true} setIsOpen={() => {}} classNameMd='nv-3'>
			<ModalHeader setIsOpen={() => setSelectedImage(false)}>
				<ModalTitle>{theFile?.name}</ModalTitle>
			</ModalHeader>
			<ModalBody className='text-center'>
				<CustomizedImage fileType={fileType} src={src} file={file} theFile={theFile} />
			</ModalBody>
		</Modal>
	);
}

const CustomizedImage = ({ fileType, src, file, theFile }) => {
	if (
		fileType === 'image' ||
		fileType === 'jpeg' ||
		fileType === 'jpg' ||
		fileType === 'png' ||
		fileType === 'ico'
	) {
		return <img src={src} alt={theFile?.name} className='w-100' />;
	} else if (
		fileType === 'video' ||
		fileType === 'mp4' ||
		fileType?.toLowerCase() === 'mov' ||
		fileType?.toLowerCase() === 'mkv'
	) {
		return (
			<video controls>
				<source src={src} type={file.type} />
			</video>
		);
	} else if (
		(fileType === 'application' && file.type === 'application/pdf') ||
		fileType === 'pdf'
	) {
		return <embed src={src} type='application/pdf' className='w-100 h-fluid-min-100' />;
	} else {
		return <embed src={src} type='application/pdf' className='w-100 h-fluid-min-100' />;
	}
};
