import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	DECHARGE_API_URI = '/devis.decharge',
	LIMIT = 0;

const createDecharge = (tokenUserCurent, newDecharge, fncSuccess, fnError = () => {}) => {
	const data = JSON.stringify(newDecharge);
	var config = {
		method: 'post',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + DECHARGE_API_URI,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			fncSuccess(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export default createDecharge;

export const uploadSignatureDecharge = (
	tokenUserCurent,
	idDecharge,
	image,
	fnSuccess = () => {},
	fnError = () => {},
) => {
	let data = new FormData();
	data.append('signature', image.toString());
	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			'/api/digital/web-admin/upload_binary' +
			DECHARGE_API_URI +
			'/' +
			idDecharge +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};
	axios
		.request(config)
		.then((response) => {
			fnSuccess(response);
		})
		.catch((error) => {
			fnError(error);
		});
};

export const sendDechargeByMail = (tokenUserCurent, dechargeId, fnSucces, fnError = () => {}) => {
	const config = {
		method: 'get',
		url: `${'/odoo' + API_URL_WEB_ADMIN + DECHARGE_API_URI + '/' + dechargeId + '/send'}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};
