// apiInterceptor.js
import axios, { AxiosRequestConfig } from 'axios';

interface ApiUpdateInfo {
	updateCount: number;
	lastUpdateTime: number | null;
}

interface ApiInterceptor {
	updateInfo: Record<string, ApiUpdateInfo>;
	timeInterval: number;
	maxUpdateCount: number;
	blockDuration: number;
	handleUpdate(config: AxiosRequestConfig): Promise<AxiosRequestConfig>;
	setup(): void;
}

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN;

const apiInterceptor: ApiInterceptor = {
	updateInfo: {},
	timeInterval: 5000, // 5 seconds
	maxUpdateCount: 3, // Allow three updates
	blockDuration: 60000, // Block for 1 minute after three updates

	async handleUpdate(config: AxiosRequestConfig) {
		const now = new Date().getTime();

		// Extract API name and ID from the request URL
		const apiInfoMatch = config.url?.match(
			new RegExp(`${BASIC_API_URL}${API_URL_WEB_ADMIN}\/(.+?)\/(\\d+)\/?`),
		);
		const apiName = apiInfoMatch ? apiInfoMatch[1] : 'unknown';
		const apiId = apiInfoMatch ? apiInfoMatch[2] : 'unknown';

		const apiKey = `${apiName}/${apiId}`;

		if (!this.updateInfo[apiKey]) {
			this.updateInfo[apiKey] = { updateCount: 0, lastUpdateTime: null };
		}

		if (now - this.updateInfo[apiKey].lastUpdateTime! > this.blockDuration) {
			// Reset update count after the block duration has passed
			this.updateInfo[apiKey].updateCount = 0;
		}

		if (this.updateInfo[apiKey].updateCount < this.maxUpdateCount) {
			// Allow the update if the update count is less than the maximum
			this.updateInfo[apiKey].updateCount++;
			this.updateInfo[apiKey].lastUpdateTime = now;
			return config;
		} else {
			alert(
				`Oops! Trop de modifications à la fois! 🤯🤯 Réessayez après ${Math.ceil(
					(this.blockDuration - (now - this.updateInfo[apiKey].lastUpdateTime!)) / 1000,
				)} secondes. Nous vous conseillons de prendre une petite pause. 😊`,
			);

			return Promise.reject({ code: 'ERR_CANCELED', message: 'Update request canceled' });
		}
	},

	setup() {
		axios.interceptors.request.use(
			async (config) => {
				if (
					config.method === 'put' &&
					config.url?.includes(`${BASIC_API_URL}${API_URL_WEB_ADMIN}/res.partner/`)
				) {
					return await this.handleUpdate(config);
				}
				return config;
			},
			(error) => Promise.reject(error),
		);
	},
};

export default apiInterceptor;
