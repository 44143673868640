import React from 'react';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../../layout/SubHeader/SubHeader';

import store2000 from '../../../../assets/img/store2000.png';
import Button from '../../../../components/bootstrap/Button';

export default function HeaderPage({ amount }) {
	return (
		<SubHeader>
			<SubHeaderLeft>
				<img src={store2000} alt='' className='p-4' />
				<SubheaderSeparator />
				{!!amount && 

					<h4>
						<span className='text-muted me-2'>Montant:</span>

						{
							// @ts-ignore
							amount?.toLocaleString('fr-FR', {
								style: 'currency',
								currency: 'EUR',
							})
						}
					</h4>
					}
				
			</SubHeaderLeft>
			<SubHeaderRight>
					<div className='col-auto'>
						<Button
							tag='a'
							href='mailto:contact@store2000.fr'
							icon='email'
							color='info'
							isLight
							className='fw-bold'
							size='sm'>
							Contactez-nous
						</Button>
					</div>
					<div className='col-auto'>
						<Button
							tag='a'
							href='tel:09 72 10 17 11'
							icon='phone'
							color='info'
							isLight
							className='fw-bold'
							size='sm'>
							09 72 10 17 11
						</Button>
					</div>
				</SubHeaderRight>
		</SubHeader>
	);
}
