import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../bootstrap/Button';
import { CardBody, CardLabel, CardTitle } from '../bootstrap/Card';
import Modal, { ModalBody, ModalHeader } from '../bootstrap/Modal';
import ConfirmationPopup from './ConfirmationPopup';

export default function PopUpSeachDevisIntervention({
	setParentDevis,
	parentInterventionsData,
	handelSubmit,
	handleCreateDevis,
	formState,
}) {
	const { t } = useTranslation(['translation', 'menu']);
	const state = {
		draft: 'Brouillon',
		sent: 'Envoyé',
		sale: 'Accepté',
		done: 'Accepté',
	};
	const [confirmCreateDevisPopup, setConfirmCreateDevisPopup] = useState(false);

	const handelSelectDevis = async (line) => {
		if (!!line?.planning_id?.id) {
			formState.parent_id = line?.planning_id?.id;
			formState.devis_liste = [line?.id];
			handelSubmit();
		} else {
			formState.devis_liste = [line?.id];
			handelSubmit();
		}

		setParentDevis(false);
	};
	return (
		<Modal isOpen={true} setIsOpen={() => {}} size={'xl'} classNameMd='nv-3'>
			<ModalHeader
				setIsOpen={() => {
					setParentDevis(false);
				}}>
				<CardLabel iconColor='primary'>
					<CardTitle>Veuillez associer un devis à la pose </CardTitle>
				</CardLabel>
			</ModalHeader>
			<ModalBody>
				<CardBody className='table-responsive'>
					{!!parentInterventionsData?.length ? (
						<>
							<table className='table table-modern table-hover'>
								<thead>
									<tr>
										<th scope='col'>N°</th>
										<th scope='col'>Titre</th>
										<th scope='col'>Date</th>
										<th scope='col'>Montant T.T.C </th>
										<th scope='col'>Etat</th>
									</tr>
								</thead>
								<tbody>
									{parentInterventionsData?.map((line) => {
										return (
											<tr
												key={line.id}
												className='cursor-pointer'
												onClick={() => {
													handelSelectDevis(line);
												}}>
												<td>
													<div>{line.name}</div>

													<small>{line?.ref_intervention}</small>
												</td>
												<td>{line.project_title}</td>
												<td>
													{moment(line.date_order).format('DD-MM-YYYY')}
												</td>

												<td>
													{
														// @ts-ignore
														line.amount_total?.toLocaleString('fr-FR', {
															style: 'currency',
															currency: 'EUR',
														})
													}
												</td>
												<td>
													{state[line.state]}
													{/* {line.state} */}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</>
					) : (
						<div className='row w-100 justify-content-center'>
							<div className='mb-5'>
								Ce client n'a aucun devis, vous devez lui créer un devis
							</div>
						</div>
					)}
					<div className='row w-100 justify-content-center'>
						<Button
							className='col mx-2'
							color='success'
							icon='PostAdd'
							onClick={() => {
								setConfirmCreateDevisPopup(true);
								//setParentDevis(false);
							}}>
							Créer un devis
						</Button>
						<Button
							className='col mx-2'
							color='warning'
							//icon='PostAdd'
							onClick={() => {
								formState.allowAucunDevis = true;
								handelSubmit();
								setParentDevis(false);
							}}>
							N'associer aucun devis
						</Button>
					</div>
				</CardBody>
				{confirmCreateDevisPopup && (
					<ConfirmationPopup
						title='Créer un devis ?'
						handleConfirm={() => {
							handleCreateDevis();
							setParentDevis(false);
						}}
						setOpen={setConfirmCreateDevisPopup}
					/>
				)}
			</ModalBody>
		</Modal>
	);
}
