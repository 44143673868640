import React, { useEffect } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import Button from '../bootstrap/Button';
import Card, { CardBody } from '../bootstrap/Card';
import Icon from '../icon/Icon';

export default function ConfirmationPopup({ setOpen, title, label, handleConfirm, handleCancel }) {
	return (
		<Modal isOpen={true} setIsOpen={() => {}} isCentered size={'sm'} classNameMd='nv-3'>
			<ModalHeader
				className='pb-0'
				setIsOpen={() => {
					setOpen(false);
				}}></ModalHeader>
			<ModalBody className='pt-0 px-4 pb-4 text-center'>
				<Icon icon={'ErrorOutline'} size='5x' color={'primary'} className='mt-0 mb-2' />
				<div className='col-12'>
					<span className='fs-4 fw-semibold'>{title}</span>
				</div>
				{!!label && (
					<div className='col-12'>
						<p>{label}</p>
					</div>
				)}
				<div className='row justify-content-center mt-4'>
					<div className='col-auto'>
						<Button
							icon='CheckCircleOutline'
							color={'primary'}
							isLight
							size={'lg'}
							className='w-100 '
							onClick={() => {
								!!handleConfirm && handleConfirm();
								setOpen(false);
							}}>
							Oui
						</Button>
					</div>
					<div className='col-auto'>
						<Button
							icon='RemoveCircleOutline'
							color={'primary'}
							isLight
							size={'lg'}
							className='w-100'
							onClick={() => {
								setOpen(false);
							}}>
							Non
						</Button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
}
