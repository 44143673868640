import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../bootstrap/Card';
import Button from '../bootstrap/Button';
import { useFormik } from 'formik';
import Textarea from '../bootstrap/forms/Textarea';
import AuthContext from '../../contexts/authContext';
import {  createDevisLine, deleteDevisLine, getDevisLinesById, updateDevisLine } from '../../odooApi/lineDevis';
import DevisLinesContext from '../../contexts/devisLinesContext';
import CardAddProdectLineDevis from './CardAddProdectLineDevis';
import Select from '../bootstrap/forms/Select';
import Option from '../bootstrap/Option';
import { searchAccountTax } from '../../odooApi/accountTax';

const PopUpUpdateAndCreateLineDevis = ({data,setEditModalLineStatus,create}) => {
    const { userData } = useContext(AuthContext);
    const {setDevisLines} = useContext(DevisLinesContext);
    const [selectProduct,setSelectProduct] = useState({});
    const [taxs,setTaxs] = useState([]);

    useEffect(()=>{
        if(userData&&userData?.id&&userData?.password){
            searchAccountTax(
                userData?.password,
                [
                    "&",
                    ["type_tax_use","=","sale"],
                    ["active","=",true]
                ],
                (taxsData)=>{
                    setTaxs(taxsData?.elements);
                }
            );
            
        }
    },[userData])
    const handelDelete = () => {
        
        if(userData&&userData?.id&&userData?.password){
            deleteDevisLine(
                userData?.password,
                data?.id,
                ()=>{
                    getDevisLinesById(
                        userData?.password,
                        data?.order_id,
                        ({elements})=>{
                            setDevisLines(elements);
                            setEditModalLineStatus(false);
                        }
                    );
                    
                }
            );
        }
        
    }
    const handelUpdate = (values) => {
        const newLineDevis = {
            id:values.id,
            name:values?.name,
            product_uom_qty:values?.product_uom_qty,
            price_unit:values?.price_unit,
            discount:values?.discount,
            tax_id: [parseInt(values?.tax_id)]
        }
        if(userData&&userData?.id&&userData?.password){
            updateDevisLine(
                userData?.password,
                newLineDevis,
                ()=>{
                    getDevisLinesById(
                        userData?.password,
                        data?.order_id,
                        ({elements})=>{
                            setDevisLines({elements});
                            setEditModalLineStatus(false);
                        }
                    );
                    
                }
            )

        }
    }
    const handelCreate = (values) => {
        
        if(userData&&userData?.id&&userData?.password){
            const newLine = {
                order_id:data?.order_id,
                product_id:selectProduct?.id,
                name:values?.name||(create&&selectProduct?.display_name)||"",
                product_uom_qty:values?.product_uom_qty,
                price_unit:values?.price_unit||create&&selectProduct?.lst_price||0,
                discount:values?.discount,
                tax_id: [parseInt(values?.tax_id||!!taxs?.length&&taxs[0]?.id)]
            }
            

            createDevisLine(
                userData?.password,
                newLine,
                ()=>{
                    getDevisLinesById(
                        userData?.password,
                        data?.order_id,
                        ({elements})=> {
                            setDevisLines(elements);
                            setEditModalLineStatus(false);

                        }
                    );
                    
                    
                }
            );
        }
    }
    const formik = useFormik({
		initialValues: {
            id:data?.id,
			display_name:data?.display_name||"",
			name:data?.name,
            price_unit:data?.price_unit||0,
            product_uom_qty:data?.product_uom_qty||1,
            discount:data?.discount?.toFixed(2)||0,
            price_subtotal:data?.price_subtotal,
            nom_produit:data?.nom_produit,
            tax_id:!!data?.tax_id?.length?data?.tax_id[0]:null
		},
        
		onSubmit: (values) => {
            if(create){
                handelCreate(values);
            }else{
                handelUpdate(values)

            }
			
			
		},
	});
    

    const calculSubTotal = () => {
        const tvaPercentage = !!taxs?.length&&taxs?.filter(tax=>tax.id==(formik?.values?.tax_id||!!taxs?.length&&taxs[0]?.id))[0]?.amount;
        const discountPrrice =  ((formik?.values?.product_uom_qty*(formik?.values?.price_unit||create&&selectProduct?.lst_price||0))/100)*
                                                    (100-formik?.values?.discount);
        
        return ((discountPrrice/100)*(100+(tvaPercentage||0))).toFixed(2)||0
    }

    return(
        <Modal
            setIsOpen={()=>{}}
            isOpen={true}
            size='lg'
            isScrollable
            data-tour='mail-app-modal'
            classNameMd="nv-2"
        >
            <ModalHeader className='px-4' setIsOpen={setEditModalLineStatus}>
                {!create&&<ModalTitle id='project-edit'>#{data?.id} | {data.display_name}</ModalTitle>}
            </ModalHeader>
            <ModalBody className='px-4'>
                <div className='row'>
                    <div className='col-md-12'>
                        {
                            create&&
                            <CardAddProdectLineDevis setSelectProduct={setSelectProduct}/>
                            
                        }
                        <Card shadow='sm'>
                            <CardHeader>
                                <CardLabel icon='Info' iconColor='success'>
                                    
                                    <CardTitle>{create?"Create new line":"Line Details"}</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className='row g-4'>
                                    {
                                        data?.id&&
                                        <FormGroup
                                            className='col-12'
                                            id='ref'
                                            label='#Ref'>
                                            <Input
                                                onChange={formik.handleChange}
                                                value={data?.id}
                                                disabled
                                            />
                                        </FormGroup>
                                    }
                                    {
                                        data?.display_name&&
                                        <FormGroup
                                            className='col-12'
                                            id='article'
                                            label='Article'>
                                            <Textarea
                                                rows={1}
                                                value={data?.nom_produit}
                                                name="display_name"
                                                disabled
                                            />
                                        </FormGroup>
                                    }
                                    
                                    <FormGroup
                                        className='col-12'
                                        id='description'
                                        label='Description'>
                                        <Textarea
                                            onChange={formik.handleChange}
                                            value={formik?.values?.name||(create&&selectProduct?.display_name)||""}
                                            rows={6}
                                            name="name"
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        className='col-6'
                                        id='description'
                                        label={'Prix unitaire'}>
                                        <Input
                                            type="number"
                                            onChange={formik.handleChange}
                                            value={formik?.values?.price_unit||create&&selectProduct?.lst_price||0}
                                            name="price_unit"
                                            min={0}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        className='col-6'
                                        id='description'
                                        label='Quantité'>
                                        <Input
                                            type='number'
                                            onChange={formik.handleChange}
                                            value={formik?.values?.product_uom_qty}
                                            name="product_uom_qty"
                                            min={1}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        className='col-6'
                                        id='description'
                                        label={'Discount%'}>
                                        <Input
                                            type='number'
                                            onChange={formik.handleChange}
                                            value={formik?.values?.discount}
                                            min={0}
                                            name="discount"
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        className='col-6'
                                        id='description'
                                        label={'TVA'}
                                    >
                                        <Select
                                            ariaLabel='Board select'
                                            placeholder='Select group'
                                            onChange={formik.handleChange}
                                            value={(formik?.values?.tax_id||!!taxs?.length&&taxs[0]?.id)+""}
                                            name="tax_id"
                                            >
                                            {
                                                !!taxs?.length&&
                                                    taxs?.map((tax)=>{
                                                        return (
                                                            <Option key={tax?.id}  value={tax?.id}>
                                                                {tax?.name}
                                                            </Option>
                                                        )
                                                    })
                                            }
                                        </Select>
                                        
                                    </FormGroup>
                                    <FormGroup
                                        className='col-12'
                                        id='description'
                                        label={'Sous-total'}>
                                        <Input
                                            
                                            value={
                                                calculSubTotal()>0?calculSubTotal().toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            }):0}
                                            disabled
                                        />
                                    </FormGroup>
                                    
                                </div>
                            </CardBody>
                        </Card>
                        
                    </div>
                    

                </div>
            </ModalBody>
            <ModalFooter className='px-4 pb-4'>
                {
                    create?
                        <Button
                            color='primary'
                            className='w-100'
                            type='submit'
                            onClick={formik.handleSubmit}
                            disabled={!selectProduct?.id}
                        >
                            Create
                        </Button>:
                        <Button
                            color='primary'
                            className='w-100'
                            type='submit'
                            onClick={formik.handleSubmit}
                        >
                            Save
                        </Button>
                }
                
                {
                    !create&&
                    <Button
                        color='danger'
                        className='w-100'
                        onClick={handelDelete}>
                        Delete
                    </Button>
                }
            </ModalFooter>
        </Modal>
    )
}

PopUpUpdateAndCreateLineDevis.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	data: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	setEditModalLineStatus:PropTypes.func.isRequired,
    create:PropTypes.bool,
    
};

export default PopUpUpdateAndCreateLineDevis;