import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {IUserProps } from '../common/data/userDummyData';

export interface IAuthContextProps {
	userData: Partial<IUserProps>;
	setUserData?(...args: unknown[]): unknown;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [userData, setUserData] = useState<Partial<IUserProps>>(JSON.parse(localStorage.getItem('facit_authUser')||"{}") ||{});
	
	useEffect(() => {
		localStorage.setItem('facit_authUser', userData?.id&&userData?.username?JSON.stringify(userData):"{}");
	}, [userData]);

	const value = useMemo(
		() => ({
			userData,
			setUserData
		}),
		[userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
