import React, { createContext, useMemo, useState } from 'react';

const MailMessageContext = createContext([]);

export const MailMessageContextProvider = ({ children }) => {
    const [mailMessages, setMailMessages] = useState([]);

    const value = useMemo(
        () => ({
            mailMessages,
            setMailMessages,
        }),
        [mailMessages],
    );
    return <MailMessageContext.Provider value={value}>{children}</MailMessageContext.Provider>;
};

export default MailMessageContext;
