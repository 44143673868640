import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	ACCOUNT_MOVE_LINE_API_URI = '/account.payment';
export const uploadAttachmentPayment = (tokenUserCurent, idPayment, attachement, fnc, fnErr) => {
	let data = new FormData();
	data.append('cheque_image', attachement);
	console.log("attachement", attachement)
	//data.append('image_1920', file);

	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_binary' +
			ACCOUNT_MOVE_LINE_API_URI +
			'/' +
			idPayment +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};
	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			fnErr(error);
		});
};

export const imagePayement = (tokenUserCurent, idPayment) => {
	return (
		BASIC_API_URL +
		'/api/digital/image' +
		ACCOUNT_MOVE_LINE_API_URI +
		'/' +
		idPayment +
		'?token=' +
		tokenUserCurent
	);
};
