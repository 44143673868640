import { useEffect, useState } from "react";

const SelectableImage = ({data,handelChange,currentFormValue}) => {

    //const [value,setValue] = useState(false);

    const handleChange = (prams) => {
        
        //setValue(prams);
        handelChange(false,prams);
        
    }

    useEffect(() => {
        const handleMessage = (event) => {
        
          // Check that the message is from the expected iframe origin
          //if (event.origin !== 'http://localhost:3001') return;
          // Handle the message content
          const idProduct = event.data;
          handleChange(idProduct);
          // Perform the desired action in your React app
          console.log(`Clicked link: ${idProduct}`);
        };
        
        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, []);
    return (
        <>
            <iframe src={data?.iframeUrl} scrolling="no" height={"500px"}/>
        </>

    )
}

export default SelectableImage;