export const dataColor = [
    {
        id: 8,
        label: 'Gris 004',
        value: 'Gris 004',
        color: '#808080',
        nextStep: false,
    },
    {
        id: 13,
        label: 'Ivoire  RAL 1015',
        value: 'Ivoire  RAL 1015',
        color: '#E6D2B5',
        nextStep: false,
    },
    {
        id: 14,
        label: 'Anthracite RAL 7016',
        value: 'Anthracite RAL 7016',
        color: '#383e42',
        nextStep: false,
    },
    {
        id: 16,
        label: 'Chêne doré 038',
        value: 'Chêne doré 038',
        color: '#CD853F',
        nextStep: false,
    },
    {
        id: 42,
        label: 'Marron 002',
        value: 'Marron 002',
        color: '#A0522D',
        nextStep: false,
    },
    {
        id: 43,
        label: 'Alu - Metallic 003',
        value: 'Alu - Metallic 003',
        color: '#c6c2c2',
        nextStep: false,
    },
    {
        id: 60,
        label: 'Gris Clair RAL 7035',
        value: 'Gris Clair RAL 7035',
        color: '#C5C7C4',
        nextStep: false,
    },
    {
        id: 66,
        label: 'Marron Sepia RAL 8014',
        value: 'Marron Sepia RAL 8014',
        color: '#49372a',
        nextStep: false,
    },
    {
        id: 6,
        label: 'Blanc',
        value: 'Blanc',
        color: '#ffffff',
        nextStep: false,
    },
];