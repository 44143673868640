import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import COLORS from '../common/data/enumColors';



const AtelierContext = createContext([]);

export const AtelierContextProvider = ({ children }) => {
    const [atelier, setAtelier] = useState([

        {
            id: 'non traité',
            title: 'Non traité',
            color: COLORS.DARK.name,
            icon: 'ScheduleSend',
            state: 'non traité',
            total: 0,
            current_page: 1,
            cards: [],
        },
        {
            id: 'en cours',
            title: 'En cours',
            color: COLORS.WARNING.name,
            icon: 'Block',
            total: 0,
            state: 'en cours',
            current_page: 1,
            cards: [],
        },
        {
            id: 'fini',
            title: 'Traité',
            color: COLORS.SUCCESS.name,
            icon: 'Block',
            total: 0,
            state: 'fini',
            current_page: 1,
            cards: [],
        },


    ]);

    const value = useMemo(
        () => ({
            atelier,
            setAtelier,
        }),
        [atelier],
    );
    return <AtelierContext.Provider value={value}>{children}</AtelierContext.Provider>;
};
AtelierContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AtelierContext;