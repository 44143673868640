import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import SimulatorMotor from './SimulatorMotor';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Button from '../bootstrap/Button';
/* import AuthContext from '../../contexts/authContext';
import { createDevisLine, getDevisLinesById } from '../../odooApi/lineDevis';
import DevisLinesContext from '../../contexts/devisLinesContext'; */
import { useTranslation } from 'react-i18next';

const SimulatorPopUp = ({
	data,
	setEditModalSimulator,
	simulatorData,
	currentFormValue,
	setCurrentFormValue,
	children,
}) => {
	const { t } = useTranslation(['translation', 'menu']);
	useEffect(() => {
		var messageBody = document.querySelector('#modelBody');
		messageBody.scrollTop = messageBody.scrollHeight;
	}, [currentFormValue]);
	return (
		<Modal
			setIsOpen={() => {}}
			isOpen={true}
			size='lg'
			isScrollable
			data-tour='mail-app-modal'
			classNameMd='nv-3'>
			<ModalHeader
				className='px-4'
				setIsOpen={(e) => {
					setEditModalSimulator(e);
					setCurrentFormValue({});
				}}>
				{<ModalTitle id='project-edit'>{data?.modalTitle}</ModalTitle>}
			</ModalHeader>
			<ModalBody className='px-4' id='modelBody'>
				<div className='row'>
					<div className='col-md-12'>
						<Card className='mb-2'>
							<CardHeader className='pb-0'>
								<CardLabel icon={'Straighten'} iconColor={'info'}>
									<CardTitle>{data?.cardTitle}</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row g-4'>
									<SimulatorMotor
										currentFormValue={currentFormValue}
										setCurrentFormValue={setCurrentFormValue}
										simulatorData={simulatorData}
									/>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</ModalBody>
			<ModalFooter className='px-4 pb-4'>{children}</ModalFooter>
		</Modal>
	);
};

SimulatorPopUp.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore

	// eslint-disable-next-line react/forbid-prop-types
	setEditModalSimulator: PropTypes.func.isRequired,
	data: PropTypes.any.isRequired,
};

export default SimulatorPopUp;
