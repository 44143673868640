import formInputsTypes from '../../simulatorConstant';
export const CATEG_ID_MOTEUR_RIDEAU = 36;
import { searchProduct } from '../../../../../odooApi/product';
import { AxeMotoriseRM } from '../data';

export const BoiteRMData = {
	id: 'BoiteRMData',
	type: formInputsTypes?.CHECKBOX_INPUT,
	label: 'Boite',
	name: 'BoiteRMData',
	className: 'col-12',
	nextStepData: false,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		let desc_data = '';
		console.log("puissance", currentFormValue?.puissance)
		const generateDescription = () => {
			return `moteur de rideau métallique ${currentFormValue?.AxeMotoriseRMData
				? '\n Moteur ' +
				currentFormValue?.AxeMotoriseRMData?.value + currentFormValue?.puissance?.label +
				" filaire avec fins de courses mécaniques s'effectuant grâce à un système progressif équipé d'une bague mémoire integrée dans le boitier de protection \n"
				: ''
				} " \n - Axe d'ancrage de (60 mm) équipé d'une couronne de diamètre 220 mm" `;
		};
		const options = [
			{
				id: 'Câble de débrayage',
				label: 'Câble de débrayage',
				value: 'Câble de débrayage',
				nextStep: false,
				checked: false,
				product_id: currentFormValue?.RMData?.id,
				description: generateDescription(),
				lst_price: parseFloat(currentFormValue?.puissance?.prix).toFixed(2)
			},
			{
				id: 'Boîte à bouton',
				label: 'Boîte à bouton',
				value: 'Boîte à bouton',
				nextStep: false,
				product_id: currentFormValue?.RMData?.id,
				description: generateDescription(),
				lst_price: parseFloat(currentFormValue?.puissance?.prix).toFixed(2)
			},
			{
				id: 'Boîte à clé',
				label: 'Boîte à clé',
				value: 'Boîte à clé',
				nextStep: false,
				product_id: currentFormValue?.RMData?.id,
				description: generateDescription(),
				lst_price: parseFloat(currentFormValue?.puissance?.prix).toFixed(2)
			},
			{
				id: 'Emetteur - Recepteur',
				label: 'Émetteur - Récepteur + Bip',
				value: 'Émetteur - Récepteur + Bip',
				nextStep: false,
				product_id: currentFormValue?.RMData?.id,
				description: generateDescription(),
				lst_price: parseFloat(currentFormValue?.puissance?.prix).toFixed(2)
			},
			{
				id: 'Boîte à clé avec système de débrayage',
				label: 'Boîte à clé avec système de débrayage',
				value: 'Boîte à clé avec système de débrayage',
				nextStep: false,
				product_id: currentFormValue?.RMData?.id,
				description: generateDescription(),
				lst_price: parseFloat(currentFormValue?.puissance?.prix).toFixed(2)
			},
		];

		fnSuccess(options);
	},
};
const puissance_tubulaire = {
	id: 'puissance',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Puissance',
	name: 'puissance',
	className: 'col-12',
	nextStepData: BoiteRMData,
	nextStep: false,
	options: [
		{
			id: '250',
			label: '250 Nm',
			value: '250',
			prix: 1887.1,
			nextStep: false,
		},
		{
			id: '300',
			label: '300 Nm',
			value: '300',
			prix: 1950.88,
			nextStep: false,
		},
		{
			id: '400',
			label: '400 Nm',
			value: '400',
			prix: 2100,
			nextStep: false,
		},
		{
			id: '550',
			label: '550 Nm',
			value: '550',
			prix: 2350,
			nextStep: false,
		},
		{
			id: '650',
			label: '650 Nm',
			value: '650',
			prix: 2600,
			nextStep: false,
		},

	],

}
const puissance_centrale_mono = {
	id: 'puissance',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Puissance',
	name: 'puissance',
	className: 'col-12',
	nextStepData: BoiteRMData,
	nextStep: false,
	options: [
		{
			id: '140',
			label: '140 Nm',
			value: '140',
			prix: 680.88,
			nextStep: false,
		},
		{
			id: '170',
			label: '170 Nm',
			value: '170',
			prix: 768.15,
			nextStep: false,
		},
		{
			id: '190',
			label: '190 Nm',
			value: '190',
			prix: 977.99,
			nextStep: false,
		},
		{
			id: '220',
			label: '220 Nm',
			value: '220',
			prix: 1099.01,
			nextStep: false,
		},

	],

}
const puissance_centrale_triphase = {
	id: 'puissance',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Puissance',
	name: 'puissance',
	className: 'col-12',
	nextStepData: BoiteRMData,
	nextStep: false,
	options: [
		{
			id: '250',
			label: '250 Nm',
			value: '250',
			prix: 751.73,
			nextStep: false,
		},
		{
			id: '400',
			label: '400 Nm',
			value: '400',
			prix: 1061.18,
			nextStep: false,
		},
		{
			id: '550',
			label: '550 Nm',
			value: '550',
			prix: 1393.50,
			nextStep: false,
		},
		{
			id: '650',
			label: '650 Nm',
			value: '650',
			prix: 1476.98,
			nextStep: false,
		},
		{
			id: '1000',
			label: '1000 Nm',
			value: '1000',
			prix: 2481.74,
			nextStep: false,
		},



	],

}
const puissance_lateral = {
	id: 'puissance',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Puissance',
	name: 'puissance',
	className: 'col-12',
	nextStepData: BoiteRMData,
	nextStep: false,
	options: [
		{
			id: '220',
			label: '220 Nm',
			value: '220',
			prix: 1028.16,
			nextStep: false,
		},

		{
			id: '380',
			label: '380 Nm',
			value: '380',
			prix: 1300.31,
			nextStep: false,
		},
		{
			id: '400',
			label: '400 Nm',
			value: '400',
			prix: 1441.39,
			nextStep: false,
		},
		{
			id: '500',
			label: '500 Nm',
			value: '500',
			prix: 1414.31,
			nextStep: false,
		},
		{
			id: '750',
			label: '750 Nm',
			value: '750',
			prix: 1422.15,
			nextStep: false,
		},



	],

}
const MoteurRMData = {
	id: 'MoteurRMData',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Moteur',
	name: 'MoteurRMData',
	className: 'col-6',
	nextStepData: BoiteRMData,
	nextStep: false,
	options: null,
	fetchData: (tokenUserCurent, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [['categ_id', '=', CATEG_ID_MOTEUR_RIDEAU]];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(), async ({ elements }) => {
			const options = elements?.length
				? elements.map((item) => {
					const { product_template_attribute_value_ids } = item;
					let name = item?.display_name + ' ';
					product_template_attribute_value_ids.map((i) => (name += i.name + ' '));
					return {
						id: item?.id,
						label: name,
						value: item?.id,
					};
				})
				: [];
			fnSuccess(options);
		});
	},
};

const RideauMetalliqueMotorisationData = {
	id: 'AxeMotoriseRMData',
	type: formInputsTypes?.SELECT_INPUT,
	label: 'Motorisation',
	name: 'AxeMotoriseRMData',
	className: 'col-6',
	nextStepData: false,
	nextStep: false,
	options:
		[
			{
				id: 'centrale_monophasé',
				label: 'Centrale monophasée',
				value: 'central monophasé',
				nextStep: puissance_centrale_mono,
			},
			{
				id: 'tubulaire',
				label: 'Tubulaire',
				value: 'tubulaire',
				nextStep: puissance_tubulaire,
			},
			{
				id: 'latérale',
				label: 'Latérale',
				value: 'latéral',
				nextStep: puissance_lateral,
			},
			{
				id: 'centrale_triphasé',
				label: 'Centrale triphasée',
				value: 'central triphasé',
				nextStep: puissance_centrale_triphase,
			},
		],
};

export default RideauMetalliqueMotorisationData;
