export const SabotRM = [
	{
		id: 'RMASABOT',
		label: 'Avec sabot',
		value: 'avec sabot',
		nextStep: false,
	},
	{
		id: 'RMESSABOT',
		label: 'sans sabot',
		value: 'sans sabot',
		nextStep: false,
	},
];

export const LameFRM = [
	{
		id: 'RMAS',
		label: 'Avec serrure',
		value: 'avec serrure',
		nextStep: false,
	},
	{
		id: 'RMESS',
		label: 'sans serrure',
		value: 'sans serrure',
		nextStep: false,
	},
];

export const EnroulementRM = [
	{
		id: 'RMINT',
		label: 'intérieur',
		value: 'intérieur',
		nextStep: false,
	},
	{
		id: 'RMEXT',
		label: 'extérieur',
		value: 'extérieur',
		nextStep: false,
	},
];

export const coulisseRM = [
	{
		id: 'sansCOUL',
		label: 'Sans coulisses',
		value: 'Sans coulisses',
		nextStep: false,
	},
	{
		id: 'avecCOUL',
		label: 'Avec coulisses',
		value: 'Avec coulisses',
		nextStep: false,
	},
];

export const BoiteRM = [
	{
		id: 'Câble de débrayage',
		label: 'Câble de débrayage',
		value: 'Câble de débrayage',
		nextStep: false,
		checked: false,
	},
	{
		id: 'Boîte à bouton',
		label: 'Boîte à bouton',
		value: 'Boîte à bouton',
		nextStep: false,
	},
	{
		id: 'Boîte à clé',
		label: 'Boîte à clé',
		value: 'Boîte à clé',
		nextStep: false,
	},
	{
		id: 'Boîte à clé avec système de débrayage',
		label: 'Boîte à clé avec système de débrayage',
		value: 'Boîte à clé avec système de débrayage',
		nextStep: false,
	},
	{
		id: 'Emetteur - Recepteur',
		label: 'Émetteur - Récepteur + Bip',
		value: 'Émetteur - Récepteur + Bip',
		nextStep: false,
	},
];
export const AxeMotoriseRM = [
	{
		id: 'centrale_monophasé',
		label: 'Centrale monophasée',
		value: 'central monophasé',
		nextStep: false,
	},
	{
		id: 'tubulaire',
		label: 'Tubulaire',
		value: 'tubulaire',
		nextStep: false,
	},
	{
		id: 'latérale',
		label: 'Latérale',
		value: 'latéral',
		nextStep: false,
	},
	{
		id: 'centrale_triphasé',
		label: 'Centrale triphasée',
		value: 'central triphasé',
		nextStep: false,
	},
];

export const AxeManuelRM = [
	{
		id: '50',
		label: '50',
		value: '50',
		nextStep: false,
	},
	{
		id: '60',
		label: '60',
		value: '60',
		nextStep: false,
	},
	{
		id: '76',
		label: '76',
		value: '76',
		nextStep: false,
	},
	{
		id: '133',
		label: '133',
		value: '133',
		nextStep: false,
	},
	{
		id: '168',
		label: '168',
		value: '168',
		nextStep: false,
	},
];
export const platBombeRM = [
	{
		id: 'sansPB',
		label: 'Sans plats bombés',
		value: 'Sans plats bombés',
		nextStep: false,
	},
	{
		id: 'avecPB',
		label: 'Avec plats bombés',
		value: 'Avec plats bombés',
		nextStep: false,
	},
];

export const bobineRM = [
	{
		id: '2',
		label: '2',
		value: '2',
		nextStep: false,
	},
	{
		id: '4',
		label: '4',
		value: '4',
		nextStep: false,
	},
	{
		id: '6',
		label: '6',
		value: '6',
		nextStep: false,
	},
	{
		id: '8',
		label: '8',
		value: '8',
		nextStep: false,
	},
];

export const sortieCableRM = [
	{
		id: 'CVE',
		label: 'droite vue extérieur',
		value: 'droite vue extérieur',
		nextStep: false,
	},
	{
		id: 'DVI',
		label: 'droite vue intérieur',
		value: 'droite vue intérieur',
		nextStep: false,
	},
	{
		id: 'GVE',
		label: 'gauche vue extérieur',
		value: 'gauche vue extérieur',
		nextStep: false,
	},
	{
		id: 'GVI',
		label: 'gauche vue intérieur',
		value: 'gauche vue intérieur',
		nextStep: false,
	},
];

export const PoseRM = [
	{
		id: 'POSEAPP',
		label: 'Pose en applique',
		value: 'Pose en applique',
		nextStep: false,
	},
	{
		id: 'POSETUNNEL',
		label: 'Pose en tunnel',
		value: 'Pose en tunnel',
		nextStep: false,
	},
	{
		id: 'POSEENCAST',
		label: 'Pose encastrée',
		value: 'Pose encastrée',
		nextStep: false,
	},
];

export const MesureLargeurRM = [
	{
		id: 'RMFN',
		label: 'FINI',
		value: 'FINI',
		nextStep: false,
	},
	{
		id: 'RME',
		label: 'DC',
		value: 'DC',
		nextStep: false,
	},
];

export const MesureHauteurRM = [
	{
		id: 'RMEC',
		label: 'Enroulement compris',
		value: 'enroulement compris',
		nextStep: false,
	},
	{
		id: 'RMPE',
		label: '+E',
		value: '+E',
		nextStep: false,
	},
];
export const coffreRM = [
	{
		id: 'sansCOFF',
		label: 'Sans coffre',
		value: 'Sans coffre',
		nextStep: false,
	},
	{
		id: 'avecCOFF',
		label: 'Avec coffre',
		value: 'Avec coffre',
		nextStep: false,
	},
];
