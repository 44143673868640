import heic2any from 'heic2any';
import { useEffect, useState } from 'react';
import SingleAttahcement from './SingleAttahcement';

const DisplayHEICImage = ({ src, theFile, deleteImage, index }) => {
	const [imgSrc, setImgSrc] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchAndDisplayImage = async () => {
			try {
				const blob = await fetch(src).then((res) => res.blob());
				console.log("blob", blob)
				const imgData = await heic2any({
					blob,
					toType: 'image/jpeg', // Convert to JPEG
				});
				const url = URL.createObjectURL(imgData);
				setImgSrc(url);
			} catch (error) {
				console.error('Error displaying HEIC image:', error);
				setError(error.message);
			}
		};

		fetchAndDisplayImage();
	}, [src]);

	if (error) {
		return <div>Error displaying image: {error}</div>; // Display error message
	}

	if (!imgSrc) {
		return <div>Loading image...</div>; // Display loading message while fetching image
	}

	return (
		<SingleAttahcement
			src={imgSrc}
			index={index}
			name={theFile?.name}
			deleteImage={deleteImage}>
			<img
				src={imgSrc}
				alt={theFile?.name}
				style={{
					objectFit: 'fill',
					width: '100%',
					height: '100%',
				}}
			/>
		</SingleAttahcement>
	);
};

export default DisplayHEICImage;
