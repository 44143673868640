import * as React from 'react';

const SvgCustomVolet = (props) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 216 218'
        xmlSpace='preserve'
        width='1em'
        height='1em'
        className='svg-icon' fill='currentColor'
        {...props}>
        <path opacity={0.3} d="M148.277 45H67.7309C42.0598 45 32 57.905 32 80.0175V154.91C32 177.385 42.0598 190 67.7309 190H148.277C174.362 190 184 177.385 184 154.91V80.0175C184 57.905 174.362 45 148.277 45Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M42.4841 42.8262H173.262C180.204 42.8262 183.746 39.7261 183.746 35.9175C183.746 32.1089 180.204 29 173.262 29H42.4841C35.5264 29 32 32.1089 32 35.9175C32 39.7261 35.5264 42.8262 42.4841 42.8262ZM57.3219 69.1967H157.766C163.122 69.1967 166.088 66.462 166.088 63.1022C166.088 59.7425 163.122 57 157.766 57H57.3219C51.9533 57 49 59.7425 49 63.1022C49 66.462 51.9533 69.1967 57.3219 69.1967ZM57.3219 87.4917H157.766C163.122 87.4917 166.088 84.757 166.088 81.3973C166.088 78.0375 163.122 75.295 157.766 75.295H57.3219C51.9533 75.295 49 78.0375 49 81.3973C49 84.757 51.9533 87.4917 57.3219 87.4917ZM157.766 105.787H57.3219C51.9533 105.787 49 103.052 49 99.6923C49 96.3326 51.9533 93.5901 57.3219 93.5901H157.766C163.122 93.5901 166.088 96.3326 166.088 99.6923C166.088 103.052 163.122 105.787 157.766 105.787ZM57.3219 124.082H157.766C163.122 124.082 166.088 121.347 166.088 117.987C166.088 114.628 163.122 111.885 157.766 111.885H57.3219C51.9533 111.885 49 114.628 49 117.987C49 121.347 51.9533 124.082 57.3219 124.082ZM157.766 142.377H57.3219C51.9533 142.377 49 139.642 49 136.282C49 132.923 51.9533 130.18 57.3219 130.18H157.766C163.122 130.18 166.088 132.923 166.088 136.282C166.088 139.642 163.122 142.377 157.766 142.377ZM57.3219 160.672H157.766C163.122 160.672 166.088 157.937 166.088 154.577C166.088 151.218 163.122 148.475 157.766 148.475H57.3219C51.9533 148.475 49 151.218 49 154.577C49 157.937 51.9533 160.672 57.3219 160.672ZM157.766 178.967H57.3219C51.9533 178.967 49 176.232 49 172.872C49 169.513 51.9533 166.77 57.3219 166.77H157.766C163.122 166.77 166.088 169.513 166.088 172.872C166.088 176.232 163.122 178.967 157.766 178.967Z" />
    </svg>

)
export default SvgCustomVolet;