import formInputsTypes from "../simulatorConstant";
import TraditIcone from '../../../../assets/img/tradi-icone.svg';
import VoletIcone from '../../../../assets/img/volet-icone.svg';
import voletRoulantTradi from "./tradi";
import voletRoulantReno from "./reno";





const voletRoulant = {
    id:"VR-TI",
    type:formInputsTypes?.RADIO_INPUT,
    label:"Sélectionner le type d'installation",
    name:"typeInstallation",
    className:"col-12",
    classGroup:"SInstallation",
    nextStep:false,
    options:[
        {
            id:"VR-TR",
            label:"Traditionnel",
            value:"VR-TR",
            imageUrl: TraditIcone,
            nextStep:voletRoulantTradi
        },
        {
            id:"VR-RN",
            label:"Réno/Bloc Baie",
            value:"VR-RN",
            imageUrl: VoletIcone,
            nextStep:voletRoulantReno
        }
    ]
    
}

export default voletRoulant;


