import formInputsTypes from './simulatorConstant';
import voletRoulant from './voletRoulant';
import VoletIcone from '../../../assets/img/volet-icone.svg';
import RideauIcone from '../../../assets/img/rideau-icone.svg';
import category from './pieceDetache';
import PieceIcone from '../../../assets/img/piece-icone.svg';
import MainOeuvre from './MainOuvre';
import StoreBanneData from './Storebanne';
import StoreBanneIcon from '../../../assets/img/store-banne.svg';
import MainOeuvreIcon from '../../../assets/img/main-oeuvre.svg';
import ForfaitIcon from '../../../assets/img/forfait.svg';
import RideauMetalliqueData from './rideauMetallique';
import Forfaits from './Forfaits';

/* const simulatorData2 = {
    id:"SP",
    type:formInputsTypes?.RADIO_INPUT,
    label:"Selectionez Un Category",
    className:"col-12",
    nextStep:false,
    options:[
        {
            id:"VR",
            label:"Volet Roulant",
            value:"VR",
            nextStep:false
        },
        {
            id:"RM",
            label:"Rideau metallique",
            value:"RM",
            disabled:true,
            nextStep:false
        }
    ]
    
} */

/* const testtttttt = {
    id:"testttt",
    type:formInputsTypes?.INPUT_NUMBER,
    className:"col-12",
    label:"ddddddddddddddddddddddd",
    nextStepData: simulatorData2,
    nextStep: false,
    validation:(value)=>{
        return value>=4
    }
} */

const simulatorData = {
	id: 'SP',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Sélectionner une categorie',
	name: 'category',
	className: 'col-12',
	classGroup: 'SCategory',
	nextStep: false,
	options: [
		{
			id: 'VR',
			label: 'Volet Roulant',
			value: 'VR',
			imageUrl: VoletIcone,
			nextStep: voletRoulant,
		},
		{
			id: 'RM',
			label: 'Rideau métallique',
			value: 'RM',
			imageUrl: RideauIcone,
			nextStep: RideauMetalliqueData,
		},
		{
			id: 'SB',
			label: 'Store banne',
			value: 'SB',
			imageUrl: StoreBanneIcon,
			nextStep: StoreBanneData,
		},
		{
			id: 'VR-TR-PCD',
			label: 'Pièces détachées',
			value: 'VR-TR-PCD',
			imageUrl: PieceIcone,
			nextStep: category,
		},
		{
			id: 'MO',
			label: "Main d'oeuvre",
			value: 'MO',
			imageUrl: MainOeuvreIcon,
			nextStep: MainOeuvre,
		},
		{
			id: 'FOR',
			label: 'Forfaits',
			value: 'FOR',
			imageUrl: ForfaitIcon,
			nextStep: Forfaits,
		},
	],
};

export default simulatorData;
