import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import COLORS from '../../common/data/enumColors';
import { getImageByModule } from '../../common/function/functionsUtils';
import AuthContext from '../../contexts/authContext';
import { searchActivities, updateActivity } from '../../odooApi/activites';
import Avatar from '../Avatar';
import ConfirmationPopup from '../PlanningCalendar/ConfirmationPopup';
import Badge from '../bootstrap/Badge';
import Button from '../bootstrap/Button';
import Card, { CardBody, CardTabItem } from '../bootstrap/Card';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas';
import Spinner from '../bootstrap/Spinner';
import ErrorPopup from '../extras/ErrorPopup';
import ActivityCreatePopup from './ActivityCreatePopup';
import ActivityUpdatePopup from './ActivityUpdatePopup';

function TasksNotifications({ setOffcanvasStatus }) {
	const { userData } = useContext(AuthContext);

	const [card, setCard] = useState();
	const [createtModalStatus, setCreatetModalStatus] = useState(false);
	const [editModalStatus, setEditModalStatus] = useState(false);
	const [todayData, setTodayData] = useState([]);
	const [todayTotal, setTodayTotal] = useState(0);
	const [lateTotal, setLateTotal] = useState(0);
	const [lateData, setLateData] = useState([]);
	const [todaySpinner, setTodaySpinner] = useState(false);
	const [lateSpinner, setLateSpinner] = useState(false);
	const [error, setError] = useState(false);

	const LoadTodayTasks = () => {
		setTodaySpinner(true);
		searchActivities(
			userData?.password,
			[
				'&',
				['x_state', '!=', 'done'],
				'&',
				['x_state', '!=', ''],
				['date_deadline', '=', moment().format('yyyy-MM-DD')],
			],
			(resuldata) => {
				setTodayData(resuldata?.elements);
				setTodayTotal(resuldata?.records?.total);
				setTodaySpinner(false);
			},
			(error) => {
				setError(error);
				setTodaySpinner(false);
			},
			0,
			0,
		);
	};
	const LoadLateTasks = () => {
		setLateSpinner(true);
		searchActivities(
			userData?.password,
			[
				'&',
				['x_state', '!=', 'done'],
				'&',
				['x_state', '!=', ''],
				['date_deadline', '<', moment().format('yyyy-MM-DD') + ' 00:00:00'],
			],
			(resuldata) => {
				setLateData(resuldata?.elements);
				setLateTotal(resuldata?.records?.total);
				setLateSpinner(false);
			},
			(error) => {
				setError(error);
				setLateSpinner(false);
			},
			0,
			0,
		);
	};
	useEffect(() => {
		LoadTodayTasks();
		LoadLateTasks();
	}, []);

	return (
		<>
			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={true}
				setOpen={() => {}}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>Taches</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='row justify-content-end'>
						<Button
							className='col-auto'
							color={'light'}
							icon='AddTask'
							onClick={() => setCreatetModalStatus(true)}>
							Créer
						</Button>
					</div>

					<Card hasTab shadow={'none'}>
						<CardTabItem id='contenu' title={`Aujourd'hui ${todayTotal}`}>
							{todaySpinner ? (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											flexDirection: 'column',
										}}>
										<Spinner color='dark' size={'4rem'} />
										<span className='p-3'>Chargement des données ...</span>
									</div>
								</div>
							) : (
								<>
									<div className='row justify-content-end mb-2'>
										<Button
											className='col-auto btn-only-icon'
											color={'info'}
											icon='PublishedWithChanges'
											isLight
											onClick={() => LoadTodayTasks()}
										/>
									</div>

									{todayData?.map((task, i) => (
										<Task task={task} key={i} setTasks={setTodayData} />
									))}
								</>
							)}
						</CardTabItem>
						<CardTabItem id='contenu' title={`En retard ${lateTotal}`}>
							{lateSpinner ? (
								<CardBody
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
											flexDirection: 'column',
										}}>
										<Spinner color='dark' size={'4rem'} />
										<span className='p-3'>Chargement des données ...</span>
									</div>
								</CardBody>
							) : (
								<>
									<div className='row justify-content-end mb-2'>
										<Button
											className='col-auto btn-only-icon'
											color={'info'}
											icon='PublishedWithChanges'
											isLight
											onClick={() => LoadLateTasks()}
										/>
									</div>

									{lateData?.map((task, i) => (
										<Task task={task} key={i} setTasks={setTodayData} />
									))}
								</>
							)}
						</CardTabItem>
					</Card>
				</OffCanvasBody>
			</OffCanvas>
			{editModalStatus && (
				<ActivityUpdatePopup card={card} setEditModalStatus={setEditModalStatus} />
			)}
			{!!createtModalStatus && (
				<ActivityCreatePopup setEditModalStatus={setCreatetModalStatus} />
			)}
		</>
	);
}

export default TasksNotifications;

export const Task = ({ task, key, setTasks }) => {
	const data = [
		{
			id: 'to_do',
			title: 'À faire',
			color: COLORS.PRIMARY.name,
			icon: 'TaskAlt',
		},
		{
			id: 'in_progress',
			title: 'En cours',
			color: COLORS.INFO.name,
			icon: 'DirectionsRun ',
		},

		{
			id: 'done',
			title: 'Terminée',
			color: COLORS.SUCCESS.name,
			icon: 'Verified',
		},
	];
	const [spinner, setSpinner] = useState(false);
	const { userData } = useContext(AuthContext);
	const [error, setError] = useState(false);
	const [confirmUpdatePopup, setConfirmUpdatePopup] = useState(false);
	const [statut, setStatut] = useState(data?.find((t) => t?.id == task?.x_state));
	const [updatedStatut, setUpdatedStatut] = useState({});
	const [openActivity, setOpenActivity] = useState(false);

	const handleUpdate = (status) => {
		updateActivity(
			userData?.password,
			{
				id: task?.id,
				x_state: status?.id,
			},
			(result) => {
				if (status?.id == 'done') {
					setTasks((prev) => prev.filter((t) => t.id != task.id));
				}
				setUpdatedStatut(status);
				setSpinner(false);
			},
			(error) => {
				setSpinner(false);
				setError(error);
			},
		);
	};
	useEffect(() => {
		setUpdatedStatut(data?.find((t) => t?.id == task?.x_state));
	}, [task?.x_state]);

	return (
		<>
			<Card shadow={'sm'} key={key} className='p-3'>
				<div className='row justify-content-end'>
					<div className='col-auto'>
						<Dropdown>
							<DropdownToggle>
								<Button
									shadow={'md'}
									color={
										!!updatedStatut?.id ? updatedStatut?.color : statut?.color
									}
									isLight
									icon={
										!!!spinner && !!updatedStatut?.id
											? updatedStatut?.icon
											: statut?.icon
									}
									size={'sm'}>
									{!!spinner && <Spinner isSmall inButton />}
									{!!spinner
										? 'En cours'
										: !!updatedStatut?.id
										? updatedStatut?.title
										: statut?.title}
								</Button>
							</DropdownToggle>
							<DropdownMenu size={'sm'}>
								{data.map((group) => (
									<DropdownItem>
										<Button
											key={group.id}
											color='link'
											icon={group.icon}
											// onClick={() => {
											// 	formik.setFieldValue('x_state', group?.id);
											// }}

											onClick={() => {
												if (
													!!updatedStatut?.id
														? updatedStatut?.id !== group?.id
														: statut?.id !== group?.id
												) {
													setStatut(group);
													setConfirmUpdatePopup(true);
												}
											}}>
											{group.title}
										</Button>
									</DropdownItem>
								))}
							</DropdownMenu>
						</Dropdown>
					</div>
				</div>
				<div className='col-8'>
					{task?.x_users_ids?.map((user) => (
						<>
							<Avatar
								src={getImageByModule(
									userData?.password,
									user?.employee_id,
									'hr.employee',
								)}
								srcSet={getImageByModule(
									userData?.password,
									user?.employee_id,
									'hr.employee',
								)}
								size={20}
								userName={`${user?.name}`}
								className='cursor-pointer img-avatar me-2'
							/>
							<span>{user?.name}</span>
						</>
					))}
					<div className='mt-2'>
						<span className='me-2'>
							<Badge isLight color={'info'}>
								{task?.activity_type_id?.name}
							</Badge>
						</span>
						<span> {task?.x_name} </span>
					</div>
					{!moment(task?.date_deadline).isSame(moment(), 'days') && (
						<span className='text-danger'>
							{moment(task?.date_deadline).format('D MMM')}
						</span>
					)}
				</div>

				<Button
					className='mt-2'
					icon='RemoveRedEye'
					color='primary'
					isLight
					onClick={() => {
						setOpenActivity(true);
					}}>
					Voir
				</Button>
			</Card>
			{confirmUpdatePopup && (
				<ConfirmationPopup
					title={`Modifier statut ! `}
					label={`${data?.find((t) => t?.id == task?.x_state)?.title} -> ${
						statut?.title
					}`}
					handleConfirm={() => handleUpdate(statut)}
					setOpen={() => {
						setConfirmUpdatePopup(false);
						setStatut(data?.find((t) => t?.id == task?.x_state));
					}}
				/>
			)}
			{!!error && <ErrorPopup error={error} setOpen={setError} />}
			{!!openActivity && (
				<ActivityUpdatePopup
					card={task}
					setEditModalStatus={setOpenActivity}
					setTasks={setTasks}
				/>
			)}
		</>
	);
};
