import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	PURCHASE_API_URI = '/purchase.order',
	LIMIT = 0;

export const searchAchat = (
	tokenUserCurent,
	filters,
	fncSuccess,
	fnError = () => { },
	signal,
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PURCHASE_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
		signal,
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data || []);
			return;
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const searchAchats = (
	tokenUserCurent,
	filters,
	fnSuccess,
	fnError = () => { },
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PURCHASE_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const createAchat = (tokenUserCurent, newAchat, fnSucces, fnError = () => { }) => {
	const data = JSON.stringify(newAchat);

	const config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PURCHASE_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			//console.log(JSON.stringify(response.data));
			fnSucces(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const updateSingleAchat = (tokenUserCurent, achat, fnSucces, fnErr) => {
	const devisLineString = JSON.stringify(achat);

	const config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PURCHASE_API_URI + '/' + achat?.id}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: devisLineString,
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			console.log(error);
			fnErr(error);
		});
};
export const sendAchatByMail = (tokenUserCurent, achatId, fnSucces, fnError) => {
	const config = {
		method: 'get',
		url: `${'/odoo' + API_URL_WEB_ADMIN + PURCHASE_API_URI + '/' + achatId + '/send'}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};
export const sendBonCommandByMail = (tokenUserCurent, achatId, fnSucces, fnError = () => { }) => {
	const config = {
		method: 'get',
		url: `${'/odoo' + API_URL_WEB_ADMIN + PURCHASE_API_URI + '/' + achatId + '/send?commande=true'
			}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};
export const sendDemandPrixByMail = (tokenUserCurent, achatId, fnSucces, fnError = () => { }) => {
	const config = {
		method: 'get',
		url: `${'/odoo' + API_URL_WEB_ADMIN + PURCHASE_API_URI + '/' + achatId + '/send'}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};
export const getSingleAchat = (tokenUserCurent, achatId, fnSucces) => {
	const config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PURCHASE_API_URI + '/' + achatId}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fnSucces(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};
