import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';


const ClientContext = createContext([]);


export const ClientContextProvider= ({ children }) => {
	const [client, setClient] = useState([]);

	const value = useMemo(
		() => ({
			client,
			setClient
		}),
		[client],
	);
	return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>;
};
ClientContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ClientContext;
