import { useFormik } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import COLORS from '../../common/data/enumColors';
import { getImageByModule } from '../../common/function/functionsUtils';
import ActivitiesContext from '../../contexts/activityContext';
import AuthContext from '../../contexts/authContext';
import {
	createActivityNote,
	deleteActivity,
	deleteActivityNote,
	deleteActivityType,
	searchActivityType,
	updateActivity,
	uploadAttachementsActivity,
} from '../../odooApi/activites';
import { searchUsers } from '../../odooApi/employee';
import { getSinglePlanning } from '../../odooApi/planningSlot';
import { ActivityChat } from '../ActivityChat';
import Avatar from '../Avatar';
import Chat from '../Chat';
import ConfirmationPopup from '../PlanningCalendar/ConfirmationPopup';
import InterventionPopUp from '../PlanningCalendar/Intervention/InterventionPopUp';
import Badge from '../bootstrap/Badge';
import Button from '../bootstrap/Button';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTabItem,
} from '../bootstrap/Card';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import Spinner from '../bootstrap/Spinner';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import InputGroup from '../bootstrap/forms/InputGroup';
import Textarea from '../bootstrap/forms/Textarea';
import ErrorPopup from '../extras/ErrorPopup';
import Icon from '../icon/Icon';
import AddActivityType from './AddActivityType';
import DisplayImage from './DisplayImage';

const ActivityUpdatePopup = ({ card, setEditModalStatus, setTasks }) => {
	const [employeeData, setEmployeeData] = useState([]);
	const { userData } = useContext(AuthContext);
	const { activities, setActivities } = useContext(ActivitiesContext);
	const [categories, setCategories] = useState([]);
	const [spinner, setSpinner] = useState(false);
	const [error, setError] = useState(false);
	const [searchItem, setSearchItem] = useState('');
	const [searchIsLoading, setSearchIsLoading] = useState(false);
	const [filterResult, setfilterResult] = useState({});
	const [rappel, setRappel] = useState(false);
	const [note, setNote] = useState('');
	const [attachements, setAttachements] = useState([]);
	const fileInputRef = useRef(null);
	const [intervention, setIntervention] = useState({});
	const [openIntervention, setOpenIntervention] = useState(false);
	const [selectedImage, setSelectedImage] = useState(false);
	const [popupImage, setPopupImage] = useState(false);
	const [spinnerDelete, setSpinnerDelete] = useState(false);
	const [popupType, setPopupType] = useState(false);
	const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
	const handleDelete = () => {
		setSpinnerDelete(true);
		deleteActivity(
			userData?.password,
			card?.id,
			() => {
				if (formik.values.x_notes?.length > 0) {
					formik.values.x_notes?.map((item, index) => {
						deleteActivityNote(
							userData?.password,
							item?.id,
							() => {
								if (index + 1 == formik.values.x_notes?.length) {
									setActivities((prevActivities) => {
										let updatedActivities = prevActivities.map((group) => {
											if (group.id === card?.x_state) {
												return {
													...group,
													cards: group.cards.filter(
														(c) => c.id !== card.id,
													),
												};
											}
											return group;
										});

										return updatedActivities;
									});
									setSpinnerDelete(false);
									setEditModalStatus(false);
								}
							},
							(error) => {
								setError(error);
								setSpinnerDelete(false);
							},
						);
					});
				} else {
					setActivities((prevActivities) => {
						let updatedActivities = prevActivities.map((group) => {
							if (group.id === card?.x_state) {
								return {
									...group,
									cards: group.cards.filter((c) => c.id !== card.id),
								};
							}
							return group;
						});

						return updatedActivities;
					});
					setSpinnerDelete(false);
					setEditModalStatus(false);
				}
				!!setTasks && setTasks((prev) => prev?.filter((item) => item?.id !== card?.id));
			},
			(error) => {
				setSpinnerDelete(false);
				setError(error);
			},
		);
	};

	const handleFileInputChange = (e) => {
		const newAttachments = Array.from(e?.target?.files);
		setAttachements((prevAttachments) => [...prevAttachments, ...newAttachments]);
	};

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};
	const deleteImage = (key) => {
		const updatedImages = attachements.filter((item, index) => index != key);
		setAttachements(updatedImages);
	};

	const data = [
		{
			id: 'to_do',
			title: 'À faire',
			color: COLORS.PRIMARY.name,
			icon: 'TaskAlt',
		},
		{
			id: 'in_progress',
			title: 'En cours',
			color: COLORS.INFO.name,
			icon: 'DirectionsRun ',
		},

		{
			id: 'done',
			title: 'Terminée',
			color: COLORS.SUCCESS.name,
			icon: 'Verified',
		},
	];

	const updateActivityContext = (values) => {
		setActivities((prevActivities) => {
			let updatedActivities = prevActivities.map((group) => {
				if (group.id === values.x_state) {
					let cardIndex = group.cards.findIndex((card) => card.id === values.id);
					if (cardIndex !== -1) {
						let newCards = [...group.cards];
						newCards[cardIndex] = values;
						return {
							...group,
							cards: newCards,
						};
					} else {
						return {
							...group,
							cards: [...group.cards, values],
						};
					}
				}
				return {
					...group,
					cards: group.cards.filter((card) => card.id !== values.id),
				};
			});

			return updatedActivities;
		});
		!!setTasks &&
			setTasks((prev) => prev?.map((item) => (item?.id == values?.id ? values : item)));
	};

	const createNote = (noteData) => {
		createActivityNote(userData?.password, noteData, (result) => {
			const newNotes = [...formik.values.x_notes, result];
			formik.setFieldValue('x_notes', newNotes);
			setNote('');
			updateActivity(
				userData?.password,
				{ id: card?.id, x_notes: newNotes?.map((item) => item?.id) },
				(result) => {
					updateActivityContext({ ...formik?.values, x_notes: newNotes });
				},
			);
		});
	};
	const formik = useFormik({
		initialValues: {
			id: card?.id,
			x_name: card?.x_name,
			x_state: card?.x_state,
			summary: card?.summary,
			x_users_ids: card?.x_users_ids,
			date_deadline: moment(card?.date_deadline).format('YYYY-MM-DD'),
			create_date: card?.create_date,
			//x_start_date: card?.x_start_date,
			create_uid: card?.create_uid,
			res_model_id: card?.res_model_id,
			res_id: card?.res_id,
			x_notes: card?.x_notes,
			x_priority: card?.x_priority,
			activity_type_id: card?.activity_type_id,
			x_client: card?.x_client,
			x_attachment_ids: card?.x_attachment_ids,
		},
		validate: (values) => {
			const errors = {};

			if (!values.x_name) {
				errors.x_name = 'Obligatoire!';
			}

			return errors;
		},
		onSubmit: (values) => {
			setSpinner(true);
			if (attachements?.length > 0) {
				uploadAttachementsActivity(
					userData?.password,
					card?.id,
					attachements,
					(res) => {
						updateActivity(
							userData?.password,
							{
								id: card?.id,
								x_name: values?.x_name,
								x_state: values?.x_state,
								summary: values?.summary,
								x_attachment_ids: [
									...card?.x_attachment_ids?.map((item) => item?.id),
									...res?.data?.attachment,
								],
								activity_type_id:
									!!values?.activity_type_id?.id && values?.activity_type_id?.id,

								//x_notes: values.x_notes?.map((item) => item?.id),
								x_users_ids: values.x_users_ids?.map((item) => item?.id),
								// x_start_date: moment(values?.x_start_date).format(
								// 	'yyyy-MM-DD HH:mm:ss',
								// ),
								date_deadline: moment(values?.date_deadline).format('YYYY-MM-DD'),
							},
							(result) => {
								updateActivityContext({
									...values,
									x_attachment_ids: [
										...card?.x_attachment_ids,
										...res?.data?.attachment,
									],
								});
								setSpinner(false);
								setEditModalStatus(false);
							},
							(error) => {
								setSpinner(false);
								setError(error);
							},
						);
					},
					(error) => {
						setSpinner(false);
						setError(error);
					},
				);
			} else {
				updateActivity(
					userData?.password,
					{
						id: card?.id,
						x_name: values?.x_name,
						x_state: values?.x_state,
						summary: values?.summary,
						//	x_start_date: moment(values?.x_start_date).format('yyyy-MM-DD HH:mm:ss'),
						date_deadline: moment(values?.date_deadline).format('YYYY-MM-DD'),
						activity_type_id:
							!!values?.activity_type_id?.id && values?.activity_type_id?.id,
						// x_notes: values.x_notes?.map((item) => item?.id),
						x_users_ids: values.x_users_ids?.map((item) => item?.id),
					},
					(result) => {
						updateActivityContext(values);
						setSpinner(false);
						setEditModalStatus(false);
					},
					(error) => {
						setSpinner(false);
						setError(error);
					},
				);
			}
		},
	});
	useEffect(() => {
		let filter = [
			'&',
			[
				'function',
				'in',
				[
					// 'Technicien',
					'Chargé affaire',
					'Gestionnaire',
					'Admin',
					'comptabilite',
					//	'achat',
					'Commercial',
				],
			],
			['active', '=', true],
		];
		searchUsers(userData?.password, filter, ({ elements }) => {
			setEmployeeData(elements);
		});
		card?.res_id !== 420 &&
			getSinglePlanning(userData?.password, card?.res_id, (result) => {
				setIntervention(result);
			});
		searchActivityType(
			userData?.password,
			[['active', '=', true]],
			({ elements }) => {
				setCategories(elements);
				console.log(elements);
			},
			(error) => {
				setError(error);
			},
			0,
			0,
		);
	}, []);

	const ImageIcon = (fileType) => {
		const image = ['image', 'jpeg', 'jpg', 'png', 'ico'];
		const pdf = ['application', 'pdf'];
		const video = [, 'video', 'mp4', 'mov', 'mkv'];
		if (image.some((type) => fileType?.includes(type))) {
			return 'InsertPhoto';
		} else if (video.some((type) => fileType?.includes(type))) {
			return 'OndemandVideo';
		} else if (pdf.some((type) => fileType?.includes(type))) {
			return 'PictureAsPdf';
		} else {
			return 'AttachFile';
		}
	};

	return (
		<Modal
			setIsOpen={() => {}}
			isOpen={true}
			size='md'
			isScrollable
			data-tour='mail-app-modal'
			classNameMd='nv-2'>
			<ModalHeader className='px-4 pt-3 pb-0' setIsOpen={setEditModalStatus}>
				<div className='w-100 text-muted text-center'>
					<ModalTitle id='project-edit'>Modifier Tâche</ModalTitle>
				</div>
			</ModalHeader>
			<ModalBody className='px-4 pt-0'>
				<Card shadow='none'>
					<CardHeader className='border-bottom  pt-0'>
						<CardLabel iconColor='success'>
							{/* <CardTitle className='fs-2'>
								<FormGroup className='col-12' id='x_name'>
									<Input
										className='bg-transparent p-0 border-0 fs-2'
										placeholder='Nom de la tâche...'
										onChange={formik.handleChange}
										value={formik.values.x_name}
									/>
								</FormGroup>
							</CardTitle> */}
							{/* <CardTitle className='fs-2'>{card.x_name}</CardTitle> */}
							<FormGroup className='col-12 mb-2' id='x_name'>
								<Input
									className=' fs-5'
									placeholder='Nom de la tâche...'
									onChange={formik.handleChange}
									value={formik.values.x_name}
									onBlur={formik.handleBlur}
									invalidFeedback={formik.errors.x_name}
									isTouched={formik.touched.x_name}
									isValid={formik.isValid}
								/>
							</FormGroup>
							<CardSubTitle>
								<span className='me-2 mt-1 fw-semibold'>Priorité :</span>
								<Badge
									className='px-3 py-2 ms-2'
									color={
										card?.x_priority == 'low'
											? COLORS.SUCCESS.name
											: card.x_priority == 'medium'
											? COLORS.WARNING.name
											: COLORS.DANGER.name
									}
									isLight>
									{card?.x_priority == 'low'
										? 'FAIBLE'
										: card.x_priority == 'medium'
										? 'MOYEN'
										: 'ÉLEVÉ'}
								</Badge>
							</CardSubTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='px-2'>
						<div className='row mb-4 d-flex align-items-center'>
							<div className='col-3'>
								<span className='fs-6 fw-semibold'>Assignée à :</span>
							</div>
							<div className='col-9'>
								<div className='row g-2'>
									{formik?.values?.x_users_ids?.map((user) => (
										<div key={user?.id} className='col-auto'>
											<Badge
												className='px-2 py-2 rounded-1 me-2'
												color={'dark'}
												isLight>
												<Avatar
													src={getImageByModule(
														userData?.password,
														user?.employee_id,
														'hr.employee',
													)}
													srcSet={getImageByModule(
														userData?.password,
														user?.employee_id,
														'hr.employee',
													)}
													/* color={card.user.color} */
													size={20}
													userName={`${user?.name}`}
													className='cursor-pointer img-avatar me-2'
												/>
												<span>{user?.name}</span>
												<Icon
													icon='Clear'
													size={'md'}
													className='ms-2 cursor-pointer'
													onClick={() =>
														formik.setFieldValue(
															'x_users_ids',
															formik?.values?.x_users_ids?.filter(
																(item) => item?.id != user?.id,
															),
														)
													}></Icon>
											</Badge>
										</div>
									))}
									<div className='col-auto'>
										<Dropdown>
											<DropdownToggle hasIcon={false}>
												<Badge
													className='cursor-pointer px-2 py-2 me-2 rounded-1'
													color={'dark'}
													isLight>
													<Icon icon='Add' size={'lg'}></Icon>
												</Badge>
											</DropdownToggle>
											<DropdownMenu
												style={{ overflow: 'scroll', maxHeight: '250px' }}>
												{employeeData.map((item) => (
													<DropdownItem>
														<Button
															key={item.id}
															color='link'
															onClick={() => {
																!formik?.values?.x_users_ids.find(
																	(xuser) =>
																		xuser?.id == item?.id,
																) &&
																	formik?.setFieldValue(
																		'x_users_ids',
																		[
																			...formik?.values
																				?.x_users_ids,
																			employeeData?.find(
																				(u) =>
																					u?.id ==
																					item?.id,
																			),
																		],
																	);
															}}>
															{item.name}
														</Button>
													</DropdownItem>
												))}
											</DropdownMenu>
										</Dropdown>
									</div>
								</div>
							</div>
						</div>
						{/* <div className='row mb-4 d-flex align-items-center'>
							<div className='col-3'>
								<span className='fs-6 fw-semibold'>Date d'échéance :</span>
							</div>
							<div className='col-9'>
								<span className='fw-semibold d-flex align-items-center'>
									<Icon
										icon='Today'
										color='isLight'
										size={'lg'}
										className='me-2'></Icon>
									{moment(card?.date_deadline).format('DD MMM yyyy')}
								</span>
							</div>
						</div> */}
						{/* <div className='row mb-4 d-flex align-items-center'>
							<div className='col-3'>
								<span className='fs-6 fw-semibold'>Date de début :</span>
							</div>
							<div className='col-9'>
								<FormGroup className='col-12' id='x_start_date'>
									<Input
										type={'datetime-local'}
										onChange={formik.handleChange}
										value={moment(formik.values.x_start_date).format(
											moment.HTML5_FMT.DATETIME_LOCAL,
										)}
									/>
								</FormGroup>
							</div>
						</div> */}

						<div className='row mb-4 d-flex align-items-center'>
							<div className='col-3'>
								<span className='fs-6 fw-semibold'>Date limite :</span>
							</div>
							<div className='col-auto'>
								<FormGroup className='col-12 w-auto' id='date_deadline'>
									<Input
										type={'date'}
										onChange={formik.handleChange}
										value={formik.values.date_deadline}
									/>
								</FormGroup>
							</div>
						</div>
						<div className='row mb-4 d-flex align-items-center'>
							<div className='col-3'>
								<span className='fs-6 fw-semibold'>Statut :</span>
							</div>
							<div className='col-9'>
								<Dropdown>
									<DropdownToggle>
										<Button
											shadow={'md'}
											color={
												data?.find(
													(item) => formik.values.x_state == item?.id,
												)?.color
											}
											isLight
											icon={
												data?.find(
													(item) => formik.values.x_state == item?.id,
												)?.icon
											}
											size={'sm'}>
											{
												data?.find(
													(item) => formik.values.x_state == item?.id,
												)?.title
											}
										</Button>
										{/* <Badge className='px-3 py-2' color={'primary'} isLight>
											<Icon></Icon>
											{formik.values.x_state}
										</Badge> */}
									</DropdownToggle>
									<DropdownMenu size={'sm'}>
										{data.map((group) => (
											<DropdownItem>
												<Button
													key={group.id}
													color='link'
													icon={group.icon}
													onClick={() => {
														formik.setFieldValue('x_state', group?.id);
													}}>
													{group.title}
												</Button>
											</DropdownItem>
										))}
									</DropdownMenu>
								</Dropdown>
							</div>
						</div>
						<div className='row mb-4 d-flex align-items-center'>
							<div className='col-3'>
								<span className='fs-6 fw-semibold'>Tags :</span>
							</div>
							<div className='col-9 d-flex row'>
								{!!formik.values.activity_type_id?.id ? (
									<Badge
										className='px-3 py-2 text-dark rounded-1 bg-l25-secondary w-auto me-2'
										isLight>
										<span className='fs-6 fw-semibold'>
											{formik.values.activity_type_id?.name}
										</span>

										<Icon
											icon='Clear'
											size={'md'}
											className='ms-2 cursor-pointer'
											onClick={() =>
												formik.setFieldValue('activity_type_id', '')
											}></Icon>
									</Badge>
								) : (
									<Dropdown className='w-auto'>
										<DropdownToggle hasIcon>
											{/* <Badge
														className=' bg-l25-secondary cursor-pointer px-2 py-2 me-2 rounded-1'
														color={'dark'}
														isLight>
														<Icon icon='Add' size={'lg'}></Icon>
													</Badge> */}
											<Button
												className='bg-l25-secondary text-black'
												shadow={'md'}
												//color={'secondary'}
												isLight
												size={'sm'}>
												--Aucun--
											</Button>
										</DropdownToggle>
										<DropdownMenu
											style={{ overflow: 'scroll', maxHeight: '250px' }}>
											{categories?.map((item) => (
												<DropdownItem>
													<ActivityType
														item={item}
														formik={formik}
														setCategories={setCategories}
														setError={setError}
													/>
												</DropdownItem>
											))}
										</DropdownMenu>
									</Dropdown>
								)}
								<Badge
									className='bg-l25-secondary cursor-pointer px-2 py-2 me-2 rounded-1 w-auto'
									color={'dark'}
									isLight
									onClick={() => {
										setPopupType(true);
									}}>
									<Icon icon='Add' size={'lg'}></Icon>
								</Badge>
							</div>
						</div>

						{!!intervention?.id && (
							<div className='row mb-4 d-flex align-items-center'>
								<div className='col-3'>
									<span className='fs-6 fw-semibold'>Intervention :</span>
								</div>
								<div className='col-9'>
									<Button
										color={'light'}
										// isLight
										icon={'OpenInNew'}
										size={'sm'}
										onClick={() => setOpenIntervention(true)}>
										{intervention?.name_seq}
									</Button>
								</div>
							</div>
						)}
						<div className='row mb-4 d-flex align-items-center'>
							<div className='col-3'>
								<span className='fs-6 fw-semibold'>Créé par :</span>
							</div>
							<div className='col-9'>
								<Badge className='px-2 py-2 rounded-1' color={'dark'} isLight>
									<Avatar
										src={getImageByModule(
											userData?.password,
											card?.create_uid?.employee_id,
											'hr.employee',
										)}
										srcSet={getImageByModule(
											userData?.password,
											card?.create_uid?.employee_id,
											'hr.employee',
										)}
										/* color={card.user.color} */
										size={20}
										userName={`${card?.create_uid?.name}`}
										className='cursor-pointer img-avatar me-2'
									/>{' '}
									<span>{card?.create_uid?.name}</span>
								</Badge>
							</div>
						</div>

						<Card hasTab shadow={'none'}>
							<CardTabItem id='description' title='Description' className='pb-0'>
								<FormGroup id='summary'>
									<Textarea
										onChange={formik.handleChange}
										value={formik.values.summary}
									/>
								</FormGroup>
							</CardTabItem>
							<CardTabItem id='description' title='Documents'>
								<div className='row g-2'>
									{card?.x_attachment_ids?.length <= 0 &&
									attachements?.length <= 0 ? (
										<div className='col-12'>
											<div className='py-5 flex-column d-flex justify-content-center align-items-center  rounded-1 w-100 bg-l10-dark border-dashed'>
												<Icon
													icon='CloudDownload'
													size={'2x'}
													onClick={handleButtonClick}
													className='cursor-pointer'></Icon>
												<span>Déposer vos fichiers</span>
											</div>
											<Input
												type='file'
												multiple
												name='attachments'
												onChange={handleFileInputChange}
												style={{ display: 'none' }}
												ref={fileInputRef}
											/>
										</div>
									) : (
										<>
											{card?.x_attachment_ids?.map((file, index) => (
												<div key={index} className='col-auto'>
													<Badge
														className='px-3 py-2 rounded-1 '
														color={'dark'}
														isLight>
														<Icon
															icon={ImageIcon(file?.mimetype)}
															size={'lg'}
															className='cursor-pointer'
															onClick={() => {
																setSelectedImage(file);
																setPopupImage(true);
															}}></Icon>
														<span
															className='fs-6 fw-semibold cursor-pointer'
															onClick={() => {
																setSelectedImage(file);
																setPopupImage(true);
															}}>
															{file?.name}
														</span>
													</Badge>
												</div>
											))}
											{!!attachements?.length &&
												attachements?.map((file, index) => {
													return (
														<div key={index} className='col-auto'>
															<Badge
																className='px-3 py-2 rounded-1'
																color={'dark'}
																isLight>
																<Icon
																	icon={ImageIcon(file?.type)}
																	size={'lg'}
																	className='me-2 cursor-pointer'></Icon>
																<span
																	className='fs-6 fw-semibold cursor-pointer'
																	onClick={() => {
																		setSelectedImage(file);
																		setPopupImage(true);
																	}}>
																	{file?.name}
																</span>

																<Icon
																	icon='Clear'
																	size={'md'}
																	className='ms-2 cursor-pointer'
																	onClick={() =>
																		deleteImage(index)
																	}></Icon>
															</Badge>
														</div>
														// <div key={index} className='col-auto'>
														// 	<Button
														// 		color='danger'
														// 		isLight
														// 		icon='CloudDownload'>
														// 		<span
														// 			onClick={() => {
														// 				window.open(file, '_blank');
														// 			}}>
														// 			{!!file?.type
														// 				? file?.type?.split('/')[0]
														// 				: file?.name?.split('.').pop()}
														// 		</span>

														// 		<Icon
														// 			onClick={() => deleteImage(index)}
														// 			isLight
														// 			className='ms-2'
														// 			icon='Clear'
														// 			color='danger'></Icon>
														// 	</Button>
														// </div>
													);
												})}
											<div className='col-auto'>
												<Badge
													className='cursor-pointer px-2 py-2 rounded-1'
													color={'dark'}
													isLight
													onClick={handleButtonClick}>
													<Icon icon='Add' size={'lg'}></Icon>
												</Badge>
												<Input
													type='file'
													multiple
													name='attachments'
													onChange={handleFileInputChange}
													style={{ display: 'none' }}
													ref={fileInputRef}
												/>
											</div>
										</>
									)}
								</div>
							</CardTabItem>
							<CardTabItem id='comments' title='Commentaires'>
								<Chat>
									{formik?.values?.x_notes?.map((note) => (
										<ActivityChat
											key={note?.id}
											messages={note?.name}
											user={note?.user_id}
											isReply={note?.user_id?.id == userData?.id}
										/>
									))}
								</Chat>
								<InputGroup className='mt-3'>
									<Input onChange={(e) => setNote(e.target.value)} value={note} />
									<Button
										color='info'
										icon='Send'
										onClick={() =>
											createNote({
												name: note,
												date: moment().format('yyyy-MM-DD HH:mm:ss'),
												user_id: userData?.id,
											})
										}>
										Envoyer
									</Button>
								</InputGroup>
							</CardTabItem>
						</Card>
					</CardBody>
				</Card>
			</ModalBody>
			<ModalFooter className='px-4 pb-4'>
				<Button
					color='primary'
					className='w-auto'
					type='submit'
					onClick={formik.handleSubmit}
					isDisable={spinner}>
					{spinner && <Spinner isSmall inButton />}
					{spinner ? 'En cours' : 'Sauvegarder'}
				</Button>
				<Button
					color='danger'
					className='w-auto'
					type='submit'
					onClick={() => setConfirmDeletePopup(true)}
					isDisable={spinnerDelete}>
					{spinnerDelete && <Spinner isSmall inButton />}
					{spinnerDelete ? 'En cours' : 'Supprimer'}
				</Button>
			</ModalFooter>
			{!!openIntervention && (
				<InterventionPopUp
					setEditModalStatus={setOpenIntervention}
					id={intervention?.id}
					getInterventions={() => {}}
				/>
			)}
			{!!popupImage && <DisplayImage setSelectedImage={setPopupImage} file={selectedImage} />}
			{!!error && <ErrorPopup error={error} setOpen={setError} />}
			{!!popupType && (
				<AddActivityType
					formikParent={formik}
					setCategories={setCategories}
					setisOpen={setPopupType}
				/>
			)}
			{confirmDeletePopup && (
				<ConfirmationPopup
					title='Supprimer tâche ?'
					handleConfirm={handleDelete}
					setOpen={setConfirmDeletePopup}
				/>
			)}
		</Modal>
	);
};

export default ActivityUpdatePopup;

const ActivityType = ({ item, formik, setCategories, setError }) => {
	const { userData } = useContext(AuthContext);
	const [tagLoading, setTagLoading] = useState(false);
	const handleDelete = (id) => {
		setTagLoading(true);
		deleteActivityType(
			userData?.password,
			id,
			() => {
				setCategories((prev) => prev?.filter((item) => item?.id !== id));
				setTagLoading(false);
			},
			(error) => {
				setTagLoading(false);
				setError(error);
			},
		);
	};
	return (
		<div className='row d-flex align-items-center'>
			{tagLoading ? (
				<Spinner isSmall inButton className='col-2' />
			) : (
				<Button
					key={item.id}
					className='col-2'
					icon='Delete'
					onClick={() => {
						handleDelete(item?.id);
					}}
				/>
			)}
			<Button key={item.id} className='col-8'>
				<span
					onClick={() => {
						formik?.setFieldValue('activity_type_id', item);
					}}>
					{item.name}
				</span>
			</Button>
		</div>
	);
};
