import { useContext, useRef } from 'react';
import AuthContext from '../../contexts/authContext';
import { createAttachementUrl } from '../../odooApi/planningSlot';
import DisplayHEICImage from './DisplayHEICImage';
import SingleAttahcement from './SingleAttahcement';

function Attachements({ file, index, deleteImage }) {
	console.log("file", file)
	const { userData } = useContext(AuthContext);
	const canvasRef = useRef(null);
	const theFile = !!file?.file ? file?.file : file;
	const fileType = !!theFile?.type
		? theFile?.type?.split('/')[0]
		: theFile?.name?.split('.').pop();
	console.log('file type ', file);
	const src =
		!!theFile?.type || (!!theFile?.size && fileType.toLowerCase() === 'heic')
			? URL.createObjectURL(theFile)
			: userData?.password && createAttachementUrl(userData?.password, theFile?.id);

	if (fileType?.toLowerCase() === 'heic') {
		return (
			<DisplayHEICImage src={src} theFile={theFile} index={index} deleteImage={deleteImage} />
		);
	}

	if (
		fileType === 'image' ||
		fileType === 'jpeg' ||
		fileType === 'jpg' ||
		fileType === 'png' ||
		fileType === 'ico'
	) {
		return (
			<SingleAttahcement
				src={src}
				index={index}
				name={theFile?.name}
				deleteImage={deleteImage}>
				<img
					src={src}
					alt={theFile?.name}
					style={{
						objectFit: 'fill',
						width: '100%',
						height: '100%',
					}}
				/>
			</SingleAttahcement>
		);
	} else if (
		fileType === 'video' ||
		fileType === 'mp4' ||
		fileType?.toLowerCase() === 'mov' ||
		fileType?.toLowerCase() === 'mkv'
	) {
		return (
			<SingleAttahcement
				src={src}
				index={index}
				name={theFile?.name}
				deleteImage={deleteImage}>
				<video
					controls
					style={{
						objectFit: 'fill',
						width: '100%',
						height: '100%',
					}}>
					<source src={src} type={file.type} />
				</video>
			</SingleAttahcement>
		);
	} else if (
		(fileType === 'application' && file.type === 'application/pdf') ||
		fileType === 'pdf'
	) {
		return (
			<SingleAttahcement
				src={src}
				index={index}
				name={theFile?.name}
				deleteImage={deleteImage}>
				<embed
					src={src}
					type='application/pdf'
					style={{
						objectFit: 'fill',
						width: '100%',
						height: '100%',
					}}
				/>
			</SingleAttahcement>
		);
	} else {
		return (
			<SingleAttahcement
				src={src}
				index={index}
				name={theFile?.name}
				deleteImage={deleteImage}>
				{/* <embed
					src={src}
					type='application/pdf'
					style={{
						objectFit: 'fill',
						width: '100%',
						height: '100%',
					}}
				/> */}
				<span>Desolé! Type de ficher non reconnu!</span>
			</SingleAttahcement>
		);
	}
}

export default Attachements;
