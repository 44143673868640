import axios from 'axios';

const optimizationRDV = (token,address,estimation,start_datetime,end_datetime,fnSc=()=>{}) => {
    let data = JSON.stringify({
        "tokenUserCurent": token,
        "address": address,
        "estimation": estimation,
        "start_datetime": start_datetime,
        "end_datetime": end_datetime
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/planning',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            
            fnSc(response.data);
        })
        .catch((error) => {
            console.log(error);
        });
};

export default optimizationRDV;