import { Loader } from "@googlemaps/js-api-loader";

export const calcDistance = (origins,destinations,fncSucess) => {
    
    const options = {
        apiKey: process.env.REACT_APP_GOOGLE_KEY,
        version: "weekly",
        libraries: ['geometry']
    };

    new Loader(options).load().then(() => {

        const service = new google.maps.DistanceMatrixService();
        const request = {
            origins,
            destinations,
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
        };
        service.getDistanceMatrix(request).then((response) => {
           
            if(response?.rows[0]?.elements?.length){
                response?.rows[0]?.elements?.map((element,idx)=>{
                    element["origin"]=0;
                    element["destination"]=idx;
                })
            }
            fncSucess(response?.rows[0]?.elements?.length?response?.rows[0]?.elements.sort((a,b)=>a?.distance?.value-b?.distance?.value):[]);
        });

    }).catch(e => {
        console.error('Sorry, something went wrong: Please try again later. Error:', e);
    });

}