import React, { useLayoutEffect, forwardRef, ReactElement, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ISubHeaderProps } from '../SubHeader/SubHeader';
import { IPageProps } from '../Page/Page';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import { demoPages } from '../../menu';
import preval from 'preval.macro';
import { firestore } from '../../apis/firebase/config';
import createOrUpdateDocDeviceAuth, {
	AUTH_STATES,
} from '../../common/function/createOrUpdateDocDeviceAuth';

interface IPageWrapperProps {
	isProtected?: boolean;
	title?: string;
	description?: string;
	children:
		| ReactElement<ISubHeaderProps>[]
		| ReactElement<IPageProps>
		| ReactElement<IPageProps>[];
	className?: string;
}
const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
	({ isProtected, title, description, className, children }, ref) => {
		useLayoutEffect(() => {
			// @ts-ignore
			document.getElementsByTagName('TITLE')[0].text = title
				? title
				: process.env.REACT_APP_SITE_NAME;
			// @ts-ignore
			document
				?.querySelector('meta[name="description"]')
				.setAttribute('content', description || process.env.REACT_APP_META_DESC || '');
		});

		const { userData, setUserData } = useContext(AuthContext);

		const navigate = useNavigate();

		// useEffect(() => {
		// 	if (userData && userData?.id && userData?.password) {

		// 		firestore
		// 			.collection('devicesAuth')
		// 			.doc(userData?.id + "")
		// 			.onSnapshot((snapshot: any) => {

		// 				if (snapshot?.data()?.deviceKey != userData?.deviceKey) {
		// 					//localStorage.removeItem("facit_authUser");

		// 					if(setUserData){
		// 						setUserData({});
		// 					}

		// 					window.location.href ="/" + demoPages?.login?.path;
		// 					return;

		// 				} else if (snapshot?.data()?.state == AUTH_STATES?.requestLogin) {

		// 					if(setUserData){
		// 						setUserData({});
		// 					}

		// 					window.location.href ="/" + demoPages?.login?.path;
		// 					return;
		// 				} else if (snapshot?.data()?.state == AUTH_STATES?.acceptedLogin) {
		// 					createOrUpdateDocDeviceAuth(
		// 						userData?.id,
		// 						{
		// 							state: AUTH_STATES?.login
		// 						},
		// 						() => {
		// 							navigate("/");
		// 						}
		// 					)
		// 					return;

		// 				} else if (snapshot?.data()?.state == AUTH_STATES?.logout) {
		// 					//localStorage.removeItem("facit_authUser");

		// 					if(setUserData){
		// 						setUserData({});
		// 					}
		// 					window.location.href ="/" + demoPages?.login?.path;
		// 					return;
		// 				}

		// 			});
		// 	}
		// }, []);

		// useEffect(() => {
		// 	const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`
		// 	const dateBuild = new Date(dateTimeStamp);

		// 	const now = new Date();

		// 	if (isProtected && (/* (now.getTime() > ((userData?.logintime || 0) + 1000 * 60 * 60 * 48)) ||  */(dateBuild.getTime() > (userData?.logintime || 0)))) {
		// 		// If the item is expired, delete the item from storage
		// 		// and return null
		// 		createOrUpdateDocDeviceAuth(
		// 			userData?.id,
		// 			{
		// 				state: AUTH_STATES?.logout
		// 			},
		// 			() => {
		// 				//localStorage.removeItem("facit_authUser");

		// 				if(setUserData){
		// 					setUserData({});
		// 				}

		// 				window.location.href ="/" + demoPages?.login?.path;
		// 			}
		// 		)

		// 	}
		// }, []);

		useEffect(() => {
			if (isProtected && !userData?.username) {
				navigate(`../${demoPages.login.path}`);
			}
			return () => {};
		}, []);

		return (
			<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
				{children}
			</div>
		);
	},
);
PageWrapper.displayName = 'PageWrapper';
PageWrapper.propTypes = {
	isProtected: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	// @ts-ignore
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
PageWrapper.defaultProps = {
	isProtected: true,
	title: undefined,
	description: undefined,
	className: undefined,
};

export default PageWrapper;
