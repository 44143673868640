import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const ClientMontantContext = createContext([]);

export const ClientMontantContextProvider = ({ children }) => {
	const [clientMontant, setClientMontant] = useState({ montant: 100 });

	const value = useMemo(
		() => ({
			clientMontant,
			setClientMontant,
		}),
		[clientMontant],
	);
	return <ClientMontantContext.Provider value={value}>{children}</ClientMontantContext.Provider>;
};
ClientMontantContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ClientMontantContext;
