import React, { useContext, useEffect, useState } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import Search from '../../../components/Search';
import CommonHeaderRight from './CommonHeaderRight';
import { SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Badge from '../../../components/bootstrap/Badge';
import AuthContext from '../../../contexts/authContext';
import Select from '../../../components/bootstrap/forms/Select';
import classNames from 'classnames';
import CommonHeaderLeft from './CommonHeaderLeft';

const DashboardHeader = () => {

	return (
		<Header>
			
			<CommonHeaderLeft />
			
			{/* <CommonHeaderRight afterChildren={<CommonHeaderChat />} /> */}
			<CommonHeaderRight />
		</Header>
	);
};

export default DashboardHeader;
