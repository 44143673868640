import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
/* import Avatar from '../Avatar'; */
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getImageByModule } from '../../common/function/functionsUtils';
import AuthContext from '../../contexts/authContext';
import { DevisLinesContextProvider } from '../../contexts/devisLinesContext';
import useDarkMode from '../../hooks/useDarkMode';
import useTourStep from '../../hooks/useTourStep';
import Avatar from '../Avatar';
import Badge from '../bootstrap/Badge';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../bootstrap/Card';
import Icon from '../icon/Icon';
import DevisPopUp from './DevisPopUp';
/* import UserImage2 from '../../assets/img/wanna/wanna2.png'; */
/* import UserImage2Webp from '../../assets/img/wanna/wanna2.webp'; */

const DevisCard = ({ card, loadData = () => {} }) => {
	useTourStep(13);
	const { darkModeStatus } = useDarkMode();
	const [editModalStatus, setEditModalStatus] = useState(false);
	const { userData } = useContext(AuthContext);

	const { t } = useTranslation(['translation', 'menu']);
	return (
		<>
			<Card
				shadow='none'
				borderSize={1}
				className='rounded-2 cursor-pointer'
				onClick={() => setEditModalStatus(true)}>
				<CardHeader className='p-2'>
					<CardLabel>
						<CardTitle
							tag='h6'
							className={classNames({
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}
							data-tour={card.name}>
							{card.name}
						</CardTitle>
						{/* {card.nom_client && (
							<CardSubTitle className='text-muted'>{card.nom_client}</CardSubTitle>
						)} */}
						{/* {card.phone_client && (
							<CardSubTitle className='text-muted mt-2'>
								{t('Client phone')}: {card.phone_client}
							</CardSubTitle>
						)}
						{card.mobile_client && (
							<CardSubTitle className='text-muted mt-2'>
								{t('Client mobile')}: {card.mobile_client}
							</CardSubTitle>
						)} */}
					</CardLabel>
					{!!card?.amount_total && (
						<CardActions>
							<Badge color='success' isLight className='fs-6 fw-bold rounded-1'>
								{card?.amount_total.toLocaleString('fr-FR', {
									style: 'currency',
									currency: 'EUR',
								})}
							</Badge>
						</CardActions>
					)}
				</CardHeader>
				<CardBody className='p-2 pt-0'>
					<div className='row d-flex'>
						{card.nom_client && (
							<div className='row'>
								<small className='text-muted'>{card.project_title}</small>
								<small className='text-info'>
									<Icon icon='person' color='info' size={'lg'}></Icon>{' '}
									{card.nom_client}
								</small>
							</div>
						)}
						{/* {card.date_order && (
							<div className='w-auto'>
								<small className='text-muted fw-bold'>
									{card.date_order}
								</small>
								<Badge isLight color={'info'} >
									<Icon icon='AccessTimeFilled' color='info' size={'2x'}></Icon>{' '}
									{card.date_order}
								</Badge>
							</div>
						)} */}
						{/* {!!card.techniciens.length && (
							<div className='w-auto'>
								<Badge isLight color={'info'} >
									<Icon icon='CustomTech' color='info' size={'2x'}></Icon>{' '}
									{card.techniciens[0]?.name}
								</Badge>
							</div>
						)} */}
					</div>
					{card.img && (
						<img
							src={card.img}
							className={classNames('img-fluid rounded mb-3 mt-1', {
								'bg-lo25-primary': darkModeStatus,
								'bg-l25-primary': !darkModeStatus,
							})}
							alt={card.name}
						/>
					)}

					<div></div>
				</CardBody>
				<CardFooter borderSize={1} size='sm' className='p-2'>
					{card.techniciens && (
						<CardFooterLeft className='d-flex w-50'>
							<Avatar
								src={getImageByModule(
									userData?.password,
									card.techniciens[0]?.id,
									'hr.employee',
								)}
								srcSet={getImageByModule(
									userData?.password,
									card.techniciens[0]?.id,
									'hr.employee',
								)}
								size={25}
								border={2}
								borderColor={'primary'}
								userName={card.techniciens[0]?.name}></Avatar>
							<div className='text-truncate text-dark fw-semibold'>
								{card.techniciens[0]?.name}
							</div>
						</CardFooterLeft>
					)}

					<CardFooterRight>
						<small className='text-muted fw-bold'>
							<Icon icon='AccessTime' color='primary' size={'2x'}></Icon>{' '}
							{moment(card.date_order).format('DD-MM-YYYY')}
						</small>
					</CardFooterRight>
				</CardFooter>
			</Card>

			{editModalStatus && (
				<DevisLinesContextProvider>
					<DevisPopUp
						card={card}
						setEditModalStatus={setEditModalStatus}
						loadData={loadData}
					/>
				</DevisLinesContextProvider>
			)}
		</>
	);
};
DevisCard.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	card: PropTypes.object.isRequired,
};

export default DevisCard;
