import moment from 'moment';
import COLORS from '../../common/data/enumColors';

export const organizationData = (devisArray) => {
	const data = [
		{
			id: 'lane1',
			title: 'Brouillon',
			color: false ? COLORS.LIGHT.name : COLORS.DARK.name,
			icon: 'RateReview',
			cards: [
				...(!!devisArray?.length ? devisArray.filter((item) => item.state == 'draft') : []),
			],
		},
		{
			id: 'lane2',
			title: 'Devis envoyé',
			color: COLORS.INFO.name,
			icon: 'ScheduleSend',
			cards: [
				...(!!devisArray?.length ? devisArray.filter((item) => item.state == 'sent') : []),
			],
		},
		{
			id: 'lane3',
			title: 'Devis acceptés',
			color: COLORS.SUCCESS.name,
			icon: 'Verified',
			cards: [
				...(!!devisArray?.length ? devisArray.filter((item) => item.state == 'sale') : []),
			],
		},
		{
			id: 'lane4',
			title: 'Devis Bloqué',
			color: COLORS.WARNING.name,
			icon: 'Block',
			cards: [
				...(!!devisArray?.length ? devisArray.filter((item) => item.state == 'done') : []),
			],
		},
		{
			id: 'lane5',
			title: 'Devis annulé',
			color: COLORS.DANGER.name,
			icon: 'Cancel',
			cards: [
				...(!!devisArray?.length
					? devisArray.filter((item) => item.state == 'cancel')
					: []),
			],
		},
	];
	return data;
};

export const organizationDataFacturation = (facturationArray, setTotalAmount) => {
	const facturesNotPaidDraft =
		!!facturationArray?.length &&
		facturationArray.filter(
			(item) => item.invoice_payment_state == 'not_paid' && item?.state == 'draft',
		);
	const facturesNotPaidNotDraft =
		!!facturationArray?.length &&
		facturationArray.filter(
			(item) => item.invoice_payment_state == 'not_paid' && item?.state == 'posted',
		);
	const facturesCancel =
		!!facturationArray?.length &&
		facturationArray.filter(
			(item) => item.invoice_payment_state == 'not_paid' && item?.state == 'cancel',
		);
	const facturesPaid =
		!!facturationArray?.length &&
		facturationArray.filter((item) => item.invoice_payment_state == 'paid');

	var /* amountTotalNotPaid = 0, */ amountTotalPaid = 0,
		amountTotalNotPaidNotDraft = 0;
	/* facturesNotPaidDraft.map(facture=>{amountTotalNotPaid+=facture.amount_total}); */
	!!facturesNotPaidNotDraft?.length &&
		facturesNotPaidNotDraft.map((facture) => {
			amountTotalNotPaidNotDraft += facture.amount_total;
		});
	!!facturesPaid?.length &&
		facturesPaid.map((facture) => {
			amountTotalPaid += facture.amount_total;
		});
	setTotalAmount((amountTotalPaid + amountTotalNotPaidNotDraft).toFixed(3));
	const data = [
		{
			id: 'draft',
			title: 'Factures brouillons',
			color: COLORS.DARK.name,
			icon: 'RateReview',
			payment: 'not_paid',
			state: 'draft',
			/* amountTotal:amountTotalNotPaid, */
			cards: facturesNotPaidDraft,
		},

		{
			id: 'posted',
			title: 'Factures comptabilisées',
			color: COLORS.WARNING.name,
			icon: 'RateReview',
			amountTotal: amountTotalNotPaidNotDraft,
			payment: 'not_paid',
			state: 'posted',
			cards: facturesNotPaidNotDraft,
		},
		{
			id: 'paid',
			title: 'Factures acquittées',
			color: COLORS.SUCCESS.name,
			icon: 'Verified',
			amountTotal: amountTotalPaid,
			payment: 'paid',
			//state:"draft",
			cards: facturesPaid,
		},
		{
			id: 'cancel',
			title: 'Factures Annulées',
			color: COLORS.DANGER.name,
			icon: 'Cancel',
			payment: 'not_paid',
			state: 'cancel',
			cards: facturesCancel,
		},
	];
	return data;
};

export const organizationDataActivitiy = (activityArray) => {
	const data = [
		{
			id: 'to_do',
			title: 'À faire',
			color: COLORS.PRIMARY.name,
			icon: 'TaskAlt',
			cards: [
				...(!!activityArray?.length
					? activityArray.filter(
							(item) =>
								item.x_state == 'to_do' &&
								moment(item.date_deadline).diff(moment(), 'days') >= 0,
					  )
					: []),
			],
		},
		{
			id: 'in_progress',
			title: 'En cours',
			color: COLORS.INFO.name,
			icon: 'DirectionsRun ',
			cards: [
				...(!!activityArray?.length
					? activityArray.filter(
							(item) =>
								item.x_state == 'in_progress' &&
								moment(item.date_deadline).diff(moment(), 'days') >= 0,
					  )
					: []),
			],
		},
		// {
		// 	id: 'pending',
		// 	title: 'En attente',
		// 	color: COLORS.INFO.name,
		// 	icon: 'PendingActions',
		// 	cards: [
		// 		...(!!activityArray?.length
		// 			? activityArray.filter((item) => item.x_state == 'pending')
		// 			: []),
		// 	],
		// },
		{
			id: 'late',
			title: 'En retard',
			color: COLORS.DANGER.name,
			icon: 'PendingActions',
			cards: [
				...(!!activityArray?.length
					? activityArray.filter(
							(item) =>
								item.x_state != 'done' &&
								moment(item.date_deadline).diff(moment(), 'days') < 0,
					  )
					: []),
			],
		},
		{
			id: 'done',
			title: 'Terminée',
			color: COLORS.SUCCESS.name,
			icon: 'Verified',
			cards: [
				...(!!activityArray?.length
					? activityArray.filter((item) => item.x_state == 'done')
					: []),
			],
		},
	];
	return data;
};
