import React from "react";
import Input from "../bootstrap/forms/Input";
import Modal, { ModalBody, ModalHeader } from "../bootstrap/Modal";
import Icon from "../icon/Icon";
import PropTypes from 'prop-types';

const SearchPopUp = ({setSearchModalStatus,filterResult,valueInput,curentInput,handelChange, handelSelect, children}) => {
    
    return (
        <Modal
            setIsOpen={()=>{}}
            isOpen={true}
            isStaticBackdrop
            isScrollable
            data-tour='search-modal'
            classNameMd="nv-3"
            >
            <ModalHeader setIsOpen={setSearchModalStatus}>
                <label className='border-0 bg-transparent cursor-pointer' htmlFor='searchInput'>
                    <Icon icon='Search' size='2x' color='primary' />
                </label>
                <Input
                    
                    name={curentInput?.inputName||"search"}
                    className='border-0 shadow-none bg-transparent'
                    placeholder='Search...'
                    value={valueInput[curentInput?.inputName]}
                    onChange={handelChange}
                    autoComplete="off"
                    onLoad={()=>{this.focus()}}
                />
            </ModalHeader>
            <ModalBody>
            <table className='table table-hover table-modern caption-top mb-0'>
                <thead style={{ top: -13 }}>
                        <tr>
                            <th className="w-50">{curentInput?.inputLabel||"Results"}</th>
                            {
                                children &&
                                <th className="w-50">
                                    {children}
                                </th>
                            }
                        </tr>
                    </thead>
            </table>
                <table className='table table-hover table-modern caption-top mb-0'>
                    <caption>Résultas: {filterResult[curentInput?.inputName]?.length}</caption>
                    <thead style={{ top: -13 }}>
                        <tr>
                            <th className="w-50">ID client</th>
                            {
                                children &&
                                <th className="w-50">
                                    Nom du client
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {filterResult[curentInput?.inputName]?.length ? (
                            filterResult[curentInput?.inputName]?.map((item) => (
                                <tr
                                    key={item?.id}
                                    className='cursor-pointer'
                                    onClick={() => {
                                        handelSelect({value:{[curentInput?.inputName]:item?.name||item?.display_name},selected:{[curentInput?.inputName]:item}});
                                    }}
                                >
                                    <td className="col">
                                        {/* {item.id && (
                                            <Icon
                                                icon={item.id}
                                                size='lg'
                                                className='me-2'
                                                color='primary'
                                            />
                                        )} */}
                                        <span >
                                            {item?.id}
                                        </span>
                                    </td>
                                    <td className="col">
                                        <span >
                                            {item?.name||item?.display_name}
                                        </span>
                                    </td>
                                       
                                    
                                </tr>
                            ))
                        ) : (
                            <tr className='table-active'>
                                No result found for query 
                            </tr>
                        )}
                    </tbody>
                </table>
            </ModalBody>
        </Modal>
    )
};

SearchPopUp.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	setSearchModalStatus: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	filterResult: PropTypes.object.isRequired,
    valueInput:PropTypes.object,
    curentInput:PropTypes.object,
    handelChange:PropTypes.func,
    handelSelect:PropTypes.func
    /* selectedValue:PropTypes.object */
};

export default SearchPopUp;