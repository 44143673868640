import React, { useContext, useEffect, useState } from 'react';
import Select from '../../../components/bootstrap/forms/Select';
import AuthContext from '../../../contexts/authContext';
import { searchCompanies } from '../../../odooApi/Company';
import classNames from 'classnames';
import Option from '../../../components/bootstrap/Option';
import { HeaderLeft } from '../../../layout/Header/Header';
import CompanieContext from '../../../contexts/companieContext';

export default function CommonHeaderLeft() {

    return (
        <HeaderLeft>
            <div className='col-md-3'>
                {/* <Select
                    id='company'
                    size='md'
                    ariaLabel='company'
                    value={selectedName}
                    onChange={handleChange}
                    className={classNames('rounded-1')}
                >
                    {societes.map((societe) => (
                        <Option key={societe.id} value={societe.id}>
                            {societe.name}
                        </Option>
                    ))}
                </Select> */}
            </div>
        </HeaderLeft>
    );
}
