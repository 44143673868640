import FormGroup from '../bootstrap/forms/FormGroup';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../bootstrap/Card';
import Modal, { ModalBody, ModalHeader } from '../bootstrap/Modal';
import Select from '../bootstrap/forms/Select';

import Option from '../bootstrap/Option';
import { useContext, useState } from 'react';
import { searchClientPartner } from '../../odooApi/clientPartner';
import AuthContext from '../../contexts/authContext';
import Icon from '../icon/Icon';
import ListGroup, { ListGroupItem } from '../bootstrap/ListGroup';
import Spinner from '../bootstrap/Spinner';
import Input from '../bootstrap/forms/Input';
import useOutsideClick from '../customComponents/SearchAutoComplete/useOutsideClick';
import { useFormik } from 'formik';
import { AutoComplete } from '../../googleApi/placeAutoComplete';
import Button from '../bootstrap/Button';
import {
	createAchat,
	sendAchatByMail,
	sendBonCommandByMail,
	sendDemandPrixByMail,
} from '../../odooApi/achat';
import StatusPopup from '../PlanningCalendar/StatusPopup';
import { createAchatLine } from '../../odooApi/lineAchats';
import moment from 'moment';
import { updateSingleMateriel } from '../../odooApi/atelier';
import { capitalizeWords, getReportProduction } from '../../common/function/functionsUtils';
import SearchClient from '../customComponents/SearchAutoComplete/SearchClient';
import ConfirmationPopup from '../PlanningCalendar/ConfirmationPopup';
import Timeline, { TimelineItem } from '../extras/Timeline';
import { getColorNameWithIndex } from '../../common/data/enumColors';
import ErrorPopup from '../extras/ErrorPopup';
import Textarea from '../bootstrap/forms/Textarea';

export default function AchatPopup({ setAchat, line, type, loadAteliersData }) {
	const { userData } = useContext(AuthContext);
	const [openList, setOpenList] = useState(false);
	const [clientIsLoading, setClientIsLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [filterResult, setfilterResult] = useState({});
	const [clientData, setClientData] = useState({});
	const [data, setData] = useState([]);
	const [result, setResult] = useState([]);
	const [error, setError] = useState();
	const [success, setSuccess] = useState();
	const [obligation, setObligation] = useState(false)
	const handleClickOutside = () => {
		setOpenList(false);
	};
	const [lines, setLines] = useState(line)


	console.log("line update atelier", lines)

	const ref = useOutsideClick(handleClickOutside);

	const formik = useFormik({
		initialValues: {
			id: '',
			name: '',
			productName: '',
		},

		onSubmit: (values) => {
			//handelUpdate(values);
		},
	});
	const handleSend = () => {
		sendDemandPrixByMail(
			userData?.password,
			achatData?.id,
			() => {
				const newMat = {
					id: line?.id,
					type_produit: type,
					achat: achatData?.id,
					state: 'en cours',
				};
				console.log('achatData : ', achatData);
				updateSingleMateriel(
					userData?.password,
					newMat,
					() => {
						setIsLoading(false);
						setSuccess('E-mail envoyé à ' + achatData?.partner_id?.email);
						// setAchat(false);
					},
					(error) => {
						setError(error);
						setIsLoading(false);
					},
				);
			},
			(error) => {
				setError(error);
				setIsLoading(false);
			},
		);
	};
	const handleSave = () => {
		if (userData && userData?.id && userData?.password) {
			setIsLoading(true);
			if (result?.length > 0) {
				result?.map((item) => {
					const newachat = {
						partner_id: item?.id,
						partner_ref: line?.atelier?.name,
						state: 'sent',
					};

					createAchat(
						userData?.password,
						newachat,
						(achatData) => {
							lines?.length > 0 &&
								lines.map((item, index) => {
									const newLine = {
										order_id: achatData?.id,
										product_id: item?.product?.id,
										name: item?.devis_line?.name,
										product_qty: 1,
										date_planned: moment(new Date()).format('YYYY-MM-DD'),
										product_uom: 1,
										taxes_id: [19],
									};
									createAchatLine(
										userData?.password,
										newLine,
										(dataLine) => {
											if (index + 1 == line?.length) {
												sendDemandPrixByMail(
													userData?.password,
													achatData?.id,
													() => {
														line?.length > 0 &&
															line.map((item, i) => {
																const newMat = {
																	id: item?.id,
																	type_produit: type,
																	achat: achatData?.id,
																	state: 'en cours',
																};
																updateSingleMateriel(
																	userData?.password,
																	newMat,
																	() => {
																		if (i + 1 == line?.length) {
																			setIsLoading(false);
																			setSuccess(
																				'E-mail envoyé à ' +
																				achatData
																					?.partner_id
																					?.email,
																			);
																		}
																		// setAchat(false);
																	},
																	(error) => {
																		setError(error);
																		setIsLoading(false);
																	},
																);
															});
													},
													(error) => {
														setError(error);
														setIsLoading(false);
													},
												);
											}
										},
										(error) => {
											setError(error);
											setIsLoading(false);
										},
									);
								});
						},
						(error) => {
							setError(error);
							setIsLoading(false);
						},
					);
				});
			} else {
				setObligation('Vous devez choisir un fournisseur !');
				setIsLoading(false);
			}
		}
	};

	const handleChange = (e) => {
		formik.handleChange(e);
		formik?.setFieldValue('name', e?.target?.value);
		setOpenList(true);
		if (e?.target?.value == '') {
			setfilterResult([]);
			formik?.setFieldValue('name', '');
			setData([]);
		} else {
			if (userData && userData?.id && userData?.password) {
				setClientIsLoading(true);
				console.log(capitalizeWords(e.target.value));
				searchClientPartner(
					userData?.password,
					[
						'|',
						['name', 'like', e.target.value],
						'|',
						[
							'name',
							'like',
							e.target.value.charAt(0).toUpperCase() +
							e.target.value.substring(1).toLowerCase(),
						],
						'|',
						['name', 'like', e.target.value.toUpperCase()],
						'|',
						['name', 'like', e.target.value.toLowerCase()],
						['name', 'like', capitalizeWords(e.target.value)],
					],
					({ elements }) => {
						setfilterResult(elements);
						setClientIsLoading(false);
					},
					() => { },
				);
			}
		}
	};

	// const handelSelect = (item) => {
	// 	setOpenList(false);

	// 	const exist = !!result?.find((i) => i?.id == item?.id);
	// 	!exist && setResult([...result, item]);

	// 	setfilterResult([]);
	// 	setClientData(item);
	// };

	const handelSelectItem = (item) => {
		const exist = !!result?.find((i) => i?.id == item?.id);
		!exist && setResult([...result, item]);
		setfilterResult([]);
		setClientData(item);
		formik.setFieldValue('name', '');
	};

	return (
		<Modal setIsOpen={() => { }} isOpen={true} size={'lg'} classNameMd='nv-2'>
			<ModalHeader
				setIsOpen={() => {
					setAchat(false);
				}}>
				<CardLabel icon='LocalOffer' iconColor='primary'>
					<CardTitle>{line?.name} Demande de prix</CardTitle>
					{/* <CardSubTitle>
								<span className='text-muted'></span>
								{line?.date}
							</CardSubTitle> */}
				</CardLabel>
			</ModalHeader>
			<ModalBody className='px-2'>
				<Card shadow={'none'}>
					<CardBody>
						<div className='row g-3 mt-3 mb-3'>
							<div className='-'>
								{/* <div className='flex-grow-1 ms-3 '>
										<div className='fw-bold fs-6 mb-0' ref={ref}>
											<FormGroup id='name' label='Nom fournisseur'>
												<Input
													placeholder='Selectionnez un fournisseur...'
													id='name'
													onChange={handleChange}
													onFocus={handleChange}
													value={formik?.values?.name}
													name='name'
													autoComplete='off'
												/>
												{openList &&
													(clientIsLoading ? (
														<ListGroup tag={'div'}>
															<ListGroupItem tag={'button'}>
																<Spinner isSmall isGrow />{' '}
																<Spinner isSmall isGrow />{' '}
																<Spinner isSmall isGrow />
															</ListGroupItem>
														</ListGroup>
													) : (
														!!filterResult.length && (
															<ListGroup tag={'div'}>
																{filterResult?.map((item) => (
																	<ListGroupItem
																		tag={'button'} // 'section' || 'div' || 'li' || 'a' || 'button' || 'label'
																		onClick={() => {
																			handelSelect(item);
																		}}>
																		{item?.display_name} (
																		{item?.street +
																			' ' +
																			item?.zip +
																			' ' +
																			item?.city}
																		)
																	</ListGroupItem>
																))}
															</ListGroup>
														)
													))}
											</FormGroup>
										</div>
									</div> */}
								<SearchClient
									value={formik?.values?.name}
									name='name'
									onChange={handleChange}
									label='Rechercher un fournisseur'
									options={filterResult}
									handelSelectItem={handelSelectItem}
									loading={clientIsLoading}
								/>
							</div>
						</div>
						{result?.length > 0 && (
							<div className='row g-3'>
								<div className='col-12 text-center'>
									<h2>Fournisseurs</h2>
								</div>
								<table className='table table-modern table-hover'>
									<thead>
										<tr>
											<th>Nom</th>
											<th>N° Tél</th>
											<th>Adresse</th>

											<th></th>
										</tr>
									</thead>

									<tbody>
										{result?.map((line, idx) => {
											return (
												<tr>
													<td>
														<div>
															{line?.name}

															{!!line?.email && (
																<div className='text-muted'>
																	<small>{line?.email}</small>
																</div>
															)}
														</div>
													</td>
													<td> {line?.mobile || line?.phone}</td>

													<td>
														{line?.street +
															' ' +
															line?.zip +
															' ' +
															line?.city}
													</td>

													<td>
														<Button
															color='danger'
															isLight
															icon='Delete'
															onClick={() => {
																setResult(
																	result?.filter(
																		(item) =>
																			item?.id != line?.id,
																	),
																);
															}}></Button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						)}
						<div className='col-12'>
							{lines?.length > 0 &&
								lines.map((l, index) => (
									<Card isCompact borderSize={2} shadow='none' className='mb-4'>
										<CardBody>
											<FormGroup label={l?.product?.name}>
												<Textarea
													onChange={(e) => setLines((prev) => prev?.map(line => line?.id == l?.id ? { ...line, devis_line: { ...line?.devis_line, name: e?.target?.value } } : line))}
													value={l?.devis_line?.name}
												/>
											</FormGroup>
										</CardBody>
									</Card>
								))}
						</div>
						{/* <Timeline>
							{line?.length > 0 &&
								line.map((l, index) => (
									<TimelineItem
										label={l?.product?.name}
										color={getColorNameWithIndex(index)}>
										<FormGroup>
											<Textarea
												onChange={formik.handleChange}
												value={l?.product?.name}
											/>
										</FormGroup>
									</TimelineItem>
								))}
						</Timeline> */}

					</CardBody>
					<CardFooter>
						<CardFooterRight>
							<Button color='primary' disabled={isLoading} onClick={handleSave}>
								{isLoading && <Spinner isSmall inButton />}
								{isLoading ? 'En cours' : 'Envoyer'}
							</Button>
							{/* <Button color='primary' disabled={isLoading} onClick={handleSend}>
								{isLoading && <Spinner isSmall inButton />}
								{isLoading ? 'En cours' : 'Envoyer'}
							</Button> */}
						</CardFooterRight>
					</CardFooter>
				</Card>
			</ModalBody>
			{!!obligation && (
				<StatusPopup
					icon='info'
					color='info'
					title={obligation}
					setOpen={setObligation}
				/>
			)}
			{!!error && (
				<ErrorPopup
					error={error}
					setOpen={setError}
				/>
			)}
			{!!success && (
				<StatusPopup
					icon='MarkEmailRead'
					color='success'
					title='E-mail envoyé avec succès'
					label={success}
					setOpen={() => {
						loadAteliersData();
						setSuccess(false);
						setAchat(false);
					}}
				/>
			)}
		</Modal>
	);
}


