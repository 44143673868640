import React, { createContext, useMemo, useState } from 'react';

const PoseContext = createContext([]);

export const PoseContextProvider = ({ children }) => {
	const [pose, setPose] = useState({});

	const value = useMemo(
		() => ({
			pose,
			setPose,
		}),
		[pose],
	);
	return <PoseContext.Provider value={value}>{children}</PoseContext.Provider>;
};

export default PoseContext;
