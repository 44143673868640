import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTabItem,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../../layout/SubHeader/SubHeader';
import { AcceptPaiementStripeMail, GetPaiementStripeMail } from '../../../../odooApi/paiementMail';
import StripeCheckout from './stripe/stripePayment';
import store2000 from '../../../../assets/img/store2000.png';
import Icon from '../../../../components/icon/Icon';
import moment from 'moment';
import PaypalPayment from './paypal/PaypalPayment';
import ClientMontantContext from '../../../../contexts/clientMontantContext';
import Button from '../../../../components/bootstrap/Button';
import { loadStripe } from '@stripe/stripe-js';
import Modal, { ModalBody, ModalHeader } from '../../../../components/bootstrap/Modal';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Select from '../../../../components/bootstrap/forms/Select';
import ValidationPaiement from './validationPaiement';
import HeaderPage from './HeaderPage';
import { ConfirmDevisMail } from '../../../../odooApi/signatureDevisMail';
import Confirmation from './ConfirmationPage';

const Checkout = () => {
	const { id } = useParams(0);
	const { token } = useParams(0);
	const { model } = useParams(0);
	const [result, setResult] = useState();
	const [clientSecret, setClientSecret] = useState('');
	const { clientMontant, setClientMontant } = useContext(ClientMontantContext);
	const [activeTab, setActiveTab] = useState('paypal');
	const [amount, setAmount] = useState();
	const [stripePromise, setStripePromise] = useState();
	const [paiementPopup, setpaiementPopup] = useState(true);
	const [confirmPaiement, setConfirmPaiement] = useState(false);
	console.log("in checkout : ",clientMontant)
	console.log("amount : ",amount)
	const navigate = useNavigate()
	useEffect(() => {
		!!clientMontant?.montant &&
			GetPaiementStripeMail(
				token,
				model,
				id,

				({ data }) => {
					console.log("data",data)
					setAmount(data[0]?.amount_total * (clientMontant?.montant / 100));
					setResult(data[0]);
				},
				(error) => {},
			);
	}, [clientMontant?.montant]);

	const handleTabChange = (tabId) => {
		setActiveTab(tabId);
		if (tabId === 'stripe') {
			setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
			const total = result?.amount_total * 100;
			const amount = Math.round(total * (clientMontant?.montant / 100));
			AcceptPaiementStripeMail(
				token,
				model,
				id,
				amount,
				(data) => {
					setClientSecret(data?.clientSecret?.client_secret);
					return data?.clientSecret?.client_secret;
				},
				(error) => {},
			);
		}
	};
	const Children = [
		{ id: 'paypal', title: 'Paypal' },
		{ id: 'stripe', title: 'Stripe' },
		{ id: 'alma', title: 'Alma' },
	];
	return (
		<>
			<PageWrapper isProtected={false}>
				<HeaderPage amount={amount} />
			
				<Page>
					{paiementPopup ? (
						<ValidationPaiement
							setAmount={setAmount}
							setpaiementPopup={setpaiementPopup}
							amount={amount}
						/>
					) : (
						<div className='row '>
							<div className='col-lg-6'>
								<Card shadow='sm'>
									<CardHeader>
										<CardLabel icon='Info' iconColor='primary'>
											<CardTitle>Informations</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody>
										<div className='row g-4'>
											<div className='d-flex align-items-center'>
												<div className='flex-shrink-0'>
													<Icon
														icon='CalendarToday'
														size='2x'
														color='primary'
													/>
												</div>
												<div className='flex-grow-1 ms-3'>
													<div className='text-muted'>
														Date de la signature
													</div>

													<div className='fw-bold fs-6 mb-0'>
														{moment(new Date()).format('DD-MM-YYYY')}
													</div>
												</div>
											</div>
											{!!result?.customer && (
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<Icon
															icon='AccountCircle'
															size='2x'
															color='primary'
														/>
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>Nom du client</div>
														<div className='fw-bold fs-6 mb-0'>
															{result?.customer}
														</div>
													</div>
												</div>
											)}
											{!!result?.email && (
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<Icon
															icon='Mail'
															size='2x'
															color='primary'
															isLight
														/>
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>
															E-mail client
														</div>
														<div className='fw-bold fs-6 mb-0'>
															{result?.email}
														</div>
													</div>
												</div>
											)}
											{!!result?.name && (
												<div className='d-flex align-items-center'>
													<div className='flex-shrink-0'>
														<Icon
															icon='Article'
															size='2x'
															color='primary'
															isLight
														/>
													</div>
													<div className='flex-grow-1 ms-3'>
														<div className='text-muted'>Document</div>
														<div className='fw-bold fs-6 mb-0'>
															{result?.name}
														</div>
													</div>
												</div>
											)}
										</div>
									</CardBody>
								</Card>
							</div>

							<div className='col-lg-6'>
								<Card>
									<CardHeader borderSize={1}>
										<CardActions>
											{Children.map((item) => (
												<Button
													color='info'
													isLight
													role='tab'
													aria-selected={item.id === activeTab}
													isActive={item.id === activeTab}
													onClick={() => handleTabChange(item.id)}>
													{item.title}
												</Button>
											))}
										</CardActions>
									</CardHeader>
									{activeTab == 'paypal' && (
										<>
											<CardHeader>
												<CardLabel icon='Payments' iconColor='primary'>
													<CardTitle>
														Complétez votre paiement avec Paypal !
													</CardTitle>
												</CardLabel>
											</CardHeader>
											<CardBody>
												<PaypalPayment
													amount={parseFloat(amount).toFixed(2)}
													result={result}
													token={token}
												/>
											</CardBody>
										</>
									)}
									{activeTab == 'stripe' && (
										<>
											<CardHeader>
												<CardLabel icon='Payments' iconColor='primary'>
													<CardTitle>
														Complétez votre paiement avec Stripe !
													</CardTitle>
												</CardLabel>
											</CardHeader>
											<CardBody>
												<StripeCheckout
													stripePromise={stripePromise}
													result={result}
													amount={amount}
													clientSecret={clientSecret}
												/>
											</CardBody>
										</>
									)}
									{/* {activeTab == 'alma' && (
										<>
											<CardHeader>
												<CardLabel icon='Payments' iconColor='primary'>
													<CardTitle>
														Complétez votre paiement avec Alma !
													</CardTitle>
												</CardLabel>
											</CardHeader>
											<CardBody>
											<Button
											tag='a'
											icon='FileDownload'
											className='text-nowrap'
											onClick={() => {
												setClientMontant({...clientMontant,client:result,paiement:'paypal'})
												navigate("/confirmation")
												
											}}>
											Confirmer
										</Button>
												
											</CardBody>
										</>
									)} */}
								</Card>
							</div>
						</div>
					)}
				</Page>
			
			</PageWrapper>
			
		</>
	);
};

export default Checkout;
