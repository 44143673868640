import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../bootstrap/Modal';
import { useContext, useState, useEffect } from 'react';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../bootstrap/Card';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { createProduct } from '../../../../odooApi/product';
import AuthContext from '../../../../contexts/authContext';
import Input from '../../../bootstrap/forms/Input';
import Alert from '../../../bootstrap/Alert';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Button from '../../../bootstrap/Button';
import showNotification from '../../../extras/showNotification';
import Icon from '../../../icon/Icon';
import StatusPopup from '../../../PlanningCalendar/StatusPopup';
import Spinner from '../../../bootstrap/Spinner';
import Textarea from '../../../bootstrap/forms/Textarea';
import ErrorPopup from '../../../extras/ErrorPopup';

const AddProductPopUp = ({ setCreateProductModalStatus, handleSelectedProduct }) => {
	const { userData } = useContext(AuthContext);
	const [spinner, setSpinner] = useState(false)
	const [error, setError] = useState(false)

	const formik = useFormik({
		initialValues: {
			name: '',
			priceAchat: 0,
			priceVente: 0,
			categ_id: '',
			categ_label: '',
			brand_id: '',
			brand_label: '',
			default_code: '',
			description_sale: ''
		},
		validate: (values) => {
			const errors = {};
			if (!values.name) {
				errors.name = 'Requis';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			handelCreateProduct(values);
		},
	});
	const handelCreateProduct = (values) => {
		if (userData && userData?.id && userData?.password) {
			const newProduct = {
				name: values?.name,
				//brand_id: values?.brand_id||editItem?.brand_id?.id,
				//categ_id: values?.categ_id||editItem?.categ_id?.id,
				lst_price: values?.priceVente,
				standard_price: values?.priceAchat,
				default_code: values?.default_code,
				description_sale: values?.description_sale
			};
			setSpinner(true)
			createProduct(userData?.password, newProduct, (data) => {
				setSpinner(false)
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Création d'un nouveau produit</span>
					</span>,
					'Le produit a été créé avec succès',
				);
				handleSelectedProduct(data);
				setCreateProductModalStatus(false);

				/*  */
			}, (error) => { setSpinner(false); setError(error); console.log(error?.response?.data?.error?.openapi_message) });
		}
	};

	return (
		<Modal setIsOpen={() => { }} isOpen={true} classNameMd='nv-3'>
			<ModalHeader
				className='px-4 pb-0'
				setIsOpen={() => {
					setCreateProductModalStatus(false);
				}}></ModalHeader>
			<ModalBody className='pt-0'>
				<Card shadow='none'>
					<CardHeader className='pt-0'>
						<CardLabel icon='ManageAccounts' iconColor='primary'>
							<CardTitle>Créer un nouveau produit</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody className='row g-4'>
						<FormGroup className='col-12' id='name' label='Nom de produit'>
							<Input
								id='name'
								onChange={formik.handleChange}
								value={formik?.values?.name}
								name='name'
								invalidFeedback={
									formik.errors.name
								}
								// onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.name}
							/>
						</FormGroup>
						<FormGroup className='col-12' id='description_sale' label='Description'>
							<Textarea
								onChange={formik.handleChange}
								value={formik.values.description_sale}
								name='description_sale'
								type='text'
								autoComplete='off'
							/>
						</FormGroup>
						<FormGroup className='col-12' id='default_code' label='Réference'>
							<Input
								onChange={formik.handleChange}
								value={formik.values.default_code}
								name='default_code'
								type='text'
								autoComplete='off'
							/>
						</FormGroup>

						<FormGroup className='col-6' id='priceAchat' label="Prix d'achat">
							<Input
								onChange={formik.handleChange}
								value={formik.values.priceAchat}
								name='priceAchat'
								type='number'
								placeholder='0'
							/>
						</FormGroup>
						<FormGroup className='col-6' id='priceVente' label='prix de Vente'>
							<Input
								onChange={formik.handleChange}
								value={formik.values.priceVente}
								name='priceVente'
								type='number'
							/>
						</FormGroup>


					</CardBody>
				</Card>
			</ModalBody>
			<ModalFooter className='px-4 pb-4'>
				<Button
					color='primary'
					className='w-100'
					type='submit'
					isDisable={spinner}
					onClick={formik.handleSubmit}>
					{spinner && <Spinner isSmall inButton />}
					{spinner ? 'En cours' : 'Créer'}

				</Button>
			</ModalFooter>
			{!!error && (
				<ErrorPopup
					error={error}
					setOpen={setError}
				/>
			)}
		</Modal >
	);
};

export default AddProductPopUp;
