import React, { useState } from 'react';
//import simulatorData from "../../common/data/simulatorData";
import formInputsTypes from '../../common/data/simulatorData/simulatorConstant';
import InputNumber from './InputNumber';
import RadioInput from './RadioInput';
import SelectInput from './SelectInput';
import PropTypes from 'prop-types';
import InputTextSearch from './InputTextSearch';
import SelectableImage from './SelectableImage';
import CheckBoxInput from './CheckBoxInput';

const SimulatorMotor = ({ currentFormValue, setCurrentFormValue, simulatorData }) => {
	const [dataForm, setDataForm] = useState(simulatorData);

	const tabInputs = [];

	const makeid = (length) => {
		let result = '';
		const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		let counter = 0;
		while (counter < length) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
			counter += 1;
		}
		return result;
	};

	const injectObject = (object, injectedObject, targetIndex, index = 0) => {
		var newObject = object;
		if (index == targetIndex) {
			newObject = {
				...object,
				...injectedObject,
			};
		} else {
			newObject = {
				...object,
				nextStep: {
					...injectObject(object.nextStep, injectedObject, targetIndex, index + 1),
				},
			};
		}
		return newObject;
	};

	const switchInputs = (data, func) => {
		switch (data?.type) {
			case formInputsTypes?.RADIO_INPUT:
				tabInputs.push(
					<RadioInput
						key={data?.id}
						data={data}
						currentFormValue={currentFormValue}
						handelChange={(nextStep, value) => {
							func(nextStep);
							setCurrentFormValue({
								...currentFormValue,
								[!nextStep ? 'productFinal' : data?.name]: value,
								complet: !nextStep || currentFormValue?.productFinal,
							});
						}}
					/>,
				);
				return;

			case formInputsTypes?.CHECKBOX_INPUT:
				tabInputs.push(
					<CheckBoxInput
						key={data?.id}
						data={data}
						currentFormValue={currentFormValue}
						handelChange={(nextStep, value) => {
							func(nextStep);
							setCurrentFormValue({
								...currentFormValue,
								[!nextStep ? 'productFinal' : data?.name]: value,
								complet: !nextStep || currentFormValue?.productFinal,
							});
						}}
					/>,
				);
				return;
			case formInputsTypes?.SELECT_INPUT:
				tabInputs.push(
					<SelectInput
						key={data?.id}
						data={data}
						currentFormValue={currentFormValue}
						handelChange={(nextStep, value) => {
							func(nextStep);
							setCurrentFormValue({
								...currentFormValue,
								[!nextStep ? 'productFinal' : data?.name]: value,
								complet: !nextStep || currentFormValue?.productFinal,
							});
						}}
					/>,
				);
				return;
			case formInputsTypes?.INPUT_NUMBER:
				tabInputs.push(
					<InputNumber
						key={data?.id}
						data={data}
						handelChange={(nextStep, value) => {
							console.log('handelchange', nextStep, value);
							func(nextStep);
							setCurrentFormValue({
								...currentFormValue,
								[!nextStep ? 'productFinal' : data?.name]: value,
								complet: !nextStep || currentFormValue?.productFinal,
							});
						}}
					/>,
				);
				return;
			case formInputsTypes?.INPUT_TEXT:
				tabInputs.push(
					<InputTextSearch
						key={data?.id}
						data={data}
						currentFormValue={currentFormValue}
						handelChange={(nextStep, value) => {
							func(nextStep);
							setCurrentFormValue({
								...currentFormValue,
								[!nextStep ? 'productFinal' : data?.name]: value,
								complet: !nextStep || currentFormValue?.productFinal,
							});
						}}
					/>,
				);
				return;
			case formInputsTypes?.SELECTABLE_IMAGE:
				tabInputs.push(
					<SelectableImage
						key={data?.id}
						data={data}
						currentFormValue={currentFormValue}
						handelChange={(nextStep, value) => {
							func(nextStep);
							setCurrentFormValue({
								...currentFormValue,
								[!nextStep ? 'productFinal' : data?.name]: value,
								complet: !nextStep || currentFormValue?.productFinal,
							});
						}}
					/>,
				);
		}
	};

	const createElementsForm = (object, index = 0) => {
		switchInputs(object, (data) => {
			setDataForm(
				injectObject(
					dataForm,
					{ nextStep: data ? { ...data, id: data?.id + '_' + makeid(5) } : false },
					index,
				),
			);
		});
		if (object?.nextStep) {
			createElementsForm(object.nextStep, index + 1);
		}
	};

	createElementsForm(dataForm);
	//console.log("dataFormdataForm",dataForm);
	console.log('currentFormValue', currentFormValue);

	return <>{tabInputs?.length ? tabInputs : 'no data set'}</>;
};

SimulatorMotor.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	// @ts-ignore
	currentFormValue: PropTypes.any.isRequired,
	setCurrentFormValue: PropTypes.func.isRequired,
};

export default SimulatorMotor;
