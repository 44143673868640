// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


// Your web app's Firebase configuration
let firebaseConfig = {
  apiKey: "AIzaSyBpiKrylEZZjzjlqMVKEwMgWwI_bPNqo-4",
  authDomain: "gestioneer-test.firebaseapp.com",
  projectId: "gestioneer-test",
  storageBucket: "gestioneer-test.appspot.com",
  messagingSenderId: "572952619077",
  appId: "1:572952619077:web:da1c7502d417b74b699b2c"
};
if (process.env.REACT_APP_DOMAINE_NAME == "https://gestioneer.fr") {
  firebaseConfig = {
    apiKey: "AIzaSyAhUDyxYVRIcUKIQGZZtM78vXgvLTHlmrQ",
    authDomain: "gestioneer-7a556.firebaseapp.com",
    projectId: "gestioneer-7a556",
    storageBucket: "gestioneer-7a556.appspot.com",
    messagingSenderId: "150894417335",
    appId: "1:150894417335:web:2edec11054c0d808ca0432",
    measurementId: "G-TES1WSYVRR"
  };
}


const firebaseApp = firebase.initializeApp(firebaseConfig);
const firestore = firebaseApp.firestore();

export { firestore };
