import axios from 'axios';

const API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	COUNTRY_API_URI = '/res.country',
	COUNTRY_STATE_API_URI = '/res.country.state',
	LIMIT = 0;
export const searchCountry = (
	tokenUserCurent,
	filters,
	fncSuccess,
	fnError = () => {},
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});
	var config = {
		method: 'patch',
		url: `${'/odoo' + API_URL_WEB_ADMIN + COUNTRY_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data || []);
			return;
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const searchCountryState = (
	tokenUserCurent,
	filters,
	fncSuccess,
	fnError = () => {},
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});
	var config = {
		method: 'patch',
		url: `${'/odoo' + API_URL_WEB_ADMIN + COUNTRY_STATE_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data || []);
			return;
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const getAllCountries = (tokenUserCurent, fncSuccess) => {
	var config = {
		method: 'get',
		url: `${'/odoo' + API_URL_WEB_ADMIN + COUNTRY_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data.elements || []);
		})
		.catch(function (error) {
			console.log(error);
		});
};
