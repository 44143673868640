import React from 'react';
import PayPalButton from './PayPalButton';

const PaypalPayment = ({ amount, result, token }) => {
	return (
		<div>
			<PayPalButton amount={amount} result={result} token={token} />
		</div>
	);
};

export default PaypalPayment;
