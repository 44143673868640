export const prix_VRCManuelReno = [
	{ id: 1000, price: 254.7 },
	{ id: 1500, price: 280.17 },
	{ id: 2000, price: 308.18 },
	{ id: 2500, price: 339 },
	{ id: 3000, price: 376.29 },
	{ id: 3500, price: 417.68 },
	{ id: 4000, price: 463.63 },
	{ id: 4500, price: 514.64 },
	{ id: 5000, price: 571.24 },
	{ id: 5500, price: 634.08 },
	{ id: 6000, price: 703.83 },
	{ id: 6500, price: 781.25 },
	{ id: 7000, price: 867.18 },
	{ id: 7500, price: 962.57 },
	{ id: 8000, price: 1068.46 },
];
export const prix_VRCManuelTradi = [
	{ id: 1000, price: 274.7 },
	{ id: 1500, price: 300.17 },
	{ id: 2000, price: 328.18 },
	{ id: 2500, price: 359 },
	{ id: 3000, price: 396.29 },
	{ id: 3500, price: 437.68 },
	{ id: 4000, price: 483.63 },
	{ id: 4500, price: 534.64 },
	{ id: 5000, price: 591.24 },
	{ id: 5500, price: 654.08 },
	{ id: 6000, price: 723.83 },
	{ id: 6500, price: 801.25 },
	{ id: 7000, price: 887.18 },
	{ id: 7500, price: 982.57 },
	{ id: 8000, price: 1088.46 },
];
