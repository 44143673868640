import axios from 'axios';

const 
  BASIC_API_URL = "/odoo",
  API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
  SEARCH_METHOD_API=process.env.REACT_APP_SEARCH_METHOD_API,
  ACCOUNT_MOVE_LINE_API_URI = "/account.move.line"
  ;

export const getAccountMoveLinesById = (tokenUserCurent,factureId,fnSucces) => {
    const bodyData = JSON.stringify({
        "kwargs": {
          "domain": [
            [
              "move_id",
              "=",
              factureId
            ],
            ["exclude_from_invoice_tab", "!=", "False"]
          ]
        }
    });
    var config = {
        method: 'patch',
        url: `${BASIC_API_URL+API_URL_WEB_ADMIN+ACCOUNT_MOVE_LINE_API_URI+SEARCH_METHOD_API}`,
        headers: { 
          'Authorization': `Basic ${btoa(process.env.REACT_APP_AUTH_OPEN_API_DB+":"+tokenUserCurent)}`, 
          'Content-Type': 'application/json'
        },
        data : bodyData
    };
    console.log("getAccountMoveLinesByIdgetAccountMoveLinesById",config);
    axios(config)
    .then(function (response) {
      fnSucces(response?.data)
    })
    .catch(function (error) {
        console.log(error);
    });
   
}