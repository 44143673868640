import { useState, useEffect } from 'react';
import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	PARTNER_API_URI = '/res.partner',
	LIMIT = 20;
export const searchClientPartner = (
	tokenUserCurent,
	filters,
	fnSuccess,
	fnError = () => { },
	page = 1,
	limit = LIMIT,
	signal,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
			order: 'name ASC',
		},
	});
	var config = {
		method: 'patch',
		url: `${'/odoo' + API_URL_WEB_ADMIN + PARTNER_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
		signal,
	};

	axios(config)
		.then(function (response) {
			fnSuccess(response?.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const createClientPartner = async (
	tokenUserCurent,
	newClient,
	fnSucces,
	fnError = () => { },
) => {
	const data = JSON.stringify(newClient);
	const config = {
		method: 'post',
		url: `${'/odoo' + API_URL_WEB_ADMIN + PARTNER_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			//console.log(JSON.stringify(response.data));
			fnSucces(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

const getAllClients = (tokenUserCurent, fnSuccess, fnError = () => { }, page = 1, limit = LIMIT) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			order: 'name ASC',
		},
	});
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PARTNER_API_URI}?limit=${limit}&page=${page}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: dataFilter
	};

	axios(config)
		.then(function (response) {
			fnSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export default getAllClients;
// export const putClient = (tokenUserCurent, idClient, datas, fnSuccess, fnError = () => {}) => {
// 	var data = JSON.stringify(datas);
// 	var config = {
// 		method: 'put',
// 		url: BASIC_API_URL + API_URL_WEB_ADMIN + PARTNER_API_URI + '/' + idClient,
// 		headers: {
// 			Authorization: `Basic ${btoa(
// 				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
// 			)}`,
// 			'Content-Type': 'application/json',
// 		},
// 		data: data,
// 	};

// 	axios(config)
// 		.then(function (response) {
// 			fnSuccess(response.data);
// 		})
// 		.catch(function (error) {
// 			fnError(error);
// 		});
// };

// export const putClient = async (tokenUserCurent, id, datas, fnSucces, fnError = () => {}) => {
// 	const data = JSON.stringify(datas);
// 	const config = {
// 		method: 'put',
// 		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PARTNER_API_URI + '/' + id}`,
// 		headers: {
// 			Authorization: `Basic ${btoa(
// 				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
// 			)}`,
// 			'Content-Type': 'application/json',
// 		},
// 		data: data,
// 	};

// 	axios(config)
// 		.then(function (response) {
// 			fnSucces(response.data);
// 		})
// 		.catch(function (error) {
// 			fnError(error);
// 		});
// };


export const updateSinglePartner = (tokenUserCurent, partner, fnSucces, fnError = () => { }) => {
	const clientLineString = JSON.stringify(partner);

	const config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PARTNER_API_URI + '/' + partner?.id}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: clientLineString,
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const getSingleClient = async (tokenUserCurent, idClient, fnSucces) => {
	const config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PARTNER_API_URI + '/' + idClient}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (result) {
			fnSucces(result?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const deleteClient = async (tokenUserCurent, idClient, fnSucces, fnError = () => { }) => {
	const config = {
		method: 'delete',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PARTNER_API_URI + '/' + idClient}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (result) {
			fnSucces(result?.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};
export const sendPartnerMail = (tokenUserCurent, clientId, fnSucces, fnError = () => { }) => {
	const config = {
		method: 'get',
		url: `${'/odoo' + API_URL_WEB_ADMIN + PARTNER_API_URI + '/' + clientId + '/send'}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};
	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};



