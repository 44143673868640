const forfaits = [
	{
		id: 1,
		label: 'Forfait déblocage de volet roulant',
		value: 'Forfait déblocage de volet roulant',
		lst_price: 180,
		isInline: true,
	},
	{
		id: 2,
		label: 'Forfait remplacement des agrafes de volet roulant',
		value: 'Forfait remplacement des agrafes de volet roulant',
		lst_price: 180,
		isInline: true,
	},
	{
		id: 3,
		label: 'Forfait réglage fin de course volet roulant',
		value: 'Forfait réglage fin de course volet roulant',
		lst_price: 180,
		isInline: true,
	},
	{
		id: 4,
		label: 'Forfait programmation de télécommande',
		value: 'Forfait programmation de télécommande',
		lst_price: 180,
		isInline: true,
	},
	{
		id: 5,
		label: 'Forfait remplacement une manivelle',
		value: 'Forfait remplacement une manivelle',
		lst_price: 190,
		isInline: true,
	},
	{
		id: 6,
		label: 'Forfait remplacement une sangle',
		value: 'Forfait remplacement une sangle',
		lst_price: 190,
		isInline: true,
	},
	{
		id: 7,
		label: 'Forfait remplacement et programmation une télécommande',
		value: 'Forfait remplacement et programmation une télécommande',
		lst_price: 0,
		isInline: true,
	},
	{
		id: 8,
		label: 'Forfait déblocage de rideau métallique',
		value: 'Forfait déblocage de rideau métallique',
		lst_price: 280,
		isInline: true,
	},
	{
		id: 9,
		label: 'Forfait remplacement des agrafes de rideau métallique',
		value: 'Forfait remplacement des agrafes de rideau métallique',
		lst_price: 180,
		isInline: true,
	},
	{
		id: 10,
		label: 'Forfait réglage fin de course de rideau métallique',
		value: 'Forfait réglage fin de course de rideau métallique',
		lst_price: 200,
		isInline: true,
	},
	{
		id: 11,
		label: 'Forfait déblocage de store banne',
		value: 'Forfait déblocage de store banne',
		lst_price: 220,
		isInline: true,
	},
	{
		id: 12,
		label: 'Forfait réglage fin de course de store banne',
		value: 'Forfait réglage fin de course de store banne',
		lst_price: 180,
		isInline: true,
	},
	{
		id: 13,
		label: 'Forfait dépose et répose de store banne',
		value: 'Forfait dépose et répose de store banne',
		lst_price: 300,
		isInline: true,
	},
	{
		id: 14,
		label: 'Forfait dépose de store banne',
		value: 'Forfait dépose de store banne',
		lst_price: 150,
		isInline: true,
	},
	{
		id: 15,
		label: 'Forfait pose de store banne',
		value: 'Forfait pose de store banne',
		lst_price: 150,
		isInline: true,
	},
	{
		id: 16,
		label: 'Forfait remplacement une ficelle de store',
		value: 'Forfait remplacement une ficelle de store',
		lst_price: 260,
		isInline: true,
	},
	{
		id: 17,
		label: "Forfait fourniture et pose d'un sabot de rideau métallique",
		value: "Forfait fourniture et pose d'un sabot de rideau métallique",
		lst_price: 220,
		isInline: true,
	},
];

export default forfaits;
