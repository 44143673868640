import React, { useEffect, useState } from 'react';
import Spinner from '../bootstrap/Spinner';
import StatusPopup from '../PlanningCalendar/StatusPopup';

export default function LoadSpinner({ error }) {
	const [errorPopup, setErrorPopup] = useState(false);
	useEffect(() => {
		!!error && setErrorPopup(true);
	}, [error]);
	
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					flexDirection: 'column',
				}}>
				{!!error ? (
					<>
						<span className='fw-bold fs-4'>Pas disponible !</span>
						<span className=''>Réessayez plus tard !</span>
					</>
				) : (
					<>
						<Spinner color='dark' size={'4rem'} />
						<span className='p-3'>Chargement des données ...</span>
					</>
				)}
			</div>
			{errorPopup && (
				<StatusPopup
					icon='Cancel'
					color='danger'
					title={'Oups!'}
					label={!!error?.message ? error?.message : error}
					setOpen={setErrorPopup}
				/>
			)}
		</div>
	);
}
