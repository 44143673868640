import { searchCategory } from '../../../../odooApi/categoryProdut';
import { searchProduct } from '../../../../odooApi/product';
import { searchProductTemplate } from '../../../../odooApi/productTemplate';
import formInputsTypes from '../simulatorConstant';

const productFinal = {
	id: 'VR-TR-PIECE-DET',
	type: formInputsTypes?.INPUT_TEXT,
	name: 'product',
	className: 'col-12',
	label: 'Produit',
	nextStepData: false,
	nextStep: false,
	module: 'product.product',
	options: null,
	fetchData: (tokenUserCurent, value, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [
				'&',
				['product_tmpl_id', '=', currentFormValue?.productTemplate?.id],
				'|',
				['display_name', 'like', value],
				'|',
				[
					'display_name',
					'like',
					value.charAt(0).toUpperCase() + value.substring(1).toLowerCase(),
				],
				['display_name', 'like', value.toUpperCase()],
			];

			return filters;
		};
		searchProduct(tokenUserCurent, generateFilter(), async ({ elements }) => {
			fnSuccess(elements);
		});
	},
};

const productTemplate = {
	id: 'VR-TR-PIECE-PT',
	type: formInputsTypes?.INPUT_TEXT,
	name: 'productTemplate',
	className: 'col-6',
	label: 'Modèle de produit',
	nextStepData: productFinal,
	nextStep: false,
	fetchData: (tokenUserCurent, value, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [
				'&',
				['categ_id', '=', currentFormValue?.category?.id],
				'|',
				['name', 'like', value],
				'|',
				['name', 'like', value.charAt(0).toUpperCase() + value.substring(1).toLowerCase()],
				['name', 'like', value.toUpperCase()],
			];

			return filters;
		};

		searchProductTemplate(tokenUserCurent, generateFilter(), async ({ elements }) => {
			fnSuccess(elements);
		});
	},
};

const category = {
	id: 'VR-TR-PIECE-CTGR',
	type: formInputsTypes?.INPUT_TEXT,
	name: 'category',
	className: 'col-6',
	label: 'Entrer votre catégorie',
	nextStepData: productTemplate,
	nextStep: false,
	fetchData: (tokenUserCurent, value, currentFormValue, fnSuccess) => {
		const generateFilter = () => {
			const filters = [
				'|',
				['name', 'like', value],
				'|',
				['name', 'like', value.charAt(0).toUpperCase() + value.substring(1).toLowerCase()],
				['name', 'like', value.toUpperCase()],
			];

			return filters;
		};
		searchCategory(tokenUserCurent, generateFilter(currentFormValue), async (results) => {
			fnSuccess(results?.elements);
		});
	},
};

export default category;
