import React, { FC, ReactNode, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Content from '../Content/Content';
import WrapperOverlay from './WrapperOverlay';
import HeaderRoutes from '../Header/HeaderRoutes';
import FooterRoutes from '../Footer/FooterRoutes';
import ThemeContext from '../../contexts/themeContext';
import { MissedCallsContextProvider } from '../../contexts/missedCallsContext';
import { ClientDataContextProvider } from '../../contexts/clientDataContext';
import { PoseContextProvider } from '../../contexts/poseContext';
import { ActivitiesContextProvider } from '../../contexts/activityContext';
import { ClientMontantContextProvider } from '../../contexts/clientMontantContext';

interface IWrapperContainerProps {
	children: ReactNode;
	className?: string;
}
export const WrapperContainer: FC<IWrapperContainerProps> = ({ children, className, ...props }) => {
	const { rightPanel } = useContext(ThemeContext);
	return (
		<div
			className={classNames(
				'bg-l25-light',
				'wrapper',
				{ 'wrapper-right-panel-active': rightPanel },
				className,
			)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			{children}
		</div>
	);
};
WrapperContainer.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
WrapperContainer.defaultProps = {
	className: undefined,
};

const Wrapper = () => {
	return (
		<>
			<ActivitiesContextProvider>
				<PoseContextProvider>
					<MissedCallsContextProvider>
						<ClientMontantContextProvider>
							<WrapperContainer>
								<HeaderRoutes />
								<Content />
								<FooterRoutes />
							</WrapperContainer>
						</ClientMontantContextProvider>
					</MissedCallsContextProvider>
				</PoseContextProvider>
			</ActivitiesContextProvider>
			<WrapperOverlay />
		</>
	);
};

export default Wrapper;
