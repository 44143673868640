import React, { useState } from 'react'
import Modal, { ModalBody, ModalHeader } from '../bootstrap/Modal'
import { CardBody, CardLabel, CardTitle } from '../bootstrap/Card'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import DevisPopUp from './DevisPopUp';
import Icon from '../icon/Icon';
import { DevisLinesContextProvider } from '../../contexts/devisLinesContext';
import { FactureLinesContextProvider } from '../../contexts/factureLineContext';
import FacturePopUp from '../Facturation/FacturePopUp';
import { AtelierContextProvider } from '../../contexts/atelierContext';
import AtelierPopup from '../Atelier/AtelierPopup';

export default function PopUpSeachDetails({ setOpenListItems, devis, invoice, atelier, filterResult, setfilterResult, setSearchItem }) {
    const { t } = useTranslation(['translation']);
    const [isOpenDevis, setIsOpenDevis] = useState(false);
    const [isOpenInvoice, setIsOpenInvoice] = useState(false);
    const [isOpenAtelier, setIsOpenAtelier] = useState(false);

    const [data, setData] = useState({});

    const handelRead = (line) => {
        setData(line);

        if (devis) {
            setIsOpenDevis(true);
        }
        if (invoice) {
            setIsOpenInvoice(true);
        }
        if (atelier) {
            setIsOpenAtelier(true);
        }
    }

    return (
        <>
            <Modal isOpen={true} setIsOpen={() => { }} size={'xl'} classNameMd='nv-1' isScrollable >
                <ModalHeader setIsOpen={() => { setOpenListItems(false); setfilterResult([]); setSearchItem('') }}  >
                    <CardLabel iconColor='primary'>
                        <CardTitle>Détails de la recherche</CardTitle>
                    </CardLabel>

                </ModalHeader>
                <ModalBody>
                    <CardBody className='table-responsive'>
                        <table className='table table-modern table-hover'>
                            <thead>
                                <tr>

                                    <th scope='col'>N°</th>
                                    <th scope='col'>Titre</th>
                                    <th scope='col'>Date</th>
                                    <th scope='col'>Client </th>
                                    <th scope='col'>Montant T.T.C </th>
                                    <th scope='col'>Etat</th>
                                    <th scope='col'>Societe</th>
                                </tr>
                            </thead>
                            {filterResult?.length > 0 && (
                                <tbody>
                                    {filterResult.map((line) => {
                                        return (
                                            <tr
                                                key={line.id}
                                                className='cursor-pointer'
                                                onClick={() => {
                                                    handelRead(line)
                                                }}>
                                                <td>
                                                    <div>
                                                        {line?.name}
                                                    </div>

                                                    <small>{line?.ref_intervention}</small>
                                                </td>
                                                <td>
                                                    {line?.project_title}
                                                </td>
                                                <td>
                                                    {moment(line?.date_order).format('DD-MM-YYYY')}

                                                </td>
                                                <td>
                                                    <div>
                                                        {line?.partner_id?.name}
                                                    </div>
                                                    <div className='text-muted'>
                                                        <small>
                                                            <Icon
                                                                icon='Phone'
                                                                size='sm'
                                                                color='primary'
                                                                isLight
                                                            />
                                                            {line?.partner_id?.phone}
                                                        </small>
                                                    </div>

                                                    <div className='text-muted'>
                                                        <small> <Icon
                                                            icon='PhoneAndroid'
                                                            size='sm'
                                                            color='primary'
                                                            isLight
                                                        />{line?.partner_id?.mobile}</small></div>
                                                    <div className='text-muted'>
                                                        <small>
                                                            <Icon
                                                                icon='Mail'
                                                                size='sm'
                                                                color='primary'
                                                                isLight
                                                            />
                                                            {line?.partner_id?.email}
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    {
                                                        // @ts-ignore
                                                        line.amount_total?.toLocaleString('fr-FR', {
                                                            style: 'currency',
                                                            currency: 'EUR',
                                                        })
                                                    }

                                                </td>
                                                <td>
                                                    {t(line?.state)}
                                                    {/* {line.state} */}
                                                </td>
                                                <td>
                                                    {line?.company_id?.name}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    {/* 
								<DevisTableRow
									key={'last-row'}
									data={{ id: 'Total' }}
									subTotal={totalPrice}
									isDisabled={true}
								/> */}
                                </tbody>
                            )}
                        </table>
                    </CardBody>
                </ModalBody>
            </Modal>
            {!!isOpenDevis && (
                <DevisLinesContextProvider>
                    <DevisPopUp
                        //  setIsOpen={setIsOpenDevis}
                        card={data}
                        setEditModalStatus={setIsOpenDevis}
                    />
                </DevisLinesContextProvider>
            )}


            {!!isOpenInvoice && (
                <FactureLinesContextProvider>
                    <FacturePopUp
                        //  setIsOpen={setIsOpenDevis}
                        card={data}
                        setEditModalStatus={setIsOpenInvoice}
                    />
                </FactureLinesContextProvider>
            )}
            {!!isOpenAtelier && (
                <AtelierContextProvider>
                    <AtelierPopup
                        setOpen={setIsOpenAtelier}

                        //  setIsOpen={setIsOpenDevis}
                        data={data}

                    />
                </AtelierContextProvider>
            )}
        </>
    )
}
