import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import COLORS from '../common/data/enumColors';

const DevisContext = createContext([]);

// export const DevisContextProvider= ({ children }) => {
// 	const [devis, setDevis] = useState([]);

// 	const value = useMemo(
// 		() => ({
// 			devis,
// 			setDevis
// 		}),
// 		[devis],
// 	);
// 	return <DevisContext.Provider value={value}>{children}</DevisContext.Provider>;
// };
// DevisContextProvider.propTypes = {
// 	children: PropTypes.node.isRequired,
// };

export const DevisContextProvider = ({ children }) => {
	const [devis, setDevis] = useState([
		{
			id: 'draft',
			title: 'Brouillon',
			color: COLORS.DARK.name,
			icon: 'RateReview',
			state: 'draft',
			total: 0,
			/* amountTotal:amountTotalNotPaid, */
			current_page: 1,
			cards: [],
		},

		{
			id: 'sent',
			title: 'Devis envoyé',
			color: COLORS.WARNING.name,
			icon: 'ScheduleSend',
			// amountTotal: amountTotalNotPaidNotDraft,
			state: 'sent',
			total: 0,
			current_page: 1,
			cards: [],
		},
		{
			id: 'done',
			title: 'Devis acceptés',
			color: COLORS.SUCCESS.name,
			icon: 'Block',
			total: 0,
			// amountTotal: amountTotalPaid,
			state: 'done',
			current_page: 1,
			cards: [],
		},
		// {
		// 	id: 'cancel',
		// 	title: 'Devis annulé',
		// 	color: COLORS.DANGER.name,
		// 	icon: 'Cancel',
		// 	total: 0,
		// 	// amountTotal: amountTotalPaid,
		// 	state: 'cancel',
		// 	current_page: 1,
		// 	cards: [],
		// },
		// {
		// 	id: 'cancel',
		// 	title: 'Factures Annulés',
		// 	color: COLORS.DANGER,
		// 	icon: 'ContentPasteOff',
		// 	pay: 'not_paid',
		// 	state: 'cancel',
		// 	total: 0,
		// 	current_page: 1,
		// 	cards: [],
		// },
	]);

	const value = useMemo(
		() => ({
			devis,
			setDevis,
		}),
		[devis],
	);
	return <DevisContext.Provider value={value}>{children}</DevisContext.Provider>;
};
DevisContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DevisContext;
