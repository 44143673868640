import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTabItem,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Checks from '../../../../components/bootstrap/forms/Checks';
import Modal, { ModalHeader, ModalTitle, ModalBody } from '../../../../components/bootstrap/Modal';
import Icon from '../../../../components/icon/Icon';
import SignatureCanvas from 'react-signature-canvas';
import Spinner from '../../../../components/bootstrap/Spinner';
import StatusPopup from '../../../../components/PlanningCalendar/StatusPopup';
import AuthContext from '../../../../contexts/authContext';
import { sendPaiementDevisByMail, uploadSignatureDevis } from '../../../../odooApi/devis';
import Attachements from '../../../../components/Attachements/Attachements';
import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import { SignatureDevisMail } from '../../../../odooApi/signatureDevisMail';
import CreatePaimentFromDevisPopup from './CreatePaimentFromDevisPopup';
import { AcceptPaiementStripeMail } from '../../../../odooApi/paiementMail';
import { MailMenu } from '../../../../gestioneerMenu';
import { useNavigate } from 'react-router-dom';
import ClientMontantContext from '../../../../contexts/clientMontantContext';
import SignatureInput from './SignatureInput';
import Button from '../../../../components/bootstrap/Button';

import ValidationPaiement from '../paiement/validationPaiement';

import ErrorPopup from '../../../../components/extras/ErrorPopup';

export default function SignaturePopup({ setOpenSignature, devis, token, setSignature, loadData }) {
	const { userData } = useContext(AuthContext);
	const navigate = useNavigate();
	const sigCanvas = useRef({});
	const [sigPad, setSigPad] = useState({});
	const [disabledBtn, setDisabledBtn] = useState(true);
	const [spinner, setSpinner] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [paiement, setPaiement] = useState(false);
	const [attachementSignature, setattachementSignature] = useState([]);
	const { clientMontant, setClientMontant } = useContext(ClientMontantContext);
	const [inputValue, setInputValue] = useState('Bon pour accord');
	const [obligation, setObligation] = useState(false);

	const handleConfirm = () => {
		if (sigPad?.isEmpty()) {
			setObligation('remplir votre signature');
		} else {
			const canvas = sigPad.getCanvas();
			const data = sigPad?.getTrimmedCanvas().toDataURL('image/png').split(',')[1];
			confirmAvecSignature(data);
		}
	};
	const handleConfirmCanvas = () => {
		const data = sigCanvas?.current?.toDataURL('image/png').split(',')[1];
		confirmAvecSignature(data);
	};

	const confirmAvecSignature = (data) => {
		setSpinner(true);
		SignatureDevisMail(
			token,
			devis?.id,
			data,
			() => {
				setPaiement(true);
				loadData();

				// setSignature(true);
				// setSpinner(false);
				// setOpenSignature(false);
			},
			(error) => {
				setSpinner(false);
				setError(error);
				setSignature(false);
				//setOpenSignature(false);
			},
		);
	};

	const handleOnClick = useCallback(
		() => navigate(`/${MailMenu?.paiement?.path}/sale.order/${devis.id}/${token}`),
		[navigate],
	);

	const CreatePaiementData = () => {
		// var amount = (30 / 100) * devis?.amount_total;
		handleOnClick();
	};
	const handleCancel = () => {
		setOpenSignature(false);
		// sendPaiementDevisByMail(
		// 	userData?.password,
		// 	devis?.id,
		// 	() => {
		// 		setSuccess("Merci d'avoir accepté et signé le devis. Email envoyé avec succés");
		// 		setOpenSignature(false);
		// 	},
		// 	(error) => {
		// 		setError(error);
		// 	},
		// );
	};

	const handleClearSignature = () => {
		sigPad?.clear();
		setDisabledBtn(true);
	};
	const deleteImage = (key) => {
		const updatedImages = attachementSignature.filter((item, index) => index != key);
		setattachementSignature(updatedImages);
	};
	return (
		<>
			<Modal setIsOpen={() => { }} isOpen={true} size='md' isScrollable classNameMd='nv-1'>
				<ModalHeader className='px-4' setIsOpen={setOpenSignature}>
					<ModalTitle id='project-edit'>
						<Icon icon={'Verified'} size={'2x'} className='me-2'></Icon>Confirmer votre
						commande
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<CardBody>
						<div className='row g-4'>
							<span>
								En signant ce devis, vous acceptez les conditions suivantes :
							</span>
							<ul className='m-4'>
								<li>
									Pour un montant de :{' '}
									<span className='fw-bold'>
										{devis?.amount_total?.toLocaleString('fr-FR', {
											style: 'currency',
											currency: 'EUR',
										})}
									</span>
								</li>
								<li>Avec les conditions générale de vente </li>
							</ul>
						</div>
						<div className='col-lg-6 col-12'>
							<div className='d-flex align-items-center'>
								<div className='flex-shrink-0'>
									<Icon icon='AccountCircle' size='2x' color='primary' />
								</div>
								<div className='flex-grow-1 ms-3'>
									<div className='text-muted'>Nom Complet</div>
									<div className='fw-bold fs-6 mb-0'>{devis?.nom_client}</div>
								</div>
							</div>
						</div>
					</CardBody>

					<Card hasTab shadow={'md'} className='p-2'>
						<CardTabItem id='tab' title='Dessiner'>
							<CardBody>
								<div>
									<SignatureCanvas
										penColor='black'
										canvasProps={{ className: 'signature' }}
										ref={(ref) => {
											setSigPad(ref);
										}}
										onBegin={() => {
											setDisabledBtn(false);
										}}
									/>
								</div>
							</CardBody>
							<CardFooter>
								<CardFooterRight>
									<Button
										color='primary'
										className='w-auto'
										icon='Replay'
										isLight
										//type='submit'
										onClick={handleClearSignature}>
										Effacer
									</Button>
									<Button
										color='success'
										type='submit'
										icon='FileDownloadDone'
										onClick={handleConfirm}
										isDisable={spinner}>
										{spinner && <Spinner isSmall inButton />}
										{spinner ? 'En cours' : 'Accepter et signer'}
									</Button>
								</CardFooterRight>
							</CardFooter>
						</CardTabItem>
						{/* <CardTabItem id='Charger' title='Charger'>
							<div className='row g-4 d-flex align-items-center'>
								{!attachementSignature?.length && (
									<FormGroup className='col-12' id='client' label={'Fichiers'}>
										<Input
											type='file'
											multiple
											name='attachementSignature'
											onChange={(e) => {
												const newAttachments = Array.from(e?.target?.files);
												setattachementSignature((prevAttachments) => [
													...prevAttachments,
													...newAttachments,
												]);
											}}
										/>
									</FormGroup>
								)}
								<div className='row'>
									{!!attachementSignature?.length &&
										attachementSignature?.map((file, index) => {
											return (
												<Attachements
													file={file}
													index={index}
													deleteImage={deleteImage}
												/>
											);
										})}
								</div>
							</div>
						</CardTabItem>
                         */}
						<CardTabItem id='auto' title='Automatique'>
							<SignatureInput name={devis?.nom_client} sigCanvas={sigCanvas} />
							<CardFooter>
								<CardFooterRight>
									<Button
										color='success'
										type='submit'
										icon='FileDownloadDone'
										onClick={handleConfirmCanvas}
										isDisable={spinner}>
										{spinner && <Spinner isSmall inButton />}
										{spinner ? 'En cours' : 'Accepter et signer'}
									</Button>
								</CardFooterRight>
							</CardFooter>
						</CardTabItem>
					</Card>
				</ModalBody>
			</Modal>
			{!!paiement && (
				<CreatePaimentFromDevisPopup
					handleConfirm={() => CreatePaiementData()}
					handleCancel={handleCancel}
					setOpen={setPaiement}
				/>
			)}
			{!!obligation && (
				<StatusPopup icon='info' color='info' title={obligation} setOpen={setObligation} />
			)}
			{!!error && <ErrorPopup error={error} setOpen={setError} />}
			{!!success && (
				<StatusPopup
					icon='Verified'
					color='success'
					title={'Super!'}
					label={success}
					setOpen={() => {
						setSuccess(false);
						// setPaymentModalStatus(false);
					}}
				/>
			)}
		</>
	);
}
