import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import COLORS from '../../../../common/data/enumColors';
import { getImageByModule } from '../../../../common/function/functionsUtils';
import AuthContext from '../../../../contexts/authContext';
import { searchActivities, updateActivity } from '../../../../odooApi/activites';
import ActivityUpdatePopup from '../../../Activity/ActivityUpdatePopup';
import Avatar from '../../../Avatar';
import Badge from '../../../bootstrap/Badge';
import Button from '../../../bootstrap/Button';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../bootstrap/Card';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../bootstrap/Dropdown';
import Spinner from '../../../bootstrap/Spinner';
import ErrorPopup from '../../../extras/ErrorPopup';
import ConfirmationPopup from '../../ConfirmationPopup';

function Tasks({ event }) {
	const [tasks, setTasks] = useState([]);
	const [tasksLoading, setTasksLoading] = useState(false);
	const [error, setError] = useState(false);
	const { userData } = useContext(AuthContext);

	const loadData = () => {
		setTasksLoading(true);
		searchActivities(
			userData?.password,
			[['res_id', '=', event?.id]],
			(resultData) => {
				setTasks(resultData?.elements);
				setTasksLoading(false);
			},
			(error) => {
				setTasksLoading(false);
				setError(error);
			},
			0,
		);
	};
	useEffect(() => {
		loadData();
	}, []);

	return (
		<>
			<Card stretch>
				<CardHeader>
					<CardLabel>
						<CardTitle>
							Taches
							<Badge color={'info'} isLight className='ms-2 fs-6'>
								{tasks?.length}
							</Badge>
						</CardTitle>
					</CardLabel>
				</CardHeader>
				{tasksLoading ? (
					<CardBody
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								flexDirection: 'column',
							}}>
							<Spinner color='dark' size={'4rem'} />
							<span className='p-3'>Chargement des données ...</span>
						</div>
					</CardBody>
				) : (
					<CardBody className='table-responsive'>
						<table className='table table-modern table-hover'>
							<thead>
								<tr>
									<th scope='col'>Responsable</th>
									<th scope='col'>Tache</th>
									<th scope='col'>Statut</th>
									<th scope='col'>Tags</th>
									<th scope='col'>Créer par</th>
									<th scope='col'>Deadline</th>
									<th></th>
									{/* <th scope='col'>Priority</th> */}
								</tr>
							</thead>
							<tbody>
								{tasks?.map((task, i) => (
									<Task task={task} key={i} setTasks={setTasks} />
								))}
							</tbody>
						</table>
					</CardBody>
				)}
			</Card>
			{!!error && <ErrorPopup error={error} setOpen={setError} />}
		</>
	);
}

export default Tasks;

const Task = ({ task, key, setTasks }) => {
	const data = [
		{
			id: 'to_do',
			title: 'À faire',
			color: COLORS.PRIMARY.name,
			icon: 'TaskAlt',
		},
		{
			id: 'in_progress',
			title: 'En cours',
			color: COLORS.INFO.name,
			icon: 'DirectionsRun ',
		},

		{
			id: 'done',
			title: 'Terminée',
			color: COLORS.SUCCESS.name,
			icon: 'Verified',
		},
	];
	const [spinner, setSpinner] = useState(false);
	const { userData } = useContext(AuthContext);
	const [error, setError] = useState(false);
	const [confirmUpdatePopup, setConfirmUpdatePopup] = useState(false);
	const [statut, setStatut] = useState(data?.find((t) => t?.id == task?.x_state));
	const [updatedStatut, setUpdatedStatut] = useState({});
	const [openActivity, setOpenActivity] = useState(false);

	const handleUpdate = (status) => {
		// updateTask(
		// 	userData?.password,
		// 	{
		// 		id: task?.id,
		// 		x_state: status?.id,
		// 	},
		// 	(result) => {
		// 		setUpdatedStatut(status);
		// 		setSpinner(false);
		// 	},
		// 	(error) => {
		// 		setSpinner(false);
		// 		setError(error);
		// 	},
		// );

		updateActivity(
			userData?.password,
			{
				id: task?.id,
				x_state: status?.id,
			},
			(result) => {
				setUpdatedStatut(status);
				setSpinner(false);
			},
			(error) => {
				setSpinner(false);
				setError(error);
			},
		);
	};
	useEffect(() => {
		setUpdatedStatut(data?.find((t) => t?.id == task?.x_state));
	}, [task?.x_state]);

	return (
		<>
			<tr key={key}>
				<td>
					{task?.x_users_ids?.map((user) => (
						<>
							<Avatar
								src={getImageByModule(
									userData?.password,
									user?.employee_id,
									'hr.employee',
								)}
								srcSet={getImageByModule(
									userData?.password,
									user?.employee_id,
									'hr.employee',
								)}
								size={20}
								userName={`${user?.name}`}
								className='cursor-pointer img-avatar me-2'
							/>
							<span>{user?.name}</span>
						</>
					))}
				</td>
				<td> {task?.x_name} </td>
				{/* <td><Icon icon={item?.type_ids?.find(t => t?.id == task?.stage_id).x_icon} color={item?.type_ids?.find(t => t?.id == task?.stage_id)?.x_color} size={"lg"} className='me-2' ></Icon>{item?.type_ids?.find(t => t?.id == task?.stage_id).name}</td> */}
				<td>
					<Dropdown>
						<DropdownToggle>
							{/* <Button
								shadow={'md'}
								color={data?.find((item) => updatedStatut?.id == item?.id)?.color}
								isLight
								icon={data?.find((item) => updatedStatut?.id == item?.id)?.icon}
								size={'sm'}>
								{!!spinner && <Spinner isSmall inButton />}
								{!!spinner
									? 'En cours'
									: !!updatedStatut?.id
									? updatedStatut?.title
									: statut?.title}
								{data?.find((item) => updatedStatut?.id == item?.id)?.title}
							</Button> */}
							<Button
								shadow={'md'}
								color={!!updatedStatut?.id ? updatedStatut?.color : statut?.color}
								isLight
								icon={
									!!!spinner && !!updatedStatut?.id
										? updatedStatut?.icon
										: statut?.icon
								}
								size={'sm'}>
								{!!spinner && <Spinner isSmall inButton />}
								{!!spinner
									? 'En cours'
									: !!updatedStatut?.id
									? updatedStatut?.title
									: statut?.title}
							</Button>
						</DropdownToggle>
						<DropdownMenu size={'sm'}>
							{data.map((group) => (
								<DropdownItem>
									<Button
										key={group.id}
										color='link'
										icon={group.icon}
										// onClick={() => {
										// 	formik.setFieldValue('x_state', group?.id);
										// }}

										onClick={() => {
											if (
												!!updatedStatut?.id
													? updatedStatut?.id !== group?.id
													: statut?.id !== group?.id
											) {
												setStatut(group);
												setConfirmUpdatePopup(true);
											}
										}}>
										{group.title}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
					{/* <Dropdown>
						<DropdownToggle>
							<Button
								shadow={'md'}
								color={
									!!updatedStatut?.id ? updatedStatut?.x_color : statut?.x_color
								}
								isLight
								icon={
									!!!spinner && !!updatedStatut?.id
										? updatedStatut?.x_icon
										: statut?.x_icon
								}
								size={'sm'}>
								{!!spinner && <Spinner isSmall inButton />}
								{!!spinner
									? 'En cours'
									: !!updatedStatut?.id
									? updatedStatut?.name
									: statut?.name}
							</Button>
						</DropdownToggle>
						<DropdownMenu size={'sm'}>
							{item?.type_ids?.map((group) => (
								<DropdownItem>
									<Button
										key={group?.id}
										color='link'
										icon={group?.x_icon}
										onClick={() => {
											if (
												!!updatedStatut?.id
													? updatedStatut?.id !== group?.id
													: statut?.id !== group?.id
											) {
												setStatut(group);
												setConfirmUpdatePopup(true);
											}
										}}>
										{group?.name}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown> */}
				</td>

				{/* <td>
					{task?.tag_ids?.length > 0 &&
						task?.tag_ids?.map((i) => (
							<span className='me-2'>
								<Badge
									isLight
									color={
										TAG_COLORS?.find((t) => t?.id == i?.color)?.name || 'info'
									}>
									{i?.name}
								</Badge>
							</span>
						))}
				</td> */}
				<td>
					<span className='me-2'>
						<Badge isLight color={'info'}>
							{task?.activity_type_id?.name}
						</Badge>
					</span>
				</td>
				<td>
					<Avatar
						src={getImageByModule(
							userData?.password,
							task?.create_uid?.employee_id,
							'hr.employee',
						)}
						srcSet={getImageByModule(
							userData?.password,
							task?.create_uid?.employee_id,
							'hr.employee',
						)}
						size={20}
						userName={`${task?.create_uid?.name}`}
						className='cursor-pointer img-avatar me-2'
					/>
					<span>{task?.create_uid?.name}</span>
				</td>
				<td>
					{moment(task?.date_deadline).isSame(moment(), 'days')
						? "Aujourd'hui"
						: moment(task?.date_deadline).format('D MMM')}
				</td>
				<td>
					<Button
						icon='RemoveRedEye'
						color='primary'
						isLight
						onClick={() => {
							setOpenActivity(true);
						}}
					/>
				</td>
				{/* <td
					className={`text-${
						task?.priority == '0' ? COLORS.SUCCESS.name : COLORS.DANGER.name
					}`}>
					{task?.priority == '0' ? 'Normale' : 'Important'}
				</td> */}
			</tr>
			{confirmUpdatePopup && (
				<ConfirmationPopup
					title={`Modifier statut ! `}
					label={`${data?.find((t) => t?.id == task?.x_state)?.title} -> ${
						statut?.title
					}`}
					handleConfirm={() => handleUpdate(statut)}
					setOpen={() => {
						setConfirmUpdatePopup(false);
						setStatut(data?.find((t) => t?.id == task?.x_state));
					}}
				/>
			)}
			{!!error && <ErrorPopup error={error} setOpen={setError} />}
			{!!openActivity && (
				<ActivityUpdatePopup
					card={task}
					setEditModalStatus={setOpenActivity}
					setTasks={setTasks}
				/>
			)}
		</>
	);
};
