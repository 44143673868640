const formInputsTypes = {
    RADIO_INPUT:"RADIO_INPUT",
    SELECT_INPUT:"SELECT_INPUT",
    INPUT_TEXT:"INPUT_TEXT",
    INPUT_NUMBER:"number",
    SELECTABLE_IMAGE:"SELECTABLE_IMAGE",
    CHECKBOX_INPUT:"CHECKBOX_INPUT"
}

export default formInputsTypes; 

export const shortCode = {
    "VR-TR-AXE-MN":"axe manuel Volet Roulant tradi",

}