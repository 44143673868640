export const prix_AxeManuelReno = [
	{ id: 1000, price: 157.18 },
	{ id: 1500, price: 177.21 },
	{ id: 2000, price: 191.11 },
	{ id: 2500, price: 210.11 },
	{ id: 3000, price: 231.21 },
	{ id: 3500, price: 247.88 },
	{ id: 4000, price: 262.99 },
	{ id: 4500, price: 287.99 },
	{ id: 5000, price: 299.01 },
	{ id: 5500, price: 321.99 },
	{ id: 6000, price: 341.11 },
	{ id: 6500, price: 387.11 },
	{ id: 7000, price: 451.88 },
	{ id: 7500, price: 487.88 },
	{ id: 8000, price: 501.7 },
];
export const prix_AxeManuelTradi = [
	{ id: 1000, price: 207.18 },
	{ id: 1500, price: 223.21 },
	{ id: 2000, price: 241.11 },
	{ id: 2500, price: 260.11 },
	{ id: 3000, price: 281.21 },
	{ id: 3500, price: 297.88 },
	{ id: 4000, price: 312.99 },
	{ id: 4500, price: 337.99 },
	{ id: 5000, price: 349.01 },
	{ id: 5500, price: 371.99 },
	{ id: 6000, price: 391.11 },
	{ id: 6500, price: 437.11 },
	{ id: 7000, price: 501.88 },
	{ id: 7500, price: 537.88 },
	{ id: 8000, price: 551.7 },
];
