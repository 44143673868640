import React from 'react'
import Accordion, { AccordionItem } from '../../../bootstrap/Accordion'
import Icon from '../../../icon/Icon'
import moment from 'moment'

export default function Historique({ event }) {
    return (
        <Accordion id='accSample' activeItemId={false} className='px-0 mt-1' shadow={"sm"} color='dark' >
            <AccordionItem
                headerTag="span"
                tag={"div"}
                id='accor1'
                title={<small className='text-muted fw-normal'><Icon icon='InfoOutline' size={'lg'} className='me-2'></Icon>Historique</small>}
            // icon='InfoOutline'
            >
                <div className={"break-word fs-6"} >

                    {event?.message_ids?.map((i) =>
                        <div className='py-4 px-4 mb-2 bg-l10-dark rounded-2 '>
                            <div className='text-muted pb-2' >
                                Note écrite par{" "}
                                <strong>{i?.author_id?.name}</strong> {" "}
                                - {moment(i?.date).format("DD/MM/YYYY HH:mm:ss")}
                            </div>
                            {!!i?.body && <div className='ps-2'>{i?.body.replace(
                                /<br\s*\/?>/g,
                                '\n',
                            ).replace(
                                /<\/?[^>]+(>|$)/g,
                                '',
                            )}</div>}
                            {i?.tracking_value_ids?.map((trace) =>
                                <div className="ps-2">
                                    {trace?.field_desc} :{" "}
                                    {trace?.field_type == "datetime" ? <>{trace?.old_value_datetime}<Icon icon='ArrowRightAlt'></Icon> {trace?.new_value_datetime}</> : trace?.field_type == "float" ? <>{trace?.old_value_float}<Icon icon='ArrowRightAlt'></Icon> {trace?.new_value_float}</> : <>{trace?.old_value_char}<Icon icon='ArrowRightAlt'></Icon> {trace?.new_value_char}</>}
                                </div>
                            )}
                        </div>
                    )}
                </div>

            </AccordionItem>
        </Accordion>

    )
}
