import React, { useContext, useState } from 'react'
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../bootstrap/Modal'
import InputGroup from '../../../bootstrap/forms/InputGroup'
import Textarea from '../../../bootstrap/forms/Textarea'
import Button from '../../../bootstrap/Button'
import { createMailMessage, deleteMailMessage, updateMailMessage } from '../../../../odooApi/mailMessage'
import AuthContext from '../../../../contexts/authContext'
import Card, { CardBody, CardFooter, CardFooterRight } from '../../../bootstrap/Card'
import Label from '../../../bootstrap/forms/Label'
import MailMessageContext from '../../../../contexts/mailMessageContext'
import ConfirmationPopup from '../../ConfirmationPopup'
import ErrorPopup from '../../../extras/ErrorPopup'

export default function EditChronologiePopup({ setIsEditModal, item }) {
    const { userData } = useContext(AuthContext);
    const [messageIDS, setMessageIDS] = useState(item?.body)
    const { mailMessages, setMailMessages } = useContext(MailMessageContext);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [error, setError] = useState(false);

    const updateChronologieContext = (values) => {

        setMailMessages((prevMailMessages) => {
            let updatedMailMessages = [...prevMailMessages];
            let groupIndex = updatedMailMessages.findIndex((group) => group.id === values.id);

            if (groupIndex !== -1) {
                updatedMailMessages[groupIndex].body = messageIDS
            }

            return updatedMailMessages;
        });

    };


    const deleteChronologieContext = (idToDelete) => {
        setMailMessages((prevMailMessages) => {
            // Filtrer les éléments qui ne correspondent pas à l'id à supprimer
            const updatedMailMessages = prevMailMessages.filter((group) => group.id !== idToDelete);
            return updatedMailMessages;
        });
    };

    const modifierMessage = (noteData) => {
        updateMailMessage(userData?.password, noteData, () => {
            setIsEditModal(false)
            updateChronologieContext(item)

        });
    };


    const handelDelete = () => {
        deleteMailMessage(userData?.password, item?.id, () => {
            setIsEditModal(false)
            deleteChronologieContext(item?.id)
        }, (error) => {
            setError(error);
        },);
    }



    return (
        <>
            <Modal setIsOpen={() => { }} isOpen={true} isCentered titleId='tour-title' classNameMd='nv-3'>
                <ModalHeader className='px-4 pb-0' setIsOpen={setIsEditModal}>
                    <div className='w-100 text-muted text-center'>
                        <ModalTitle id='tour-title' className='fw-semibold fs-4'>Modifier l'activité</ModalTitle>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Card>
                        <CardBody>
                            <Label>Note</Label>
                            <Textarea
                                onChange={(e) => setMessageIDS(e.target.value)}
                                value={messageIDS?.replace(
                                    /<br\s*\/?>/g,
                                    '\n',
                                ).replace(
                                    /<\/?[^>]+(>|$)/g,
                                    '',
                                )} />
                        </CardBody>
                        <CardFooter>
                            <CardFooterRight>
                                {/* <Button
                                    color='danger'
                                    icon='Delete'
                                    onClick={() =>
                                        setIsConfirmed(true)
                                    }
                                >
                                    Supprimer
                                </Button> */}
                                <Button
                                    color='info'
                                    icon='Save'
                                    onClick={() =>
                                        modifierMessage({
                                            id: item?.id,
                                            body: messageIDS,
                                        })
                                    }
                                >
                                    Modifier
                                </Button>
                            </CardFooterRight>

                        </CardFooter>
                    </Card>


                </ModalBody>
            </Modal>
            {
                isConfirmed && (
                    <ConfirmationPopup
                        handleConfirm={handelDelete}
                        setOpen={setIsConfirmed}
                        title={'Supprimer cette note ?'}
                    />
                )
            }
            {!!error && (
                <ErrorPopup
                    error={error}
                    setOpen={setError}
                />
            )}
        </>
    )
}
