import React, { useState, useEffect } from 'react';

import { Elements } from '@stripe/react-stripe-js';

import PaiementInterface from './PaiementInterface';
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const appearance = {
	theme: 'flat',

	variables: {
		colorPrimary: '#0570de',
		colorBackground: '#ffffff',
		colorText: '#30313d',
		colorDanger: '#df1b41',
		fontFamily: 'Ideal Sans, system-ui, sans-serif',
		spacingUnit: '2px',
		borderRadius: '4px',
		// See all possible variables below
	},
	rules: {
		'.Tab': {
			border: '1px solid #E0E6EB',
			boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
		},
		'.Input': {
			border: '1px solid #E0E6EB',
		},
		'.Label': {
			padding: '20px 0',
			fontWeight: '700',
		},

		'.Tab:hover': {
			color: 'var(--colorText)',
		},

		'.Tab--selected': {
			borderColor: '#E0E6EB',
			boxShadow:
				'0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
		},

		'.Input--invalid': {
			boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
		},

		// See all supported class names and selector syntax below
	},
};

export default function StripeCheckout({ amount, clientSecret, result, stripePromise }) {
	// const [clientSecret, setClientSecret] = useState(
	// 	'pi_3O5m4UJhbFoX25tf1T8zs4MT_secret_kHqlspuQiyW0dJseiwC99Os5F',
	// );

	// 	fetch('https://dev.volet-store.fr/api/stripe/client_secret', {
	// 		method: 'POST',
	// 		headers: { 'Content-Type': 'application/json' },
	// 		body: JSON.stringify({ items: [{}] }),
	// 	})
	// 		.then((res) => {
	// 			if (!res.ok) {
	// 				throw new Error('Network response was not ok');
	// 			}
	// 			return res.json();
	// 		})
	// 		.then((data) => setClientSecret(data.clientSecret))
	// 		.catch((error) => {
	// 			console.log(error);
	// 		});
	// }, []);

	const options = {
		clientSecret,
		appearance,
		//amount: amount,
	};

	return (
		<div>
			{clientSecret && (
				<Elements options={options} stripe={stripePromise}>
					<PaiementInterface
					clientSecret={clientSecret}
						amount={amount}
						result={result}
					/>
				</Elements>
			)}
		</div>
	);
}
