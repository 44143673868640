import * as React from 'react';

const SvgCustomTech = (props) => (
    <svg
      	xmlns='http://www.w3.org/2000/svg'
		height='1em'
		viewBox="-10 -10 65 70"
		width='1em'
		className='svg-icon'
		fill='currentColor'
		{...props}>
        <path d="m5,51.64c-2.76,0-5-2.24-5-5v-1.09c0-7.72,6.28-14,14-14h.36c-3.22-2.47-5.21-6.22-5.21-10.19v-.75c-1.41-.91-2.29-2.49-2.29-4.19,0-1.33.52-2.59,1.47-3.54.32-.32.67-.59,1.05-.8.75-3.8,3.33-7.18,6.98-9.11l2-1.06c1.04-1.19,2.54-1.9,4.12-1.91h.73c1.64,0,3.2.74,4.25,2l1.9,1.01c3.65,1.95,6.23,5.31,6.98,9.07,1.55.89,2.52,2.54,2.52,4.35,0,1.32-.53,2.61-1.46,3.54-.23.24-.51.46-.82.66v.74c0,3.97-2.01,7.72-5.26,10.19h.42c7.72,0,14,6.28,14,14v1.09c0,2.76-2.24,5-5,5H5Zm8.99-16.09c-2.67,0-5.17,1.04-7.06,2.93-1.89,1.89-2.94,4.41-2.94,7.07v1.09c0,.26.11.51.3.7.19.19.44.3.7.3h35.74c.27,0,.52-.11.71-.3.19-.19.29-.43.29-.7v-1.09c0-2.67-1.04-5.19-2.93-7.07-1.89-1.89-4.4-2.93-7.07-2.93H13.99Zm-.78-13.19c.25,2.01,1.24,3.89,2.86,5.4,1.84,1.7,4.3,2.68,6.73,2.68s4.93-.98,6.81-2.69c1.64-1.51,2.66-3.4,2.91-5.39H13.21Zm-1.34-6.94c-.14,0-.26.02-.36.07-.15.06-.26.14-.35.23-.1.1-.17.2-.21.31-.06.13-.08.26-.08.39,0,.26.11.52.31.72.18.18.44.29.7.29h21.99c.13,0,.26-.03.4-.08.1-.04.21-.12.3-.21.09-.09.17-.2.21-.32.05-.13.08-.27.08-.4,0-.34-.16-.58-.3-.71-.19-.18-.43-.29-.7-.29h-1.29v-1c0-1.6-.49-3.19-1.42-4.59-.71-1.07-1.63-1.99-2.72-2.72l-1.71,4.13h-2.01v-5.74c0-.4-.16-.78-.44-1.06-.28-.28-.67-.44-1.06-.44h-.71c-.4,0-.77.16-1.05.44-.29.29-.44.67-.44,1.07v5.73h-2.01l-1.21-2.93-.52-1.24c-1.08.73-2.01,1.66-2.72,2.72-.92,1.41-1.41,3.01-1.41,4.63v1h-1.28Z"/>
      <path d="m31.73,34.55H13.99c-3.04,0-5.79,1.23-7.78,3.22-1.99,1.99-3.22,4.74-3.22,7.78v1.09c0,.55.22,1.05.59,1.41.36.36.86.59,1.41.59h35.74c.55,0,1.05-.22,1.41-.59.36-.36.59-.86.59-1.41v-1.09c0-3.04-1.23-5.79-3.22-7.78-1.99-1.99-4.74-3.22-7.78-3.22Z"
		opacity={0.3}
	  />
      <path d="m30.28,28.49c2-1.82,3.3-4.34,3.3-7.12H12.15c0,2.78,1.27,5.3,3.24,7.12,1.97,1.82,4.63,2.95,7.41,2.95s5.48-1.13,7.47-2.95Z"
		opacity={0.3}
	  />
      <path d="m11.87,18.43h21.99c.27,0,.54-.06.78-.16.24-.1.45-.25.63-.43.18-.18.33-.4.43-.64.1-.24.16-.51.16-.78,0-.55-.23-1.05-.59-1.41-.36-.36-.86-.59-1.41-.59h-.29c0-1.88-.59-3.64-1.59-5.14-1-1.5-2.4-2.75-4.03-3.62l-.95,2.29-.95,2.29h-.34v-4.74c0-.69-.28-1.31-.73-1.77-.45-.45-1.08-.73-1.77-.73h-.71c-.69,0-1.31.29-1.76.74-.45.45-.73,1.08-.73,1.77v4.73h-.34l-.96-2.31-.96-2.31c-1.63.86-3.03,2.11-4.03,3.62s-1.58,3.28-1.58,5.18h-.28c-.28,0-.54.06-.79.16-.24.1-.46.25-.64.43-.18.18-.33.4-.43.63s-.16.5-.16.78c0,.55.22,1.06.59,1.42.36.36.87.59,1.42.59Z"
		opacity={0.3}
	  />
    </svg>

)
export default SvgCustomTech;