import * as React from 'react';

const SvgCustomTechFill = (props) => (
    <svg
      	xmlns='http://www.w3.org/2000/svg'
		height='1em'
		viewBox="-10 -10 60 70"
		width='1em'
		className='svg-icon'
		fill='currentColor'
		{...props}>
        <path d="m28.74,31.55H11c-3.04,0-5.79,1.23-7.78,3.22-1.99,1.99-3.22,4.74-3.22,7.78v1.09c0,.55.22,1.05.59,1.41.36.36.86.59,1.41.59h35.74c.55,0,1.05-.22,1.41-.59.36-.36.59-.86.59-1.41v-1.09c0-3.04-1.23-5.79-3.22-7.78-1.99-1.99-4.74-3.22-7.78-3.22Z"/>
      	<path d="m27.28,25.49c2-1.82,3.3-4.34,3.3-7.12H9.16c0,2.78,1.27,5.3,3.24,7.12,1.97,1.82,4.63,2.95,7.41,2.95s5.48-1.13,7.47-2.95Z"/>
      	<path d="m8.88,15.43h21.99c.27,0,.54-.06.78-.16.24-.1.45-.25.63-.43.18-.18.33-.4.43-.64.1-.24.16-.51.16-.78,0-.55-.23-1.05-.59-1.41-.36-.36-.86-.59-1.41-.59h-.29c0-1.88-.59-3.64-1.59-5.14-1-1.5-2.4-2.75-4.03-3.62l-.95,2.29-.95,2.29h-.34V2.5c0-.69-.28-1.31-.73-1.77-.45-.45-1.08-.73-1.77-.73h-.71c-.69,0-1.31.29-1.76.74-.45.45-.73,1.08-.73,1.77v4.73h-.34l-.96-2.31-.96-2.31c-1.63.86-3.03,2.11-4.03,3.62s-1.58,3.28-1.58,5.18h-.28c-.28,0-.54.06-.79.16-.24.1-.46.25-.64.43-.18.18-.33.4-.43.63s-.16.5-.16.78c0,.55.22,1.06.59,1.42.36.36.87.59,1.42.59Z"/>
    </svg>

)
export default SvgCustomTechFill;