import React, { useContext, useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
} from '../../../../../components/bootstrap/Card';
import { AcceptPaiementStripeMail, paymentJustifyAPI } from '../../../../../odooApi/paiementMail';
import { useNavigate, useParams } from 'react-router-dom';
import { MailMenu } from '../../../../../gestioneerMenu';
import ClientMontantContext from '../../../../../contexts/clientMontantContext';
import moment from 'moment';

export default function PaiementInterface({ amount,clientSecret, result }) {
	const { id } = useParams(0);
	const { token } = useParams(0);
	const { model } = useParams(0);
	const navigate = useNavigate();
	const stripe = useStripe();
	const elements = useElements();
	const confirmationPage = process.env.REACT_APP_REDIRECT_URI_PAYMENT;
	const { clientMontant, setClientMontant } = useContext(ClientMontantContext);

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		console.log('clientSecret', clientSecret);
		if (!stripe) {
			return;
		}

		if (!clientSecret) {
			return;
		}
		const total = result?.amount_total * 100;
		const amount = Math.round(total * (clientMontant?.montant / 100));
		AcceptPaiementStripeMail(
			token,
			model,
			id,
			amount,
			(data) => {
				//setClientSecret(data?.clientSecret?.client_secret);
			},
			(error) => {},
		);
		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			setMessage(
				paymentIntent.status === 'succeeded'
					? 'Your payment succeeded'
					: 'Unexpected error occurred',
			);
		});
	}, [stripe]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		setIsLoading(true);

		const payInfo = {
			mode_paiement: 'Stripe',
			montant: amount,
			client: result?.customer_id,
			total: result?.amount_total,
			source: result?.invoice_id,
		};
		paymentJustifyAPI(
			payInfo,

			() => {},
			(error) => {
				console.log('error', error);
			},
		);
		
		setClientMontant({...clientMontant,client:result,paiement:'Stripe'})
			

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: confirmationPage,
			},
		});

		if (error && (error.type === 'card_error' || error.type === 'validation_error')) {
			setMessage(error.message);
		}

		setIsLoading(false);
	};

	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement />

			<CardFooter>
				<CardFooterLeft></CardFooterLeft>
				<CardFooterRight>
					<button
						className='bg-info text-white p-2'
						disabled={isLoading || !stripe || !elements}>
						{isLoading ? 'Loading...' : `Payer`}
					</button>
				</CardFooterRight>
			</CardFooter>
			{/* <button
				icon='Draw'
				size='2x'
				isLight
				color='info'
				className='bg-black rounded-xl text-white p-2 mt-6 mb-2'
				disabled={isLoading || !stripe || !elements}>
				{isLoading ? 'Loading...' : 'Pay now'}
			</button> */}
			{message && <div>{message}</div>}
		</form>
	);
}
