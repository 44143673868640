import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import COLORS from '../../../../common/data/enumColors';
import Badge from '../../../../components/bootstrap/Badge';
import Button from '../../../../components/bootstrap/Button';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardActions,
	CardTitle,
	CardSubTitle,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
} from '../../../../components/bootstrap/Card';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import DevisTable from '../../../../components/Devis/DevisTable';
import Icon from '../../../../components/icon/Icon';
import AuthContext from '../../../../contexts/authContext';
import { MailMenu } from '../../../../gestioneerMenu';
import useDarkMode from '../../../../hooks/useDarkMode';
import useTourStep from '../../../../hooks/useTourStep';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import { getSingleDevis } from '../../../../odooApi/devis';
import { getDevisLinesById } from '../../../../odooApi/lineDevis';
import {
	ConfirmDevisMail,
	DownloadReportDevisMail,
	ReportDevisMail,
} from '../../../../odooApi/signatureDevisMail';
import Droit from '../../../../assets/img/scene2.webp';
import Spinner from '../../../../components/bootstrap/Spinner';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../../layout/SubHeader/SubHeader';
import store2000 from '../../../../assets/img/store2000.png';
import SignaturePopup from './SignaturePopup';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';

export default function AcceptDevisMail() {
	useTourStep(13);
	const { id } = useParams(0);
	const { token } = useParams(0);
	console.log(useParams(0));
	const [devis, setDevis] = useState();
	const [devisLines, setDevisLines] = useState();
	const { userData } = useContext(AuthContext);
	const [openSignature, setOpenSignature] = useState(false);
	const [access_token, setAccess_token] = useState();
	const [readDevis, setReadDevis] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const [signature, setSignature] = useState(false);
	const { darkModeStatus } = useDarkMode();
	const isMobile = useMediaQuery({ query: '(max-width: 991.98px)' });
	const loadData = () => {
		ConfirmDevisMail(
			token,
			parseInt(id),
			(result) => {
				setReadDevis(true);
				setSpinner(true);
				setDevis(result?.devis[0]);
				setDevisLines(result?.devisLines);
			},
			(error) => {
				setReadDevis(false);
				setSpinner(true);
			},
		);
	};

	useEffect(() => {
		loadData();
	}, [id, token]);


	return (
		<PageWrapper title={MailMenu?.acceptDevis?.text} isProtected={false}>
			<SubHeader>
				<SubHeaderLeft>
					<h2 className='fw-bold p-2 text-uppercase'>
						{devis?.company_id}
					</h2>
					{/* <img src={store2000} alt='' className='p-4' /> */}
					<SubheaderSeparator />
					{!!readDevis && (
						<h4>
							<span className='me-2 text-primary'>Montant:</span>

							{
								devis?.amount_total?.toLocaleString('fr-FR', {
									style: 'currency',
									currency: 'EUR',

								})

							}
						</h4>
					)}
				</SubHeaderLeft>
				{!!readDevis && (
					<SubHeaderRight>
						{!devis?.signed_by && (
							<Button
								icon='Draw'
								size='2x'
								isLight
								color='success'
								onClick={() => setOpenSignature(true)}>
								Accepter et signer
							</Button>
						)}
						<Button
							tag='a'
							color='info'
							size='2x'
							icon='DownloadForOffline'
							className='text-nowrap'
							href={DownloadReportDevisMail(token, id)}
							target='_blank'>
							Télécharger
						</Button>

						{/* <Button icon='DownloadForOffline' size='2x' isLight color='info'>
						Télécharger
					</Button> */}
					</SubHeaderRight>
				)}
			</SubHeader>
			<Page className='mt-3'>
				<>
					{!!spinner && (
						<>
							{readDevis ? (
								<>
									<div className='row '>
										{/* <div
											className='d-flex justify-content-center'
											style={{ fontSize: 'calc(2rem + 1vw)' }}>
											<p>Devis {devis?.name}</p>
										</div> */}
										<div className='col-lg-4'>
											{/* <Card>
												<CardBody>
													<div
														className='row text-center d-flex justify-content-center'
														style={{
															fontSize: 'calc(1rem + 1vw)',
														}}>
														Montant
														<span>
															<Badge>
																{
																	// @ts-ignore
																	devis?.amount_total?.toLocaleString(
																		'fr-FR',
																		{
																			style: 'currency',
																			currency: 'EUR',
																		},
																	)
																}
															</Badge>
														</span>
													</div>
												</CardBody>
												<CardFooter>
													<CardFooterLeft>
														<Button
															icon='pen'
															size='2x'
															isLight
															color='success'
															
															onClick={() =>
																setOpenSignature(true)
															}>
															Accepter et signer
														</Button>
													</CardFooterLeft>
													<CardFooterRight>
														<Button
															icon='DownloadForOffline'
															size='2x'
															isLight
															color='info'
															>
															Télécharger
														</Button>
													</CardFooterRight>
												</CardFooter>
											</Card> */}
											<Card shadow='sm'>
												<CardHeader>
													<CardLabel icon='Info' iconColor='primary'>
														<CardTitle>Informations</CardTitle>
													</CardLabel>
												</CardHeader>
												<CardBody>
													<div className='row g-4'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='AssignmentInd'
																	size='2x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='text-muted'>
																	Responsable
																</div>
																<div className='fw-bold fs-6 mb-0'>
																	{devis?.nom_utilisateur}
																</div>
															</div>
														</div>

														{!!devis?.nom_client && (
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='AccountCircle'
																		size='2x'
																		color='primary'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='text-muted'>
																		Nom du client
																	</div>
																	<div className='fw-bold fs-6 mb-0'>
																		{devis?.nom_client}
																	</div>
																</div>
															</div>
														)}
														{!!devis?.email_client && (
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='Mail'
																		size='2x'
																		color='primary'
																		isLight
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='text-muted'>
																		E-mail du client
																	</div>
																	<div className='fw-bold fs-6 mb-0'>
																		{devis?.email_client}
																	</div>
																</div>
															</div>
														)}
														{!!devis?.mobile_client && (
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='PhoneAndroid'
																		size='2x'
																		color='primary'
																	/>
																</div>

																<div className='flex-grow-1 ms-3'>
																	<div className='text-muted'>
																		N° Tél du client
																	</div>
																	<div className='fw-bold fs-6 mb-0'>
																		{devis?.mobile_client}
																	</div>
																</div>
															</div>
														)}
														{!!devis?.phone_client && (
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='Phone'
																		size='2x'
																		color='primary'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='text-muted'>
																		N° Tél du client
																	</div>
																	<div className='fw-bold fs-6 mb-0'>
																		{devis?.phone_client}
																	</div>
																</div>
															</div>
														)}

														<div className='d-flex align-items-center mt-3'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='Assistant'
																	size='2x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='text-muted'>
																	Titre du devis
																</div>
																<div className='fw-bold fs-6 mb-0'>
																	{devis?.project_title}
																</div>
															</div>
														</div>
														{!!devis?.remarque && (
															<div className='d-flex align-items-center mt-3'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='StickyNote2'
																		size='2x'
																		color='primary'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='text-muted'>
																		Notes
																	</div>
																	<div className='fw-bold fs-6 mb-0'>
																		{devis?.remarque}
																	</div>
																</div>
															</div>
														)}
													</div>
												</CardBody>
											</Card>
											{!!devis?.signed_by && (
												<Card shadow='sm'>
													<CardHeader>
														<CardLabel icon='Draw' iconColor='primary'>
															<CardTitle>Signature</CardTitle>
														</CardLabel>
													</CardHeader>
													<CardBody>
														<div className='row g-4'>
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='AccountCircle'
																		size='2x'
																		color='primary'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='text-muted'>
																		Signé par
																	</div>
																	<div className='fw-bold fs-6 mb-0'>
																		{devis?.signed_by}
																	</div>
																</div>
															</div>
															<div className='d-flex align-items-center'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='AccessTime'
																		size='2x'
																		color='primary'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='text-muted'>
																		Signé le
																	</div>
																	<div className='fw-bold fs-6 mb-0'>
																		{devis?.signed_on}
																	</div>
																</div>
															</div>
														</div>
													</CardBody>
												</Card>
											)}
											{/* <Card>
												<CardBody>
													<div
														className='row text-center d-flex justify-content-center'
														style={{
															fontSize: 'calc(1rem + 1vw)',
														}}>
														Montant
														<span>
															<Badge>
																{
																	// @ts-ignore
																	devis?.amount_total?.toLocaleString(
																		'fr-FR',
																		{
																			style: 'currency',
																			currency: 'EUR',
																		},
																	)
																}
															</Badge>
														</span>
													</div>
												</CardBody>
											</Card> */}
										</div>
										<div className='col-lg-8'>
											<div className='row'>
												<Card>
													<CardHeader>
														<CardLabel
															icon='Assignment'
															iconColor='primary'>
															<CardTitle>
																Devis {devis?.name}
															</CardTitle>
														</CardLabel>
													</CardHeader>

													<CardBody className='table-responsive'>
														<table className='table table-modern table-hover'>
															<thead>
																<tr>
																	<th scope='col'>Article</th>
																	{!isMobile && (
																		<th scope='col'>
																			Description
																		</th>
																	)}
																	<th scope='col'>Prix</th>
																	<th scope='col'>Qté</th>
																	{!isMobile && (
																		<th scope='col'>Remise</th>
																	)}
																	<th scope='col'>Total T.T.C</th>
																</tr>
															</thead>
															{devisLines?.length > 0 && (
																<tbody>
																	{devisLines.map((line) => {
																		return (
																			<tr>
																				<td>
																					<div>
																						{
																							line?.product
																						}
																					</div>
																				</td>
																				{!isMobile && (
																					<td>
																						<span
																							className={
																								!!line?.name
																									? 'devis-desc'
																									: undefined
																							}>
																							{
																								line?.name
																							}
																						</span>
																					</td>
																				)}
																				<td>
																					<span>
																						{line?.price_unit?.toLocaleString(
																							'fr-FR',
																							{
																								style: 'currency',
																								currency:
																									'EUR',
																							},
																						)}
																					</span>
																				</td>
																				<td>
																					<span>
																						{
																							line?.product_uom_qty
																						}
																					</span>
																				</td>
																				{!isMobile && (
																					<td>
																						<span>
																							{parseFloat(
																								line?.discount,
																							).toFixed(
																								2,
																							)}{' '}
																							%
																						</span>
																					</td>
																				)}

																				<td>
																					<span>
																						{
																							// @ts-ignore
																							line?.price_subtotal?.toLocaleString(
																								'fr-FR',
																								{
																									style: 'currency',
																									currency:
																										'EUR',
																								},
																							)
																						}
																					</span>
																				</td>
																			</tr>
																		);
																	})}
																</tbody>
															)}
														</table>
													</CardBody>
													<CardFooter
														borderColor={'light'}
														borderSize={2}>
														<CardFooterLeft className='d-md-flex d-sm-block'>
															<div className='flex-grow-1 ms-3 mb-3 d-flex col-auto'>
																<div className='text-primary fw-bold'>
																	Total H.T:{' '}
																</div>
																<div className='ms-2 fw-bold fs-6 mb-0'>
																	{devis?.amount_untaxed?.toLocaleString(
																		'fr-FR',
																		{
																			style: 'currency',
																			currency: 'EUR',
																		},
																	)}
																</div>
															</div>
															<div className='flex-grow-1 ms-3 mb-3 d-flex col-auto'>
																<div className='text-primary fw-bold'>
																	Remise:{' '}
																</div>
																<div className='ms-2 fw-bold fs-6 mb-0'>
																	{devis?.amount_discount?.toLocaleString(
																		'fr-FR',
																		{
																			style: 'currency',
																			currency: 'EUR',
																		},
																	)}
																</div>
															</div>
															<div className='flex-grow-1 ms-3 mb-3 d-flex col-auto'>
																<div className='text-primary fw-bold'>
																	TVA:
																</div>
																<div className='ms-2 fw-bold fs-6 mb-0'>
																	{devis?.amount_tax?.toLocaleString(
																		'fr-FR',
																		{
																			style: 'currency',
																			currency: 'EUR',
																		},
																	)}
																</div>
															</div>

															<div className='flex-grow-1 ms-3 mb-3 d-flex col-auto'>
																<div className='text-primary fw-bold'>
																	Total T.T.C:
																</div>
																<div className='ms-2 fw-bold fs-6 mb-0'>
																	{devis?.amount_total?.toLocaleString(
																		'fr-FR',
																		{
																			style: 'currency',
																			currency: 'EUR',
																		},
																	)}
																</div>
															</div>
														</CardFooterLeft>
													</CardFooter>
												</Card>
												{/* <div className='mx-2 row d-flex justify-content-end'>
													<Card shadow='sm' className='col-lg-4'>
														<CardBody>
															<div className='flex-grow-1 ms-3 mb-3 d-flex'>
																<div className='text-muted'>
																	Total H.T{' '}
																</div>
																<div className='ms-3 fw-bold fs-6 mb-0'>
																	{devis?.amount_untaxed?.toLocaleString(
																		'fr-FR',
																		{
																			style: 'currency',
																			currency: 'EUR',
																		},
																	)}
																</div>
															</div>
															<div className='flex-grow-1 ms-3 mb-3 d-flex'>
																<div className='text-muted'>
																	Remise{' '}
																</div>
																<div className='ms-3 fw-bold fs-6 mb-0'>
																	{devis?.amount_discount?.toLocaleString(
																		'fr-FR',
																		{
																			style: 'currency',
																			currency: 'EUR',
																		},
																	)}
																</div>
															</div>
															<div className='flex-grow-1 ms-3 mb-3 d-flex'>
																<div className='text-muted'>
																	TVA
																</div>
																<div className='ms-3 fw-bold fs-6 mb-0'>
																	{devis?.amount_tax?.toLocaleString(
																		'fr-FR',
																		{
																			style: 'currency',
																			currency: 'EUR',
																		},
																	)}
																</div>
															</div>

															<div className='flex-grow-1 ms-3 mb-3 d-flex'>
																<div className='text-muted me-3'>
																	Total T.T.C
																</div>
																<div className='ms-3 fw-bold fs-6 mb-0'>
																	{devis?.amount_total?.toLocaleString(
																		'fr-FR',
																		{
																			style: 'currency',
																			currency: 'EUR',
																		},
																	)}
																</div>
															</div>
														</CardBody>
														
													</Card>
												</div> */}
											</div>
										</div>
									</div>
									{!!openSignature && (
										<SignaturePopup
											setOpenSignature={setOpenSignature}
											devis={devis}
											token={token}
											loadData={loadData}
											setSignature={setSignature}
										/>
									)}
								</>
							) : (
								<div className='row g-16'>
									<div className='row d-flex align-items-center h-100'>
										<div className='col-12 d-flex align-items-baseline justify-content-center'>
											<Card className='bg-transparent' shadow='none'>
												<CardBody>
													<div className='row scroll-margin justify-content-center'>
														<div className='col-md-6'>
															<Card stretch>
																<CardBody>
																	<div className='row pt-5 g-4 text-center'>
																		<div className='col-12'>
																			<img
																				srcSet={Droit}
																				src={Droit}
																				alt='Droit'
																				width={'100%'}
																			/>
																		</div>
																	</div>
																</CardBody>
															</Card>
														</div>
														<div className='col-md-5'>
															<Card stretch>
																<CardBody className='d-flex'>
																	<div className='row pt-5 g-4 text-center'>
																		<div className='col-12'>
																			<Icon
																				icon='Info'
																				size='7x'
																				color='info'
																			/>
																		</div>
																		<div className='col-12'>
																			<h5 style={{ lineHeight: 2 }}>
																				Bonjour.
																				Malheureusement,
																				vous n’avez pas le
																				droit de valider ce
																				devis. Si vous avez
																				besoin d'aide ou de
																				renseignement,
																				n'hésitez pas à nous
																				contacter.
																			</h5>
																		</div>
																		<div className='row justify-content-center g-1'>
																			<div className='col-auto'>
																				<Button
																					tag='a'
																					href='mailto:contact@store2000.fr'
																					icon='email'
																					color='info'
																					isLight
																					className='w-100 py-3'
																					size='lg'>
																					Contactez-nous
																				</Button>
																			</div>
																			<div className='col-auto'>
																				<Button
																					tag='a'
																					href='tel:09 72 10 17 11'
																					icon='phone'
																					color='info'
																					isLight
																					className='w-100 py-3'
																					size='lg'>
																					09 72 10 17 11
																				</Button>
																			</div>
																		</div>
																	</div>
																</CardBody>
															</Card>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>
									</div>
									{/* 
									<div
										className='col-12 m-16 d-flex text-center justify-content-center'
										style={{ fontSize: 'calc(1rem + 1vw)' }}>
										<p>
											Bonjour. Malheureusement, vous n’avez pas le droit de
											valider ce devis. Si vous avez besoin d'aide ou de
											renseignement, n'hésitez pas à nous contacter.
										</p>
									</div>
									<div className='col-12 d-flex align-items-baseline justify-content-center'>
										<img
											srcSet={Droit}
											src={Droit}
											alt='Droit'
											style={{ height: '50vh' }}
										/>
									</div>
									<div className='col-12 g-4 d-flex align-items-baseline justify-content-center'>
										<div className='d-flex align-items-center'>
											<div className='flex-shrink-0'>
												<Icon icon='Email' size='xl' color='primary' />
											</div>
											<div className='flex-grow-1 ms-3'>
												<div className='fw-bold fs-6 mb-0'>
													<a href='mailto:contact@store2000.fr'>
														contact@store2000.fr
													</a>
												</div>
											</div>
										</div>
										<div className='d-flex align-items-center m-4'>
											<div className='flex-shrink-0'>
												<Icon icon='LocalPhone' size='xl' color='primary' />
											</div>
											<div className='flex-grow-13'>
												<div className='fw-bold fs-6 '>
													<a href='tel:09 72 10 17 11'>09 72 10 17 11</a>
												</div>
											</div>
										</div>
									</div> */}
								</div>
							)}
						</>
					)}
				</>
			</Page>
		</PageWrapper>
	);
}
