import React, { useContext, useEffect, useRef, useState } from 'react';
import StartCallingPopup from './StartCallingPopup';
import Spinner from '../bootstrap/Spinner';
import AuthContext from '../../contexts/authContext';
import { searchOneCall, updateCall } from '../../reminderApi/Consumptions';
import { getUserLines } from '../../reminderApi/Lines';
import { searchClients, updateClient } from '../../reminderApi/Clients';
import Icon from '../icon/Icon';
import showNotification, { removeNotification } from '../extras/ringingNotif';
import WebSocketSession from '../../reminderApi/WebSocketSession';
import moment from 'moment';

export default function OvhTelephonyEvents() {
	const [callingModals, setCallingModals] = useState([]);
	const { userData } = useContext(AuthContext);
	const [lineNumbers, setlineNumbers] = useState([]);

	const getCall = (event) => {
		let attempts = 0;
		const maxAttempts = 50;
		const delay = 60000;
		const search = () => {
			const existingCalls = JSON.parse(localStorage.getItem('calls') || '{}');
			const savedCall = existingCalls[event?.data.CallId];
			attempts++;
			savedCall?.date &&
				userData?.tokenJava &&
				searchOneCall(
					userData?.tokenJava,
					savedCall?.date,
					event?.data.Calling == event.ressource
						? event.data.Called
						: event?.data.Calling,
					event?.ressource,
					(result) => {
						if (result.length) {
							if (!savedCall?.saved) {
								savedCall.saved = true;
								savedCall.callId = result[0]?.id;
								savedCall.clientId = result[0]?.source;
								existingCalls[event?.data.CallId] = savedCall;
								localStorage.setItem('calls', JSON.stringify(existingCalls));
							} else {
								userData?.tokenJava &&
									updateClient(userData?.tokenJava, result[0]?.source, {
										displayName: savedCall.data.displayName,
									});
								userData?.tokenJava &&
									updateCall(
										userData?.tokenJava,
										result[0]?.id,
										{
											comment: savedCall.data.comment,
											etat: savedCall.data.etat,
										},
										(result) => {
											delete existingCalls[event?.data.CallId];
											localStorage.setItem(
												'calls',
												JSON.stringify(existingCalls),
											);
										},
									);
							}
						} else if (attempts < maxAttempts) {
							console.log('Call not found. Retrying in ' + delay + 'ms...');
							setTimeout(search, delay);
						} else {
							console.log('Max attempts reached. Call not found.');
						}
					},
				);
		};
		search();
	};

	const getLines = () => {
		userData?.tokenJava &&
			getUserLines(userData?.tokenJava, userData?.id, (result) => {
				const lines = lineNumbers;
				result.map((item) => lines.push(item?.lineNumber));
				setlineNumbers(lines);
			});
	};

	const eventHandler = (event) => {
		if (lineNumbers.includes(event?.ressource)) {
			if (event.data.Calling == '0033185420091') {
				if (event.event === 'start_ringing') {
					userData?.tokenJava &&
						searchClients(
							userData?.tokenJava,
							[
								['phone', '=', event.data.Called],
								'OR',
								['mobile', '=', event.data.Called],
							],
							(result) => {
								showNotification(
									event?.data.CallId,
									'Appel entrant',
									<div className='row d-flex align-items-center'>
										<div className='col-auto'>
											<Icon
												icon={'RingVolume'}
												size={'2x'}
												className='animate__animated animate__heartBeat animate__infinite animate__slower'
											/>{' '}
										</div>
										<div className='col-auto h5'>
											<div>{result[0]?.displayName}</div>
											{event.data.Called.replace(/^00/, '').replace(
												/(\d{2})(\d)(\d{2})(\d{2})(\d{2})(\d{2})/,
												'+$1 $2 $3 $4 $5$6',
											)}
										</div>
									</div>,
									'danger',
									'true',
								);
							},
						);
				}
				if (event.event === 'start_calling') {
					removeNotification(event.data?.CallId);
					setCallingModals((prev) => [...prev, event]);

					const existingCalls = JSON.parse(localStorage.getItem('calls') || '{}');
					existingCalls[event?.data.CallId] = {
						date: moment(`${event?.data.Datetime}`)
							.subtract(2, 'hours')
							.format('YYYY-MM-DD HH:mm:ss'),
						saved: false,
						data: '',
						clientId: '',
						callId: '',
					};
					localStorage.setItem('calls', JSON.stringify(existingCalls));
				}
			}
			if (event.data.Calling != event.ressource) {
				if (event.event === 'start_ringing') {
					userData?.tokenJava &&
						searchClients(
							userData?.tokenJava,
							[
								['phone', '=', event.data.Calling],
								'OR',
								['mobile', '=', event.data.Calling],
							],
							(result) => {
								showNotification(
									event?.data.CallId,
									'Appel entrant',
									<div className='row d-flex align-items-center'>
										<div className='col-auto'>
											<Icon
												icon={'RingVolume'}
												size={'2x'}
												className='animate__animated animate__heartBeat animate__infinite animate__slower'
											/>
										</div>
										<div className='col-auto h5'>
											<div>{result[0]?.displayName}</div>
											{event.data.Calling.replace(/^00/, '').replace(
												/(\d{2})(\d)(\d{2})(\d{2})(\d{2})(\d{2})/,
												'+$1 $2 $3 $4 $5$6',
											)}
										</div>
									</div>,
									'danger',
									'true',
								);
							},
						);
				}
				if (event.event === 'start_calling') {
					removeNotification(event.data?.CallId);
					setCallingModals((prev) => [...prev, event]);

					const existingCalls = JSON.parse(localStorage.getItem('calls') || '{}');
					existingCalls[event?.data.CallId] = {
						date: moment(`${event?.data.Datetime}`)
							.subtract(2, 'hours')
							.format('YYYY-MM-DD HH:mm:ss'),
						saved: false,
						data: '',
						clientId: '',
						callId: '',
					};
					localStorage.setItem('calls', JSON.stringify(existingCalls));
				}
			}
			if (event.event === 'end_ringing') {
				console.log('end ringing : ' + event.ressource + event.data.Datetime);
				removeNotification(event.data?.CallId);
			}

			if (event.event === 'end_calling') {
				console.log('end calling : ' + event.data.Calling);
				removeNotification(event.data?.CallId);
				setTimeout(() => {
					console.log('Delayed for 1 minute.');
					getCall(event);
				}, '60000');
			}
		}
	};

	useEffect(() => {
		WebSocketSession((result) => {
			const WEB_SOCKET_URL = `${process.env.REACT_APP_REMINDER_WEB_SOCKET_URL}/${result}/events/websocket`;
			const ws = new WebSocket(WEB_SOCKET_URL);

			if (userData?.role?.toLowerCase() == 'gestionnaire') {
				getLines();
				console.log('WebSocket listening');
				ws.addEventListener('message', (res) => {
					eventHandler(JSON.parse(res.data));
				});
			} else {
				setlineNumbers([]);
				ws.close();
				console.log('WebSocket disconnected');
			}
		});
	}, [userData?.role]);

	return (
		<>
			{callingModals.map((item) => (
				<StartCallingPopup
					key={item?.data.CallId}
					event={item}
					onClose={() => {
						setCallingModals((prevModals) => {
							return prevModals.filter(
								(modal) => modal?.data?.CallId !== item?.data?.CallId,
							);
						});
					}}
				/>
			))}
		</>
	);
}
