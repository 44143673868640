const services = [
	{ id: 1, name: 'Volet roulant' },
	{ id: 2, name: 'Rideau métallique' },
];
export default services;

export const matieres = [
	{ id: 1, name: 'Alu', serviceId: [1] },
	{ id: 2, name: 'PVC', serviceId: [1] },
	{ id: 3, name: 'Bois', serviceId: [1] },
];
export const manoeuvres = [
	{ id: 1, name: 'Manuel', serviceId: [1, 2] },
	{ id: 2, name: 'Motorisé', serviceId: [1, 2] },
];
export const manoeuvres_manuel = [
	{ id: 1, name: 'Manivelle', manoeuvreId: [1], serviceId: [1] },
	{ id: 2, name: 'Sangle', manoeuvreId: [1], serviceId: [1] },
];
export const manoeuvres_motorise = [
	{ id: 1, name: 'Radio', manoeuvreId: [2], serviceId: [1] },
	{ id: 2, name: 'Filaire', manoeuvreId: [2], serviceId: [1] },
];

export const coffres = [
	{ id: 1, name: 'Alu', serviceId: [1] },
	{ id: 2, name: 'PVC', serviceId: [1] },
	{ id: 3, name: 'Bois', serviceId: [1] },
];
export const lames = [
	{ id: 1, name: 'Lames pleines', serviceId: [2] },
	{ id: 2, name: 'Lames micro perforées', serviceId: [2] },
	{ id: 3, name: 'Lames Cobra', serviceId: [2] },
];
export const enroulements = [
	{ id: 1, name: 'Intérieur', serviceId: [2] },
	{ id: 2, name: 'Extérieur', serviceId: [2] },
];

export const problemeList = [
	{
		id: 1,
		name: 'Volet roulant bloqué en position fermée',
		checked: false,
		serviceId: [1],
	},
	{
		id: 2,
		name: 'Volet roulant bloqué en position ouverte',
		checked: false,
		serviceId: [1],
	},
	{
		id: 3,
		name: 'Volet roulant bloqué à mi-hauteur',
		checked: false,
		serviceId: [1],
	},
	{
		id: 4,
		name: 'Volet roulant bloqué à trois-quarts fermé',
		checked: false,
		serviceId: [1],
	},
	{
		id: 5,
		name: 'Tablier déraillé (sorti de ses coulisses)',
		checked: false,
		serviceId: [1],
	},
	{
		id: 6,
		name: 'Moteur ne fait pas de bruit ',
		checked: false,
		serviceId: [1, 2],
		manoeuvreId: [2],
	},
	{
		id: 7,
		name: "Moteur provoquant des dysfonctionnements électriques",
		checked: false,
		serviceId: [1, 2],
		manoeuvreId: [2],
	},
	{
		id: 8,
		name: 'Télécommande ne fonctionne pas',
		checked: false,
		serviceId: [1],
		manoeuvreId: [2],
	},
	{
		id: 9,
		name: 'Manivelle coincée',
		checked: false,
		serviceId: [1, 2],
		manoeuvreId: [1],
		manoeuvreManuelId: [1],
	},
	{
		id: 10,
		name: 'Manivelle tournant à vide',
		checked: false,
		serviceId: [1, 2],
		manoeuvreId: [1],
		manoeuvreManuelId: [1],
	},
	{
		id: 11,
		name: 'Manivelle cassée',
		checked: false,
		serviceId: [1, 2],
		manoeuvreId: [1],
		manoeuvreManuelId: [1],
	},
	{
		id: 12,
		name: 'Sangle bloquée',
		checked: false,
		serviceId: [1],
		manoeuvreId: [1],
		manoeuvreManuelId: [2],
	},
	{
		id: 13,
		name: 'Sangle bloquée',
		checked: false,
		serviceId: [1],
		coffreId: [1, 2, 3],
	},
	{
		id: 14,
		name: 'Rideau métallique bloqué en position fermée',
		checked: false,
		serviceId: [2],
	},
	{
		id: 15,
		name: 'Rideau métallique bloqué en position ouverte',
		checked: false,
		serviceId: [2],
	},
	{
		id: 16,
		name: 'Rideau métallique bloqué à mi-hauteur',
		checked: false,
		serviceId: [2],
	},
	{
		id: 17,
		name: 'Rideau métallique bloqué aux trois-quarts fermé',
		checked: false,
		serviceId: [2],
	},
	{
		id: 18,
		name: 'Axe endommagé/cassé',
		checked: false,
		serviceId: [2],
	},
	{
		id: 19,
		name: 'Ressort endommagé/cassé',
		checked: false,
		serviceId: [2],
	},

	{
		id: 19,
		name: 'Motorisation volet roulant',
		checked: false,
		serviceId: [1],
	},
	{
		id: 20,
		name: 'Moteur bruyant mais le volet ne remonte ou ne descend pas',
		checked: false,
		serviceId: [1],
	},
	{
		id: 21,
		name: 'Bruits provenant du coffre du volet roulant',
		checked: false,
		serviceId: [1],
	},
	{
		id: 22,
		name: 'Moteur tournant à vide',
		checked: false,
		serviceId: [1],
	},
	{
		id: 23,
		name: 'Tablier désaxé (sorti de ses coulisses)',
		checked: false,
		serviceId: [2],
	},
	{
		id: 24,
		name: 'Moteur tourne sans actionner le mécanisme',
		checked: false,
		serviceId: [2],
	},
];

export const aFaireList = [
	{
		id: 1,
		name: "Remplacement d'un moteur filaire",
		price: 'entre 280 et 380 €',
		checked: false,
		serviceId: [1],
	},
	{
		id: 2,
		name: "Remplacement d'un moteur radio",
		price: 'entre 360 et 500 €',
		checked: false,
		serviceId: [1],
	},
	{
		id: 3,
		name: "Remplacement d'un moteur solaire",
		price: 'entre 480 et 800 €',
		checked: false,
		serviceId: [1],
	},
	{ id: 4, name: "Remplacement d'un tablier", price: 'sur devis', checked: false, serviceId: [1] },
	{
		id: 5,
		name: 'Remplacement volet roulant complet Radio Tradi',
		price: 'sur devis',
		checked: false,
		serviceId: [1],
	},
	{
		id: 6,
		name: 'Remplacement volet roulant complet Radio Réno',
		price: 'sur devis',
		checked: false,
		serviceId: [1],
	},
	{
		id: 7,
		name: 'Installation volet roulant complet motorisé Tradi',
		price: 'sur devis',
		checked: false,
		serviceId: [1],
	},
	{
		id: 8,
		name: 'Installation volet roulant complet filaire Tradi',
		price: 'sur devis',
		checked: false,
		serviceId: [1],
	},
	{
		id: 9,
		name: 'Installation volet roulant complet Radio Réno',
		price: 'sur devis',
		checked: false,
		serviceId: [1],
	},
	{
		id: 10,
		name: 'Installation Volet roulant Radio Réno',
		price: 'sur devis',
		checked: false,
		serviceId: [1],
	},
	{
		id: 11,
		name: 'Installation volet roulant complet Radio Réno',
		price: 'sur devis',
		checked: false,
		serviceId: [1],
	},
	{
		id: 12,
		name: 'Installation volet roulant Solaire',
		price: 'sur devis',
		checked: false,
		serviceId: [1],
	},
	{ id: 13, name: "Remplacement d'une manivelle", price: '180 €', checked: false, serviceId: [1] },
	{ id: 14, name: "Remplacement d'une sangle", price: '180 €', checked: false, serviceId: [1] },
	{
		id: 15,
		name: "Remplacement d'un mécanisme manuel Réno",
		price: '220 €',
		checked: false,
		serviceId: [1],
	},
	{
		id: 16,
		name: "Remplacement d'un mécanisme manuel Tradi",
		price: '280 €',
		checked: false,
		serviceId: [1],
	},
	{
		id: 17,
		name: "Remplacement d'une télécommande",
		price: 'sur devis',
		checked: false,
		serviceId: [1],
	},
	{
		id: 18,
		name: 'Forfait remplacement des agrafes',
		price: '235 €',
		checked: false,
		serviceId: [1],
	},
	{
		id: 19,
		name: "Création d'un volet roulant complet Radio",
		price: 'sur devis',
		checked: false,
		serviceId: [1],
	},
	{
		id: 20,
		name: "Création d'un volet roulant complet Manuel",
		price: 'sur devis',
		checked: false,
		serviceId: [1],
	},
	{ id: 21, name: "Déblocage d'un volet roulant", price: '150 €', checked: false, serviceId: [1] },
	{
		id: 22,
		name: "Installation d'un moteur",
		price: 'entre 750 et 1250 €',
		checked: false,
		serviceId: [2],
	},
	{
		id: 23,
		name: "Remplacement d'un moteur",
		price: 'entre 750 et 1250 €',
		checked: false,
		serviceId: [2],
	},
	{
		id: 24,
		name: "Installation d'un axe ",
		price: 'entre 900 et 1800 €',
		checked: false,
		serviceId: [2],
	},
	{
		id: 25,
		name: "Remplacement d'un tablier",
		price: 'sur devis',
		checked: false,
		serviceId: [2],
	},
	{
		id: 26,
		name: "Remplacement d'un axe ",
		price: 'entre 900 et 1800 €',
		checked: false,
		serviceId: [2],
	},
	{ id: 27, name: 'Déblocage', price: '280 €', checked: false, serviceId: [2] },
	{
		id: 28,
		name: 'Remplacement rideau métallique ',
		price: 'sur devis',
		checked: false,
		serviceId: [2],
	},
	{
		id: 29,
		name: 'Installation rideau métallique ',
		price: 'sur devis',
		checked: false,
		serviceId: [2],
	},
	{
		id: 30,
		name: "Remplacement d'une manivelle",
		price: 'à partir de 180 €',
		checked: false,
		serviceId: [2],
	},
	{
		id: 31,
		name: 'Forfait remplacement des agrafes',
		price: 'à partir de 220 €',
		checked: false,
		serviceId: [2],
	},
	{
		id: 32,
		name: "Réglage de fins de course",
		price: '150 €',
		checked: false,
		serviceId: [1],
	},
	{
		id: 33,
		name: 'Création rideau métallique',
		price: 'sur devis',
		checked: false,
		serviceId: [2],
	},
];
