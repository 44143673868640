import React, { useContext, useEffect, useRef, useState } from 'react'
import FormGroup from '../../bootstrap/forms/FormGroup';
import Icon from '../../icon/Icon';
import Input from '../../bootstrap/forms/Input';
import { useFormik } from 'formik';
import useOutsideClick from '../../customComponents/SearchAutoComplete/useOutsideClick';
import AuthContext from '../../../contexts/authContext';
import { searchClientPartner } from '../../../odooApi/clientPartner';
import { capitalizeWords } from '../../../common/function/functionsUtils';
import Spinner from '../../bootstrap/Spinner';
import Button from '../../bootstrap/Button';
import SearchClient from '../../Clients/SearchClient';

export default function SearchBoxClient({ setSelectedClient, selectedClient, handelSelect }) {
    const { userData } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [clientIsLoading, setClientIsLoading] = useState(false);
    const [clientData, setClientData] = useState({});
    const [filterResult, setfilterResult] = useState({});
    const inputRef = useRef(null);
    const [clientValues, setClientValues] = useState({});
    const [searchItem, setSearchItem] = useState('');
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [openListItems, setOpenListItems] = useState(false);
    const [updateClientModal, setUpdateClientModal] = useState(false)
    const [newClient, setNewClient] = useState(false)

    const handleClickOutside = () => {
        setOpenList(false);
    };
    const ref = useOutsideClick(handleClickOutside);


    const formik = useFormik({
        initialValues: {
            name: selectedClient?.name || '',

        },

    });
    // const handleChangeClient = (e, signal) => {
    //     formik.handleChange(e);
    //     setOpenList(true);
    //     if (e == '') {
    //         setfilterResult([]);
    //         formik?.setFieldValue('fullName', '');
    //         formik?.setFieldValue('mobile', '');
    //         formik.setFieldValue('adrsFact', '');
    //         formik.setFieldValue('phone', '');
    //         formik.setFieldValue('email', '');
    //         formik?.setFieldValue('x_code', '');
    //         formik?.setFieldValue('adrLivraison', '');
    //         setClientData({});
    //     } else {
    //         setClientIsLoading(true);
    //         searchClientPartner(
    //             userData?.password,
    //             [
    //                 '|',
    //                 ['name', 'like', e],
    //                 '|',
    //                 ['name', 'like', capitalizeWords(e)],
    //                 '|',
    //                 ['name', 'like', e.charAt(0).toUpperCase() + e.substring(1).toLowerCase()],
    //                 '|',
    //                 ['name', 'like', e.toUpperCase()],
    //                 '|',
    //                 ['email', 'like', e],
    //                 '|',
    //                 ['mobile', 'like', e],
    //                 ['phone', 'like', e],
    //             ],
    //             ({ elements }) => {
    //                 if (!signal.aborted) { // Vérifier si la requête n'a pas été annulée
    //                     setfilterResult(elements);
    //                     setClientIsLoading(false);
    //                 }
    //             },
    //             (error) => {
    //                 !(error?.code == 'ERR_CANCELED') && setError(error);
    //                 setClientIsLoading(false);
    //             },
    //             0,
    //             0,
    //             signal,
    //         );

    //     }

    // };

    // useEffect(() => {

    //     const abortController = new AbortController();
    //     const signal = abortController.signal;
    //     handleChangeClient(formik.values.name, signal);

    //     return () => {
    //         abortController.abort();
    //     };




    // }, [formik.values.name]);


    const handelChange = (e) => {
        formik?.setFieldValue('name', e?.target?.value);
        //setSearchItem(e.target.value);
        setOpenListItems(true);
    };


    const handelCreateClient = () => {
        setSelectedClient({});
        setUpdateClientModal(true);
        setNewClient(true)
    }

    const handelSelectItem = (item) => {
        formik?.setFieldValue('name', item.name);
        setfilterResult([]);
        setSelectedClient(item);
        setSearchIsLoading(false)
        setOpenListItems(false)


    };

    useEffect(() => {
        // Create an AbortController instance
        const abortController = new AbortController();
        const signal = abortController.signal;

        if (formik?.values?.name === '') {
            setfilterResult([]);
            setSearchIsLoading(false);
        } else {
            if (userData && userData?.id && userData?.password) {
                setSearchIsLoading(true);
                searchClientPartner(
                    userData?.password,
                    [
                        '|',
                        ['name', 'like', formik?.values?.name],
                        '|',
                        ['name', 'like', capitalizeWords(formik?.values?.name)],
                        '|',
                        ['name', 'like', formik?.values?.name.charAt(0).toUpperCase() + formik?.values?.name.substring(1).toLowerCase()],
                        '|',
                        ['name', 'like', formik?.values?.name.toUpperCase()],
                        '|',
                        ['email', 'like', formik?.values?.name],
                        '|',
                        ['mobile', 'like', formik?.values?.name],
                        ['phone', 'like', formik?.values?.name],
                    ],
                    ({ elements }) => {
                        setfilterResult(elements);
                        setSearchIsLoading(false);

                    },
                    () => {
                        setSearchIsLoading(true);
                    },
                    0,
                    0,
                    signal,
                );
            }
        }

        return () => {
            abortController.abort();
        };
    }, [formik?.values?.name]);




    console.log("selected client", selectedClient)
    return (
        <>

            <div ref={ref} className='row'>
                <FormGroup className='autocomplete col-lg-8'>
                    <div className='d-flex align-items-center bg-l10-brand-two rounded-2'>
                        <Input
                            ref={inputRef}
                            onChange={handelChange}
                            //onFocus={handleChangeClient}
                            value={formik?.values?.name}
                            placeholder='Rechercher...'
                            type='text'
                            autoComplete='off'
                            className='border-0 bg-transparent'
                        />

                    </div>


                    {openListItems &&
                        (searchIsLoading ? (
                            <div
                                id='myInputautocomplete-list'
                                className='autocomplete-items col-12 position-absolute'>
                                <div className='body'>
                                    <div>
                                        <Spinner isSmall isGrow /> <Spinner isSmall isGrow />{' '}
                                        <Spinner isSmall isGrow />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                id='myInputautocomplete-list'
                                className='autocomplete-items position-absolute'>
                                <div className='body'>
                                    {
                                        !!filterResult?.length && (
                                            filterResult?.map((item) => (

                                                <div
                                                    key={item?.id}
                                                    onClick={() => {
                                                        handelSelectItem(item);
                                                    }}>
                                                    <Icon
                                                        color={'primary'}
                                                        isLight
                                                        icon={'Person'}
                                                        size='lg'
                                                    />
                                                    {item?.name}{' '}
                                                    <Icon
                                                        color={'primary'}
                                                        isLight
                                                        icon={'Place'}
                                                        size='lg'
                                                    />
                                                    {item?.street +
                                                        ' ' +
                                                        item?.zip +
                                                        ' ' +
                                                        item?.city}
                                                </div>


                                            ))
                                        )}

                                    <Button className='col-12'
                                        color='dark'
                                        isLight
                                        onClick={() => { handelCreateClient(); setOpenListItems(false) }}>Crèer et modifier ...</Button>

                                </div>
                            </div>

                        ))}


                </FormGroup>

                {Object.keys(selectedClient).length > 0 && (
                    <div className='col-lg-4'>
                        <Button
                            icon='Edit'
                            isLight
                            color='primary'
                            onClick={() => { setUpdateClientModal(true) }}>
                            Modifier
                        </Button>
                    </div>
                )}

            </div>

            {updateClientModal && (
                <SearchClient
                    setCreateClientModalStatus={setUpdateClientModal}
                    handelSelect={handelSelect}
                    data={Object.keys(selectedClient).length ? selectedClient : { name: formik?.values?.name }}
                    onChange={handelChange}
                />
            )}

        </>
    )
}
