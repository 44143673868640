import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	PLANNING_API_URI = '/planning.slot',
	PV_RECEPTION_API_URI = '/pv.reception',
	LIMIT = 0;
export const getAllPlanning = (tokenUserCurent, limit, fncSuccess) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PLANNING_API_URI}${
			limit ? '?limit=' + limit : ''
		}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data.elements || []);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getAllPlanningByDate = (
	tokenUserCurent,
	start,
	end,
	fncSuccess,
	fnError = () => {},
	signal,
) => {
	var config = {
		method: 'get',
		url: `${
			BASIC_API_URL +
			process.env.REACT_APP_API_URL_FUNCTION_UTILS +
			'/planning?start=' +
			start +
			'&end=' +
			end +
			'&token=' +
			tokenUserCurent
		}`,
		// headers: {
		// 	Authorization: `Basic ${btoa(
		// 		process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
		// 	)}`,
		// 	'Content-Type': 'application/json',
		// },
		signal,
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const getSinglePlanning = (
	tokenUserCurent,
	id,
	fncSuccess,
	fnError = () => {},
	signal = null,
) => {
	console.log(id);
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PLANNING_API_URI + '/' + id}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		signal,
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const createPlanningSlot = async (
	tokenUserCurent,
	newPlanning,
	fnSucces,
	fnError = () => {},
) => {
	const data = JSON.stringify(newPlanning);
	const config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PLANNING_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			fnSucces(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const updateSlotPlanning = async (
	tokenUserCurent,
	newData,
	fnSucces,
	fnError = () => {},
) => {
	const data = JSON.stringify(newData);

	const config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PLANNING_API_URI + '/' + newData?.id}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			//console.log(JSON.stringify(response.data));
			fnSucces(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const deletePlanning = async (tokenUserCurent, idPlanning, fnSucces) => {
	const config = {
		method: 'delete',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PLANNING_API_URI + '/' + idPlanning}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			console.log(error);
		});
};

const searchPlanning = (
	tokenUserCurent,
	filters,
	fncSuccess,
	fnError = () => {},
	signal,
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});
	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PLANNING_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
		signal,
	};
	axios(config)
		.then(function (response) {
			fncSuccess(response.data);
			///console.log("getAllPlanninggetAllPlanning",response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export default searchPlanning;

export const sendRepport = (tokenUserCurent, idSlot, file, fnc) => {
	let data = new FormData();
	data.append('image_1920', file);

	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload' +
			PLANNING_API_URI +
			'/' +
			idSlot +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': file?.type,
		},
		data: data,
	};
	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			console.log(error);
		});
};

export const uploadAttachementsTechnicien = (tokenUserCurent, idSlot, attachements, fnc, fnErr) => {
	let data = new FormData();
	//data.append('image_1920', file);
	Object.keys(attachements)?.map((key) => {
		data.append('attachment_report', attachements[key]);
	});
	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_attachments' +
			PLANNING_API_URI +
			'/' +
			idSlot +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			fnErr(error);
		});
};

export const uploadAttachementsSuppTechnicien = (
	tokenUserCurent,
	idSlot,
	attachements,
	fnc,
	fnErr,
) => {
	let data = new FormData();
	//data.append('image_1920', file);
	Object.keys(attachements)?.map((key) => {
		data.append('attachment_report_supp', attachements[key]);
	});
	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_attachments' +
			PLANNING_API_URI +
			'/' +
			idSlot +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			fnErr(error);
		});
};

export const uploadAttachementsCommercial = (
	tokenUserCurent,
	idSlot,
	attachements,
	fnc,
	fnError = () => {},
) => {
	let data = new FormData();
	//data.append('image_1920', file);
	Object.keys(attachements)?.map((key) => {
		data.append('attachment', attachements[key]);
	});
	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_attachments' +
			PLANNING_API_URI +
			'/' +
			idSlot +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};
	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			fnError(error);
		});
};

export const createAttachementUrl = (tokenUserCurent, id) => {
	return BASIC_API_URL + '/api/digital/web-admin/common/' + id + '?token=' + tokenUserCurent;
};

export const sendInterventionMail = (
	tokenUserCurent,
	InterventionId,
	fnSucces,
	fnError = () => {},
) => {
	const config = {
		method: 'get',
		url: `${'/odoo' + API_URL_WEB_ADMIN + PLANNING_API_URI + '/' + InterventionId + '/send'}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};
	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};
export const sendInterventionPoseByMail = (tokenUserCurent, interventionId, relance, fnSucces) => {
	const config = {
		method: 'get',
		url: `${
			'/odoo' +
			API_URL_WEB_ADMIN +
			PLANNING_API_URI +
			'/' +
			interventionId +
			'/send?pose=' +
			relance
		}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			console.log(error);
		});
};
export const uploadSignaturePV = (tokenUserCurent, idpv, image, fnc) => {
	let data = new FormData();
	data.append('signature', image.toString());
	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			'/api/digital/web-admin/upload_binary' +
			PV_RECEPTION_API_URI +
			'/' +
			idpv +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};
	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			console.log('errorerrorerror', error);
		});
};

export const createPVReception = (tokenUserCurent, newLine, fnSucces, fnError = () => {}) => {
	const data = JSON.stringify(newLine);

	const config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PV_RECEPTION_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			//console.log(JSON.stringify(response.data));
			fnSucces(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const uploadSignaturePlanning = (tokenUserCurent, idDevis, image, fnc, fnError) => {
	let data = new FormData();
	data.append('signature', image.toString());
	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			'/api/digital/web-admin/upload_binary' +
			PLANNING_API_URI +
			'/' +
			idDevis +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};
	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			fnError(error);
		});
};

export const sendPVReceptionByMail = (tokenUserCurent, PVId, fnSucces, fnError = () => {}) => {
	const config = {
		method: 'get',
		url: `${'/odoo' + API_URL_WEB_ADMIN + PV_RECEPTION_API_URI + '/' + PVId + '/send'}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};
