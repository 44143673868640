export const prix_AxeMotoriseReno = [
	{ id: 1000, price: 327.31 },
	{ id: 1500, price: 352.99 },
	{ id: 2000, price: 364.99 },
	{ id: 2500, price: 376.99 },
	{ id: 3000, price: 388.99 },
	{ id: 3500, price: 408.55 },
	{ id: 4000, price: 420.55 },
	{ id: 4500, price: 436.63 },
	{ id: 5000, price: 448.63 },
	{ id: 5500, price: 470.63 },
	{ id: 6000, price: 482.63 },
	{ id: 6500, price: 504.68 },
	{ id: 7000, price: 516.68 },
	{ id: 7500, price: 528.68 },
	{ id: 8000, price: 540.68 },
];
export const prix_AxeMotoriseTradi = [
	{ id: 1000, price: 347.31 },
	{ id: 1500, price: 372.99 },
	{ id: 2000, price: 384.99 },
	{ id: 2500, price: 396.99 },
	{ id: 3000, price: 408.99 },
	{ id: 3500, price: 428.55 },
	{ id: 4000, price: 440.55 },
	{ id: 4500, price: 456.63 },
	{ id: 5000, price: 468.63 },
	{ id: 5500, price: 470.63 },
	{ id: 6000, price: 502.63 },
	{ id: 6500, price: 524.68 },
	{ id: 7000, price: 536.68 },
	{ id: 7500, price: 548.68 },
	{ id: 8000, price: 560.68 },
];
