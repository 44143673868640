import { useFormik } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import COLORS from '../../common/data/enumColors';
import createNotification from '../../common/function/createNotification';
import { getImageByModule } from '../../common/function/functionsUtils';
import ActivitiesContext from '../../contexts/activityContext';
import AuthContext from '../../contexts/authContext';
import { ActivityMenu } from '../../gestioneerMenu';
import {
	createActivity,
	createActivityNote,
	deleteActivityType,
	searchActivityType,
	updateActivity,
	uploadAttachementsActivity,
} from '../../odooApi/activites';
import { searchUsers } from '../../odooApi/employee';
import searchPlanning from '../../odooApi/planningSlot';
import Avatar from '../Avatar';
import InterventionPopUp from '../PlanningCalendar/Intervention/InterventionPopUp';
import Badge from '../bootstrap/Badge';
import Button from '../bootstrap/Button';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTabItem,
} from '../bootstrap/Card';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import Spinner from '../bootstrap/Spinner';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Textarea from '../bootstrap/forms/Textarea';
import SearchBox from '../customComponents/SearchAutoComplete/SearchBox';
import ErrorPopup from '../extras/ErrorPopup';
import Icon from '../icon/Icon';
import AddActivityType from './AddActivityType';
import DisplayImage from './DisplayImage';

export default function ActivityCreatePopup({ res_id = 420, setEditModalStatus, client }) {
	const [employeeData, setEmployeeData] = useState([]);
	const { userData } = useContext(AuthContext);
	const { activities, setActivities } = useContext(ActivitiesContext);
	const [categories, setCategories] = useState([]);
	const [spinner, setSpinner] = useState(false);
	const [error, setError] = useState(false);
	const [searchItem, setSearchItem] = useState('');
	const [searchIsLoading, setSearchIsLoading] = useState(false);
	const [filterResult, setfilterResult] = useState({});
	const [rappel, setRappel] = useState(false);
	const [note, setNote] = useState('');
	const [motif, setMotif] = useState();
	const [devisList, setDevisList] = useState([]);
	const [factureList, setFactureList] = useState([]);
	const [attachements, setAttachements] = useState([]);
	const [intervention, setIntervention] = useState({});
	const [openIntervention, setOpenIntervention] = useState(false);
	const { t } = useTranslation(['translation', 'menu']);
	const [selectedImage, setSelectedImage] = useState(false);
	const [popupImage, setPopupImage] = useState(false);
	const fileInputRef = useRef(null);
	const [popupType, setPopupType] = useState(false);
	const data = [
		{
			id: 'to_do',
			title: 'À faire',
			color: COLORS.PRIMARY.name,
			icon: 'TaskAlt',
		},
		{
			id: 'in_progress',
			title: 'En cours',
			color: COLORS.INFO.name,
			icon: 'DirectionsRun ',
		},

		{
			id: 'done',
			title: 'Terminée',
			color: COLORS.SUCCESS.name,
			icon: 'Verified',
		},
	];

	const ImageIcon = (fileType) => {
		const image = ['image', 'jpeg', 'jpg', 'png', 'ico'];
		const pdf = ['application', 'pdf'];
		const video = [, 'video', 'mp4', 'mov', 'mkv'];
		if (image.some((type) => fileType.includes(type))) {
			return 'InsertPhoto';
		} else if (video.some((type) => fileType.includes(type))) {
			return 'OndemandVideo';
		} else if (pdf.some((type) => fileType.includes(type))) {
			return 'PictureAsPdf';
		} else {
			return 'AttachFile';
		}
	};

	const updateActivityContext = (values) => {
		if (values?.x_users_ids?.map((i) => i?.id)?.includes(userData?.id)) {
			setActivities((prevActivities) => [
				...prevActivities.map((group) => {
					if (group.id === values.x_state) {
						return {
							...group,
							cards: [
								...group.cards,
								{
									...values,
									id: values?.id,
									date_deadline: moment(values.date_deadline).format(
										'YYYY-MM-DD',
									),
									res_id: !!values?.res_id?.id
										? values?.res_id?.id
										: values?.res_id,
									x_rappel_date:
										!!rappel &&
										moment(values.x_rappel_date).format('yyyy-MM-DD HH:mm:ss'),
									x_users_ids: values.x_users_ids,
									x_client: values?.x_client,
									x_attachment_ids: values?.x_attachment_ids,
								},
							],
						};
					}
					return group;
				}),
			]);
		}
	};

	const handleFileInputChange = (e) => {
		const newAttachments = Array.from(e?.target?.files);
		setAttachements((prevAttachments) => [...prevAttachments, ...newAttachments]);
	};

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	const createNote = (noteData) => {
		createActivityNote(userData?.password, noteData, (result) => {
			formik.setFieldValue('x_notes', [...formik.values.x_notes, result?.id]);
			setNote('');
		});
	};
	const formik = useFormik({
		initialValues: {
			x_name: '',
			x_state: 'to_do',
			summary: '',
			user_id: userData?.id,
			x_users_ids: [
				employeeData?.find((user) => user?.id == userData?.id) || {
					id: userData?.id,
					name: userData?.name,
					employee_id: userData?.employee,
				},
			],
			date_deadline: moment().format('YYYY-MM-DD'),
			create_date: moment().format('yyyy-MM-DD HH:mm:ss'),
			x_rappel_date: moment().format('yyyy-MM-DD HH:mm:ss'),
			//x_start_date: moment().format('yyyy-MM-DD HH:mm:ss'),
			create_uid: userData?.id,
			res_model_id: 907,
			res_id: res_id,
			x_notes: [],
			x_priority: 'low',
			activity_type_id: '',
			x_client: !!client?.id ? client : '',
		},
		validate: (values) => {
			const errors = {};

			// if (!values.x_name) {
			// 	errors.x_name = 'Obligatoire!';
			// }

			return errors;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		onSubmit: (values) => {
			setSpinner(true);
			createActivity(
				userData?.password,
				{
					...values,
					x_users_ids: values?.x_users_ids?.map((item) => item?.id) || [],
					x_client: values?.x_client?.id,
					res_id: !!values?.res_id?.id ? values?.res_id?.id : values?.res_id,
					//x_start_date: moment(values.x_start_date).format('yyyy-MM-DD HH:mm:ss'),
					date_deadline: moment(values.date_deadline).format('YYYY-MM-DD'),
					x_rappel_date:
						!!rappel && moment(values.x_rappel_date).format('yyyy-MM-DD HH:mm:ss'),
					activity_type_id: values?.activity_type_id?.id,
				},
				(result) => {
					if (attachements?.length > 0) {
						uploadAttachementsActivity(
							userData?.password,
							result?.id,
							attachements,
							(res) => {
								updateActivity(
									userData?.password,
									{ x_attachment_ids: res?.data?.attachment, id: result?.id },
									(r) => {
										updateActivityContext({
											...result,
											x_attachment_ids: res?.data?.attachment,
										});
										setSpinner(false);
										setEditModalStatus(false);
									},
									(error) => {
										setSpinner(false);
										setError(error);
									},
								);
							},
							(error) => {
								setSpinner(false);
								setError(error);
							},
						);
					} else {
						updateActivityContext(result);
						setSpinner(false);
						setEditModalStatus(false);
					}

					createNotification({
						recieverMembers: values?.x_users_ids?.map((item) => item?.id) || [],
						text: ` 
									Nouvelle tâche ${values?.activity_type_id?.name ? `- : ${values?.activity_type_id?.name}` : ''}
									Date : ${moment(values.date_deadline).format('D MMMM')}
									${values?.x_client?.id ? `Client : ${values?.x_client?.name}` : ''}
									${values?.res_id !== 420 ? `Intervention : ${values?.res_id?.name_seq}` : ''}
									`,
						icon: 'TaskAlt',
						color: 'primary',
						link: ActivityMenu?.path,
					});
				},
				(error) => {
					setSpinner(false);
					setError(error);
				},
			);
		},
	});
	useEffect(() => {
		let filter = [
			'&',
			[
				'function',
				'in',
				[
					// 'Technicien',
					'Chargé affaire',
					'Gestionnaire',
					'Admin',
					'comptabilite',
					//	'achat',
					'Commercial',
				],
			],
			['active', '=', true],
		];
		searchUsers(userData?.password, filter, ({ elements }) => {
			setEmployeeData(elements);
		});
		searchActivityType(
			userData?.password,
			[['active', '=', true]],
			({ elements }) => {
				setCategories(elements);
				console.log(elements);
			},
			(error) => {
				setError(error);
			},
			0,
			0,
		);
	}, []);

	const handleChange = (e) => {
		setSearchItem(e.target.value);
	};

	const handelSelectItem = (item) => {
		setfilterResult([]);
		formik.setFieldValue('res_id', item);
		formik.setFieldValue('x_client', item?.partner_id);
		setEditModalStatus(true);
		setSearchItem('');
		// searchDevis(
		// 	userData?.password,
		// 	[['ref_intervention', '=', item?.name_seq]],
		// 	(result) => {
		// 		setDevisList(result?.elements);
		// 		result?.elements?.length > 0 &&
		// 			result?.elements?.map((devis) => {
		// 				searchFactures(
		// 					userData?.password,
		// 					[['invoice_origin', '=', devis?.name]],
		// 					(result) => {
		// 						setFactureList([...factureList, ...result?.elements]);
		// 					},
		// 					(error) => {
		// 						setError(error);
		// 					},
		// 					null,
		// 				);
		// 			});
		// 	},
		// 	(error) => {
		// 		setError(error);
		// 	},
		// 	null,
		// );
	};
	useEffect(() => {
		// Create an AbortController instance
		const abortController = new AbortController();
		const signal = abortController.signal;

		if (searchItem === '') {
			setfilterResult([]);
			setSearchIsLoading(false);
		} else {
			if (userData && userData?.id && userData?.password) {
				setSearchIsLoading(true);
				searchPlanning(
					userData?.password,
					[
						'|',
						['name_seq', 'ilike', searchItem],
						'|',
						['partner_id.name', 'ilike', searchItem],
						'|',
						['partner_id.email', 'ilike', searchItem],
						'|',
						['partner_id.mobile', 'ilike', searchItem],
						['partner_id.phone', 'ilike', searchItem],
					],
					({ elements }) => {
						setfilterResult(elements);
						console.log(elements.length);
						setSearchIsLoading(false);
					},
					() => {
						setSearchIsLoading(true);
					},
					signal,
					1,
					10,
				);
			}
		}

		// Clean up the AbortController when component is unmounted or when client changes
		return () => {
			abortController.abort();
		};
	}, [searchItem]);
	const deleteImage = (key) => {
		const updatedImages = attachements.filter((item, index) => index != key);
		setAttachements(updatedImages);
	};

	return (
		<>
			<Modal setIsOpen={() => {}} isOpen={true} size='md' isScrollable classNameMd='nv-2'>
				<ModalHeader className='px-4 pt-3 pb-0' setIsOpen={setEditModalStatus}>
					<div className='w-100 text-muted text-center'>
						<ModalTitle id='project-edit'>Nouvelle tâche</ModalTitle>
					</div>
				</ModalHeader>
				<ModalBody className='px-4'>
					<Card shadow='none'>
						<CardHeader className='border-bottom  pt-0'>
							<CardLabel iconColor='success'>
								{/* <CardTitle className='fs-2'>
									<FormGroup className='col-12' id='x_name'>
										<Input
											className='bg-transparent p-0 border-0 fs-2'
											placeholder='Nom de la tâche...'
											onChange={formik.handleChange}
											value={formik.values.x_name}
										/>
									</FormGroup>
								</CardTitle> */}
								<FormGroup className='col-12 mb-2' id='x_name'>
									<Input
										className=' fs-5'
										placeholder='Nom de la tâche...'
										onChange={formik.handleChange}
										value={formik.values.x_name}
										onBlur={formik.handleBlur}
										invalidFeedback={formik.errors.x_name}
										isTouched={formik.touched.x_name}
										isValid={formik.isValid}
									/>
								</FormGroup>
								<CardSubTitle className='d-flex align-items-center mt-2'>
									<span className='me-2 mt-1 fw-semibold'>Priorité :</span>
									<Dropdown>
										<DropdownToggle>
											<Button
												shadow={'md'}
												color={
													formik?.values?.x_priority == 'low'
														? 'success'
														: formik?.values?.x_priority == 'medium'
														? 'warning'
														: 'danger'
												}
												isLight
												size={'sm'}>
												{formik?.values?.x_priority == 'low'
													? 'Faible'
													: formik?.values?.x_priority == 'medium'
													? 'Moyen'
													: 'Élevé'}
											</Button>
										</DropdownToggle>
										<DropdownMenu size={'sm'}>
											<DropdownItem>
												<Button
													onClick={() => {
														formik.setFieldValue('x_priority', 'low');
													}}>
													Faible
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													onClick={() => {
														formik.setFieldValue(
															'x_priority',
															'medium',
														);
													}}>
													Moyen
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													onClick={() => {
														formik.setFieldValue('x_priority', 'high');
													}}>
													Élevé
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</CardSubTitle>
							</CardLabel>
						</CardHeader>
						<CardBody className='px-2'>
							<div className='row mb-4 d-flex align-items-center'>
								<div className='col-3'>
									<span className='fs-6 fw-semibold'>Assignée à :</span>
								</div>
								<div className='col-9'>
									<div className='row g-2'>
										{formik?.values?.x_users_ids?.map((user) => (
											<div key={user?.id} className='col-auto'>
												<Badge
													className='px-2 py-2 rounded-1 me-2'
													color={'dark'}
													isLight>
													<Avatar
														src={getImageByModule(
															userData?.password,
															user?.employee_id,
															'hr.employee',
														)}
														srcSet={getImageByModule(
															userData?.password,
															user?.employee_id,
															'hr.employee',
														)}
														/* color={card.user.color} */
														size={20}
														userName={`${user?.name}`}
														className='cursor-pointer img-avatar me-2'
													/>
													<span>{user?.name}</span>
													<Icon
														icon='Clear'
														size={'md'}
														className='ms-2 cursor-pointer'
														onClick={() =>
															formik.setFieldValue(
																'x_users_ids',
																formik?.values?.x_users_ids?.filter(
																	(item) => item?.id != user?.id,
																),
															)
														}></Icon>
												</Badge>
											</div>
										))}
										<div className='col-auto'>
											<Dropdown direction='up'>
												<DropdownToggle hasIcon={false}>
													<Badge
														className='cursor-pointer px-2 py-2 me-2 rounded-1'
														color={'dark'}
														isLight>
														<Icon icon='Add' size={'lg'}></Icon>
													</Badge>
												</DropdownToggle>
												<DropdownMenu
													style={{
														overflow: 'scroll',
														maxHeight: '250px',
													}}>
													{employeeData.map((item) => (
														<DropdownItem>
															<Button
																key={item.id}
																color='link'
																onClick={() => {
																	!formik?.values?.x_users_ids.find(
																		(xuser) =>
																			xuser?.id == item?.id,
																	) &&
																		formik?.setFieldValue(
																			'x_users_ids',
																			[
																				...formik?.values
																					?.x_users_ids,
																				employeeData?.find(
																					(u) =>
																						u?.id ==
																						item?.id,
																				),
																			],
																		);
																}}>
																{item.name}
															</Button>
														</DropdownItem>
													))}
												</DropdownMenu>
											</Dropdown>
										</div>
									</div>
								</div>
							</div>
							{/* <div className='row mb-4 d-flex align-items-center'>
								<div className='col-3'>
									<span className='fs-6 fw-semibold'>Date de début :</span>
								</div>
								<div className='col-9'>
									<FormGroup className='col-12' id='x_start_date'>
										<Input
											type={'datetime-local'}
											onChange={formik.handleChange}
											value={moment(formik.values.x_start_date).format(
												moment.HTML5_FMT.DATETIME_LOCAL,
											)}
										/>
									</FormGroup>
								</div>
							</div> */}

							<div className='row mb-4 d-flex align-items-center'>
								<div className='col-3'>
									<span className='fs-6 fw-semibold'>Date limite :</span>
								</div>
								<div className='col-auto'>
									<FormGroup className='col-12 w-auto' id='date_deadline'>
										<Input
											type={'date'}
											onChange={formik.handleChange}
											// value={moment(formik.values.date_deadline).format(
											// 	'DD/MM/YYYY',
											// )}
											value={formik.values.date_deadline}
										/>
									</FormGroup>
								</div>
							</div>

							<div className='row mb-4 d-flex align-items-center'>
								<div className='col-3'>
									<span className='fs-6 fw-semibold'>Statut :</span>
								</div>
								<div className='col-9'>
									<Dropdown>
										<DropdownToggle>
											<Button
												shadow={'md'}
												color={
													data?.find(
														(item) => formik.values.x_state == item?.id,
													)?.color
												}
												isLight
												icon={
													data?.find(
														(item) => formik.values.x_state == item?.id,
													)?.icon
												}
												size={'sm'}>
												{
													data?.find(
														(item) => formik.values.x_state == item?.id,
													)?.title
												}
											</Button>
											{/* <Badge className='px-3 py-2' color={'primary'} isLight>
											<Icon></Icon>
											{formik.values.x_state}
										</Badge> */}
										</DropdownToggle>
										<DropdownMenu size={'sm'}>
											{data.map((group) => (
												<DropdownItem>
													<Button
														key={group.id}
														color='link'
														icon={group.icon}
														onClick={() => {
															formik.setFieldValue(
																'x_state',
																group?.id,
															);
														}}>
														{group.title}
													</Button>
												</DropdownItem>
											))}
										</DropdownMenu>
									</Dropdown>
								</div>
							</div>
							<div className='row mb-4 d-flex align-items-center'>
								<div className='col-3'>
									<span className='fs-6 fw-semibold'>Tags :</span>
								</div>
								<div className='col-9 d-flex row'>
									{!!formik.values.activity_type_id?.id ? (
										<Badge
											className='px-3 py-2 text-dark rounded-1 bg-l25-secondary w-auto me-2'
											isLight>
											<span className='fs-6 fw-semibold'>
												{formik.values.activity_type_id?.name}
											</span>

											<Icon
												icon='Clear'
												size={'md'}
												className='ms-2 cursor-pointer'
												onClick={() =>
													formik.setFieldValue('activity_type_id', '')
												}></Icon>
										</Badge>
									) : (
										<Dropdown className='w-auto'>
											<DropdownToggle hasIcon>
												{/* <Badge
														className=' bg-l25-secondary cursor-pointer px-2 py-2 me-2 rounded-1'
														color={'dark'}
														isLight>
														<Icon icon='Add' size={'lg'}></Icon>
													</Badge> */}
												<Button
													className='bg-l25-secondary text-black'
													shadow={'md'}
													//color={'secondary'}
													isLight
													size={'sm'}>
													--Aucun--
												</Button>
											</DropdownToggle>
											<DropdownMenu
												style={{ overflow: 'scroll', maxHeight: '250px' }}>
												{categories?.map((item) => (
													<DropdownItem>
														<ActivityType
															item={item}
															formik={formik}
															setCategories={setCategories}
															setError={setError}
														/>
													</DropdownItem>
												))}
											</DropdownMenu>
										</Dropdown>
									)}
									<Badge
										className='bg-l25-secondary cursor-pointer px-2 py-2 me-2 rounded-1 w-auto'
										color={'dark'}
										isLight
										onClick={() => {
											setPopupType(true);
										}}>
										<Icon icon='Add' size={'lg'}></Icon>
									</Badge>
								</div>
							</div>

							<div className='row mb-4 d-flex align-items-center'>
								<div className='col-3'>
									<span className='fs-6 fw-semibold'>Intervention :</span>
								</div>
								<div className='col-9 d-flex align-items-center'>
									{formik.values?.res_id != 420 && (
										<div className='col-auto me-2'>
											<Badge
												className='px-3 py-2 rounded-1 bg-l75-light text-dark'
												isLight>
												<Icon
													icon={'OpenInNew'}
													size={'sm'}
													className='me-2 cursor-pointer'
													onClick={() =>
														setOpenIntervention(true)
													}></Icon>
												<span
													className='fs-6 fw-semibold cursor-pointer'
													onClick={() => setOpenIntervention(true)}>
													{formik.values?.res_id?.name_seq}
												</span>

												<Icon
													icon='Clear'
													size={'md'}
													className='ms-2 cursor-pointer'
													onClick={() =>
														formik.setFieldValue('res_id', 420)
													}></Icon>
											</Badge>
										</div>
									)}
									<FormGroup className='col-auto'>
										<SearchBox
											value={searchItem}
											name='searchInput'
											onChange={handleChange}
											label='Rechercher une intervention...	'
											options={filterResult}
											loading={searchIsLoading}
											handelSelectItem={handelSelectItem}
											title={'name_seq'}
											client={'nom_client'}
										/>
									</FormGroup>
								</div>
							</div>

							{/* {intervention?.id != 72 && (
								<div className='row mb-4'>
									<div className='col-3'>
										<Label className='fs-6'>Intervention</Label>
									</div>
									<div className='col-9'>
										<Button
											color={'light'}
											// isLight
											icon={'OpenInNew'}
											size={'sm'}
											onClick={() => setOpenIntervention(true)}>
											{intervention?.name_seq}
										</Button>
									</div>
								</div>
							)} */}

							<Card hasTab shadow={'none'}>
								<CardTabItem id='description' title='Description' className='pb-0'>
									<FormGroup id='summary'>
										<Textarea
											onChange={formik.handleChange}
											value={formik.values.summary}
										/>
									</FormGroup>
								</CardTabItem>
								<CardTabItem id='description' title='Documents'>
									<div className='row g-2'>
										{attachements?.length <= 0 ? (
											<div className='col-12'>
												<div className='py-5 flex-column d-flex justify-content-center align-items-center  rounded-1 w-100 bg-l10-dark border-dashed'>
													<Icon
														icon='CloudDownload'
														size={'2x'}
														onClick={handleButtonClick}
														className='cursor-pointer'></Icon>
													<span>Déposer vos fichiers</span>
												</div>
												<Input
													type='file'
													multiple
													name='attachments'
													onChange={handleFileInputChange}
													style={{ display: 'none' }}
													ref={fileInputRef}
												/>
											</div>
										) : (
											<>
												{!!attachements?.length &&
													attachements?.map((file, index) => {
														return (
															<div key={index} className='col-auto'>
																<Badge
																	className='px-3 py-2 rounded-1'
																	color={'dark'}
																	isLight>
																	<Icon
																		icon={ImageIcon(file?.type)}
																		size={'lg'}
																		className='me-2 cursor-pointer'></Icon>
																	<span
																		className='fs-6 fw-semibold cursor-pointer'
																		onClick={() => {
																			setSelectedImage(file);
																			setPopupImage(true);
																		}}>
																		{file?.name}
																	</span>

																	<Icon
																		icon='Clear'
																		size={'md'}
																		className='ms-2 cursor-pointer'
																		onClick={() =>
																			deleteImage(index)
																		}></Icon>
																</Badge>
															</div>
														);
													})}
												<div className='col-auto'>
													<Badge
														className='cursor-pointer px-2 py-2 rounded-1'
														color={'dark'}
														isLight
														onClick={handleButtonClick}>
														<Icon icon='Add' size={'lg'}></Icon>
													</Badge>
													<Input
														type='file'
														multiple
														name='attachments'
														onChange={handleFileInputChange}
														style={{ display: 'none' }}
														ref={fileInputRef}
													/>
												</div>
											</>
										)}
									</div>
								</CardTabItem>
							</Card>
						</CardBody>
					</Card>
					<div className='row'>
						<div className='col-md-4'>
							{/* <FormGroup className='col-12' id='x_rappel_date'>
									<div className='d-flex'>
										<Checks
											className='me-0'
											type='checkbox'
											name='selectedBank'
											id='rappel_id'
											value={rappel}
											onChange={() => setRappel(!rappel)}
											checked={!!rappel}
										/>
										<Label>Planifier un rappel</Label>
									</div>

									{!!rappel && (
										<Input
											name='x_rappel_date'
											className='mt-2'
											type={'datetime-local'}
											onChange={formik.handleChange}
											value={moment(formik.values.x_rappel_date).format(
												moment.HTML5_FMT.DATETIME_LOCAL,
											)}
										/>
									)}
								</FormGroup> */}
						</div>
					</div>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button
						color='primary'
						className='w-100'
						type='submit'
						onClick={formik.handleSubmit}
						isDisable={spinner}>
						{spinner && <Spinner isSmall inButton />}
						{spinner ? 'En cours' : 'Sauvegarder'}
					</Button>
				</ModalFooter>
			</Modal>
			{!!error && <ErrorPopup error={error} setOpen={setError} />}
			{!!openIntervention && (
				<InterventionPopUp
					setEditModalStatus={setOpenIntervention}
					event={formik?.values?.res_id}
					getInterventions={() => {}}
				/>
			)}
			{!!popupImage && <DisplayImage setSelectedImage={setPopupImage} file={selectedImage} />}

			{!!popupType && (
				<AddActivityType
					formikParent={formik}
					setCategories={setCategories}
					setisOpen={setPopupType}
				/>
			)}
		</>
	);
}

const ActivityType = ({ item, formik, setCategories, setError }) => {
	const { userData } = useContext(AuthContext);
	const [tagLoading, setTagLoading] = useState(false);
	const handleDelete = (id) => {
		setTagLoading(true);
		deleteActivityType(
			userData?.password,
			id,
			() => {
				setCategories((prev) => prev?.filter((item) => item?.id !== id));
				setTagLoading(false);
			},
			(error) => {
				setTagLoading(false);
				setError(error);
			},
		);
	};
	return (
		<div className='row d-flex align-items-center'>
			{tagLoading ? (
				<Spinner isSmall inButton className='col-2' />
			) : (
				<Button
					key={item.id}
					className='col-2'
					icon='Delete'
					onClick={() => {
						handleDelete(item?.id);
					}}
				/>
			)}
			<Button key={item.id} className='col-8'>
				<span
					onClick={() => {
						formik?.setFieldValue('activity_type_id', item);
					}}>
					{item.name}
				</span>
			</Button>
		</div>
	);
};
