import React, { useContext, useEffect, useState } from "react";
import Checks, { ChecksGroup } from "../bootstrap/forms/Checks";
import FormGroup from "../bootstrap/forms/FormGroup";
import Label from '../bootstrap/forms/Label';
import PropTypes from 'prop-types';
import AuthContext from "../../contexts/authContext";
import Spinner from "../bootstrap/Spinner";
import { useTranslation } from 'react-i18next';
import Icon from "../icon/Icon";



const RadioInput = ({ data, handelChange, currentFormValue }) => {

    const [value, setValue] = useState(false);
    const [options, setOptions] = useState(data?.options || []);
    const { userData } = useContext(AuthContext);
    const [spinner, setSpinner] = useState(false);
    const { t } = useTranslation(['translation', 'menu']);


    const handleChange = (e) => {
        setValue(e.target.value);

        handelChange(options.filter(option => option?.value == e.target.value)[0]?.nextStep || data?.nextStepData || false, options.filter(option => option?.value == e.target.value)[0]);

    }

    useEffect(() => {
        
        if (!!data?.options?.length) {
            setOptions(data?.options);
            const checkedOption = data?.options?.filter(option=>option?.checked);
            
            if(!!checkedOption?.length){
                setValue(checkedOption[0]?.value);
                handelChange(checkedOption[0]?.nextStep || data?.nextStepData || false, checkedOption[0]);
            }
            
        } else {
            if (userData && userData?.id && userData?.password) {
                setSpinner(data?.id);
                data?.fetchData(
                    userData?.password,
                    currentFormValue,
                    (optionsData) => {
                        setOptions(optionsData);
                        const checkedOption = optionsData?.filter(option=>option?.checked);
                        if(!!checkedOption?.length){
                            setValue(checkedOption[0]?.value);
                            handelChange(checkedOption[0]?.nextStep || data?.nextStepData || false, checkedOption[0]);
                        }
                        setSpinner(false);
                    }
                )
            }
        }
    },[true]);

    return (
        <FormGroup className={data.className || "col-12"}>
            {
                data?.label &&
                <Label>
                    {data?.label}
                </Label>
            }
            {

                !!options?.length&&!spinner ?
                    <ChecksGroup className={"radioGr " + (data.classGroup || "")}>

                        {

                            options?.map((option, idx) => {

                                return (
                                    <>
                                            {
                                                option?.icon&&
                                                <span
                                                    //icon={"AccessAlarm"}
                                                    //color={option?.color}
                                                    //size="3x"
                                                    style={{width:"10px",backgroundColor:option?.color,height:"10px"}}
                                                />
                                            }
                                            <Checks
                                                key={idx}
                                                className={'simulatorRadio '+option.className}
                                                type='radio'
                                                name={'flexRadioDefault' + data?.id}
                                                id={option?.label + option?.id + ""}
                                                label={option?.label}
                                                value={option?.value}
                                                disabled={option?.disabled || false}
                                                onChange={(e) => handleChange(e, option?.nextStep || false)}
                                                checked={option?.value == value}
                                                style={{ backgroundImage: `url(${option?.imageUrl})`,backgroundColor:option?.color }}
                                            />
                                    </>
                                    
                                )
                            })
                        }


                    </ChecksGroup> :
                    spinner==data?.id?
                    <Spinner
                        color="secondary"
                        size="10vh"
                        tag="div"
                        className='m-auto'
                    />:
                    <div>{t('is Empty')}</div>
            }

        </FormGroup>
        
    )
};

RadioInput.propTypes = {

    data: PropTypes.object.isRequired,
    handelChange: PropTypes.func.isRequired,
    currentFormValue: PropTypes.any.isRequired
};

export default RadioInput;