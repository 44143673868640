import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	EMPLOYEE_API_URI = '/hr.employee',
	USER_API_URI = '/res.users',
	JOB_API_URI = '/hr.job',
	CONTRAT_API_URI = '/hr.contract',
	PRIME_API_URI = '/employee.prime',
	LIMIT = 0;

const getAllemployees = (tokenUserCurent, fncSuccess, fnError = () => {}) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + EMPLOYEE_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data.elements || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};
export default getAllemployees;

export const getAllUsers = (tokenUserCurent, fncSuccess, fnError = () => {}) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + USER_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data.elements || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const getAllTechniciens = (tokenUserCurent, fncSuccess, fnError = () => {}, signal) => {
	var config = {
		method: 'get',
		url: `${
			BASIC_API_URL +
			process.env.REACT_APP_API_URL_FUNCTION_UTILS +
			'/tech?token=' +
			tokenUserCurent
		}`,
		// headers: {
		// 	Authorization: `Basic ${btoa(
		// 		process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
		// 	)}`,
		// 	'Content-Type': 'application/json',
		// },
		signal,
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const searchEmployee = (
	tokenUserCurent,
	filters,
	fnSuccess,
	fnErr = () => {},
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + EMPLOYEE_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};

	axios(config)
		.then(function (response) {
			fnSuccess(response?.data);
		})
		.catch(function (error) {
			console.log(error);
			fnErr(error);
		});
};

export const searchUsers = (
	tokenUserCurent,
	filters,
	fnSuccess,
	fnErr = () => {},
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + USER_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};

	axios(config)
		.then(function (response) {
			fnSuccess(response?.data);
		})
		.catch(function (error) {
			console.log(error);
			fnErr(error);
		});
};

export const updateEmployee = (
	tokenUserCurent,
	idEmployee,
	body,
	fnSuccess,
	fnError = () => {},
) => {
	var data = JSON.stringify(body);
	var config = {
		method: 'put',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + EMPLOYEE_API_URI + '/' + idEmployee,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const createemployee = async (
	tokenUserCurent,
	newEmployee,
	fnSucces,
	fnError = () => {},
) => {
	const data = JSON.stringify(newEmployee);
	var config = {
		method: 'post',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + EMPLOYEE_API_URI,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	console.log('search ', config);
	axios(config)
		.then(function (response) {
			fnSucces(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const deleteEmployee = (tokenUserCurent, idDelete, fcSuccess) => {
	var config = {
		method: 'delete',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + EMPLOYEE_API_URI + '/' + idDelete,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function () {
			fcSuccess();
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const uploadImageEmployee = (
	tokenUserCurent,
	idEmployee,
	image,
	fnc,
	fnError = () => {},
) => {
	let data = new FormData();
	//data.append('image_1920', file);

	console.log(image);
	data.append('image_1920', image);
	let config = {
		method: 'put',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			'/upload_binary' +
			EMPLOYEE_API_URI +
			'/' +
			idEmployee +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			fnError(error);
		});
};

export const createImageEmployee = (tokenUserCurent, image, fnc) => {
	let data = new FormData();
	data.append('image_1920', image);

	let config = {
		method: 'post',
		url:
			BASIC_API_URL +
			API_URL_WEB_ADMIN +
			EMPLOYEE_API_URI +
			'/' +
			'?token=' +
			tokenUserCurent,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			fnc(response);
		})
		.catch((error) => {
			console.log('error', error);
		});
};

export const getSingleEmployee = (
	tokenUserCurent,
	employeeId,
	fnSucces,
	fnError = () => {},
	signal = false,
) => {
	const config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + EMPLOYEE_API_URI + '/' + employeeId}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		signal,
	};
	axios(config)
		.then(function (response) {
			fnSucces(response?.data || []);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const getAllPostes = (tokenUserCurent, fncSuccess) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + JOB_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data.elements || []);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const createContrat = async (tokenUserCurent, newcontrat, fnSucces, fnErr) => {
	const data = JSON.stringify(newcontrat);
	var config = {
		method: 'post',
		url: BASIC_API_URL + API_URL_WEB_ADMIN + CONTRAT_API_URI,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	console.log('contrat ', config);
	axios(config)
		.then(function (response) {
			fnSucces(response.data);
		})
		.catch(function (error) {
			console.log(error);
			fnErr(error);
		});
};

export const getContrat = async (tokenUserCurent, contratId, fnSucces) => {
	var config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + CONTRAT_API_URI + '/' + contratId}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	console.log('contrat ', config);
	axios(config)
		.then(function (response) {
			fnSucces(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};
export const searchContrat = (tokenUserCurent, filters, fnSuccess, page = 1, limit = LIMIT) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + CONTRAT_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};

	axios(config)
		.then(function (response) {
			fnSuccess(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getAllPrimes = (tokenUserCurent, fncSuccess) => {
	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PRIME_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const postPrime = (tokenUserCurent, prime, fncSuccess, fnError = () => {}) => {
	console.log(prime);
	var config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PRIME_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: JSON.stringify(prime),
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const updatePrime = (tokenUserCurent, idPrime, prime, fncSuccess, fnError = () => {}) => {
	var config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PRIME_API_URI + '/' + idPrime}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: JSON.stringify(prime),
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response);
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const searchPrime = (tokenUserCurent, filters, fnSuccess, fnErr = () => {}) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PRIME_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
	};

	axios(config)
		.then(function (response) {
			fnSuccess(response?.data);
		})
		.catch(function (error) {
			console.log(error);
			fnErr(error);
		});
};
