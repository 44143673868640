import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Button from '../bootstrap/Button';
import moment from 'moment';
import { searchPaymentByInvoice, updatePaymentJustifyState } from '../../odooApi/paymentJustify';
import LoadSpinner from '../Spinner/LoadSpinner';
import Alert from '../bootstrap/Alert';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import { useContext, useEffect, useState } from 'react';
import COLORS from '../../common/data/colorDummyData';
import AuthContext from '../../contexts/authContext';
import { payFacture } from '../../odooApi/pay';
import { uploadAttachmentPayment } from '../../odooApi/accountPayment';
import ConfirmationPopup from '../PlanningCalendar/ConfirmationPopup';
import PaymentPopup from './PaymentPopup';
import { updateFacture } from '../../odooApi/accountMove';
import Icon from '../icon/Icon';
import Badge from '../bootstrap/Badge';
import { ImageIcon, convertIMG, convertURL, getReport } from '../../common/function/functionsUtils';
import DisplayImage from '../Activity/DisplayImage';
import SuiviPayementCard from './SuiviPaiement/SuiviPayementCard';
import { getAttachment } from '../../odooApi/voiture';

export default function PaymentJustifyTable({ invoiceId, Invoice, updateFactureContext }) {
	const { userData } = useContext(AuthContext);
	const [paymentData, setPaymentData] = useState();
	const [spinner, setSpinner] = useState(false);
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [paymentModalStatus, setPaymentModalStatus] = useState(false);
	const [groups, setGroups] = useState(false);
	const [selectedImage, setSelectedImage] = useState(false);
	const [popupImage, setPopupImage] = useState(false);
	const [data, setData] = useState({});
	const [isOpen, setIsOpen] = useState(false);

	const etat_paiement = [
		{
			id: 'Brouillon',
			title: 'Brouillon',
			color: COLORS.PRIMARY,
			icon: 'ErrorOutline',
		},
		{
			id: 'Valider',
			title: 'Valider',
			color: COLORS.SUCCESS,
			icon: 'DoneAll',
		},

		{
			id: 'Refuser',
			title: 'Refuser',
			color: COLORS.DANGER,
			icon: 'RemoveDone',
		},
	];

	const handelCreatePayment = (line, groups) => {
		setIsLoading(true);
		if (groups == 'Valider') {
			console.log("line", line)
			const payInfo = {
				amount: line?.montant,
				journal_id: line?.moyen_paiement?.id,
				invoice_ids: [invoiceId],
				activity_user_id: userData?.id,
				numero_cheque: line?.memo,
				date_encaissement: moment(line?.date_paiement).format("YYYY-MM-DD")
			};

			payFacture(
				userData?.password,
				payInfo,
				(result) => {
					const info = []
					if (line?.attachment) {
						convertIMG(
							getAttachment(userData?.password, line?.attachment?.id),
							'paiement_' + line?.source?.name,
							(file) => {
								uploadAttachmentPayment(
									userData?.password,
									result?.id,
									file,
									(data) => {
										console.log("data", data)

									},
									(error) => {
										setIsLoading(false);
										setError(error);
										formik.setFieldError('attachements', `Erreur : ${error}`);
									},
								);
							},
							(error) => {
								console.error('Erreur :', error);
							},
						);

					}
					// console.log(
					// 	'Invoice?.amount_residual - line?.montant == 0',
					// 	Invoice?.amount_residual,
					// 	line?.montant,
					// 	Invoice?.amount_residual - line?.montant == 0,
					// );
					updateFactureContext({
						...Invoice,
						accompte: 0,
						amount_residual: Invoice?.amount_residual - line?.montant,
						invoice_payment_state:
							Invoice?.amount_residual - line?.montant == 0
								? 'paid'
								: Invoice?.invoice_payment_state,
						state: 'posted',
					});
					updatePaymentJustifyState(
						userData?.password,
						{
							id: line?.id,
							etat: groups,
						},
						() => {
							updateFacture(
								userData?.password,
								{
									id: invoiceId,
									accompte: 0,
									state: 'posted',
									invoice_user_id: userData?.id,
									activity_user_id: userData?.id,
								},
								() => {
									setIsLoading(false);
									loadPaymentData();
								},
							);
						},
						(error) => {
							setError(error);
							setIsLoading(false);
						},
					);
				},
				(error) => {
					setIsLoading(false);
					setError(error);
				},
			);
		} else {
			updatePaymentJustifyState(
				userData?.password,
				{
					id: line?.id,
					etat: groups,
				},
				() => {
					updateFacture(
						userData?.password,
						{
							id: invoiceId,
							accompte: 0,
						},
						() => {
							setIsLoading(false);
							loadPaymentData();
						},
					);
				},
				(error) => {
					setIsLoading(false);
					setError(error);
				},
			);
		}
	};

	const loadPaymentData = () => {
		setSpinner(true);
		searchPaymentByInvoice(
			userData?.password,
			invoiceId,

			({ elements }) => {
				setPaymentData(elements);
				setSpinner(false);
			},
			(error) => {
				setError(error);
				setSpinner(false);
			},
		);
	};

	useEffect(() => {
		loadPaymentData();
	}, []);

	return (
		<>
			{/* <CardHeader>
				<CardLabel>
					<CardTitle></CardTitle>
				</CardLabel>
				<CardActions>
					<Button
						color='info'
						icon='CreditCard'
						isLight
						isLink={true}
						tag='a'
						target='_blank'
						onClick={() => setPaymentModalStatus(true)}>
						Nouveau paiement
					</Button>
				</CardActions>
			</CardHeader> */}
			{Invoice?.invoice_payment_state !== 'paid' && (
				<div className='row justify-content-end  mb-3'>
					<Button
						color='info'
						icon='CreditCard'
						isLight
						isLink={true}
						tag='a'
						target='_blank'
						className='w-auto'
						onClick={() => setPaymentModalStatus(true)}>
						Nouveau paiement
					</Button>
				</div>
			)}

			<table className='table table-modern table-hover'>
				<thead>
					<tr>
						<th>Date</th>
						<th>Montant</th>
						<th>Moyen de paiement</th>
						<th>Pièce jointe</th>
						{(userData?.role?.toLowerCase().includes('comptabilite') ||
							userData?.role?.toLowerCase().includes('admin')) && (
								<>
									<th>Détails</th>
									<th>Etat de paiement</th>
								</>

							)}
						{/* <th></th> */}
					</tr>
				</thead>
				{!spinner &&
					!!paymentData?.length &&
					paymentData?.map((line) => {
						return (
							<>
								<tbody>
									<tr key={line.id}
									>
										<td >
											<div>{moment(`${line.date}`).format('ll')}</div>
											<div>
												<small className='text-muted'>
													{moment(`${line.date}`).fromNow()}
												</small>
											</div>
										</td>
										<td >
											{line.montant &&
												line.montant?.toLocaleString('fr-FR', {
													style: 'currency',
													currency: 'EUR',
												})}
										</td>
										<td>
											<div className='fs-6 fw-bold'>
												{line?.moyen_paiement?.name}
											</div>
											<div className='text-muted'>
												<Icon icon='Label' />{' '}
												<small>{line?.user_id?.name}</small>
											</div>
										</td>
										<td>
											{/* <Badge
												className='cursor-pointer px-2 py-2 rounded-1'
												color={'dark'}
												isLight
												onClick={() => {}}>
												<Icon icon='AttachFile' size={'lg'}></Icon>
											</Badge> */}
											<Button
												color='light'
												icon={ImageIcon(line?.attachment?.mimetype)}
												size={'sm'}
												onClick={() => {
													setSelectedImage(line?.attachment);
													setPopupImage(true);
												}}></Button>
										</td>
										{(userData?.role?.toLowerCase().includes('comptabilite') ||
											userData?.role?.toLowerCase().includes('admin')) && (
												<>
													<td oclassName='cursor-pointer'><Button
														color='light'
														icon={'RemoveRedEye'}
														size={'sm'}
														onClick={() => {
															setData(line);
															setIsOpen(true);
														}} >voir</Button></td>
													<td>
														{line.etat == 'Brouillon' ? (
															<Dropdown>
																<DropdownToggle>
																	<Button
																		color={
																			etat_paiement?.find(
																				(item) =>
																					line.etat == item?.id,
																			)?.color
																		}
																		isLight
																		icon={
																			etat_paiement?.find(
																				(item) =>
																					line.etat == item?.id,
																			)?.icon
																		}
																		size={'sm'}
																		isDisable={line.etat == 'Valider'}>
																		{
																			etat_paiement?.find(
																				(item) =>
																					line.etat == item?.id,
																			)?.title
																		}
																	</Button>
																</DropdownToggle>
																<DropdownMenu>
																	{etat_paiement?.map((group) => (
																		<DropdownItem>
																			<Button
																				key={group.id}
																				color='link'
																				icon={group.icon}
																				onClick={() => {
																					setGroups({
																						line: line,
																						etat: group?.id,
																					});
																					setConfirm(true);
																				}}>
																				{group.title}
																			</Button>
																		</DropdownItem>
																	))}
																</DropdownMenu>
															</Dropdown>
														) : (
															<Badge
																className={`me-2 bg-l10-${line.etat == 'Valider'
																	? 'success'
																	: 'danger'
																	} text-${line.etat == 'Valider'
																		? 'success'
																		: 'danger'
																	} px-3 py-2 me-2 rounded-1`}
																isLight>
																<Icon
																	size={'lg'}
																	icon={
																		etat_paiement?.find(
																			(item) =>
																				item?.id == line?.etat,
																		)?.icon
																	}
																	color={
																		etat_paiement?.find(
																			(item) =>
																				item?.id == line?.etat,
																		)?.color
																	}></Icon>
																<span className='fw-semibold fs-6 ms-2 mt-5'>
																	{line.etat}
																</span>
															</Badge>
														)}
													</td>


												</>
											)}
									</tr>
								</tbody>
							</>
						);
					})}
			</table>

			{!!spinner ? (
				<LoadSpinner error={error} />
			) : (
				!paymentData?.length && (
					<Alert color='warning' isLight icon='Report' className='mt-3'>
						Aucun paiement.
					</Alert>
				)
			)}
			{!!confirm && (
				<ConfirmationPopup
					handleConfirm={() => handelCreatePayment(groups?.line, groups?.etat)}
					setOpen={setConfirm}
					title={groups?.etat + ' ce paiement ?' + groups?.line?.id}
				/>
			)}
			{paymentModalStatus && (
				<PaymentPopup
					setPaymentModalStatus={setPaymentModalStatus}
					amountResidual={Invoice?.amount_residual}
					invoiceId={invoiceId}
					Invoice={Invoice}
					loadPaymentData={loadPaymentData}
				/>
			)}
			{!!isOpen && (
				<SuiviPayementCard setIsOpen={setIsOpen} data={data} loadPaymentData={loadPaymentData} />
			)}
			{!!popupImage && <DisplayImage setSelectedImage={setPopupImage} file={selectedImage} />}
		</>
	);
}
