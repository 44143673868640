import { useContext, useState } from 'react';
import AuthContext from '../../contexts/authContext';
import { updateSingleMateriel } from '../../odooApi/atelier';
import Button from '../bootstrap/Button';
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardLabel,
	CardTitle,
} from '../bootstrap/Card';
import Modal, { ModalBody, ModalHeader } from '../bootstrap/Modal';
import Spinner from '../bootstrap/Spinner';
import Timeline, { TimelineItem } from '../extras/Timeline';
import { getColorNameWithIndex } from '../../common/data/enumColors';

export default function MaterielPopup({ setMateriel, line, type, loadAteliersData }) {
	const { userData } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);

	const handleSave = () => {
		line?.length > 0 &&
			line.map((item, index) => {
				const newMat = {
					id: item?.id,
					type_produit: type,
					state: 'en cours',
				};
				if (index == 0) {
					setIsLoading(true);
				}
				updateSingleMateriel(userData?.password, newMat, (result) => {
					if (index + 1 == line?.length) {
						setIsLoading(false);
						setMateriel(false);
						// setAtelier((prev) => {
						// 	const updatedAtelier = prev.map((item) => {

						// 		if (item.id === line) {
						// 			return {
						// 				...item,
						// 				cards: data?.elements || [],
						// 				total: data?.records?.total,
						// 			};	 
						// 		}

						// 		return item;
						// 	});

						// 	return updatedAtelier;
						// });

						loadAteliersData();
					}
				});
			});
	};

	return (
		<Modal setIsOpen={() => { }} isOpen={true} size={'md'} classNameMd='nv-2'>
			<ModalHeader
				setIsOpen={() => {
					setMateriel(false);
				}}>
				<CardLabel icon='Storefront' iconColor='primary'>
					<CardTitle>Matériel à préparer</CardTitle>
					{/* <CardSubTitle>
							<span className='text-muted'></span>
							veuillez confirmer la preparation de ce materiau
						</CardSubTitle> */}
				</CardLabel>
			</ModalHeader>
			<ModalBody className='px-2'>
				<Card shadow={'none'}>
					{/* <CardHeader>
						<CardLabel icon='Storefront' iconColor='primary'>
							<CardTitle>Matériel à préparer</CardTitle>
							<CardSubTitle>
								<span className='text-muted'></span>
								veuillez confirmer la preparation de ce materiau
							</CardSubTitle>
						</CardLabel>
					</CardHeader> */}
					<CardBody>
						<Timeline>
							{line?.length > 0 &&
								line.map((l, index) => (
									<TimelineItem
										label={l?.product?.name}
										color={getColorNameWithIndex(index)}>
										{l?.name}
									</TimelineItem>
								))}
						</Timeline>
					</CardBody>
					<CardFooter>
						<CardFooterRight>
							<Button color='primary' disabled={isLoading} onClick={handleSave}>
								{isLoading && <Spinner isSmall inButton />}
								{isLoading ? 'En cours' : 'Confirmer'}
							</Button>
						</CardFooterRight>
					</CardFooter>
				</Card>
			</ModalBody>
		</Modal>
	);
}
