import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	REACT_APP_API_URL_FUNCTION_UTILS = process.env.REACT_APP_API_URL_FUNCTION_UTILS,
	RAPPORT_INTERVENTION_API_URI = '/add.rapport.intervention',
	LIMIT = 50;

export const createRapportIntervention = (
	tokenUserCurent,
	newData,
	fnSucces,
	fnError = () => {},
) => {
	const data = JSON.stringify(newData);

	const config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + RAPPORT_INTERVENTION_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			//console.log(JSON.stringify(response.data));
			fnSucces(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};
