import { serverTimestamp } from "firebase/firestore"
import { firestore } from "../../apis/firebase/config"

const createNotification = (notification) => {
    /* {
        recieverMembers: [58,21],
        text: "aaaaaaaa",
        icon: "Notifications",
        color: "primary",
        link: "/plannig"
    } */
    firestore.collection('notifications').add({
        recieverMembers: notification?.recieverMembers||[],
        createdAt: serverTimestamp(),
        text:notification?.text||"",
        icon:notification?.icon||"Notifications",
        color:notification?.color||"info",
        link:notification?.link||"#",
        memberNotVu:notification?.recieverMembers||[]

    });/* .then((result) => {

        selectCurrentChat(result?.id);
    }); */
}

export default createNotification;