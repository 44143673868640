import * as React from 'react';

const SvgCustomRideau = (props) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 216 216'
        xmlSpace='preserve'
        width='1em'
        height='1em'
        className='svg-icon' fill='currentColor'
        {...props}>
        <path opacity={0.3} d="M70.1487 180.029H145.852C165.847 180.029 175.886 163.768 175.886 143.709V81.2857H40.1144V143.709C40.1144 163.768 50.1528 180.029 70.1487 180.029Z" />
        <path d="M155.507 35H60.4934C40.8005 35 27.7715 45.8436 27.7715 59.2199V63.0043C27.7715 69.6925 35.7536 75.1143 45.6001 75.1143H170.4C180.247 75.1143 188.229 69.6925 188.229 63.0043V59.2199C188.229 45.8436 175.199 35 155.507 35Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M141.231 85.9332H74.7693C70.1811 85.9332 66.4616 89.131 66.4616 93.0756V170.622C66.4616 174.566 66.4616 172.917 66.4616 177.509H149.539C149.539 173.428 149.539 174.566 149.539 170.622V93.0756C149.539 89.131 145.819 85.9332 141.231 85.9332ZM74.7693 81.8519C67.5593 81.8519 61.7144 86.8769 61.7144 93.0756V170.622C61.7144 176.82 61.7142 177.509 66.4616 177.509H149.539C154.286 177.509 154.286 176.82 154.286 170.622V93.0756C154.286 86.8769 148.441 81.8519 141.231 81.8519H74.7693Z" />
        <path d="M147.693 102.123H68.3067C64.0622 102.123 61.7144 100.047 61.7144 97.4972C61.7144 94.9472 64.0622 92.8657 68.3067 92.8657H147.693C151.928 92.8657 154.286 94.9472 154.286 97.4972C154.286 100.047 151.928 102.123 147.693 102.123Z" />
        <path d="M147.693 117.331H68.3067C64.0622 117.331 61.7144 115.255 61.7144 112.705C61.7144 110.155 64.0622 108.073 68.3067 108.073H147.693C151.928 108.073 154.286 110.155 154.286 112.705C154.286 115.255 151.928 117.331 147.693 117.331Z" />
        <path d="M147.693 132.538H68.3067C64.0622 132.538 61.7144 130.463 61.7144 127.913C61.7144 125.363 64.0622 123.281 68.3067 123.281H147.693C151.928 123.281 154.286 125.363 154.286 127.913C154.286 130.463 151.928 132.538 147.693 132.538Z" />
        <path d="M147.693 148.75H68.3067C64.0622 148.75 61.7144 146.675 61.7144 144.125C61.7144 141.575 64.0622 139.493 68.3067 139.493H147.693C151.928 139.493 154.286 141.575 154.286 144.125C154.286 146.675 151.928 148.75 147.693 148.75Z" />
        <path d="M147.693 164.962H68.3067C64.0622 164.962 61.7144 162.886 61.7144 160.336C61.7144 157.786 64.0622 155.705 68.3067 155.705H147.693C151.928 155.705 154.286 157.786 154.286 160.336C154.286 162.886 151.928 164.962 147.693 164.962Z" />
        <path d="M147.693 180.029H68.3067C64.0622 180.029 61.7144 177.953 61.7144 175.403C61.7144 172.853 64.0622 170.771 68.3067 170.771H147.693C151.928 170.771 154.286 172.853 154.286 175.403C154.286 177.953 151.928 180.029 147.693 180.029Z" />
    </svg>

)
export default SvgCustomRideau;