import React, { useState, useEffect, useContext } from 'react';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import Button from '../bootstrap/Button';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Label from '../bootstrap/forms/Label';
import Select from '../bootstrap/forms/Select';
import Spinner from '../bootstrap/Spinner';
import { useFormik } from 'formik';
import { createActivityType } from '../../odooApi/activites';
import StatusPopup from '../PlanningCalendar/StatusPopup';
import AuthContext from '../../contexts/authContext';
import ErrorPopup from '../extras/ErrorPopup';

export default function AddActivityType({ formikParent, setCategories, setisOpen }) {
	const [isLoading, setisLoading] = useState(false);
	const { userData } = useContext(AuthContext);

	const [error, setError] = useState();
	const formik = useFormik({
		initialValues: {
			name: '',
		},

		onSubmit: (values) => {
			setisLoading(true);
			createActivityType(
				userData?.password,
				values,
				(result) => {
					setCategories((prev) => [...prev, result]);
					formikParent?.setFieldValue('activity_type_id', result);
					setisOpen(false);
					loadData();
				},
				(error) => {
					setisLoading(false);
					setError(error);
				},
			);
		},
	});

	return (
		<Modal
			setIsOpen={() => setisOpen(false)}
			isOpen={true}
			size='sm'
			isScrollable
			classNameMd='nv-3'>
			<form onSubmit={formik.handleSubmit}>
				<ModalHeader className='px-4 pt-3 pb-0' setIsOpen={() => setisOpen(false)}>
					<div className='w-100 text-muted text-center'>
						<ModalTitle id='project-edit'> Créer un Tag</ModalTitle>
					</div>
				</ModalHeader>
				<ModalBody className='px-4'>
					<FormGroup id='name' label='Titre' isFloating>
						<Input onChange={formik.handleChange} value={formik.values.name} />
					</FormGroup>
				</ModalBody>
				<ModalFooter className='px-4 pb-4'>
					<Button color='warning' className='w-100' type='submit' isDisable={isLoading}>
						{isLoading && <Spinner isSmall inButton />}
						{isLoading ? 'En cours' : 'Sauvegarder'}
					</Button>
				</ModalFooter>
			</form>
			{!!error && (
				<ErrorPopup
					error={error}
					setOpen={setError}
				/>
			)}
		</Modal>
	);
}
