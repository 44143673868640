export interface ILang {
	[key: string]: {
		text: string;
		lng: 'fr-FR' | 'en-US' | 'de-DE' | 'tr-TR';
		icon: string;
	};
}

const LANG: ILang = {
	FR: {
		text: 'Français',
		lng: 'fr-FR',
		icon: 'CustomFrance',
	},
}

export const getLangWithKey = (key: ILang['key']['lng']): ILang['key'] => {
	// @ts-ignore
	return LANG[Object.keys(LANG).filter((f) => LANG[f].lng === key)];
};

export default LANG;
