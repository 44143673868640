import React, { useState } from 'react';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import PropTypes from 'prop-types';
import Icon from '../../icon/Icon';
import useOutsideClick from './useOutsideClick';
import ListGroup, { ListGroupItem } from '../../bootstrap/ListGroup';
import Spinner from '../../bootstrap/Spinner';

const SearchClient = ({
	value,
	name,
	onChange,
	className,
	label,
	options,
	setSelectItem = () => {},
	isTouched = null,
	isValid = null,
	onBlur = null,
	invalidFeedback = null,
	loading = false,
	handelSelectItem = () => {},
}) => {
	const [openListItems, setOpenListItems] = useState(false);
	const handelChange = (e) => {
		onChange(e);
		setOpenListItems(true);
	};
	const handelSelect = (item) => {
		handelSelectItem(item);

		setOpenListItems(false);
	};
	const handleClickOutside = () => {
		setOpenListItems(false);
	};

	const ref = useOutsideClick(handleClickOutside);
	return (
		<div ref={ref} className={className}>
			<FormGroup className={`autocomplete `} id={name} isFloating label={label}>
				<Input
					placeholder={label}
					onChange={handelChange}
					value={value}
					name={name}
					type='search'
					autoComplete='off'
					isTouched={isTouched}
					isValid={isValid}
					onBlur={onBlur}
					invalidFeedback={invalidFeedback}
				/>
				{openListItems &&
					(loading ? (
						<div id='myInputautocomplete-list' className='autocomplete-items'>
							<div className='body'>
								<div>
									<Spinner isSmall isGrow /> <Spinner isSmall isGrow />{' '}
									<Spinner isSmall isGrow />
								</div>
							</div>
						</div>
					) : (
						!!options?.length && (
							<div id='myInputautocomplete-list' className='autocomplete-items'>
								<div className='body'>
									{options?.map((item, idx) => (
										<div
											key={idx}
											onClick={() => {
												handelSelect(item);
											}}>
											<Icon color={'primary'} icon={'Person'} size='lg' />
											<span className='fw-bold me-2 ms-1'>{item?.name}</span>
											<Icon color={'primary'} icon={'Email'} size='lg' />
											<span className='me-2'>
												{item?.email || '--aucun--'}
											</span>
											<Icon color={'primary'} icon={'Place'} size='lg' />
											<span>
												{item?.street + ' ' + item?.zip + ' ' + item?.city}
											</span>
										</div>
									))}
								</div>
							</div>
						)
					))}
				{/* {!!options?.length && openListItems && (
					<div id='myInputautocomplete-list' className='autocomplete-items'>
						<div className='body'>
							{options?.map((item, idx) => {
								return (
									<div key={idx} id={item?.id} onClick={() => handelSelect(item)}>
										<Icon icon={item?.icon || 'AddLocation'} size='2x' />
										{item?.label}
									</div>
								);
							})}
						</div>
					</div>
				)} */}
			</FormGroup>
		</div>
	);
};

export default SearchClient;
