import formInputsTypes from '../../../simulatorConstant';
import TypeMesureLarg from './manuel';
import TypeMesureLargMot from './motorise';

export const MIN_WIDTH = 0;
export const PRODUCT_ID_AXE_MANUEL_TRADI = 4674;
export const PRODUCT_ID_AXE_MANUEL = 4674;
export const PRODUCT_ID_AXE_MOTORISE_TRADI = 118;
export const PRODUCT_ID_EQUERRE = 3481;
export const PRODUCT_ID_JAMBE = 3453;
export const PRODUCT_ID_COUSSINET = 3245;
export const PRODUCT_ID_PLAQUE = 7195;
export const CATEG_ID_TANDEM = 100;
export const CATEG_ID_AXE = 149;

const typeAxeVoletRoulant = {
	id: 'VR-TR-AXE',
	type: formInputsTypes?.RADIO_INPUT,
	label: "Selectionez Un Type d'Axe",
	name: 'typeAxe',
	nextStep: false,
	options: [
		{
			id: 'VR-TR-AXE-MTR',
			label: 'Axe Motorisé',
			value: 'axe motorisé volet roulant tradi',
			nextStep: TypeMesureLargMot,
		},
		{
			id: 'VR-TR-AXE-MN',
			label: 'Axe Manuel',
			value: 'axe manuel volet roulant tradi',
			nextStep: TypeMesureLarg,
		},
	],
};

export default typeAxeVoletRoulant;
