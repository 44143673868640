import React, { useState } from 'react';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../bootstrap/Popovers';
import { DateRange } from 'react-date-range';
import Button from '../../bootstrap/Button';
import moment from 'moment';
import 'moment/locale/fr';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import { useWindowSize } from 'react-use';
import Icon from '../../icon/Icon';

export default function FilterByDate({
	defaultDate,
	setFilterDataByDate,
	setStringFilterDataByDate,
	setAnalysisDate,
}) {
	moment.locale('fr');
	const { darkModeStatus } = useDarkMode();
	const [selectionRange, setselectionRange] = useState([
		{
			startDate: defaultDate?.startDate?new Date(defaultDate?.startDate):new Date(),
			endDate: defaultDate?.startDate?new Date(defaultDate?.endDate):new Date(),
			key: 'selection',
		},
	]);
	const [isOpenCalendar, setisOpenCalendar] = useState(false);
	const handelChangeDate = (param) => {
		setselectionRange(param);
		if (!!setFilterDataByDate) {
			if (param[0].startDate < param[0].endDate) {
				setFilterDataByDate([
					[
						'creationDate',
						'>=',
						`${moment(`${param[0].startDate}`).format('yyyy-MM-DD HH:mm:ss')}`,
					],
					'AND',
					[
						'creationDate',
						'<=',
						`${moment(`${param[0].endDate}`)
							.endOf('day')
							.format('yyyy-MM-DD HH:mm:ss')}`,
					],
				]);
			} else {
				setFilterDataByDate([
					'creationDate',
					'like',
					`${moment(`${param[0].startDate}`).format('yyyy-MM-DD HH:mm:ss')}`,
				]);
			}
		}
		if (!!setStringFilterDataByDate) {
			setStringFilterDataByDate(
				" AND creation_date >= '" +
					moment(param[0].startDate).format('yyyy-MM-DD HH:mm:ss') +
					"' AND creation_date <= '" +
					moment(param[0].endDate).endOf('day').format('yyyy-MM-DD HH:mm:ss') +
					"'",
			);
		}
		if (!!setAnalysisDate) {
			var time = "18:00";
			setAnalysisDate({
				startDate: moment(param[0].startDate).startOf("day").set("hour", 7).format('yyyy-MM-DD HH:mm:ss'),
				endDate: moment(param[0].endDate).startOf('day').set("hour", 19).format('yyyy-MM-DD HH:mm:ss'),
			});
		}
	};
	const windowsWidth = useWindowSize().width;

	return (
		<Dropdown isOpen={isOpenCalendar} setIsOpen={setisOpenCalendar}>
			<DropdownToggle hasIcon={false}>
				{!(windowsWidth < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)) ? (
					<Button
						color={darkModeStatus ? 'light' : 'dark'}
						isLight
						className='calender-btn pe-2'
						icon='CalendarToday'>
						{selectionRange[0].startDate < selectionRange[0].endDate
							? `${moment(`${selectionRange[0].startDate}`).format('LL')} -
							  ${moment(`${selectionRange[0].endDate}`).format('LL')}`
							: moment(`${selectionRange[0].startDate}`).format('LL')}
					</Button>
				) : (
					<Button
						color={darkModeStatus ? 'light' : 'dark'}
						isLight
						// className='calender-btn'
						icon='CalendarToday'></Button>
				)}
			</DropdownToggle>
			<DropdownMenu isCloseAfterLeave={false}>
				<DateRange
					editableDateInputs={true}
					onChange={(item) => handelChangeDate([item.selection])}
					moveRangeOnFirstSelection={false}
					ranges={selectionRange}
				/>
			</DropdownMenu>
		</Dropdown>
	);
}
