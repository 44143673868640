import axios from 'axios';

const 
  BASIC_API_URL = "/odoo",
  API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
  SEARCH_METHOD_API=process.env.REACT_APP_SEARCH_METHOD_API,
  PRODUCT_ATTRIBUTE_API_URI="/product.attribute",
  LIMIT=0
;

export const searchProductAttribute = (tokenUserCurent,filters,fnSuccess,page=1,limit=LIMIT) => {
    var dataFilter = JSON.stringify({
        "kwargs": {
            "domain": filters,
            "limit":limit,
            "page":page
        }
      });
      
      var config = {
        method: 'patch',
        url: `${BASIC_API_URL+API_URL_WEB_ADMIN+PRODUCT_ATTRIBUTE_API_URI+SEARCH_METHOD_API}`,
        headers: { 
          'Authorization': `Basic ${btoa(process.env.REACT_APP_AUTH_OPEN_API_DB+":"+tokenUserCurent)}`, 
          'Content-Type': 'application/json', 
          
        },
        data : dataFilter
      };
      
      axios(config)
      .then(function (response) {
        fnSuccess(response?.data);
        
      })
      .catch(function (error) {
        console.log(error);
      });
}

export const getSingleattribute = (tokenUserCurent,attributeId,fnSucces) => {
 
    
  const config = {
    method: 'get',
    url: `${BASIC_API_URL+API_URL_WEB_ADMIN+PRODUCT_ATTRIBUTE_API_URI+"/"+attributeId}`,
    headers: { 
      'Authorization': `Basic ${btoa(process.env.REACT_APP_AUTH_OPEN_API_DB+":"+tokenUserCurent)}`, 
      'Content-Type': 'application/json', 
      
    }
  };
  axios(config)
  .then(function (response) {
    console.log("elelelelel",response?.data)
    fnSucces(response?.data ||[])
  })
  .catch(function (error) {
    console.log(error);
  });
}