import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';




const DevisLinesContext = createContext([]);


export const DevisLinesContextProvider= ({ children }) => {
	const [devisLines, setDevisLines] = useState([]);
	
	/* useEffect(() => {
		localStorage.setItem('facit_authUser', userData?.id&&userData?.username?JSON.stringify(userData):"{}");
	}, [devisLines]); */

	const value = useMemo(
		() => ({
			devisLines,
			setDevisLines
		}),
		[devisLines],
	);
	return <DevisLinesContext.Provider value={value}>{children}</DevisLinesContext.Provider>;
};
DevisLinesContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default DevisLinesContext;
