import formInputsTypes from "../../simulatorConstant";
import TablierIcone from '../../../../../assets/img/tablier-icone.svg';
import AxeIcone from '../../../../../assets/img/axe-icone.svg';
import vRComplet from "./vRComplet";
import typeAxeVoletRoulant from "./axe";
import tablierData from "./tablier";
import VoletIcone from '../../../../../assets/img/volet-icone.svg';

const voletRoulantReno = {
    id:"VR-TI-TR",
    type:formInputsTypes?.RADIO_INPUT,
    label:"Selectionez Un Produit",
    name:"product",
    classGroup:"SProduit",
    nextStep:false,
    options:[
        {
            id:"VR-TR-VRC",
            label:"V.R Complet",
            value:"Volet roulant réno complet",
            imageUrl: VoletIcone,
            nextStep:vRComplet
        },
        {
            id:"VR-TR-TAB",
            label:"Tablier",
            value:"Tablier Volet roulant réno",
            imageUrl: TablierIcone,
            nextStep:tablierData
        },
        {
            id:"VR-TR-AXE",
            label:"Axe",
            value:"VR-TR-AXE",
            imageUrl: AxeIcone,
            nextStep:typeAxeVoletRoulant
        }
    ]
    
}

export default voletRoulantReno;