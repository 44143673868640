import React, { useEffect, useState } from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTabItem,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Select from '../../../../components/bootstrap/forms/Select';
import Checks, { ChecksGroup } from '../../../../components/bootstrap/forms/Checks';
import { useFormik } from 'formik';
import Input from '../../../../components/bootstrap/forms/Input';
import { AcceptPaiementStripeMail, GetPaiementStripeMail } from '../../../../odooApi/paiementMail';
import { useParams } from 'react-router-dom';
import Icon from '../../../../components/icon/Icon';
import Label from '../../../../components/bootstrap/forms/Label';

export default function ValidationPaiement({ setAmount, setpaiementPopup, amount }) {
	const { id } = useParams(0);
	const { token } = useParams(0);
	const { model } = useParams(0);
	const [montant, setMontant] = useState(30);
	const [amountResidual, setAmountResidual] = useState();
	const [minAmount, setMinAmount] = useState();
	const percentList = [30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95];

	useEffect(() => {
		!!id &&
			GetPaiementStripeMail(
				token,
				model,
				id,

				({ data }) => {
					setAmountResidual(data[0]?.amount_total);
					setMinAmount((data[0]?.amount_total * 30) / 100);
					formik.setFieldValue('amount', (data[0]?.amount_total * 30) / 100);
				},
				(error) => {},
			);
	}, [id]);

	const formik = useFormik({
		initialValues: {
			amount: 0,
		},
		onSubmit: (values) => {},
	});
	const handelBlurAmount = (e) => {
		formik.setFieldValue(
			'amount',
			(e.target.value < minAmount && minAmount) ||
				(e.target.value > amountResidual && amountResidual) ||
				e.target.value,
		);
		setAmount(formik?.values?.amount);
	};

	useEffect(() => {
		if (montant >= 30) {
			const amount = amountResidual * (montant / 100);
			setAmount(amount);
		}
	}, [montant]);
	return (
		<Modal isOpen={true} setIsOpen={() => {}} isCentered size={'sm'} classNameMd='nv-3'>
			<ModalHeader>
				<div className='w-100 text-info text-center d-flex justify-content-center'>
					<Icon icon='CreditCard' size={'2x'}></Icon>
					<ModalTitle className='fs-4 fw-semibold'> Paiement</ModalTitle>
				</div>
				{/* <ModalTitle>{'Enregistrez le montant que vous souhaitez payer'}</ModalTitle> */}
			</ModalHeader>
			<ModalBody className='px-4'>
				<div className='fs-5 text-center'>
					Le montant total est{' '}
					<strong>
						{amountResidual?.toLocaleString('fr-FR', {
							style: 'currency',
							currency: 'EUR',
						})}
					</strong>
				</div>
				<div className='mt-4 mb-2'>Vous avez la possibilité de payer :</div>

				<div className='row g-4'>
					<ChecksGroup>
						<Checks
							type='radio'
							id='normal'
							label='La totalité du montant.'
							name='accompte'
							value='normal'
							onChange={formik.handleChange}
							checked={formik.values.accompte == 'normal' && true}
						/>
						<Checks
							type='radio'
							id='pourcentage'
							label='Un acompte %.'
							name='accompte'
							value='pourcentage'
							onChange={formik.handleChange}
							checked={formik.values.accompte == 'pourcentage' && true}
						/>
						{/* <Checks
									type='radio'
									id='pourcentage'
									label='Acompte'
									name='accompte'
									value='pourcentage'
									onChange={formik.handleChange}
									checked={formik.values.accompte == 'pourcentage' && true}
								/> */}
						<Checks
							type='radio'
							id='montant'
							label='Un acompte spécifique.'
							name='accompte'
							value='montant'
							onChange={formik.handleChange}
							checked={formik.values.accompte == 'montant' && true}
						/>
					</ChecksGroup>
					{formik?.values?.accompte == 'pourcentage' && (
						<div className='row mt-4 d-flex '>
							<FormGroup label='Acompte (%)' className='col-6'>
								<Select
									id='percent'
									ariaLabel='pourcentage'
									placeholder='pourcentage'
									list={percentList?.map((item) => {
										return { value: item, text: item + '%' };
									})}
									onChange={(event) => setMontant(event.target.value)}
									value={montant}
								/>
							</FormGroup>
							<div className='col-6 text-center'>
								<Label>Montant (€) </Label>
								<div className=' fw-semibold'>
									{amount?.toLocaleString('fr-FR', {
										style: 'currency',
										currency: 'EUR',
									})}
								</div>
							</div>
						</div>
					)}
					{formik?.values?.accompte == 'montant' && (
						<FormGroup id='amount' label='Acompte'>
							<small className='text-muted'> (minimum 30%)</small>
							<Input
								onChange={formik.handleChange}
								onBlur={handelBlurAmount}
								value={formik.values.amount}
								name='amount'
								type='number'
								min={minAmount}
								max={amountResidual}
								autoComplete='off'
							/>
						</FormGroup>
					)}

					<div className='col-12'>
						<Button
							color={'info'}
							className='w-100'
							// size='lg'
							isDisable={!formik.values.accompte}
							onClick={() => {
								setpaiementPopup(false);
							}}>
							Enregistrer
						</Button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
}
