import axios from 'axios';

const BASIC_API_URL = '/odoo',
	API_URL_WEB_ADMIN = process.env.REACT_APP_API_URL_WEB_ADMIN,
	SEARCH_METHOD_API = process.env.REACT_APP_SEARCH_METHOD_API,
	FUNCTION_UTILS = process.env.REACT_APP_API_URL_FUNCTION_UTILS,
	FABRICATION_API_URI = '/mrp.production',
	NOMENCLATURE_API_URI = '/mrp.bom',
	PRODUCTION_API_URI = '/production',
	LIMIT = 0;
const getAllFabrications = (
	tokenUserCurent,
	fncSuccess,
	fnError = () => { },
	page = 1,
	limit = LIMIT) => {

	var config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + FABRICATION_API_URI}${limit ? '?limit=' + limit : ''}${page ? '&page=' + page : ''}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
		},
	};

	axios(config)
		.then(function (response) {
			fncSuccess(response.data || []);
		})
		.catch(function (error) {
			console.log(error);
			fnError(error);
		});
};

export default getAllFabrications;

export const searchFabrications = (
	tokenUserCurent,
	filters,
	fnSuccess,
	fnError = () => { },
	signal,
	page = 1,
	limit = LIMIT,
) => {
	var dataFilter = JSON.stringify({
		kwargs: {
			domain: filters,
			limit: limit,
			page: page,
			order: 'date_planned_start desc',
		},
	});

	var config = {
		method: 'patch',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + FABRICATION_API_URI + SEARCH_METHOD_API}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: dataFilter,
		signal,
	};
	axios(config)
		.then(function (response) {
			fnSuccess(response.data || []);
			return;
		})
		.catch(function (error) {
			fnError(error);
		});
};
export const getSingleProduction = (tokenUserCurent, Id, fnSucces) => {
	const config = {
		method: 'get',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + FABRICATION_API_URI + '/' + Id}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
	};

	axios(config)
		.then(function (response) {
			fnSucces(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};
export const updateSingleProduction = (tokenUserCurent, production, fnSucces, fnError = () => { }) => {
	const LineString = JSON.stringify(production);

	const config = {
		method: 'put',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + FABRICATION_API_URI + '/' + production?.id}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: LineString,
	};

	axios(config)
		.then(function () {
			fnSucces();
		})
		.catch(function (error) {
			fnError(error);
		});
};

export const createproduction = (tokenUserCurent, newData, fnSucces) => {
	const data = JSON.stringify(newData);
	const config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + FABRICATION_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			console.log(response?.data);
			fnSucces(response?.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const createNomenclature = (tokenUserCurent, newData, fnSucces) => {
	const data = JSON.stringify(newData);

	const config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + NOMENCLATURE_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			fnSucces(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const createProductionFromDevis = (
	tokenUserCurent,
	newData,
	fnSucces,
	fnError = () => { },
) => {
	const data = JSON.stringify(newData);
	var config = {
		method: 'post',
		url: `${BASIC_API_URL + API_URL_WEB_ADMIN + PRODUCTION_API_URI}`,
		headers: {
			Authorization: `Basic ${btoa(
				process.env.REACT_APP_AUTH_OPEN_API_DB + ':' + tokenUserCurent,
			)}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	axios(config)
		.then(function (response) {
			fnSucces(response.data);
		})
		.catch(function (error) {
			fnError(error);
		});
};
