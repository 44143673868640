import { serverTimestamp } from "firebase/firestore";
import { firestore } from "../../apis/firebase/config";

export const AUTH_STATES = {
    login: "LOGIN",
    requestLogin: "REQUEST_LOGIN",
    acceptedLogin: "ACCEPTED_LOGIN",
    logout: "LOGOUT"
}

const createOrUpdateDocDeviceAuth = (docId, deviceAuth, fnSuc = () => { }) => {
    const docRef = firestore
        .collection("devicesAuth")
        .doc(docId + "");
    docRef.get()
        .then((doc) => {
            if (doc.exists) {
                // Document already exists
                docRef.update({
                    ...deviceAuth,
                    loginDate: serverTimestamp()
                }).then(() => {
                    fnSuc();
                });
            } else {
                // Document does not exist, create a new one
                return docRef.set({
                    ...deviceAuth,
                    loginDate: serverTimestamp()
                }).then(() => {
                    fnSuc();
                });
            }
        })
        .catch((error) => {
            console.error('Error checking document existence:', error);
        });
    
}

export default createOrUpdateDocDeviceAuth;