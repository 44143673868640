import formInputsTypes from '../simulatorConstant';

import VoletIcone from '../../../../assets/img/volet-icone.svg';
import RideauIcone from '../../../../assets/img/rideau-icone.svg';
import StoreBanneIcon from '../../../../assets/img/store-banne.svg';

import deposeStore from './store';
import OuvertureCoffreVR from './volet';
import OuvertureCoffreRM from './rideau';

const MainOuvreData = {
	id: 'MO',
	type: formInputsTypes?.RADIO_INPUT,
	label: 'Overture coffre',
	name: 'category',
	className: 'col-12',
	classGroup: 'SCategory',
	nextStep: false,
	options: [
		{
			id: 'VR1',
			label: 'Volet Roulant',
			value: 'VR1',
			imageUrl: VoletIcone,
			nextStep: OuvertureCoffreVR,
			lst_price: 0,
		},
		{
			id: 'SB1',
			label: 'Store banne',
			value: 'SB1',
			imageUrl: StoreBanneIcon,
			nextStep: deposeStore,
			lst_price: 0,
		},
		{
			id: 'RM1',
			label: 'Rideau métallique',
			value: 'RM',
			imageUrl: RideauIcone,
			nextStep: OuvertureCoffreRM,
			lst_price: 0,
		},
	],
};

export default MainOuvreData;
