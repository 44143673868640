import React, { useContext, useEffect, useState } from 'react';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import FormGroup from '../bootstrap/forms/FormGroup';
import Label from '../bootstrap/forms/Label';
import PropTypes from 'prop-types';
import AuthContext from '../../contexts/authContext';
import Spinner from '../bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import Icon from '../icon/Icon';

const CheckBoxInput = ({ data, handelChange, currentFormValue }) => {
	const [values, setValues] = useState([]);
	const [options, setOptions] = useState(data?.options || []);
	const { userData } = useContext(AuthContext);
	const [spinner, setSpinner] = useState(false);
	const { t } = useTranslation(['translation', 'menu']);

	//console.log('valuesvaluesvalues', values);
	const handleChange = (e) => {
		const newValues = values.includes(e.target.value)
			? [...values.filter((value) => value != e.target.value)]
			: [...values, e.target.value];

		setValues(newValues);
		handelChange(
			data?.nextStepData || false,
			options.filter((option) => newValues.includes(option.value)),
		);
	};
	useEffect(() => {
		if (!!data?.options?.length) {
			setOptions(data?.options);
			const checkedOption = data?.options?.filter((option) => option?.checked);

			if (!!checkedOption?.length) {
				setValues(checkedOption[0]?.value);
				handelChange(
					checkedOption[0]?.nextStep || data?.nextStepData || false,
					checkedOption[0],
				);
			}
		} else {
			if (userData && userData?.id && userData?.password) {
				setSpinner(data?.id);
				data?.fetchData(userData?.password, currentFormValue, (optionsData) => {
					setOptions(optionsData);
					const checkedOption = optionsData?.filter((option) => option?.checked);
					if (!!checkedOption?.length) {
						setValues(checkedOption[0]?.value);
						handelChange(
							checkedOption[0]?.nextStep || data?.nextStepData || false,
							checkedOption[0],
						);
					}
					setSpinner(false);
				});
			}
		}
	}, [true]);

	return (
		<FormGroup className={data.className || 'col-12'}>
			{data?.label && <Label>{data?.label}</Label>}
			{!!options?.length && !spinner ? (
				<ChecksGroup className={'checkGr ' + (data.classGroup || '')}>
					{options?.map((option, idx) => {
						return (
							<Checks
								key={idx}
								type='checkbox'
								name={'flexCheckDefault' + data?.id}
								id={option?.label + option?.id + ''}
								label={option?.label}
								value={option?.value}
								isInline={option?.isInline}
								className={option?.className}
								// disabled={option?.disabled || false}
								onChange={(e) => {
									handleChange(e, option?.nextStep || false);
								}}
								checked={values.includes(option?.value)}
							/>
						);
					})}
				</ChecksGroup>
			) : spinner == data?.id ? (
				<Spinner color='secondary' size='10vh' tag='div' className='m-auto' />
			) : (
				<div>{t('is Empty')}</div>
			)}
		</FormGroup>
	);
};

CheckBoxInput.propTypes = {
	data: PropTypes.object.isRequired,
	handelChange: PropTypes.func.isRequired,
	currentFormValue: PropTypes.any.isRequired,
};

export default CheckBoxInput;
