import React from 'react';
import Label from '../bootstrap/forms/Label';
import Icon from '../icon/Icon';

export default function SingleAttahcement({ src, index, name, deleteImage, children }) {
	return (
		<div
			key={index}
			className='
           
            shadow border-0 rounded-2 cursor-pointer me-3'
			style={{ height: '200px', width: '150px' }}>
			<div
				onClick={() => {
					window.open(src, '_blank');
				}}
				style={{
					position: 'relative',
					height: '70%',
				}}>
				{children}
			</div>

			<div
				className='d-flex align-items-center justify-content-between mt-2'
				style={{ height: '30%' }}>
				<Label
					className='cursor-pointer'
					onClick={() => {
						window.open(src, '_blank');
					}}
					style={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						width: '80%',
					}}>
					{name}
				</Label>
				{!!deleteImage && (
					<Icon
						size={'2x'}
						icon='Delete'
						color='danger'
						onClick={() => deleteImage(index)}></Icon>
				)}
			</div>
		</div>
	);
}
