import axios from 'axios';

const REMINDER_TELEPHONY_MICROSERVICE_API_URL =
	process.env.REACT_APP_REMINDER_TELEPHONY_MICROSERVICE_API_URL;

const LINES_API_URI = '/phones';

export const getAllLines = async (tokenUserCurent, fn, fnErr) => {
	var config = {
		method: 'get',
		url: '/reminder' + REMINDER_TELEPHONY_MICROSERVICE_API_URL + LINES_API_URI,
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
		},
	};

	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			fnErr(error);
			console.log(error);
		});
};

const searchLines = (tokenUserCurent, filterData, fn, fnError = () => { }, signal) => {
	var config = {
		method: 'patch',
		url: '/reminder' + REMINDER_TELEPHONY_MICROSERVICE_API_URL + LINES_API_URI + '/search',
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
			'Content-Type': 'application/json',
		},
		data: JSON.stringify(filterData),
		signal: signal
	};

	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};
export default searchLines;
export const getSingleLine = async (tokenUserCurent, idLine, fn) => {
	var config = {
		method: 'get',
		url: '/reminder' + REMINDER_TELEPHONY_MICROSERVICE_API_URL + LINES_API_URI + '/' + idLine,
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
		},
	};

	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const updateLine = async (tokenUserCurent, idLine, oldLine, fn) => {
	const data = JSON.stringify(oldLine);
	const config = {
		method: 'put',
		url: '/reminder' + REMINDER_TELEPHONY_MICROSERVICE_API_URL + LINES_API_URI + '/' + idLine,
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const getUserLines = async (tokenUserCurent, userId, fn, fnErr = () => { }) => {
	var config = {
		method: 'get',
		url:
			'/reminder' +
			REMINDER_TELEPHONY_MICROSERVICE_API_URL +
			LINES_API_URI +
			'/user?userId=' +
			userId,
		headers: {
			Authorization: `Bearer ${tokenUserCurent}`,
		},
	};

	axios(config)
		.then(function (response) {
			fn(response.data);
		})
		.catch(function (error) {
			console.log(error);
			fnErr(error);
		});
};
